import {
  Card,
  Check,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  Radio,
  Selector,
  Table,
  TorStatus,
  ArrowCollapse,
  ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Modal,
  SearchNameModal,
} from 'components/Modal';
import {
  TorEditCancelStatusConstant,
  TorStatusConstant,
} from 'constant/PreProcumentTorStatus';
import {
  IFile,
  ItemModel,
} from 'models';
import {
  AppointPreProcurement,
  committeeMiddlePriceModel,
  CommitteeTor,
  Financial,
  FineRate,
  middlePriceSourceModel,
  Objec,
  Parcel,
  TorWorkPhase,
  Qualification,
  TorDetailModel,
  TorDocumentModel,
  TorPaymentTermPeriodModel,
} from 'models/PP/TorModels';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import {
  FaCircle,
  FaFilePdf,
  FaHistory,
  FaPlus,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router';
import { shareValue, documentTemplate } from 'services';
import PreProcurementTorServices from 'services/PP/PreProcurementTorServices';
import {
  HttpStatusCode,
  submitForm,
  THCurrency,
  useAppContext,
  ValidatePattern,
} from 'utils';
import toast from 'utils/toast';
import { nullObject } from '../../../utils/helper';
import { dataSearchName as dataSearchNameProvide, defaultQualificationValues } from './data';
import { HistoryModal } from './Modal/HistoryModal';
import { Context } from './step';
import UploadFile from './UploadFile';
import { TorTemplateCode } from 'utils/constants/torTemplateCodeEnum';
import { InputNumber } from 'components/Controls/InputNumber';
import { scrollToTop } from 'utils/scroll';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type Loader = {
  departmentDDL: ItemModel[],
  supplyMethodDDL: ItemModel[],
  supplyMethodTypeDDL: ItemModel[],
  periodUnitDDL: ItemModel[],
  unitOfMeasureDDL: ItemModel[],
  deliveryCountUnitDDL: ItemModel[],
  documentTemplateDDL: ItemModel[],
  maintenancePeriodTypeDDL: ItemModel[],
};

const HasCommitteeMAAndSupervisor = [
  {
    label: 'มี',
    value: 'true',
  },
  {
    label: 'ไม่มี',
    value: 'false',
  },
];

const HasBond = [
  {
    label: 'มี',
    value: 'true',
  },
  {
    label: 'ไม่มี',
    value: 'false',
  },
];

function Step1({ onClickNext, onClickBack }: Props) {
  const { data, setData, readonly, setReadonly } = useContext(Context);
  const [torData, setTorData] = useState<TorDetailModel>({} as TorDetailModel);
  const { departmentDDL, supplyMethodDDL, supplyMethodTypeDDL, periodUnitDDL, unitOfMeasureDDL, deliveryCountUnitDDL, documentTemplateDDL, maintenancePeriodTypeDDL } = useLoaderData() as Loader;
  const [show, setShow] = useState(false);
  const [showProvide, setShowProvide] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [openCollapse3, setOpenCollapse3] = useState(true);
  const [openCollapse4, setOpenCollapse4] = useState(true);
  const [openCollapse5, setOpenCollapse5] = useState(true);
  const [openCollapse6, setOpenCollapse6] = useState(true);
  const [appointPreProcurement, setAppointPreProcurement] = useState<AppointPreProcurement>({} as AppointPreProcurement);
  const [committeeTors, setCommitteeTors] = useState<CommitteeTor[]>([]);
  const [committeeMiddlePrices, setCommitteeMiddlePrices] = useState<committeeMiddlePriceModel[]>([]);
  const [objectives, setObjectives] = useState<Objec[]>([]);
  const [parcels, setParcels] = useState<Parcel[]>([]);
  const [qualifications, setQualifications] = useState<Qualification[]>([]);
  const [financials, setFinancials] = useState<Financial[]>([]);
  const [workPhases, setWorkPhases] = useState<TorWorkPhase[]>([]);
  const [paymentTermPeriod, setPaymentTermPeriod] = useState<TorPaymentTermPeriodModel[]>([]);
  const [currentPath, setCurrentPath] = useState('preprocurement-tor/detail');
  const [files, setFiles] = useState<IFile[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [posotionOnBoardItems, setPosotionOnBoardItems] = useState<ItemModel[]>([]);
  const [middlePriceSetDate, setMiddlePriceSetDate] = useState<Date>();
  const [middlePriceSources, setMiddlePriceSources] = useState<middlePriceSourceModel[]>([]);
  const [isHasMiddlePrice, setIsHasMiddlePrice] = useState(false);
  const { userId } = useAppContext();
  const [isDraftTorPerson, setIsDraftTorPerson] = useState(false);
  const [supplyMethod, setSupplyMethod] = useState('');
  const [documentTemplateCode, setDocumentTemplateCode] = useState('');
  const [documentTemplateDescription, setDocumentTemplateDescription] = useState('');
  const [supplyMethodType, setSupplyMethodType] = useState('');
  const [supplyMethodSpecialType, setSupplyMethodSpecialType] = useState('');
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);
  const [deliveryUnit, setDeliveryUnit] = useState('');
  const [isMA, setIsMA] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);

    setCurrentPath(location.pathname.replace(paramId, ''));

    getPositionOnBoardAsync();
  }, []);

  useEffect(() => {
    if (!nullObject(data)) {
      setTorData(data);
      if (data.appointPreProcurement) {
        setAppointPreProcurement(data.appointPreProcurement);
        if (data.appointPreProcurement.committeeTors) {
          setCommitteeTors(data.appointPreProcurement.committeeTors);
          const isCanSave = data.appointPreProcurement.committeeTors.filter((x) => x.committeeUserId === userId).length > 0;
          setIsDraftTorPerson(isCanSave);
          if (!isCanSave && (data.status === TorStatusConstant.Draft || data.status === TorStatusConstant.DraftTor || data.status === TorStatusConstant.Rejected)) {
            toast.warn('ท่านไม่ใช่บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน (TOR)');
            setReadonly(true);
          } else {
            setReadonly(false);
          }
        }

        // if (data.appointPreProcurement.committeeMiddlePrices) {
        //   setCommitteeMiddlePrices(data.appointPreProcurement.committeeMiddlePrices);
        // }

        setIsHasMiddlePrice(data.appointPreProcurement.planBudget > 100000);
      }

      if (data.isMA) {
        setIsMA(data.isMA)
      } else {
        setIsMA(false)
      }

      if (data.planBudget) {
        setIsHasMiddlePrice(data.planBudget > 100000);
      }

      if (data.objectives && (!objectives || objectives.length === 0)) {
        setObjectives(data.objectives);
      }

      if (data.parcels && (!parcels || parcels.length === 0)) {
        setParcels(data.parcels);
      }

      if (data.workPhases && (!workPhases || workPhases.length === 0)) {
        setWorkPhases(data.workPhases);
      }

      if (data.paymentTermPeriods && (!paymentTermPeriod || paymentTermPeriod.length === 0)) {
        setPaymentTermPeriod(data.paymentTermPeriods);
      }

      if (data.deliveryUnit && data.deliveryUnit !== '') {
        setDeliveryUnit(data.deliveryUnit);
      } else {
        setDeliveryUnit('day');
      }

      if (data.qualifications && (!qualifications || qualifications.length === 0)) {
        if (data.qualifications.length > 0) {
          setQualifications(data.qualifications);
        } else {
          setQualifications(getDefaultQualification());
        }
      }

      if (data.financials && (!financials || financials.length === 0)) {
        setFinancials(data.financials);
      }

      if (data.documents) {
        handleSetFile(data.documents);
      }

      setIsDisabled(data.isCancel ||
        !(
          data.status === TorStatusConstant.Draft ||
          data.status === TorStatusConstant.DraftTor ||
          data.status === TorStatusConstant.Rejected
        ));

      if (data.supplyMethod && !supplyMethod) {
        setSupplyMethod(data.supplyMethod);
        getSupplyMethodSpecialType(data.supplyMethod);
      }

      if (data.supplyMethodType && !supplyMethodType) {
        setSupplyMethodType(data.supplyMethodType);
      }

      if (data.supplyMethodSpecialType && !supplyMethodSpecialType) {
        setSupplyMethodSpecialType(data.supplyMethodSpecialType);
      }

      if (data.documentTemplateCode) {
        onChangeDocumentTemplateCode(data.documentTemplateCode);
      }
    }
  }, [data]);

  const getDefaultQualification = () => {
    const newQulifications: Qualification[] = [];
    for (let index = 0; index < defaultQualificationValues.length; index++) {
      const newData: Qualification = {
        id: undefined,
        torId: torData.id,
        description: defaultQualificationValues[index],
        sequence: index + 1,
        isDefault: true,
      };
      newQulifications.push(newData);
    }
    return newQulifications;
  };

  const getPositionOnBoardAsync = async () => {
    const { data, status } = await shareValue.getPositionOnBoardListAsync();

    if (status === HttpStatusCode.OK) {
      const positionOnBoards: ItemModel[] = data;
      setPosotionOnBoardItems(positionOnBoards);
    }
  };

  const onChangeBudget = (value: number) => {
    setIsHasMiddlePrice(value > 100000);

    if (!(value > 100000)) {
      setMiddlePriceSetDate(undefined);
      setMiddlePriceSources([]);
      setTorData({ ...torData, middlePrice: 0 });
      setTorData({ ...torData, planBudget: value, middlePrice: 0 });
    } else {
      setTorData({ ...torData, planBudget: value });
    }
  };

  const onChangeSupplyMethod = (value: string) => {
    getSupplyMethodSpecialType(value);
    setSupplyMethod(value);
  };

  const onChangeDocumentTemplateCode = (value: string) => {
    setDocumentTemplateCode(value);
    const template = documentTemplateDDL.find(dt => dt.value === value);

    if (template) {
      setDocumentTemplateDescription(template.value3 ?? '');
    }
  };

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(supplyMethodId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [supplyMethod]);

  const addObjective = useCallback(async () => {
    const newData: Objec = {
      id: undefined,
      torId: torData.id,
      description: '',
      sequence: objectives.length + 1,
    };

    setObjectives((objectives) => [...objectives, newData]);
  }, [objectives]);

  const handlerOnChangeObjective = (value: string, index: number) => {
    const data = [...objectives];
    data[index].description = value;
    setObjectives(data);
  };

  const removeObjective = (i: number) => {
    const temp: Objec[] = [...objectives];
    temp.splice(i, 1);
    setObjectives(temp);
  };

  const addPacel = useCallback(async () => {

    const newData: Parcel = {
      id: undefined,
      torId: torData.id,
      sequence: parcels.length + 1,
      description: '',
      quantity: 0,
      unit: '',
      price: 0,
      total: undefined,
    };
    setParcels((parcels) => [...parcels, newData]);
  }, [parcels]);

  const handlerOnChangeParcelDesc = (value: string, index: number) => {
    const data = [...parcels];
    data[index].description = value;
    setParcels(data);
  };

  const handlerOnChangeParcelQuantity = (value: number, index: number) => {
    const data = [...parcels];
    data[index].quantity = value;
    setParcels(data);
  };

  const handlerOnChangeParcelUnit = (value: string, index: number) => {
    const data = [...parcels];
    data[index].unit = value;
    setParcels(data);
  };

  const removeParcel = (i: number) => {
    const temp: Parcel[] = [...parcels];
    temp.splice(i, 1);
    setParcels(temp);
  };

  const addQualification = useCallback(async () => {
    const newData: Qualification = {
      id: undefined,
      torId: torData.id,
      description: '',
      sequence: qualifications.length + 1,
      isDefault: false,
    };

    setQualifications((qualifications) => [...qualifications, newData]);
  }, [qualifications]);

  const handlerOnChangeQualification = (value: string, sequence: number) => {
    const data = [...qualifications];
    const index = qualifications.findIndex(q => q.sequence === sequence);
    data[index].description = value;
    setQualifications(data);
  };

  const removeQualification = (sequence: number) => {
    const temp: Qualification[] = [...qualifications];
    const index = qualifications.findIndex(q => q.sequence === sequence);
    temp.splice(index, 1);
    setQualifications(temp);
  };

  const addFinancial = useCallback(async () => {
    const newData: Financial = {
      id: undefined,
      torId: torData.id,
      financialType: 'default',
      description: '',
      amount: !financials || !financials.length ? torData.planBudget : 0,
      sequence: financials.length + 1,
    };

    setFinancials((financials) => [...financials, newData]);
  }, [financials]);

  const handlerOnChangeFinancialDescription = (value: string, index: number) => {
    const data = [...financials];
    data[index].description = value;
    setFinancials(data);
  };

  const handlerOnChangeFinancialAmount = (value: number, index: number) => {
    const data = [...financials];
    data[index].amount = value;
    setFinancials(data);
  };

  const removeFinancial = (i: number) => {
    const temp: Financial[] = [...financials];
    temp.splice(i, 1);
    setFinancials(temp);
  };

  const addWorkPhase = useCallback(async (length: number) => {
    const newData: TorWorkPhase = {
      id: undefined,
      termOfRefId: torData.id,
      sequence: length + 1,
      phaseNumber: length + 1,
      periodDay: 0,
      amount: undefined,
      percent: undefined,
      detail: '',
      deliveryDueDate: undefined
    };

    setWorkPhases((workPhases) => [...workPhases, newData]);
  }, [workPhases]);

  const handlerOnChangeWorkPhasePhaseNumber = (value: number, index: number) => {
    const data = [...workPhases];
    data[index].phaseNumber = value;
    setWorkPhases(data);
  };

  const handlerOnChangeWorkPhasePeriodDay = (value: number, index: number) => {
    const data = [...workPhases];
    const periodDay = Math.max.apply(null,
      data?.map((x) => {
        return x.periodDay;
      }));

    data[index].periodDay = value;
    setWorkPhases(data);

    if (value > periodDay || data.length === 1) {
      setTorData({ ...torData, deliveryPeriodSum: value });
    }

    // const sumPeriodDay = data.reduce((a, v) => a += v.periodDay, 0);
    // setTorData({ ...torData, deliveryPeriodSum: sumPeriodDay });
  };

  const handlerOnChangeWorkPhasePct = (value: number, index: number) => {
    const data = [...workPhases];
    data[index].percent = value;
    setWorkPhases(data);
  };

  const handlerOnChangeWorkPhaseDetail = (value: string, index: number) => {
    const data = [...workPhases];
    data[index].detail = value;
    setWorkPhases(data);
  };

  const removeWorkPhase = (i: number) => {
    const temp: TorWorkPhase[] = [...workPhases];
    temp.splice(i, 1);
    setWorkPhases(temp);

    if (temp) {
      if (temp.length > 0) {
        const periodDay = Math.max.apply(null,
          temp?.map((x) => {
            return x.periodDay;
          }));
        setTorData({ ...torData, deliveryPeriodSum: periodDay });
      } else {
        setTorData({ ...torData, deliveryPeriodSum: 0 });
      }
    }
  };

  const handleSetFile = (documents: TorDocumentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    const newUpdFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFiles((pre) => [...pre, newFile]);
      newUpdFiles.push(file);
    }

    if (newUpdFiles && newUpdFiles.length > 0) {
      const res = await PreProcurementTorServices.onAttachmentTorDocumentByIdAsync(torData.id, newUpdFiles);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('อัปโหลดเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('อัปโหลดเอกสารแนบสำเร็จ');
    }

    await getTorDocumentsByIdAsync(torData.id);
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    const delId = newArray.filter((i) => i.name === docName)[0].id;
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName && delId) {
      const res = await PreProcurementTorServices.deleteTorDocumentByIdAsync(data.id, delId);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('ลบเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('ลบเอกสารแนบสำเร็จ');
    }
  }, [files, torData.id]);


  const onSave = async () => {
    submitForm();

    if (torData.isChange || torData.isCancel) {
      if (!torData.remark || torData.remark === '') {
        return toast.error('กรุณากรอกเหตุผลส่งกลับแก้ไข');
      }
    }

    if (!documentTemplateCode) {
      return toast.warn('กรุณาระบุรูปแบบเอกสาร');
    }

    if (!supplyMethod || !supplyMethodType || !supplyMethodSpecialType) {
      return;
    }

    if (!torData.planBudget || torData.planBudget === 0) {
      return;
    }

    if (torData.planBudget > appointPreProcurement.planBudget) {
      toast.warn(`วงเงินต้องน้อยกว่า ${THCurrency(appointPreProcurement.planBudget)}บาท (แผน)`);
      return;
    }

    if (!torData.planName || torData.planName === '') {
      return;
    }

    if (!torData.sourceAndReasons || torData.sourceAndReasons === '') {
      return;
    }

    if (!objectives || objectives.length === 0) {
      toast.warn('กรุณาระบุวัตถุประสงค์');
      return;
    }

    if (objectives.filter((x) => x.description === '').length > 0) {
      return;
    }

    if (!torData.deliveryUnit || torData.deliveryUnit === '') {
      return;
    }

    if (!torData.deliveryCountUnit || torData.deliveryCountUnit === '') {
      return;
    }

    if (!parcels || parcels.length === 0) {
      toast.warn('กรุณาระบุรายการพัสดุ');
      return;
    }

    if (parcels.filter((x) => x.description === '' || x.quantity <= 0 || x.unit === '').length > 0) {
      return;
    }

    if (!torData.isMA && (!workPhases || workPhases.length === 0)) {
      toast.warn('กรุณาระบุระยะเวลาดำเนินการ/ส่งมอบงาน');
      return;
    }

    if (!torData.isMA && (workPhases.filter((x) => x.detail === '' || x.periodDay <= 0).length > 0)) {
      toast.warn('กรุณาระบุรายละเอียด หรือจำนวนวัน ระยะเวลาดำเนินการ/ส่งมอบงาน');
      return;
    }

    if (!torData.isMA && (workPhases.reduce((a, v) => a += v.percent!, 0) < 100 || workPhases.reduce((a, v) => a += v.percent!, 0) > 100)) {
      toast.warn('กรุณาระบุจำนวนเงิน (%) รวมเท่ากับ 100%');
      return;
    }

    if (!financials || financials.length === 0) {
      toast.warn('กรุณาระบุวงเงินที่จะจัดจ้าง');
      return;
    }

    const financialSum = financials.reduce((p, c) => p += c.amount, 0);

    if (parseFloat(financialSum?.toFixed(2)) > torData.planBudget) {
      toast.warn('วงเงินที่จะจัดซื้อจัดจ้างจะต้องเท่ากับจำนวนเงินของ TOR');
      return;
    }

    if (financials.filter((x) => x.description === '' || x.amount <= 0).length > 0) {
      toast.warn('กรุณาระบุรายละเอียด หรือราคา วงเงินที่จะจัดจ้าง');
      return;
    }

    if (parseFloat(financials.reduce((a, v) => a += v.amount, 0).toFixed(2)) > appointPreProcurement.planBudget) {
      toast.warn('ไม่สามารถระบุวงเงินที่จะจัดจ้างได้มากกว่าวงเงินงบประมาณ');
      return;
    }

    if (!torData.isMA && (!torData.deliveryPeriodSum || torData.deliveryPeriodSum <= 0 || !deliveryUnit || deliveryUnit === '')) {
      //toast.warn('กรุณาระบุระยะเวลาที่ต้องใช้พัสดุ หรือให้งานนั้นแล้วเสร็จ');
      return;
    }

    // if (workPhases.length > 0) {
    //   const sumPeriodDay = workPhases.reduce((a, v) => a += v.periodDay, 0);
    //   if (sumPeriodDay !== torData.deliveryPeriodSum) {
    //     toast.warn('จำนวนวันที่กำหนดให้งานแล้วเสร็จไม่ถูกต้อง มีการระบุจำนวนวันมากกว่า');
    //     return;
    //   }
    // }

    if (torData.isMA && paymentTermPeriod.length === 0) {
      toast.warn('กรุณาระบุเงื่อนไขคณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)');
      return;
    }

    if (torData.isMA && paymentTermPeriod.filter((x) => x.description === null || x.description === '' || x.description === undefined).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดเงื่อนไขคณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)');
      return;
    }

    if (torData.isMA && paymentTermPeriod.filter((x) => x.quantity === null || x.quantity === undefined).length > 0) {
      toast.warn('กรุณาระบุจำนวนเงื่อนไขคณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)');
      return;
    }

    if (torData.isMA && paymentTermPeriod.filter((x) => x.periodType === null || x.periodType === '' || x.periodType === undefined).length > 0) {
      toast.warn('กรุณาระบุระยะเวลาเงื่อนไขคณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)');
      return;
    }

    if (torData.isMA && paymentTermPeriod.filter((x) => x.totalQuantity === null || x.totalQuantity === undefined).length > 0) {
      toast.warn('กรุณาระบุจำนวนรวมเงื่อนไขคณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)');
      return;
    }

    if (torData.isMA && paymentTermPeriod.filter((x) => x.totalPeriodType === null || x.totalPeriodType === '' || x.totalPeriodType === undefined).length > 0) {
      toast.warn('กรุณาระบุระยะเวลารวมเงื่อนไขคณะกรรมการตรวจรับพัสดุ (งานจ้างบริการบำรุงรักษา)');
      return;
    }

    const saveMiddlePriceSources: middlePriceSourceModel[] = [];
    const saveObjectives: Objec[] = [];
    const saveParcels: Parcel[] = [];
    const saveQualifications: Qualification[] = [];
    const saveFinancials: Financial[] = [];
    const saveWorkPhases: TorWorkPhase[] = [];
    const savePaymentterms: TorPaymentTermPeriodModel[] = [];

    objectives.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        const newData: Objec = {
          id: data.id,
          torId: data.torId,
          description: data.description,
          sequence: index + 1,
        };

        saveObjectives.push(newData);
      });

    parcels.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        const newData: Parcel = {
          id: data.id,
          torId: data.torId,
          sequence: index + 1,
          description: data.description,
          quantity: data.quantity,
          unit: data.unit,
          price: data.price,
          total: data.quantity * data.price,
        };

        saveParcels.push(newData);
      });

    qualifications.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        const newData: Qualification = {
          id: torData.id,
          torId: data.torId,
          description: data.description,
          sequence: index + 1,
          isDefault: data.isDefault,
        };

        saveQualifications.push(newData);
      });

    financials.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        const newData: Financial = {
          id: torData.id,
          torId: data.torId,
          financialType: data.financialType,
          description: data.description,
          amount: data.amount,
          sequence: index + 1,
        };

        saveFinancials.push(newData);
      });

    workPhases.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        const newData: TorWorkPhase = {
          id: torData.id,
          termOfRefId: data.termOfRefId,
          periodDay: data.periodDay,
          amount: data.amount,
          percent: data.percent,
          detail: data.detail,
          deliveryDueDate: data.deliveryDueDate,
          sequence: index + 1,
          phaseNumber: data.phaseNumber,
        };

        saveWorkPhases.push(newData);
      });

    paymentTermPeriod.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        const newData: TorPaymentTermPeriodModel = {
          id: data.id,
          termOfRefId: data.termOfRefId,
          sequence: index + 1,
          description: data.description,
          quantity: data.quantity,
          periodType: data.periodType,
          totalQuantity: data.totalQuantity,
          totalPeriodType: data.totalPeriodType,
        };

        savePaymentterms.push(newData);
      });

    let torTemplateCode = TorTemplateCode.LW5STD;
    if (torData.planBudget > 500000) {
      torTemplateCode = TorTemplateCode.OV5STD;
    } else {
      torTemplateCode = TorTemplateCode.LW5STD;
    }

    const updateData: TorDetailModel = {
      id: torData.id,
      torNumber: torData.torNumber,
      torTemplateCode: torTemplateCode,
      appointNumber: torData.appointNumber,
      planName: torData.planName,
      sourceAndReasons: torData.sourceAndReasons,
      departmentId: torData.departmentId,
      budgetYear: torData.budgetYear,
      memorandumNo: torData.memorandumNo,
      memorandumDate: torData.memorandumDate,
      expectingProcurementAt: torData.expectingProcurementAt,
      planBudget: torData.planBudget,
      supplyMethod: supplyMethod,
      supplyMethodType: supplyMethodType,
      supplyMethodSpecialType: supplyMethodSpecialType,
      objectives: saveObjectives,
      parcels: saveParcels,
      qualifications: saveQualifications,
      financialAmount: financials.reduce((a, v) => a += v.amount, 0),
      financials: saveFinancials,
      deliveryPeriodSum: torData.deliveryPeriodSum,
      deliveryUnit: torData.deliveryUnit,
      deliveryCountUnit: torData.deliveryCountUnit,
      workPhases: workPhases,
      paymentTermPeriods: savePaymentterms,
      defectsWarranty: torData.defectsWarranty,
      fineRates: [],
      appointPreProcurement: appointPreProcurement,
      documents: torData.documents,
      committeeTorApprovals: torData.committeeTorApprovals,
      committeeMiddlePriceApprovals: torData.committeeMiddlePriceApprovals,
      jorPorDirectors: torData.jorPorDirectors,
      assignees: torData.assignees,
      responsibles: torData.responsibles,
      committeeApproverTorSections: torData.committeeApproverTorSections,
      middlePrice: torData.middlePrice,
      middlePriceSetDate: middlePriceSetDate || torData.middlePriceSetDate,
      middlePriceSources: saveMiddlePriceSources,
      isConsumableMaterials: torData.isConsumableMaterials,
      evidenceCompareItems: torData.evidenceCompareItems,
      trainingItems: torData.trainingItems,
      impediments: torData.impediments,
      evidence: torData.evidence,
      evidenceDescription: torData.evidenceDescription,
      evidenceNumber: torData.evidenceNumber,
      documentDescription: torData.documentDescription,
      collateral: torData.collateral,
      criteriaConsiderDescription: torData.criteriaConsiderDescription,
      manuelDescription: torData.manuelDescription,
      commentDescription: torData.commentDescription,
      preventiveMaintenance: torData.preventiveMaintenance,
      correctiveMaintenance: torData.correctiveMaintenance,
      training: torData.training,
      isInCanceling: torData.isInCanceling,
      isInEditing: torData.isInEditing,
      status: torData.status,
      remark: torData.remark,
      isChange: torData.isChange,
      isCancel: torData.isCancel,
      isActive: torData.isActive,
      hasPerformanceBond: torData.hasPerformanceBond,
      hasBidBond: torData.hasBidBond,
      refId: torData.refId,
      docId: torData.docId,
      documentTemplateCode: documentTemplateCode,
      isMA: torData.isMA,
      step: (torData.step != null && torData.step > 0) ? torData.step : 1
    };

    const res = await PreProcurementTorServices.updateTorStep1Async(updateData);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('บันทึกร่างขอบเขตของงาน (TOR) ไม่สำเร็จ');
      return;
    }

    getTorByIdAsynce(torData.id);
    toast.success('บันทึกร่างขอบเขตของงาน (TOR) สำเร็จ');
    navigate(`${currentPath}/${torData.id}`);
    onClickNext();
  };

  const getTorByIdAsynce = async (id: string) => {
    const response = await PreProcurementTorServices.getTorByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
    }
  };

  const getTorDocumentsByIdAsync = async (id: string) => {
    const response = await PreProcurementTorServices.getTorByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      if (response.data.documents) {
        handleSetFile(response.data.documents);
        setTorData({ ...torData, documents: response.data.documents });
      }
    }
  };

  const addPaymentterms = useCallback(async (length: number) => {
    const newData: TorPaymentTermPeriodModel = {
      id: undefined,
      termOfRefId: torData.id,
      sequence: length + 1,
      description: '',
      quantity: undefined,
      periodType: undefined,
    };

    setPaymentTermPeriod((paymentTermPeriod) => [...paymentTermPeriod, newData]);
  }, [paymentTermPeriod]);

  const handlerOnChangePaymenttermsDetail = (value: string, index: number) => {
    const data = [...paymentTermPeriod];
    data[index].description = value;
    setPaymentTermPeriod(data);
  };

  const removePaymentterms = (i: number) => {
    const temp: TorPaymentTermPeriodModel[] = [...paymentTermPeriod];
    temp.splice(i, 1);
    setPaymentTermPeriod(temp);
  };

  const handlerOnChangePaymenttermsQuantity = (value: number, index: number) => {
    const data = [...paymentTermPeriod];
    data[index].quantity = value;
    setPaymentTermPeriod(data);
  };

  const handlerOnChangePeriodType = (value: string, index: number) => {
    const data = [...paymentTermPeriod];
    data[index].periodType = value;
    setPaymentTermPeriod(data);
  };

  const handlerOnChangePaymenttermsTotalQuantity = (value: number, index: number) => {
    const data = [...paymentTermPeriod];
    data[index].totalQuantity = value;
    setPaymentTermPeriod(data);
  };

  const handlerOnChangeTotalPeriodType = (value: string, index: number) => {
    const data = [...paymentTermPeriod];
    data[index].totalPeriodType = value;
    setPaymentTermPeriod(data);
  };

  const onChangeIsMA = (val: string) => {
    if (val === 'true') {
      setIsMA(true);
      setTorData({
        ...torData,
        isMA: true,
      });
    } else {
      setIsMA(false);
      setTorData({
        ...torData,
        isMA: false,
      });
    }
  };

  const openPdfAsync = async () => {
    const res = await documentTemplate.getPdfId(documentTemplateCode);

    if (res.status === HttpStatusCode.OK) {
      const endpoint = process.env.REACT_APP_API_URL!;

      window.open(`${endpoint}/file/${res.data}/pdf`, '_blank');
    }
  };

  const firstPeriodUnit = useMemo(() => {
    if (periodUnitDDL.length) {
      return [periodUnitDDL[0]];
    }

    return [];
  }, [periodUnitDDL]);

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title
          text='บุคคล/คณะกรรมการจัดทำร่างขอบเขตของงาน'
          className='fs-5 text-primary'
          extraElement={(
            <div className='d-flex gap-2'>
              {
                torData.isInEditing && (
                  <div className='status'
                    style={{ marginRight: 10 }}>
                    <span><div><FaCircle style={{ fontSize: 10 }}
                      className='text-warning' /></div>
                      {TorEditCancelStatusConstant.Editing}</span>
                  </div>
                )
              }
              {
                torData.isInCanceling && (
                  <div className='status'
                    style={{ marginRight: 10 }}>
                    <span><div><FaCircle style={{ fontSize: 10 }}
                      className='text-danger' /></div>
                      {TorEditCancelStatusConstant.Canceling}</span>
                  </div>
                )
              }
              <TorStatus
                value={torData.status}
              />
              <Button
                onClick={() => setShowHistory(!showHistory)}
                variant='outline-primary'
              >
                <FaHistory className='me-1' />
                ประวัติการใช้งาน
              </Button>
            </div>
          )}
        />

        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}>
            <Table total={1}
              hidePagination>
              <thead>
                <tr>
                  <th style={{ width: 100 }}>ลำดับ</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th style={{ width: 300 }}>ฝ่าย/สำนัก</th>
                  <th style={{ width: 300 }}>ตำแหน่ง</th>
                  <th style={{ width: 400 }}>ตำแหน่งในคณะกรรมการ</th>
                </tr>
              </thead>
              <tbody>
                {(committeeTors && committeeTors.length > 0) && (
                  committeeTors.map((data, index) => (
                    <tr key={index}>
                      <td className='text-center'>{index + 1}</td>
                      <td className='text-start'>{data.committeeUserName}</td>
                      <td className='text-start'>{data.department}</td>
                      <td className='text-start text-wrap'>{data.position}</td>
                      <td className='text-center'>
                        <Selector
                          className='primary'
                          items={posotionOnBoardItems}
                          value={data.positionOnBoardId}
                          disabled
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title
          text='ข้อมูลเอกสารบันทึกข้อความแต่งตั้ง'
          className='fs-5 text-primary'
        />
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={4}>
            <Input
              label='เลขที่จัดทำร่างขอบเขตงาน'
              placeholder='เลขที่จัดทำร่างขอบเขตงาน'
              value={torData.torNumber}
              disabled
            />
          </Col>
          <Col sm={12}
            lg={4}>
            <Input
              label='เลขที่บันทึกแต่งตั้ง'
              placeholder='เลขที่บันทึกแต่งตั้ง'
              value={torData.appointNumber}
              disabled
            />
          </Col>
        </Row>
        <Row className='ps-40'>
          <Col sm={12}
            lg={4}>
            <Selector
              label='รูปแบบเอกสาร'
              placeholder='กรุณาเลือก'
              value={documentTemplateCode}
              onChange={val => onChangeDocumentTemplateCode(val)}
              items={documentTemplateDDL}
              rule={{ required: true }}
              disabled={isDisabled || readonly}
            />
          </Col>
          <Col
            sm={12}
            lg={8}
            className='d-flex'>
            <Button
              style={{ marginTop: '37px' }}
              onClick={openPdfAsync}>
              <FaFilePdf />
            </Button>
            <p style={{ marginTop: '43px', marginLeft: '15px' }}>
              * {documentTemplateDescription}
            </p>
          </Col>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={4}>
            <DatePicker
              label='วันที่เอกสารบันทึกข้อความแต่งตั้ง'
              value={torData.memorandumDate}
              fullDate
              disabled
            />
          </Col>
          <Col sm={12}
            lg={4}>
            <Input
              label='เลขที่ระบบสารบรรณ'
              placeholder='เลขที่ระบบสารบรรณ'
              value={torData.memorandumNo}
              disabled />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={4}>
            <Input
              label='อ้างอิงแผนการจัดซื้อจัดจ้าง'
              placeholder='อ้างอิงแผนการจัดซื้อจัดจ้าง'
              value={appointPreProcurement.planNumber}
              disabled
            />
          </Col>
        </Row>
        <Row className='ps-40'>
          <Col sm={9}
            lg={4}>
            <div className='d-flex align-items-center'>
              <Selector label='ฝ่าย/สำนัก'
                placeholder='กรุณาเลือก'
                className='primary'
                items={departmentDDL}
                value={torData.departmentId}
                disabled />
            </div>
          </Col>
          <Col sm={3}
            lg={4}>
            <Input
              label='ปีงบประมาณ'
              placeholder='ปีงบประมาณ'
              value={torData.budgetYear}
              rule={{ required: true }}
              disabled
            />
          </Col>
        </Row>

        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={8}>
            <Row>
              <Col sm={12}
                lg={4}>
                <Selector
                  label='วิธีการจัดหา'
                  placeholder='กรุณาเลือก'
                  items={supplyMethodDDL}
                  rule={{ required: true }}
                  value={supplyMethod}
                  onChange={val => onChangeSupplyMethod(val)}
                  disabled={isDisabled || readonly}
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <Selector
                  className='no-label'
                  placeholder='กรุณาเลือก'
                  items={supplyMethodTypeDDL}
                  rule={{ required: true }}
                  value={supplyMethodType}
                  onChange={val => setSupplyMethodType(val)}
                  disabled={isDisabled || readonly}
                />
              </Col>
              <Col sm={12}
                lg={4}>
                <Selector
                  className='no-label'
                  placeholder='กรุณาเลือก'
                  items={supplyMethodSpecialDDL}
                  rule={{ required: true }}
                  value={supplyMethodSpecialType}
                  onChange={val => setSupplyMethodSpecialType(val)}
                  disabled={isDisabled || readonly}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={4}>
            <Currency
              label='วงเงิน (บาท)'
              rule={{ required: true }}
              placeholder='วงเงิน (บาท)'
              value={torData.planBudget}
              onChange={val => onChangeBudget(Number(val))}
              disabled={isDisabled || readonly}
            />
          </Col>
          <Col sm={12}
            lg={4}>
            <DatePicker
              label='ประมาณการช่วงเวลาการจัดซื้อจัดจ้าง'
              rule={{ required: true }}
              value={torData.expectingProcurementAt}
              monthYearOnly
              disabled
            />
          </Col>
          <Col sm={12}
            lg={4}>
            <Check
              className='no-label'
              label='(Stock) เป็นรายการวัสดุเครื่องเขียนแบบพิมพ์และวัสดุสิ้นเปลืองคลังพัสดุ'
              value={torData.isConsumableMaterials}
              onChange={val => setTorData({ ...torData, isConsumableMaterials: val })}
              disabled={isDisabled || readonly}
            />
          </Col>
        </Row>
        <Row className='mt-3 ps-40'>
          <Col sm={12}
            lg={8}>
            <InputTextArea label='ชื่อโครงการ (เรื่อง)'
              value={torData.planName}
              onChange={val => setTorData({ ...torData, planName: val })}
              rule={{ required: true }}
              disabled={isDisabled || readonly} />
          </Col>
          <Col sm={12}
            lg={8}>
            <InputTextArea label='ที่มาและเหตุผล'
              value={torData.sourceAndReasons}
              onChange={val => setTorData({ ...torData, sourceAndReasons: val })}
              rule={{ required: true }}
              disabled={isDisabled || readonly} />
          </Col>
          {
            (torData.isCancel || torData.isChange) && (
              <Col sm={12}
                lg={8}>
                <InputTextArea
                  label={data.isChange ? 'เหตุผลที่ขอเปลี่ยนแปลง' : 'เหตุผลที่ขอยกเลิก'}
                  value={torData.remark}
                  onChange={(val) => setTorData({ ...torData, remark: val })}
                  rule={{ required: true }}
                  placeholder='หมายเหตุ'
                />
              </Col>
            )
          }
        </Row>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='วัตถุประสงค์'
            className='fs-5 text-primary'
          />
          {openCollapse1 ? (
            <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />)}
        </div>
        <Collapse in={openCollapse1} className='ps-40'>
          <div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <div className='d-flex justify-content-between my-3 '>
                  <p>วัตถุประสงค์</p>
                  <Button
                    variant='outline-primary'
                    onClick={addObjective}
                    disabled={isDisabled || readonly}
                  >
                    <FaPlus className='me-1' />เพิ่มวัตถุประสงค์
                  </Button>
                </div>

                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>ลำดับ</th>
                      <th style={{ minWidth: 250 }}>รายละเอียด</th>
                      <th style={{ width: 20 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {objectives.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr key={index}>
                          <td className='text-center'>{index + 1}</td>
                          <td>
                            <Input
                              value={data.description}
                              onChange={(val) => handlerOnChangeObjective(val, index)}
                              rule={{ required: true }}
                              disabled={isDisabled || readonly}
                            />
                          </td>
                          <td className='text-end'>
                            <Button
                              onClick={() => removeObjective(index)}
                              variant='danger'
                              disabled={isDisabled || readonly}
                            >
                              <FaTrashAlt />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='คุณสมบัติ'
            className='fs-5 text-primary'
          />

          {openCollapse2 ? (
            <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
        </div>
        <Collapse in={openCollapse2} className='ps-40'>
          <div>
            <Row style={{ marginLeft: 40 }} className='mt-2'>
              {qualifications?.filter(q => q.isDefault).sort((a, b) => a.sequence - b.sequence)
                .map((data, index) => (
                  <Col key={`q${index}`} sm={12} className='mb-2'>{`${data.sequence}. ${data.description}`}</Col>
                ))}
            </Row>
            <div className='d-flex justify-content-between align-items-center'>
              <p className='mt-4'>คุณสมบัติอื่นๆ (ถ้ามี)</p>
              <Button
                variant='outline-primary'
                onClick={addQualification}
                disabled={isDisabled || readonly}
              >
                <FaPlus className='me-1' />เพิ่มคุณสมบัติ
              </Button>
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>ลำดับ</th>
                      <th style={{ minWidth: 150 }}>รายละเอียด</th>
                      <th style={{ width: 20 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {qualifications?.filter(q => !q.isDefault).sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr key={index}>
                          <td className='text-center'>{data.sequence}</td>
                          <td>
                            <Input
                              value={data.description}
                              onChange={(val) => handlerOnChangeQualification(val, data.sequence)}
                              rule={{ required: true }}
                              disabled={isDisabled || readonly}
                            />
                          </td>
                          <td className='text-end'>
                            <Button
                              onClick={() => removeQualification(data.sequence)}
                              variant='danger'
                              disabled={isDisabled || readonly}
                            >
                              <FaTrashAlt />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='คุณลักษณะเฉพาะของพัสดุ'
            className='fs-5 text-primary'
          />
          {openCollapse3 ? (
            <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />)}
        </div>
        <Collapse in={openCollapse3} className='ps-40'>
          <div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <div className='d-flex justify-content-between my-3 '>
                  <p>คุณลักษณะเฉพาะของพัสดุ</p>
                  <Button
                    variant='outline-primary'
                    onClick={addPacel}
                    disabled={isDisabled || readonly}
                  >
                    <FaPlus className='me-1' />เพิ่มรายการ
                  </Button>
                </div>

                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>ลำดับ</th>
                      <th style={{ minWidth: 250 }}>รายละเอียด</th>
                      <th style={{ width: 250 }}>จำนวน</th>
                      <th style={{ width: 250 }}>หน่วย</th>
                      <th style={{ width: 20 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {parcels.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr key={index}>
                          <td className='text-center'>{index + 1}</td>
                          <td>
                            <Input
                              value={data.description}
                              onChange={(val) => handlerOnChangeParcelDesc(val, index)}
                              rule={{ required: true }}
                              disabled={isDisabled || readonly}
                            />
                          </td>
                          <td>
                            <InputNumber
                              value={data.quantity}
                              onChange={(val) => handlerOnChangeParcelQuantity(Number(val), index)}
                              rule={{ required: true, pattern: ValidatePattern.NUMBER }}
                              disabled={isDisabled || readonly}
                            />
                          </td>
                          <td>
                            <Selector
                              className='primary'
                              items={unitOfMeasureDDL}
                              value={data.unit}
                              onChange={(val) => handlerOnChangeParcelUnit(val, index)}
                              rule={{ required: false }}
                              disabled={isDisabled || readonly} />
                          </td>
                          <td className='text-end'>
                            <Button
                              onClick={() => removeParcel(index)}
                              variant='danger'
                              disabled={isDisabled || readonly}
                            >
                              <FaTrashAlt />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='วงเงินที่จะจัดซื้อจัดจ้าง'
            className='fs-5 text-primary'
          />

          {openCollapse4 ? (
            <ArrowCollapse onClick={() => setOpenCollapse4(!openCollapse4)} openCollapse={openCollapse4} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse4(true)} openCollapse={openCollapse4} />)}
        </div>
        <div className='ps-40'>
          <Row className='g-3 align-items-center'>
            <Col className='col-auto'>
              <label className='col-form-label fs-5 text-primary'>วงเงินทั้งสิ้น :</label>
            </Col>
            <Col className='col-auto'>
              <Currency value={financials.reduce((a, v) => a += v.amount, 0)}
                disabled />
            </Col>
          </Row>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='mb-0'>รายละเอียดวงเงินที่จัดซื้อจัดจ้าง</p>
            <Button
              variant='outline-primary'
              onClick={addFinancial}
              disabled={isDisabled || readonly}
            >
              <FaPlus className='me-1' />เพิ่มรายการ
            </Button>
          </div>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}>
              <Table total={1}
                hidePagination>
                <thead>
                  <tr>
                    <th style={{ width: 100 }}>ลำดับ</th>
                    <th style={{ minWidth: 150 }}>รายละเอียด</th>
                    <th style={{ width: 250 }}>ราคา</th>
                    <th style={{ width: 25 }} />
                  </tr>
                </thead>
                <tbody>
                  {financials.sort((a, b) => a.sequence - b.sequence)
                    .map((data, index) => (
                      <tr key={index}>
                        <td className='text-center'>{index + 1}</td>
                        <td>
                          <Input
                            value={data.description}
                            onChange={(val) => handlerOnChangeFinancialDescription(val, index)}
                            rule={{ required: true }}
                            disabled={isDisabled || readonly}
                          />
                        </td>
                        <td>
                          <Currency
                            value={data.amount}
                            onChange={(val) => handlerOnChangeFinancialAmount(Number(val), index)}
                            rule={{ required: true }}
                            disabled={isDisabled || readonly}
                          />
                        </td>
                        <td className='text-end'>
                          <Button
                            onClick={() => removeFinancial(index)}
                            variant='danger'
                            disabled={isDisabled || readonly}
                          >
                            <FaTrashAlt />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className='mt-5'>
            <Col sm={12}
              md={5}>
              <InputNumber
                label='กำหนดเวลาที่ต้องการใช้พัสดุนั้น หรือ ให้งานนั้นแล้วเสร็จ'
                value={torData.deliveryPeriodSum ? torData.deliveryPeriodSum : 0}
                onChange={(val) => setTorData({ ...torData, deliveryPeriodSum: Number(val) })}
                rule={{ required: !torData.isMA, pattern: ValidatePattern.NUMBER }}
                disabled
              />
            </Col>
            <Col sm={12}
              md={2}>
              <Selector
                className='primary no-label'
                items={firstPeriodUnit}
                value={torData.deliveryUnit}
                onChange={(val) => setTorData({ ...torData, deliveryUnit: val })}
                rule={{ required: !torData.isMA }}
                disabled={isDisabled || readonly} />
            </Col>
            <Col sm={12}
              md={3}>
              <Selector
                className='primary no-label'
                items={deliveryCountUnitDDL}
                value={torData.deliveryCountUnit}
                onChange={(val) => setTorData({ ...torData, deliveryCountUnit: val })}
                rule={{ required: !torData.isMA }}
                disabled={isDisabled || readonly} />
            </Col>
          </Row>
          <div>
            <div className='d-flex align-items-center justify-content-between'>
              <p className='mb-0'>เงื่อนไขการชำระเงิน (งานซื้อและงานจ้าง)</p>
              <Button
                variant='outline-primary'
                onClick={() => addWorkPhase(workPhases.length)}
                disabled={isDisabled || readonly}
              >
                <FaPlus className='me-1' />เพิ่มเงื่อนไขการชำระเงิน
              </Button>
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>ลำดับ</th>
                      <th style={{ width: 100 }}>งวดที่</th>
                      <th style={{ width: 100 }}>
                        {
                          periodUnitDDL.filter(d => d.value === torData.deliveryUnit).length > 0 ? (<>{`จำนวน${periodUnitDDL.filter(d => d.value === torData.deliveryUnit)[0].label}`}</>) : (<>จำนวนวัน</>)
                        }
                      </th>
                      <th style={{ width: 100 }}>จำนวนเงิน (%)</th>
                      <th>รายละเอียด</th>
                      <th style={{ width: 20 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {workPhases.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr key={index}>
                          <td className='text-center'>{index + 1}</td>
                          <td>
                            <InputNumber
                              value={data.phaseNumber}
                              onChange={(val) => handlerOnChangeWorkPhasePhaseNumber(Number(val), index)}
                              rule={{ required: true }}
                              disabled={isDisabled || readonly}
                            />
                          </td>
                          <td>
                            <InputNumber
                              value={data.periodDay}
                              onChange={(val) => handlerOnChangeWorkPhasePeriodDay(Number(val), index)}
                              rule={{ required: true }}
                              disabled={isDisabled || readonly}
                            />
                          </td>
                          <td>
                            <Currency
                              value={data.percent}
                              onChange={(val) => handlerOnChangeWorkPhasePct(Number(val), index)}
                              rule={{ required: true, pattern: ValidatePattern.PERCENTAGE }}
                              maxValue={100}
                              disabled={isDisabled || readonly}
                            />
                          </td>
                          <td>
                            <InputTextArea
                              value={data.detail}
                              onChange={(val) => handlerOnChangeWorkPhaseDetail(val, index)}
                              rule={{ required: true }}
                              disabled={isDisabled || readonly}
                            />
                          </td>
                          <td className='text-end'>
                            <Button
                              onClick={() => removeWorkPhase(index)}
                              variant='danger'
                              disabled={isDisabled || readonly}
                            >
                              <FaTrashAlt />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>

          <Row className='mt-5 align-items-center'>
            <Col sm={12}>
              <Radio
                dflex
                label='เงื่อนไขชำระเงินกรณีมี (MA) (ถ้ามี)'
                subLabel={<span>* เงื่อนไขการชำระเงิน (ระยะเวลา เช่นงานจ้าง บำรุงรักษา จ้างแม่บ้าน จ้างรปภ เช่า)</span>}
                items={HasCommitteeMAAndSupervisor}
                rule={{ required: true }}
                value={isMA.toString()}
                name='ma'
                onChange={(val) => (onChangeIsMA(val.toString()))}
                disabled={isDisabled || readonly}
              />
            </Col>
          </Row>
          {(isMA) && (
            <div className='text-end ps-40'>
              <Button
                variant='outline-primary'
                onClick={() => addPaymentterms(workPhases.length)}
                disabled={isDisabled || readonly || !isMA}
              >
                <FaPlus className='me-1' />เพิ่มเงื่อนไข
              </Button>

              <Row className='align-items-center'>
                <Col sm={12}>
                  <Table total={1}
                    hidePagination>
                    <thead>
                      <tr>
                        <th style={{ width: 100 }}>ลำดับ</th>
                        <th>รายละเอียด</th>
                        <th style={{ width: 250 }}>จำนวน</th>
                        <th style={{ width: 250 }}>ระยะเวลา</th>
                        <th style={{ width: 250 }}>จำนวนรวม</th>
                        <th style={{ width: 250 }}>ระยะเวลารวม</th>
                        <th style={{ width: 20 }} />
                      </tr>
                    </thead>
                    <tbody>
                      {paymentTermPeriod.sort((a, b) => a.sequence - b.sequence)
                        .map((data, index) => (
                          <tr key={index}>
                            <td className='text-center'>{index + 1}</td>
                            <td>
                              <InputTextArea
                                value={data.description}
                                onChange={(val) => handlerOnChangePaymenttermsDetail(val, index)}
                                disabled={isDisabled || readonly || !isMA}
                                rule={{ required: true }}
                              />
                            </td>
                            <td>
                              <InputNumber
                                className='text-center'
                                value={data.quantity}
                                onChange={(val) => handlerOnChangePaymenttermsQuantity(Number(val), index)}
                                disabled={isDisabled || readonly || !isMA}
                                rule={{ required: true }}
                              />
                            </td>
                            <td>
                              <Selector
                                className='text-start'
                                items={maintenancePeriodTypeDDL}
                                value={data.periodType}
                                onChange={(val) => handlerOnChangePeriodType(val, index)}
                                disabled={isDisabled || readonly || !isMA}
                                rule={{ required: true }}
                              />
                            </td>
                            <td>
                              <InputNumber
                                className='text-center'
                                value={data.totalQuantity}
                                onChange={(val) => handlerOnChangePaymenttermsTotalQuantity(Number(val), index)}
                                disabled={isDisabled || readonly || !isMA}
                                rule={{ required: true }}
                              />
                            </td>
                            <td>
                              <Selector
                                className='text-start'
                                items={maintenancePeriodTypeDDL}
                                value={data.totalPeriodType}
                                onChange={(val) => handlerOnChangeTotalPeriodType(val, index)}
                                disabled={isDisabled || readonly || !isMA}
                                rule={{ required: true }}
                              />
                            </td>
                            <td className='text-end'>
                              <Button
                                onClick={() => removePaymentterms(index)}
                                variant='danger'
                                disabled={isDisabled || readonly || !isMA}
                              >
                                <FaTrashAlt />
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          )}

        </div>
      </Card >

      <Card className='mt-3'>
        <div className='d-flex align-items-center'>
          <Title
            text='หลักประกันสัญญา'
            className='fs-5 text-primary'
          />

          {openCollapse5 ? (
            <ArrowCollapse onClick={() => setOpenCollapse5(!openCollapse5)} openCollapse={openCollapse5} />
          ) : (
            <ArrowCollapse onClick={() => setOpenCollapse5(true)} openCollapse={openCollapse5} />)}
        </div>
        <div className='ps-40'>
          <Row className='mt-4'>
            <Col>
              <Radio
                label='หลักประกันสัญญา'
                dflex
                items={HasBond}
                value={torData.hasPerformanceBond !== undefined ? torData.hasPerformanceBond.toString() : 'false'}
                name='hasPerformanceBond'
                onChange={(val) => setTorData({ ...torData, hasPerformanceBond: val === 'true' })}
                rule={{ required: true }}
                disabled={isDisabled || readonly}
              />
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <Radio
                label='หลักประกันการเสนอราคา'
                dflex
                items={HasBond}
                value={torData.hasBidBond !== undefined ? torData.hasBidBond.toString() : 'false'}
                name='hasBidBond'
                onChange={(val) => setTorData({ ...torData, hasBidBond: val === 'true' })}
                rule={{ required: true }}
                disabled={isDisabled || readonly}
              />
            </Col>
          </Row>
        </div>
      </Card >

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              torId={torData.id}
              disabled={readonly}
            />
          </Col>
        </Row>
      </Card>
      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="กลับหน้าแรก"
        />
        {
          ((torData.status === TorStatusConstant.Draft || torData.status === TorStatusConstant.DraftTor
            || torData.status === TorStatusConstant.Rejected) && isDraftTorPerson) && (
            <ButtonCustom
              onClick={onSave}
              text="บันทึก"
            />
          )
        }
        {
          (torData.status !== TorStatusConstant.Draft && torData.step > 0) ? (
            <ButtonCustom
              onClick={onClickNext}
              text="ถัดไป"
            />
          ) : (<div style={{ width: 120 }} />)
        }
      </div>

      <HistoryModal
        title='ประวัติการใช้งานแบบร่างขอบเขตของงาน (TOR)'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        torNumber={torData.torNumber}
      />

      <Modal
        show={showEdit}
        size='lg'
        onHide={() => setShowEdit(!showEdit)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowEdit(!showEdit)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={[]}
      />

      <Modal
        show={showProvide}
        size='xl'
        onHide={() => setShowProvide(!showProvide)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>
                <Title
                  text='ค้นหารายการจัดซื้อจัดจ้าง'
                  className='fs-5 text-primary'
                />
              </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <Row className='align-items-center'>
                <Col sm={12}
                  md={4}
                  xl={3}>
                  <Input label='เลขที่รายการ'
                    placeholder='เลขที่รายการ' />
                </Col>
                <Col sm={12}
                  md={4}
                  xl={3}>
                  <Input label='ชื่อโครงการ'
                    placeholder='ชื่อโครงการ' />
                </Col>
              </Row>
              <Table className='my-3'>
                <thead>
                  <tr>
                    <th style={{ width: 20 }}>ลำดับ</th>
                    <th style={{ minWidth: 50 }}>เลขที่รายการ</th>
                    <th style={{ minWidth: 50 }}>ฝ่าย/สำนัก</th>
                    <th style={{ minWidth: 50 }}>ชื่อโครงการ</th>
                    <th style={{ minWidth: 50 }}>วิธีจัดหา</th>
                    <th style={{ minWidth: 50 }}>วงเงิน</th>
                    <th style={{ minWidth: 25 }} />
                  </tr>
                </thead>
                <tbody>
                  {
                    dataSearchNameProvide.map((data, index) => (
                      <tr key={index}>
                        <td className='text-center'>{data.no}</td>
                        <td className='text-center'>{data.id}</td>
                        <td className='text-start'>{data.class}</td>
                        <td className='text-start'>{data.position}</td>
                        <td className='text-center'>{data.provide} </td>
                        <td className='text-end'>{data.limit}</td>
                        <td>
                          <Button variant='primary'
                            className='px-3'>
                            เลือก
                          </Button>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </ModalBT.Body>
          </>
        )}
      />
    </div >
  );
}

export default Step1;
