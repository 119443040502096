import {
  Card,
  Check,
  Currency,
  DatePicker,
  Input,
  InputTextArea,
  MedianPriceStatus,
  Readonly,
  Selector,
  Table,
  ButtonCustom,
  Radio,
} from 'components';
import Title from 'components/Controls/Title';
import {
  Modal,
  SearchNameModal,
} from 'components/Modal';
import {
  IFile,
  ItemModel,
} from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Accordion,
  Button,
  Col,
  Form,
  InputGroup,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  BsArrowReturnLeft,
} from 'react-icons/bs';
import {
  FaCircle,
  FaHistory,
  FaPlus,
  FaSave,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router';
import {
  HttpStatusCode,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { generateUniqueId, } from '../../../utils/helper';
import { HistoryModal } from './Modal/HistoryModal';
import { Context } from './step';
import UploadFile from './UploadFile';
import { AttachmentFileModel, CreateMedianPriceModel, MedianPriceConsiderInformationModel, MedianPriceConsultantTypeQualifyModel, MedianPriceSourceModel, MedianPriceStaffModel, MedianPriceTemplateModel, UpdateMedianPriceModel } from 'models/PP/MedianPriceModel';
import MedianPriceService from 'services/PP/MedianPriceService';
import { StatusMedianPrice } from 'components/StatusMedianPrice';
import { TorEditCancelStatusConstant } from 'constant/PreProcumentTorStatus';
import { MedianPriceStaff } from './Components/MedianPriceStaff';
import { defaultStaffValues } from './data';
import { shareValue } from 'services';
import { MedianPriceSources } from './Components/MedianPriceSources';
import { MedianPriceConsultantType } from './Components/MedianPriceConsultantType';
import { MedianPriceConsultantQualification } from './Components/MedianPriceConsultantQualification';
import { InputNumber } from 'components/Controls/InputNumber';
import { MedianPriceTemplateCodeEnum } from 'utils/constants/MedianPriceEnum';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type Loader = {
  criteriaConsiderationDDL: ItemModel[],
  medianPriceTemplateDDL: ItemModel[],
};

export interface ShowMedianPriceSectionModel {
  jobDescription: boolean;
  staffCompensationAmount: boolean;
  materialAmount: boolean;
  additionalCostAmount: boolean;
  unitPrice: boolean;
  consultantCount: boolean;
  internationalTravelCostAmount: boolean;
  hardwareCostAmount: boolean;
  softwareCostAmount: boolean;
  systemDevelopmentCostAmount: boolean;
  medianPriceStaffs: boolean;
  medianPriceConsultantTypes: boolean;
  medianPriceConsultantQualifications: boolean;
  medianPriceTemplateCode: boolean;
}

export interface considerInformationModel {
  label: string;
  value: string;
  result: boolean
}

// const PASS_TYPE = [
//   {
//     label: 'ผ่าน',
//     value: 'pass',
//   },
//   {
//     label: 'ไม่ผ่าน',
//     value: 'notPass',
//   },
// ];

function Step1({ onClickNext, onClickBack }: Props) {
  const { data, setData, readonly } = useContext(Context);
  const { criteriaConsiderationDDL, medianPriceTemplateDDL } = useLoaderData() as Loader;
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [currentPath, setCurrentPath] = useState('preprocurement-tor/detail');
  const [files, setFiles] = useState<IFile[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCommittee, setIsCommittee] = useState(false);
  const { userId } = useAppContext();
  const [medianPriceSourceData, setMedianPriceSourceData] = useState<MedianPriceSourceModel[]>([]);
  const [medianPriceConsiderInformationData, setMedianPriceConsiderInformationData] = useState<MedianPriceConsiderInformationModel[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectionCriteria, setselectionCriteria] = useState<string | undefined>(undefined);
  const [financialAmount, setFinancialAmount] = useState<number>();
  const [medianPriceTemplateId, setMedianPriceTemplateId] = useState<string>('');
  const [medianPriceTemplate, setMedianPriceTemplate] = useState<MedianPriceTemplateModel>({} as MedianPriceTemplateModel);
  const [medianPriceStaffs, setMedianPriceStaffs] = useState<MedianPriceStaffModel[]>([]);
  const [medianPriceConsultantTypes, setMedianPriceConsultantTypes] = useState<MedianPriceConsultantTypeQualifyModel[]>([]);
  const [medianPriceConsultantQualifications, setMedianPriceConsultantQualifications] = useState<MedianPriceConsultantTypeQualifyModel[]>([]);
  const [medianPriceTemplateCode, setMedianPriceTemplateCode] = useState<string>('');
  const [isShowCostSection, setIsShowCostSection] = useState<boolean>(false);
  const [isShowStaffSection, setIsShowStaffSection] = useState<boolean>(false);
  const [showMedianPriceSection, setShowMedianPriceSection] = useState<ShowMedianPriceSectionModel>({} as ShowMedianPriceSectionModel);
  const [objective, setObjective] = useState<string | undefined>(undefined);
  const [reasonDescription, setReasonDescription] = useState<string | undefined>(undefined);
  const [specification, setSpecification] = useState<string | undefined>(undefined);
  const [completeTimeToUseDescription, setCompleteTimeToUseDescription] = useState<string | undefined>(undefined);
  const [considerInformationData, setConsiderInformationData] = useState<considerInformationModel[]>([]);
  const [isconsider, setIsconsider] = useState('');

  const getMedianPriceByIdAsynce = async (id: string) => {
    const response = await MedianPriceService.getMedianPriceByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
    }
  };

  const handlerOnChangeTemplateValue = useCallback(async (value: string) => {
    setMedianPriceTemplateId(value);
    if (value) {
      getTemplateCodeAsync(value);
    } else {
      setMedianPriceTemplateCode('');
      SetShowSection('');
    }
  }, [medianPriceTemplateId]);

  const getTemplateCodeAsync = useCallback(async (value: string) => {
    const { data, status } = await shareValue.getSingleSharedValueAsync(value);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setMedianPriceTemplateCode(data.sharedValue.code);
    SetShowSection(data.sharedValue.code);
  }, []);

  const getFilesAsync = useCallback(async (id: string) => {
    const { data, status } = await MedianPriceService.getAttachmentAsync(id);

    if (status !== HttpStatusCode.OK) {
      toast.error('ไม่พบข้อมูล');

      return;
    }

    handleSetFile(data.attachments);

  }, []);

  const SetShowSection = useCallback((code: string) => {
    const newShowMedianPriceSection = showMedianPriceSection;
    setIsShowStaffSection(false);
    setIsShowCostSection(false);
    newShowMedianPriceSection.jobDescription = false;
    newShowMedianPriceSection.staffCompensationAmount = false;
    newShowMedianPriceSection.materialAmount = false;
    newShowMedianPriceSection.additionalCostAmount = false;
    newShowMedianPriceSection.unitPrice = false;
    newShowMedianPriceSection.consultantCount = false;
    newShowMedianPriceSection.internationalTravelCostAmount = false;
    newShowMedianPriceSection.hardwareCostAmount = false;
    newShowMedianPriceSection.softwareCostAmount = false;
    newShowMedianPriceSection.systemDevelopmentCostAmount = false;
    newShowMedianPriceSection.medianPriceStaffs = false;
    newShowMedianPriceSection.medianPriceConsultantTypes = false;
    newShowMedianPriceSection.medianPriceConsultantQualifications = false;
    newShowMedianPriceSection.medianPriceTemplateCode = false;

    switch (code) {
      case MedianPriceTemplateCodeEnum.MPTT01:
        newShowMedianPriceSection.jobDescription = true;
        break;
      case MedianPriceTemplateCodeEnum.MPTT02:
        setIsShowStaffSection(true);
        setIsShowCostSection(true);
        newShowMedianPriceSection.staffCompensationAmount = true;
        newShowMedianPriceSection.medianPriceStaffs = true;
        newShowMedianPriceSection.materialAmount = true;
        newShowMedianPriceSection.additionalCostAmount = true;
        break;
      case MedianPriceTemplateCodeEnum.MPTT03:
        setIsShowStaffSection(true);
        setIsShowCostSection(true);
        newShowMedianPriceSection.staffCompensationAmount = true;
        newShowMedianPriceSection.medianPriceStaffs = true;
        newShowMedianPriceSection.materialAmount = true;
        newShowMedianPriceSection.additionalCostAmount = true;
        break;
      case MedianPriceTemplateCodeEnum.MPTT04:
        setIsShowStaffSection(true);
        setIsShowCostSection(true);
        newShowMedianPriceSection.staffCompensationAmount = true;
        newShowMedianPriceSection.medianPriceConsultantTypes = true;
        newShowMedianPriceSection.medianPriceConsultantQualifications = true;
        newShowMedianPriceSection.consultantCount = true;
        newShowMedianPriceSection.materialAmount = true;
        newShowMedianPriceSection.internationalTravelCostAmount = true;
        newShowMedianPriceSection.additionalCostAmount = true;
        break;
      case MedianPriceTemplateCodeEnum.MPTT05:
        setIsShowCostSection(true);
        newShowMedianPriceSection.hardwareCostAmount = true;
        newShowMedianPriceSection.softwareCostAmount = true;
        newShowMedianPriceSection.systemDevelopmentCostAmount = true;
        newShowMedianPriceSection.additionalCostAmount = true;
        break;
      case MedianPriceTemplateCodeEnum.MPTT06:
        newShowMedianPriceSection.unitPrice = true;
        break;
    }
    setShowMedianPriceSection(newShowMedianPriceSection);
  }, []);

  const handleSetFile = (documents: AttachmentFileModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await MedianPriceService.uploadAttachmentAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        getFilesAsync(data.id);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {

    if (data && data.id) {
      const res = await MedianPriceService.removeAttachmentAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        getFilesAsync(data.id);

      }
    }

  }, [files, data.id]);

  useEffect(() => {

    if (data) {

      if (data.id) {
        getFilesAsync(data.id);
      }

      if (!data.status || data.status === MedianPriceStatus.None || data.status === MedianPriceStatus.Draft || data.status === MedianPriceStatus.Rejected || data.status === MedianPriceStatus.CommitteeRejected) {

        setIsDisabled(false);
      } else {

        setIsDisabled(true);
      }

      if (data.committees && data.committees.filter(x => x.userId === userId || x.delegateUserId === userId).length > 0) {
        setIsCommittee(true)
      } else {
        setIsCommittee(false)
      }

      if (data.considerInformation) {
        const considerInformation: considerInformationModel[] = [];

        data.considerInformation.sort((a, b) => a.sequence - b.sequence)
          .forEach((data, index) => {
            const newData: considerInformationModel = {
              label: data.considerInformationLabel,
              value: data.considerInformationId,
              result: data.result,
            };
            considerInformation.push(newData);
          });

        if (data.considerInformation.filter(x => x.result === true).length > 0) {

          const result = data.considerInformation.filter(x => x.result === true)[0].considerInformationId

          setIsconsider(result);
        }

        setConsiderInformationData(considerInformation);
        setMedianPriceConsiderInformationData(data.considerInformation);
      }

      if (data.medianPriceSources) {
        setMedianPriceSourceData(data.medianPriceSources)
      }

      if (data.financialAmount) {
        setFinancialAmount(data.financialAmount)
      }

      if (data.selectionCriteria) {
        setselectionCriteria(data.selectionCriteria)
      }

      if (data.objective) {
        setObjective(data.objective)
      }

      if (data.reasonDescription) {
        setReasonDescription(data.reasonDescription)
      }

      if (data.specification) {
        setSpecification(data.specification)
      }

      if (data.completeTimeToUseDescription) {
        setCompleteTimeToUseDescription(data.completeTimeToUseDescription)
      }

      if (data.medianPriceTemplateId) {
        setMedianPriceTemplateId(data.medianPriceTemplateId);
        getTemplateCodeAsync(data.medianPriceTemplateId);
      }

      if (data.medianPriceTemplate) {
        setMedianPriceTemplate(data.medianPriceTemplate);
      }

      if (data.medianPriceStaffs && data.medianPriceStaffs.length > 0) {
        setMedianPriceStaffs(data.medianPriceStaffs);
      } else {
        setMedianPriceStaffs(getDefaultStaff());
      }

      if (data.medianPriceConsultantTypes) {
        setMedianPriceConsultantTypes(data.medianPriceConsultantTypes);
      }

      if (data.medianPriceConsultantQualifications) {
        setMedianPriceConsultantQualifications(data.medianPriceConsultantQualifications);
      }

    }

  }, [data, userId]);

  useEffect(() => {
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);
    setCurrentPath(location.pathname.replace(paramId, ''));

  }, []);

  const onChangeCheckConsiderResult = (considerInformationId: string | number) => {
    const medianPriceConsiderInformationResult = [...medianPriceConsiderInformationData];

    medianPriceConsiderInformationResult.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        data.result = false;
      });

    const index = medianPriceConsiderInformationData?.findIndex(s => s.considerInformationId === considerInformationId);
    medianPriceConsiderInformationResult[index].result = true;

    setMedianPriceConsiderInformationData(medianPriceConsiderInformationResult);
  };

  const getDefaultStaff = () => {
    const newMedianPriceStaffs: MedianPriceStaffModel[] = [];
    for (let index = 0; index < defaultStaffValues.length; index++) {
      const newData: MedianPriceStaffModel = {
        id: undefined,
        medianPriceId: data.id,
        description: defaultStaffValues[index],
        sequence: index + 1,
        count: undefined
      };
      newMedianPriceStaffs.push(newData);
    }
    return newMedianPriceStaffs;
  };

  const onValidateMedianPriceByTemplate = (code: string) => {
    switch (code) {
      case MedianPriceTemplateCodeEnum.MPTT01:
        if (!medianPriceTemplate.jobDescription || medianPriceTemplate.jobDescription === '') {
          toast.warn('กรุณาระบุลักษณะงาน (โดยสังเขป)');
          return false
        }
        break;
      case MedianPriceTemplateCodeEnum.MPTT02:
        if (!medianPriceTemplate.staffCompensationAmount || medianPriceTemplate.staffCompensationAmount === 0) {
          toast.warn('กรุณาระบุค่าตอบแทนบุคลากร');
          return false
        }
        break;
      case MedianPriceTemplateCodeEnum.MPTT03:
        if (!medianPriceTemplate.staffCompensationAmount || medianPriceTemplate.staffCompensationAmount === 0) {
          toast.warn('กรุณาระบุค่าตอบแทนบุคลากร');
          return false
        }
        break;
      case MedianPriceTemplateCodeEnum.MPTT04:
        if (!medianPriceTemplate.staffCompensationAmount || medianPriceTemplate.staffCompensationAmount === 0) {
          toast.warn('กรุณาระบุค่าตอบแทนบุคลากร');
          return false
        }
        break;
      case MedianPriceTemplateCodeEnum.MPTT05:
        if (!medianPriceTemplate.systemDevelopmentCostAmount || medianPriceTemplate.systemDevelopmentCostAmount === 0) {
          toast.warn('กรุณาระบุค่าพัฒนาระบบ');
          return false
        }

        const totals = medianPriceSourceData.map(x => x.amount);
        const min = Math.min(...totals);
        const hardwareCostAmount = medianPriceTemplate.hardwareCostAmount ? medianPriceTemplate.hardwareCostAmount : 0;
        const softwareCostAmount = medianPriceTemplate.softwareCostAmount ? medianPriceTemplate.softwareCostAmount : 0;
        const additionalCostAmount = medianPriceTemplate.additionalCostAmount ? medianPriceTemplate.additionalCostAmount : 0;
        const sumTotal = hardwareCostAmount + softwareCostAmount + additionalCostAmount + medianPriceTemplate.systemDevelopmentCostAmount;

        if (sumTotal > min) {
          toast.warn('รายละเอียดค่าใช้จ่าย ต้องต่ำกว่า หรือเท่ากับราคาต่ำสุด ของแหล่งที่มาราคา');
          return false
        }

        break;
      default:
        return true;
        break;
    }

    return true;
  };

  const onSubmitAsync = async () => {

    submitForm();

    if (!medianPriceTemplateCode || medianPriceTemplateCode === '') {
      toast.warn('กรุณาเลือกรูปแบบเอกสารราคากลาง');
      return;
    }

    if (!financialAmount) {
      toast.warn('กรุณากรอกวงเงิน');
      return;
    }

    if (financialAmount && financialAmount <= 100000) {
      toast.warn('กรุณากรอกวงเงินมากกว่า 100,000 บาท');
      return;
    }

    if (!objective || objective === '') {
      toast.warn('กรุณาระบุวัตถุประสงค์การขอซื้อ');
      return;
    }

    if (!reasonDescription || reasonDescription === '') {
      toast.warn('กรุณาระบุเหตุผลและความจำเป็นที่จะซื้อหรือจ้างหรือเช่า');
      return;
    }

    if (!specification || specification === '') {
      toast.warn('กรุณาระบุรายละเอียดคุณลักษณะเฉพาะของพัสดุที่จะซื้อหรือจ้างหรือเช่าแล้วแต่กรณี');
      return;
    }

    if (!medianPriceConsiderInformationData || medianPriceConsiderInformationData.filter(x => x.result === true).length === 0) {
      toast.warn('กรุณาระบุข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา');
      return;
    }
    // if (!completeTimeToUseDescription || completeTimeToUseDescription === '') {
    //   toast.warn('กรุณาระบุกำหนดเวลาที่ต้องการใช้พัสดุนั้น หรือให้งานนั้นเสร็จแล้ว');
    //   return;
    // }

    if (!medianPriceSourceData || medianPriceSourceData.length === 0 || (medianPriceSourceData.filter((x) => x.description === null || x.description === '' || x.description === undefined
      || x.amount === undefined || x.amount === null || x.amount === 0 || Number.isNaN(x.amount)).length > 0)) {
      toast.warn('กรุณากรอกที่มาของราคากลาง (ราคาอ้างอิง)');
      return;
    }

    if (!onValidateMedianPriceByTemplate(medianPriceTemplateCode)) {
      return;
    }

    const medianPriceSource: MedianPriceSourceModel[] = [];
    if (medianPriceSourceData) {
      medianPriceSourceData.sort((a, b) => a.sequence - b.sequence)
        .forEach((item, index) => {
          medianPriceSource.push({
            description: item.description,
            sequence: index + 1,
            id: item.id,
            medianPriceId: item.medianPriceId,
            amount: item.amount,
          } as MedianPriceSourceModel);
        });
    }

    if (data && data.id) {
      const updateDataModel: UpdateMedianPriceModel = {
        id: data.id,
        financialAmount: financialAmount,
        selectionCriteria: selectionCriteria,
        medianPriceTemplateId: medianPriceTemplateId,
        medianPriceTemplate: medianPriceTemplate,
        medianPriceSources: medianPriceSource,
        considerInformation: medianPriceConsiderInformationData,
        medianPriceStaffs: medianPriceStaffs,
        medianPriceConsultantTypes: medianPriceConsultantTypes,
        medianPriceConsultantQualifications: medianPriceConsultantQualifications,
        objective: objective,
        reasonDescription: reasonDescription,
        specification: specification,
        completeTimeToUseDescription: completeTimeToUseDescription,
      };

      const res = await MedianPriceService.updateMedianPriceModelAsync(data.id, updateDataModel);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {

        toast.success('บันทึกข้อมูลสำเร็จ');

        getMedianPriceByIdAsynce(data.id)

      } else {
        toast.error(res.statusText);
      }
    } else {
      const createDataModel: CreateMedianPriceModel = {
        appointPreProcurementId: data.appointPreProcurement.id,
        termOfRefId: data.termOfRefId,
        financialAmount: financialAmount,
        remark: '',
        selectionCriteria: selectionCriteria,
        medianPriceTemplateId: medianPriceTemplateId,
        medianPriceTemplate: medianPriceTemplate,
        medianPriceSources: medianPriceSourceData,
        medianPriceConsiderInformation: medianPriceConsiderInformationData,
        medianPriceCommittees: data.committees,
        medianPriceStaffs: medianPriceStaffs,
        medianPriceConsultantTypes: medianPriceConsultantTypes,
        medianPriceConsultantQualifications: medianPriceConsultantQualifications,
        objective: objective,
        reasonDescription: reasonDescription,
        specification: specification,
        completeTimeToUseDescription: completeTimeToUseDescription,
      };

      const res = await MedianPriceService.createMedianPriceModelAsync(createDataModel);

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
        toast.success('บันทึกข้อมูลสำเร็จ');
        const newId: string = res.data;
        getMedianPriceByIdAsynce(newId)
        navigate(`/preprocurement-median-price/detail/${newId}`);

      } else {
        toast.error(res.statusText);
      }
    }
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <Title
          text='บุคคล/คณะกรรมการกำหนดราคากลาง'
          className='fs-5 text-primary'
          extraElement={(
            <div className='d-flex gap-2'>
              {
                data.isChanging && (
                  <div className='status'
                    style={{ marginRight: 10 }}>
                    <span><div><FaCircle style={{ fontSize: 10 }}
                      className='text-warning' /></div>
                      {TorEditCancelStatusConstant.Editing}</span>
                  </div>
                )
              }
              {
                data.isCanceling && (
                  <div className='status'
                    style={{ marginRight: 10 }}>
                    <span><div><FaCircle style={{ fontSize: 10 }}
                      className='text-danger' /></div>
                      {TorEditCancelStatusConstant.Canceling}</span>
                  </div>
                )
              }
              <StatusMedianPrice
                value={data.status}
              />
              <Button
                onClick={() => setShowHistory(!showHistory)}
                variant='outline-primary'
              >
                <FaHistory className='me-1' />
                ประวัติการใช้งาน
              </Button>
            </div>
          )}
        />
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}>
            <Table total={1}
              hidePagination>
              <thead>
                <tr>
                  <th style={{ width: 100 }}>ลำดับ</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th style={{ width: 300 }}>ฝ่าย/สำนัก</th>
                  <th style={{ width: 300 }}>ตำแหน่ง</th>
                  <th style={{ width: 400 }}>ตำแหน่งในบุคคล/คณะกรรมการ</th>
                </tr>
              </thead>
              <tbody>
                {(data.committees && data.committees.length > 0) && (
                  data.committees.map((data, index) => (
                    <tr key={index}>
                      <td className='text-center'>{index + 1}</td>
                      <td className='text-start'>{data.fullName}</td>
                      <td className='text-center'>{data.departmentName}</td>
                      <td className='text-center'>{data.positionName}</td>
                      <td className='text-center'>
                        {data.positionOnBoardName}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>

      <Row className='my-3'>
        <Col xs={12}>
          <Accordion defaultActiveKey='0'
            className='info'>
            <Accordion.Item eventKey='0'>
              <Accordion.Body>
                <Title
                  text='ข้อมูลเอกสารบันทึกข้อความแต่งตั้ง'
                  className='fs-5 text-primary'
                />
                <Row className='ps-40'>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='เลขที่กำหนดราคากลาง'
                      textReadonly={data.medianPriceNumber ?? '-'}
                    />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='เลขที่บันทึกแต่งตั้ง'
                      textReadonly={data.appointPreProcurement?.appointNumber ?? '-'} />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='วันที่เอกสารบันทึกข้อความแต่งตั้ง'
                      textReadonly={thaiFormatDateWithSlash(data.appointPreProcurement?.memorandumDate) ?? '-'}
                    />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='เลขที่ระบบสารบรรณ'
                      textReadonly={data.appointPreProcurement?.memorandumNo ?? '-'}
                    />
                    <Readonly label='ปีงบประมาณ'
                      textReadonly={data.appointPreProcurement?.budgetYear ?? '-'}
                      className='show-hide-info' />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='อ้างอิงแผนการจัดซื้อจัดจ้าง'
                      textReadonly={data.appointPreProcurement?.planNumber ?? '-'}
                      className='show-header-info' />
                    <Readonly label='ชื่อโครงการ'
                      textReadonly={data.appointPreProcurement?.planName ?? '-'}
                      className='show-hide-info' />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='ฝ่าย/สำนัก'
                      textReadonly={data.appointPreProcurement?.departmentName ?? '-'}
                      className='show-header-info' />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='วิธีจัดหา'
                      textReadonly={data.appointPreProcurement?.supplyMethodName}
                      className='show-hide-info' />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='ประเภทจัดหา'
                      textReadonly={data.appointPreProcurement?.supplyMethodTypeName}
                      className='show-hide-info' />
                  </Col>
                  <Col sm={12}
                    md={6}
                    lg={4}>
                    <Readonly label='วิธี'
                      textReadonly={data.appointPreProcurement?.supplyMethodSpecialTypeName}
                      className='show-hide-info' />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>

      <Card className='mt-3'>
        <Title text='รายละเอียดราคากลาง'
          className='fs-5 text-primary' />
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={4}>
            <Selector
              label='รูปแบบเอกสารราคากลาง'
              placeholder='รูปแบบเอกสารราคากลาง'
              items={medianPriceTemplateDDL}
              value={medianPriceTemplateId}
              onChange={(val) => handlerOnChangeTemplateValue(val)}
              rule={{ required: true }}
              disabled={isDisabled}
            />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12} md={12}>
            <InputTextArea
              label='วัตถุประสงค์การขอซื้อ'
              value={data.objective}
              onChange={(val) => setObjective(val)}
              rule={{ required: true }}
              disabled={true} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12} md={12}>
            <InputTextArea
              label='เหตุผลและความจำเป็นที่จะซื้อหรือจ้างหรือเช่า'
              value={data.reasonDescription}
              onChange={(val) => setReasonDescription(val)}
              rule={{ required: true }}
              disabled={true} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12} md={12}>
            <InputTextArea
              label='รายละเอียดคุณลักษณะเฉพาะของพัสดุที่จะซื้อหรือจ้างหรือเช่าแล้วแต่กรณี'
              value={data.specification}
              onChange={(val) => setSpecification(val)}
              rows={10}
              rule={{ required: true }}
              disabled={true} />
          </Col>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12} md={12}>
            <InputTextArea
              label='กำหนดเวลาที่ต้องการใช้พัสดุนั้น หรือให้งานนั้นเสร็จแล้ว'
              value={data.completeTimeToUseDescription}
              onChange={(val) => setCompleteTimeToUseDescription(val)}
              rule={{ required: false }}
              rows={10}
              disabled={true} />
          </Col>
        </Row>
        <>
          {
            showMedianPriceSection.jobDescription && (
              <Row className='ps-40'>
                <Col sm={12}
                  lg={12}>
                  <InputTextArea
                    label='ลักษณะงาน (โดยสังเขป)'
                    value={medianPriceTemplate?.jobDescription}
                    onChange={(val) => setMedianPriceTemplate({ ...medianPriceTemplate, jobDescription: val })}
                    rule={{ required: true }}
                    disabled={isDisabled}
                  />
                </Col>
              </Row>
            )
          }
        </>
      </Card>

      <Card className='mt-3'>
        <Title text='ข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา'
          className='fs-5 text-primary' />
        <Row>
          <Col sm={12}
            className='mt-2 ps-40'>
            <p className='mb-0'>ราคากลางของพัสดุที่จะซื้อหรือจ้างเช่า หรือ
              ข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา</p>
            <p>กรณีวงเงิน <u>เกิน 100,000 บาท</u> มีข้อมูลประกอบการพิจารณาด้านความเหมาะสมของราคา ดังนี้</p>
            <ul>
              {/* {medianPriceConsiderInformationData.map((item, index) => (
              <li key={generateUniqueId(index)}> */}
              {/* <Check
                  label={item.considerInformationLabel}
                  onChange={(val) => onChangeCheckConsiderResult(item.considerInformationId, val)}
                  value={item.result}
                  disabled={isDisabled}
                /> */}
              <Radio
                name='result'
                disabled={isDisabled}
                items={considerInformationData}
                value={isconsider}
                rule={{ required: true }}
                onChange={(val) => onChangeCheckConsiderResult(val)}
              />
              {/* </li>
            ))} */}
            </ul>
          </Col>
        </Row>
      </Card>

      <Card className='mt-3'>
        <Title text='ตารางแสดงวงเงินงบประมาณที่ได้รับจัดสรรและรายละเอียดค่าใช้จ่าย'
          className='fs-5 text-primary' />
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={4}>
            <DatePicker
              label='วันที่กำหนดราคากลาง (ราคาอ้างอิง)'
              placeholder='วันที่กำหนดราคากลาง (ราคาอ้างอิง)'
              value={data.medianPriceDate}
              rule={{ required: false }}
              fullDate
              disabled={true}
            />
          </Col>
          <Col sm={12}
            lg={4}>
            <Currency
              label='วงเงิน (บาท)'
              rule={{ required: true }}
              placeholder='วงเงิน (บาท)'
              value={financialAmount}
              onChange={(val) => setFinancialAmount(Number(val))}
              disabled={isDisabled}
            />
          </Col>
          <>
            {
              showMedianPriceSection.unitPrice && (
                <Col sm={12}
                  lg={4}>
                  <Currency
                    label='ราคา/หน่วย (ถ้ามี)'
                    rule={{ required: false }}
                    placeholder='ราคา/หน่วย (ถ้ามี)'
                    value={medianPriceTemplate.unitPrice}
                    onChange={(val) => setMedianPriceTemplate({ ...medianPriceTemplate, unitPrice: Number(val) })}
                    disabled={isDisabled}
                  />
                </Col>
              )
            }
          </>
        </Row>
        <Row className='mt-3 align-items-center ps-40'>
          <Col sm={12}
            lg={12}>
            <MedianPriceSources
              medianPriceId={data.id}
              medianPriceSources={medianPriceSourceData}
              onMedianPriceSources={(obj) => setMedianPriceSourceData(pre => (obj))}
              isDisabled={isDisabled || !isCommittee}
            />
          </Col>
        </Row>
      </Card>
      {
        isShowStaffSection && (
          <Card className='mt-3'>
            <Title text='รายละเอียดบุคลากร'
              className='fs-5 text-primary' />
            <div>
              {
                showMedianPriceSection.staffCompensationAmount && (
                  <Row className='mt-2 ps-40'>
                    <Col sm={12} md={4}>
                      <Currency
                        label='ค่าตอบแทนบุคลากร (บาท)'
                        rule={{ required: true }}
                        value={medianPriceTemplate?.staffCompensationAmount}
                        onChange={(val) => setMedianPriceTemplate({ ...medianPriceTemplate, staffCompensationAmount: Number(val) })}
                        disabled={isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                showMedianPriceSection.consultantCount && (
                  <Row className='mt-2 ps-40'>
                    <Col sm={12} md={4}>
                      <InputNumber
                        label='จำนวนที่ปรึกษา (คน)'
                        rule={{ required: false }}
                        value={medianPriceTemplate?.consultantCount}
                        onChange={(val) => setMedianPriceTemplate({ ...medianPriceTemplate, consultantCount: Number(val) })}
                        disabled={isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                showMedianPriceSection.medianPriceStaffs && (
                  <Row className='ps-40'>
                    <Col sm={12}>
                      <MedianPriceStaff
                        medianPriceId={data.id}
                        medianPriceStaffs={medianPriceStaffs}
                        onMedianPriceStaffs={(obj) => setMedianPriceStaffs(obj)}
                        isDisabled={isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                showMedianPriceSection.medianPriceConsultantTypes && (
                  <Row className='mt-3 align-items-center ps-40'>
                    <Col sm={12}
                      lg={12}>
                      <MedianPriceConsultantType
                        medianPriceId={data.id}
                        medianPriceConsultantTypes={medianPriceConsultantTypes}
                        onMedianPriceConsultantTypes={(obj) => setMedianPriceConsultantTypes(pre => (obj))}
                        isDisabled={isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                showMedianPriceSection.medianPriceConsultantQualifications && (
                  <Row className='mt-3 align-items-center ps-40'>
                    <Col sm={12}
                      lg={12}>
                      <MedianPriceConsultantQualification
                        medianPriceId={data.id}
                        medianPriceConsultantQualifications={medianPriceConsultantQualifications}
                        onMedianPriceConsultantQualifications={(obj) => setMedianPriceConsultantQualifications(pre => (obj))}
                        isDisabled={isDisabled || !isCommittee}
                      />
                    </Col>
                  </Row>
                )
              }
            </div>
          </Card>
        )
      }

      {
        isShowCostSection && (
          <Card className='mt-3'>
            <Title text='รายละเอียดค่าใช้จ่าย'
              className='fs-5 text-primary' />
            <div>
              {
                showMedianPriceSection.materialAmount && (
                  <Row className='mt-2 ps-40'>
                    <Col sm={12} md={4}>
                      <Currency
                        label='ค่าวัสดุอุปกรณ์ (ถ้ามี)'
                        rule={{ required: false }}
                        placeholder='ค่าวัสดุอุปกรณ์'
                        value={medianPriceTemplate?.materialAmount}
                        onChange={(val) => setMedianPriceTemplate({ ...medianPriceTemplate, materialAmount: Number(val) })}
                        disabled={isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                showMedianPriceSection.internationalTravelCostAmount && (
                  <Row className='mt-2 ps-40'>
                    <Col sm={12} md={4}>
                      <Currency
                        label='ค่าใช้ในการเดินทางไปต่างประเทศ (ถ้ามี)'
                        rule={{ required: false }}
                        placeholder='ค่าใช้ในการเดินทางไปต่างประเทศ (ถ้ามี)'
                        value={medianPriceTemplate?.internationalTravelCostAmount}
                        onChange={(val) => setMedianPriceTemplate({ ...medianPriceTemplate, internationalTravelCostAmount: Number(val) })}
                        disabled={isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                showMedianPriceSection.hardwareCostAmount && (
                  <Row className='mt-2 ps-40'>
                    <Col sm={12} md={4}>
                      <Currency
                        label='ค่า Hardware'
                        rule={{ required: false }}
                        placeholder='ค่า Hardware'
                        value={medianPriceTemplate?.hardwareCostAmount}
                        onChange={(val) => setMedianPriceTemplate({ ...medianPriceTemplate, hardwareCostAmount: Number(val) })}
                        disabled={isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                showMedianPriceSection.softwareCostAmount && (
                  <Row className='mt-2 ps-40'>
                    <Col sm={12} md={4}>
                      <Currency
                        label='ค่า Software'
                        rule={{ required: false }}
                        placeholder='ค่า Software'
                        value={medianPriceTemplate?.softwareCostAmount}
                        onChange={(val) => setMedianPriceTemplate({ ...medianPriceTemplate, softwareCostAmount: Number(val) })}
                        disabled={isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                showMedianPriceSection.systemDevelopmentCostAmount && (
                  <Row className='mt-2 ps-40'>
                    <Col sm={12} md={4}>
                      <Currency
                        label='ค่าพัฒนาระบบบ'
                        rule={{ required: true }}
                        placeholder='ค่าพัฒนาระบบบ'
                        value={medianPriceTemplate?.systemDevelopmentCostAmount}
                        onChange={(val) => setMedianPriceTemplate({ ...medianPriceTemplate, systemDevelopmentCostAmount: Number(val) })}
                        disabled={isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
              {
                showMedianPriceSection.additionalCostAmount && (
                  <Row className='mt-2 ps-40'>
                    <Col sm={12} md={4}>
                      <Currency
                        label='ค่าใช้จ่ายอื่นๆ (ถ้ามี)'
                        rule={{ required: false }}
                        placeholder='ค่าใช้จ่ายอื่นๆ (ถ้ามี)'
                        value={medianPriceTemplate?.additionalCostAmount}
                        onChange={(val) => setMedianPriceTemplate({ ...medianPriceTemplate, additionalCostAmount: Number(val) })}
                        disabled={isDisabled}
                      />
                    </Col>
                  </Row>
                )
              }
            </div>
          </Card>
        )
      }

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              id={data.id}
              disabled={!data.id || readonly}
            />
          </Col>
        </Row>
      </Card>
      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="กลับหน้าแรก"
        />
        {
          (isCommittee && (data.status === MedianPriceStatus.Draft || data.status === MedianPriceStatus.None
            || data.status === MedianPriceStatus.Rejected || data.status === MedianPriceStatus.CommitteeRejected)) && (
            <ButtonCustom
              onClick={onSubmitAsync}
              text="บันทึก"
            />
          )
        }
        {
          (data.status !== MedianPriceStatus.None) ? (
            <ButtonCustom
              onClick={onClickNext}
              text="ถัดไป"
            />
          ) : (<div style={{ width: 120 }} />)
        }
      </div>

      <HistoryModal
        title='ประวัติการใช้งานกำหนดราคากลาง'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={data.id}
      />

      <Modal
        show={showEdit}
        size='lg'
        onHide={() => setShowEdit(!showEdit)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowEdit(!showEdit)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <SearchNameModal
        show={show}
        onHide={() => setShow(!show)}
        data={[]}
      />
    </div>
  );
}

export default Step1;
