export const enum TorStatusConstant {
  Draft = 'Draft',
  DraftTor = 'DraftTor',
  WaitingForCommitteeApprove = 'WaitingForCommitteeApprove',
  WaitingForDirectorJorPorAssign = 'WaitingForDirectorJorPorAssign',
  WaitingForAssignResponsible = 'WaitingForAssignResponsible',
  DraftSectionApprover = 'DraftSectionApprover',
  WaitingForApprove = 'WaitingForApprove',
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected'
}

export const enum TorEditCancelStatusConstant {
  Canceling = 'อยู่ระหว่างขอยกเลิก',
  Editing = 'อยู่ระหว่างขอแก้ไข'
}

export const enum TorInRefCodeConstant {
  JorPorDirector = 'JorPorDirector',
  Assignee = 'Assignee',
  Responsible = 'Responsible'
}

export const enum TorBudgetConstant {
  TwoMillion = 2000000
}
