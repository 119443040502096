import {
  Modal,
  Status,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { StatusMedianPrice } from 'components/StatusMedianPrice';
import { HistoryListResponseModel } from 'models/PP/MedianPriceModel';
import {
  useEffect,
  useState,
} from 'react';
import { Modal as ModalBT } from 'react-bootstrap';
import MedianPriceService from 'services/PP/MedianPriceService';
import {
  fullDatetime,
  HttpStatusCode,
} from 'utils';

interface Props {
  show: boolean;
  onHide?: () => void;
  title?: string;
  id?: string;
}

export function HistoryModal(props: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [history, setHistory] = useState<HistoryListResponseModel>({} as HistoryListResponseModel);

  useEffect(() => {
    if (props.id) {
      getTorHistoryAsynce(page, size);
    }
  }, [props.id]);

  const getTorHistoryAsynce = async (page: number, size: number) => {
    setPage(page);
    setSize(size);
    const response = await MedianPriceService.getListHistoryAsync(page, size, props.id);
    if (response.status === HttpStatusCode.OK) {
      setHistory(response.data);
    }
  };

  const handleSetPagination = (page: number, size: number) => {
    getTorHistoryAsynce(page, size);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => (props.onHide ? props.onHide() : null)}
      size="xl"
    >
      <Modal.Header>
        <Title
          text="ประวัติการใช้งานกำหนดราคากลาง"
          className="fs-5 text-primary"
        />
      </Modal.Header>
      <ModalBT.Body className="p-0 ps-4">
        <Table
          className="my-3"
          total={history.totalRecords}
          onChange={(size, page) => {
            handleSetPagination(page, size);
          }}
        >
          <thead>
            <tr>
              <th>วันที่/เวลา</th>
              <th>ผู้ดำเนินการ</th>
              <th>การดำเนินการ</th>
              <th style={{ minWidth: 100, width: 100 }}>สถานะการดำเนินการ</th>
              <th>หมายเหตุ</th>
            </tr>
          </thead>
          <tbody>
            {
              (history && history.data) && (
                history.data.map((data, index) => (
                  <tr key={index}>
                    <td className="text-center">{fullDatetime(data.date)}</td>
                    <td>{data.fullName}</td>
                    <td>{data.action}</td>
                    <td className="text-start">
                      <Status value={data.status}
                        type={StatusType.MEDIANPRICE} />
                    </td>
                    <td>{data.remark}</td>
                  </tr>
                ))
              )
            }
          </tbody>
        </Table>
      </ModalBT.Body>
    </Modal>
  );
}
