import {
  Card,
  Check,
  Currency,
  Input,
  InputTextArea,
  Modal,
  Radio,
  Selector,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import {
  DocumentModel,
  IFile,
  ItemModel,
} from 'models';
import {
  CoiAndWatchListAndEgp,
  Consideration,
  ConsiderationUpdate,
  Entrepreneurs,
  getEntrepreneurType,
  MaintenancesModel,
  Parcels,
  ParcelsUpdate,
  ResultRemark,
} from 'models/PR/JorPor06Models';
import { Context } from 'pages/Procurement/CreateJOR006/Detail/index';
import {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaEraser,
  FaPlus,
  FaSave,
  FaSearch,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  jorPor06,
  shareValue,
} from 'services';
import InvitationServices from 'services/PR/InvitationServices';
import {
  HttpStatusCode,
  THCurrency,
  submitForm,
} from 'utils';
import { convertNationalToThWord } from 'utils/convertors/convertNational';
import toast from 'utils/toast';
import UploadFile from './UploadFile';
import { useLoaderData } from 'react-router';
import { setuid } from 'process';
import { InputNumber } from 'components/Controls/InputNumber';
import { TraderModalTypeConstant } from 'constant/ProcumentInvitationStatus';

interface COIAndWatchListProps {
  entrepreneurData: Entrepreneurs;
  show: boolean;
  titleName?: string;
  type: string;
  onSubmit?: () => void;
  onHide: () => void;
  enabledGetDataAfterSubmit?: boolean;
}

const PASS_TYPE = [
  {
    label: 'ผ่าน',
    value: 'pass',
  },
  {
    label: 'ไม่ผ่าน',
    value: 'notPass',
  },
];

const formatter = new Intl.NumberFormat('th-TH', {
  currency: 'THB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function COIAndWatchListModal({
  show,
  onHide,
  titleName,
  type,
  entrepreneurData,
  enabledGetDataAfterSubmit,
}: COIAndWatchListProps) {
  const {
    getJorPor06DetailAsync,
    disabledField,
  } = useContext(Context);
  const [entrepreneurs, setEntrepreneurs] = useState<Entrepreneurs>({} as Entrepreneurs);
  const [resultData, setResultData] = useState<ResultRemark>({} as ResultRemark);
  const [onInit, setOnInit] = useState(true);
  const [files, setFiles] = useState<IFile[]>([]);

  useEffect(() => {
    if (show && entrepreneurData) {
      setEntrepreneurs(entrepreneurData);
      if (type === 'Coi') {
        setResultData(entrepreneurData.coi);
      } else if (type === 'WatchList') {
        setResultData(entrepreneurData.watchList);
      } else {
        setResultData(entrepreneurData.eGp);
      }

      getTraderDocumentByIdAsync(entrepreneurData.inviteEntrepreneursId, type);
      setOnInit(false);
    }
  }, [show]);

  const onHideModal = () => {
    onHide();
  };

  const setInitialValueCheck = (value: boolean): string => {
    if (value === null) {
      return 'null';
    }

    if (value) {
      return 'pass';
    }

    return 'notPass';
  };

  const onClickLink = (link: string) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const handleOnUpdateAsync = async () => {
    const body: CoiAndWatchListAndEgp = {
      jorPor06Id: entrepreneurs.jorPor06Id,
      remark: resultData.remark,
      result: resultData.result !== null ? resultData.result : undefined,
      type,
    };

    submitForm();

    if (!resultData.result && !resultData.remark) {
      return;
    }

    onUpdateAsync(body);
  };

  const onUpdateAsync = async (body: CoiAndWatchListAndEgp) => {

    const response = await jorPor06.updateCOIAndWatchAndEgpAsync(entrepreneurs.inviteEntrepreneursId, body);

    if (response.status === HttpStatusCode.ACCEPTED) {
      toast.success('บันทึกผลสำเร็จ');

      onHide();

      if (enabledGetDataAfterSubmit) {
        getJorPor06DetailAsync();
      }
    }
  };

  const handleDocFile = (documents: DocumentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };
      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    const newUpdFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFiles((pre) => [...pre, newFile]);
      newUpdFiles.push(file);
    }

    if (newUpdFiles && newUpdFiles.length > 0 && entrepreneurs.inviteEntrepreneursId) {
      const res = await InvitationServices.onAttachmentInviteEntrepreneurDocumentByIdAsync(entrepreneurs.inviteEntrepreneursId, type, newUpdFiles);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('อัปโหลดเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('อัปโหลดเอกสารแนบสำเร็จ');
      await getTraderDocumentByIdAsync(entrepreneurs.inviteEntrepreneursId, type);
    }
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    const delId = newArray.filter((i) => i.name === docName)[0].id;
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName && delId && entrepreneurs.inviteEntrepreneursId) {
      const res = await InvitationServices.deleteInviteEntrepreneurDocumentByIdAsync(entrepreneurs.inviteEntrepreneursId, delId);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('ลบเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('ลบเอกสารแนบสำเร็จ');
      await getTraderDocumentByIdAsync(entrepreneurs.inviteEntrepreneursId, type);
    }
  }, [files, entrepreneurs.inviteEntrepreneursId]);

  const getTraderDocumentByIdAsync = async (id: string, documentType: string) => {

    const response = await InvitationServices.getInviteEntrepreneurDocumentAsync(id, documentType);

    if (response.status === HttpStatusCode.OK) {
      handleDocFile(response.data);
    }
  };

  const handleSetResultData = async (paramResult: boolean) => {
    setResultData({
      ...resultData,
      result: paramResult,
    })

    if (type === TraderModalTypeConstant.COI) {
      entrepreneurs.coi.result = paramResult;
    } else if (type === TraderModalTypeConstant.WATCHLIST) {
      entrepreneurs.watchList.result = paramResult;
    } else if (type === TraderModalTypeConstant.EGP) {
      entrepreneurs.eGp.result = paramResult;
    }
  };

  return (
    <Modal show={show}
      size='xl'>
      {!onInit ? (
        <>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <h5 className='mb-1'>{titleName ?? ''}</h5>
            <FaTimes onClick={onHideModal} />
          </div>
          <hr />
          <Card className='mt-2'>
            <Title className='fs-5 text-primary'
              text='ข้อมูลคู่ค้า' />
            <Row className='mx-2 mt-3'>
              <Col sm={4}>
                <p>สัญชาติของผู้ประกอบการ : {convertNationalToThWord(entrepreneurs.nationality)} </p>
              </Col>
              <Col sm={4}>
                <p>ประเภท : {getEntrepreneurType(entrepreneurs.type)} </p>
              </Col>
            </Row>
            <Row className='mx-2'>
              <Col sm={4}>
                <p>เลขประจำตัวผู้เสียภาษี : {entrepreneurs.taxPayerIdentificationNo}</p>
              </Col>
              <Col sm={4}>
                <p>ประเภทผู้ประกอบการ : {entrepreneurs.entrepreneurType} </p>
              </Col>
              <Col sm={4}>
                <p>ชื่อสถานประกอบการ : {entrepreneurs.establishmentName} </p>
              </Col>
            </Row>
            <Row className='mx-2'>
              <Col sm={4}>
                <p>หมายเลขโทรศัพท์สำหรับติดต่อ : {entrepreneurs.tel} </p>
              </Col>
              <Col sm={4}>
                <p>อีเมล : {entrepreneurs.email} </p>
              </Col>
            </Row>
          </Card>
          <Card className='mt-2'>
            <Title className='fs-5 text-primary'
              text={`ข้อมูล ${titleName}`} />
            <Row className='mt-1'>
              <Col sm={12}>
                {
                  type === TraderModalTypeConstant.COI
                    ? (<Button variant='link'
                      onClick={() => onClickLink(entrepreneurs.coi.webLink ?? "")}>
                      {
                        entrepreneurs.coi.webLink
                      }
                    </Button>)
                    : <></>
                }
                {
                  type === TraderModalTypeConstant.WATCHLIST
                    ? (<Button variant='link'
                      onClick={() => onClickLink(entrepreneurs.watchList.webLink ?? "")}>
                      {
                        entrepreneurs.watchList.webLink
                      }
                    </Button>)
                    : <></>
                }
                {
                  type === TraderModalTypeConstant.EGP
                    ? (<Button variant='link'
                      onClick={() => onClickLink(entrepreneurs.eGp.webLink ?? "")}>
                      {
                        entrepreneurs.eGp.webLink
                      }
                    </Button>)
                    : <></>
                }

              </Col>
              <Col sm={12}
                className='mx-3 mt-2 mb-2'>
                <Radio
                  name='result'
                  disabled={disabledField}
                  inline
                  dflex
                  items={PASS_TYPE}
                  value={setInitialValueCheck(resultData.result)}
                  rule={{ required: true }}
                  onChange={(value) => handleSetResultData(value === 'pass')} />
              </Col>
              <Col sm={12}>
                <InputTextArea
                  name='remark'
                  disabled={disabledField}
                  label='หมายเหตุ'
                  placeholder='ระบุหมายเหตุ'
                  value={resultData.remark}
                  rule={{ required: !resultData.result }}
                  onChange={(value) => setResultData({
                    ...resultData,
                    remark: value,
                  })} />
              </Col>
            </Row>
          </Card>
          <Card className='mt-3'>
            <Title text='เอกสารแนบ'
              className='fs-5 text-primary' />
            <Row className='justify-content-center'>
              <Col sm={12}
                lg={6}>
                <UploadFile
                  files={files}
                  handleFileChange={handleFileChange}
                  removeItem={removeItemAsync}
                  inviteEntreprenurId={entrepreneurs.inviteEntrepreneursId}
                  disabled={disabledField}
                />
              </Col>
            </Row>
          </Card>
          <ModalBT.Footer>
            {!disabledField && (
              <>
                <Button
                  variant='outline-primary'
                  onClick={onHideModal}
                  className='me-2 px-3'>
                  ยกเลิก
                </Button>
                <Button
                  variant='success'
                  onClick={handleOnUpdateAsync}
                  className='me-2 px-3'>
                  <FaSave className='me-1 pb-1 fs-5' />
                  บันทึก
                </Button>
              </>
            )}
          </ModalBT.Footer>
        </>
      ) : <></>}
    </Modal>
  );
}

const ENTREPRENEUR_TYPE = [
  {
    label: 'ทั้งหมด',
    value: 'All',
  },
  {
    label: 'นิติบุคคล',
    value: 'JuristicPerson',
  },
  {
    label: 'บุคคลธรรมดา',
    value: 'Individual',
  },
];

interface TraderListProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (add: number) => void;
}

function TraderListModal({
  show,
  onHide,
  onSubmit,
}: TraderListProps) {
  const onHideModal = () => {
    onHide();
  };

  const onSubmitModal = () => {
    onSubmit(1);
    onHideModal();
  };

  return (
    <Modal show={show}
      size='xl'>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <h5 className='mb-1'>ค้นหาคู่ค้า</h5>
        <FaTimes onClick={onHideModal} />
      </div>
      <hr />
      <Row>
        <Col sm={4}
          className='mx-3 mt-2 mb-2'>
          <Radio
            label='ประเภทผู้ประกอบการ'
            items={ENTREPRENEUR_TYPE}
            value='All'
            inline
            dflex
            name='name1'
          />
        </Col>
        <Col sm={3}>
          <Input
            label='เลขประจำตัวผู้เสียภาษี'
          />
        </Col>
        <Col sm={3}>
          <Input
            label='ชื่อสถานประกอบการ'
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className='d-flex gap-2 button-inline'>
            <Button variant='primary'
              className='d-flex align-items-center gap-2'>
              <FaSearch />ค้นหา
            </Button>
            <Button
              variant='outline-primary'
              className='d-flex align-items-center gap-2'
            >
              <FaEraser />ล้าง
            </Button>
          </div>
        </Col>
      </Row>
      <Table className='mt-3'
        total={3}>
        <thead>
          <tr>
            <th style={{ minWidth: 80 }}>ลำดับ</th>
            <th style={{ minWidth: 100 }}>เลขประจำตัวผู้เสียภาษี</th>
            <th style={{ minWidth: 300 }}>ประเภทผู้ประกอบการ</th>
            <th style={{ minWidth: 200 }}>ชื่อสถานประกอบการ</th>
            <th style={{ minWidth: 150 }}>อีเมล</th>
            <th style={{ minWidth: 80 }} />
          </tr>
        </thead>
        <tbody>
          {
            Array.from({ length: 3 })
              .map((_, index) => (
                <tr
                  key={index}
                  className='text-center'>
                  <td>{index + 1}</td>
                  <td>8765432119861</td>
                  <td>บริษัทจำกัด</td>
                  <td>เครื่องไฟฟ้าไทย</td>
                  <td>codhard@codehard.co.th</td>
                  <td className='text-end'>
                    <Button
                      variant='primary'
                      onClick={onSubmitModal}
                    >
                      เลือก
                    </Button>
                  </td>
                </tr>
              ))
          }
        </tbody>
      </Table>
    </Modal>
  );
}

interface BidPriceProps {
  entrepreneurData: Entrepreneurs;
  show: boolean;
  type: boolean;
  onHide: () => void;
  onSubmit?: () => void;
  setType: Dispatch<React.SetStateAction<boolean>>;
  vatTypeItems: ItemModel[];
  financialAmount: number;
  medianPriceSourcesAmount: number;
  isTorMA: any;
  unitOfMeasureItems: ItemModel[];
  periodUnitItems: ItemModel[];
}

function BidPrice(props: BidPriceProps) {
  const {
    getJorPor06DetailAsync,
    disabledField,
  } = useContext(Context);
  const [entrepreneurs, setEntrepreneurs] = useState<Entrepreneurs>({} as Entrepreneurs);
  const [parcelData, setParcelData] = useState<Parcels[]>([]);
  const [maintenancesData, setMaintenanceData] = useState<MaintenancesModel[]>([]);
  const [considerationsData, setConsiderationsData] = useState<Consideration>({} as Consideration);
  const [winnerReasonItems, setWinnerReasonItems] = useState<ItemModel[]>([]);
  const [onInit, setOnInit] = useState(true);
  const [totalOfferPrice, setTotalOfferPrice] = useState(0);
  const [totalConfirmPrice, setTotalConfirmPrice] = useState(0);
  const [totalConfirmPriceLower, setTotalConfirmPriceLower] = useState(0);
  const [totalConfirmPriceLowerPercent, setTotalConfirmPriceLowerPercent] = useState(0);
  const [budgetAmountLower, setBudgetAmountLower] = useState(0);
  const [budgetAmountLowerPercent, setBudgetAmountLowerPercent] = useState(0);
  const [medianPriceSourcesAmountLower, setMedianPriceSourcesAmountLower] = useState(0);
  const [medianPriceSourcesAmountLowerPercent, setMedianPriceSourcesAmountLowerPercent] = useState(0);
  const [vatType, setVatType] = useState<ItemModel[]>([]);
  const [UnitType, setUnitType] = useState<ItemModel[]>([]);
  const [periodUnitType, setPeriodUnitType] = useState<ItemModel[]>([]);

  useEffect(() => {
    if (props.show && props.entrepreneurData) {
      setEntrepreneurs(props.entrepreneurData);
      setParcelData(props.entrepreneurData.parcels);
      setConsiderationsData(props.entrepreneurData.consideration);
      getWinnerReasonItemsAsync();
      setOnInit(false);
      setMaintenanceData(props.entrepreneurData.maintenances);
    }
  }, [props.show]);

  useEffect(() => {
    getTotalOfferPriceAndTotalConfirmPrice();
  }, [parcelData, maintenancesData]);

  useEffect(() => {
    if (props.vatTypeItems) {
      setVatType(props.vatTypeItems);
    }
  }, [props.vatTypeItems]);

  // useEffect(() => {
  //   if (props.isTorMA) {
  //     setIsTorMA(props.isTorMA);
  //   }
  // }, [props.isTorMA]);

  useEffect(() => {
    if (props.unitOfMeasureItems) {
      setUnitType(props.unitOfMeasureItems);
    }
  }, [props.unitOfMeasureItems]);

  useEffect(() => {
    if (props.periodUnitItems) {
      setPeriodUnitType(props.periodUnitItems);
    }
  }, [props.periodUnitItems]);


  const getWinnerReasonItemsAsync = async () => {
    const {
      data,
      status,
    } = await shareValue.getWinnerAndReasonListAsync();

    if (status === HttpStatusCode.OK) {
      setWinnerReasonItems(data);
    }
  };

  const getTotalOfferPriceAndTotalConfirmPrice = () => {
    const offerPrice = parcelData.map(d => d.offerPriceTotal);
    const sumOfferPrice = offerPrice.reduce((x, y) => x + y, 0);
    const confirmPrice = parcelData.map(d => d.confirmPriceTotal);
    const sumConfirmPrice = confirmPrice.reduce((x, y) => x + y, 0);

    const offerPriceMA = maintenancesData.map(m => m.offerPriceTotal);
    const sumOfferPriceMA = offerPriceMA.reduce((x, y) => x + y, 0);
    const confirmPriceMA = maintenancesData.map(d => d.confirmPriceTotal);
    const sumConfirmPriceMA = confirmPriceMA.reduce((x, y) => x + y, 0);

    const sumOfferPriceAll = (sumOfferPrice + sumOfferPriceMA);
    const sumConfirmPriceAll = (sumConfirmPrice + sumConfirmPriceMA);

    const totalConfirmPriceLower = sumOfferPriceAll - sumConfirmPriceAll;
    const totalConfirmPriceLowerPercent = sumOfferPriceAll > 0 ? (totalConfirmPriceLower * 100) / sumOfferPriceAll : 0;

    const budgetAmountLower = sumConfirmPriceAll > 0 ? props.financialAmount - sumConfirmPriceAll : 0;
    const budgetAmountLowerPercent = sumOfferPriceAll > 0 ? (budgetAmountLower * 100) / props.financialAmount : 0;

    const medianPriceAmountLower = sumConfirmPriceAll > 0 && props.medianPriceSourcesAmount > 0 ? props.medianPriceSourcesAmount - sumConfirmPriceAll : 0;
    const medianPriceAmountLowerPercent = sumOfferPriceAll > 0 && props.medianPriceSourcesAmount > 0 ? (medianPriceAmountLower * 100) / props.medianPriceSourcesAmount : 0;

    setTotalOfferPrice(sumOfferPriceAll);
    setTotalConfirmPrice(sumConfirmPriceAll);
    setTotalConfirmPriceLower(totalConfirmPriceLower);
    setTotalConfirmPriceLowerPercent(totalConfirmPriceLowerPercent);
    setBudgetAmountLower(budgetAmountLower);
    setBudgetAmountLowerPercent(budgetAmountLowerPercent);
    setMedianPriceSourcesAmountLower(medianPriceAmountLower);
    setMedianPriceSourcesAmountLowerPercent(medianPriceAmountLowerPercent);
  };

  const onHideModal = () => {
    props.setType(false);
    props.onHide();
  };

  const onChangeVatType = (value: string, index: number) => {
    const parcelDataLists = [...parcelData];
    const data: Parcels = {
      ...parcelDataLists[index],
      vatTypeId: value,
    };

    parcelDataLists.splice(index, 1, data);

    setParcelData(parcelDataLists);
  };

  const onChangeofferPriceQuantity = (value: number, index: number) => {
    const parcelDataLists = [...parcelData];
    const data: Parcels = {
      ...parcelDataLists[index],
      offerPriceQuantity: value,
      offerPriceTotal: (value * parcelData[index].offerPricePerUnit),
      confirmPriceTotal: (value * parcelData[index].confirmPriceTotal),
    };

    parcelDataLists.splice(index, 1, data);

    setParcelData(parcelDataLists);
  };

  const onChangeOfferPricePerUnit = (value: number, index: number) => {
    const parcelDataLists = [...parcelData];
    const data: Parcels = {
      ...parcelDataLists[index],
      offerPricePerUnit: value,
      offerPriceTotal: (value * parcelData[index].offerPriceQuantity),
    };

    parcelDataLists.splice(index, 1, data);

    setParcelData(parcelDataLists);
  };

  const onChangeConfirmPriceUnit = (value: number, index: number) => {
    const parcelDataLists = [...parcelData];
    const data: Parcels = {
      ...parcelDataLists[index],
      confirmPriceUnit: value,
      confirmPriceTotal: (value * parcelData[index].offerPriceQuantity),
    };

    parcelDataLists.splice(index, 1, data);

    setParcelData(parcelDataLists);
  };

  const updateParcels = async () => {
    submitForm();
    if (parcelData.length === 0) {
      toast.warn('กรุณาระบุรายการของพัสดุที่จะซื้อ');
      return;
    }

    if (parcelData && parcelData.filter((x) => x.offerParcel === '' || x.offerParcel === undefined || x.offerParcel === null || x.offerPriceUnitId === '' || x.offerPriceUnitId === undefined || x.offerPriceUnitId === null || x.vatTypeId === '' || x.vatTypeId === undefined || x.vatTypeId === null).length > 0) {
      toast.warn('กรุณาระบุรายละเอียดรายการของพัสดุที่จะซื้อ');
      return;
    }

    if (parcelData && parcelData.filter((x) => x.offerPriceQuantity === 0 || x.offerPriceQuantity === undefined || x.offerPriceQuantity === null).length > 0) {
      toast.warn('กรุณาระบุจำนวนรายการของพัสดุที่จะซื้อ มากกว่า 0');
      return;
    }

    if (props.isTorMA) {
      if (maintenancesData.length === 0) {
        toast.warn('กรุณาระบุรายการของพัสดุที่จะซื้อ (งานจ้างบริการบำรุงรักษา)');
        return;
      }

      if (maintenancesData && maintenancesData.filter((x) => x.offerParcel === '' || x.offerParcel === undefined || x.offerParcel === null || x.offerPeriodType === '' || x.offerPeriodType === undefined || x.offerPeriodType === null || x.vatTypeId === '' || x.vatTypeId === undefined || x.vatTypeId === null).length > 0) {
        toast.warn('กรุณาระบุรายละเอียดรายการของพัสดุที่จะซื้อ (งานจ้างบริการบำรุงรักษา)');
        return;
      }

      if (maintenancesData && maintenancesData.filter((x) => x.offerPriceQuantity === 0 || x.offerPriceQuantity === undefined || x.offerPriceQuantity === null).length > 0) {
        toast.warn('กรุณาระบุจำนวนรายการของพัสดุที่จะซื้อ (งานจ้างบริการบำรุงรักษา) มากกว่า 0');
        return;
      }
    }

    parcelData.sort((a, b) => a.sequence - b.sequence)
      .forEach((data, index) => {
        data.sequence = index + 1;
      });

    if (props.isTorMA) {
      maintenancesData.sort((a, b) => a.sequence - b.sequence)
        .forEach((data, index) => {
          data.sequence = index + 1;
        });
    }

    const body: ParcelsUpdate = {
      jorPor06Id: entrepreneurs.jorPor06Id,
      parcels: parcelData,
      maintenances: maintenancesData,
    };

    const response = await jorPor06.updateParcelsAsync(entrepreneurs.inviteEntrepreneursId, body);

    if (response.status === HttpStatusCode.ACCEPTED) {
      toast.success('บันทึกเสนอราคาสำเร็จ');
      props.onHide();

      getJorPor06DetailAsync();
    }
  };

  const updateConsiderationsDataAsync = async () => {
    const body: ConsiderationUpdate = {
      jorPor06id: entrepreneurs.jorPor06Id,
      isWinner: considerationsData.isWinner ? considerationsData.isWinner : false,
      remark: considerationsData.remark,
      winnerReason: considerationsData.winnerReason,
    };

    const response = await jorPor06.updateConsiderationAsync(considerationsData.id, body);

    if (response.status === HttpStatusCode.ACCEPTED) {
      toast.success('บันทึกผลพิจารณาสำเร็จ');

      props.onHide();

      getJorPor06DetailAsync();
    }
  };

  const onUpdate = () => {
    submitForm();

    if (totalOfferPrice > props.financialAmount) {
      return toast.warn('ราคารวมที่เสนอต้องน้อยกว่าหรือเท่ากับราคางบประมาณ');
    }

    if (totalConfirmPrice > totalOfferPrice) {
      return toast.warn('ราคารวมที่ตกลงต้องน้อยกว่าหรือเท่ากับราคารวมที่เสนอ');
    }

    if (props.type) {
      if (!considerationsData.winnerReason) {
        return;
      }

      if (considerationsData.isWinner
        && (entrepreneurs.coi.result !== true
          || entrepreneurs.watchList.result !== true
          || entrepreneurs.eGp.result !== true)) {
        toast.warn('ผู้ชนะต้องผ่านการพิจารณาขั้นต้นทั้งหมดก่อน');
        return;
      }

      updateConsiderationsDataAsync();

      return;
    }

    // if (!parcelData.some(p => (p.vatType, p.offerPricePerUnit, p.confirmPriceUnit))) {
    //   return;
    // }

    updateParcels();
  };

  const addParcelData = useCallback(async () => {

    const sequence = Math.max.apply(null,
      parcelData?.map((x) => {
        return x.sequence;
      }));

    const newData: Parcels = {
      confirmPriceTotal: 0,
      confirmPriceTotalVat: 0,
      confirmPriceUnit: 0,
      description: '',
      id: undefined,
      offerParcel: '',
      offerPricePerUnit: 0,
      offerPriceQuantity: 0,
      offerPriceTotal: 0,
      offerPriceTotalVat: 0,
      offerPriceUnitId: '',
      parcelId: undefined,
      torQuantity: 0,
      torUnit: undefined,
      vatTypeId: '',
      sequence: sequence + 1,
    };

    setParcelData((parcelData) => [...parcelData, newData]);
  }, [parcelData]);

  const onChangeOfferParcel = (value: string, index: number) => {
    const parcelDataLists = [...parcelData];
    parcelDataLists[index].offerParcel = value;
    setParcelData(parcelDataLists);
  };

  const onChangeOfferPriceUnit = (value: string, index: number) => {
    const parcelDataLists = [...parcelData];
    parcelDataLists[index].offerPriceUnitId = value;

    setParcelData(parcelDataLists);
  };

  const handlerRemove = (index: number) => {
    const temp: Parcels[] = [...parcelData];
    temp.splice(index, 1);
    setParcelData(temp);
  };

  const onChangeMaintenanceOfferPeriodType = (value: string, index: number) => {
    const maintenancesDataLists = [...maintenancesData];
    maintenancesDataLists[index].offerPeriodType = value;

    setMaintenanceData(maintenancesData);
  };

  const onChangeMaintenanceOfferPriceQuantity = (value: number, index: number) => {
    const maintenanceDataLists = [...maintenancesData];
    const data: MaintenancesModel = {
      ...maintenanceDataLists[index],
      offerPriceQuantity: value,
      offerPriceTotal: (value * maintenancesData[index].offerPricePerUnit),
      confirmPriceTotal: (value * maintenancesData[index].confirmPriceTotal),
    };

    maintenanceDataLists.splice(index, 1, data);

    setMaintenanceData(maintenanceDataLists);
  };

  const onChangeMaintenanceOfferParcel = (value: string, index: number) => {
    const maintenancesDataLists = [...maintenancesData];
    maintenancesDataLists[index].offerParcel = value;
    setMaintenanceData(maintenancesDataLists);
  };

  const handlerMaintenanceRemove = (index: number) => {
    const temp: MaintenancesModel[] = [...maintenancesData];
    temp.splice(index, 1);
    setMaintenanceData(temp);
  };

  const onChangeVatTypeMaintenance = (value: string, index: number) => {
    const maintenancesDataLists = [...maintenancesData];
    const data: MaintenancesModel = {
      ...maintenancesDataLists[index],
      vatTypeId: value,
    };

    maintenancesDataLists.splice(index, 1, data);

    setMaintenanceData(maintenancesDataLists);
  };

  const addMaintenancesData = useCallback(async () => {

    const sequence = Math.max.apply(null,
      maintenancesData?.map((x) => {
        return x.sequence;
      }));

    const newData: MaintenancesModel = {
      confirmPriceTotal: 0,
      confirmPriceTotalVat: 0,
      confirmPriceUnit: 0,
      description: '',
      id: undefined,
      offerParcel: '',
      offerPricePerUnit: 0,
      offerPriceQuantity: 0,
      offerPriceTotal: 0,
      offerPriceTotalVat: 0,
      torQuantity: 0,
      vatTypeId: '',
      offerPeriodType: '',
      torPaymentTermPeriodId: '',
      sequence: sequence + 1,
    };

    setMaintenanceData((maintenancesData) => [...maintenancesData, newData]);
  }, [maintenancesData]);

  const onChangeOfferPricePerUnitMaintenances = (value: number, index: number) => {
    const maintenancesDataLists = [...maintenancesData];
    const data: MaintenancesModel = {
      ...maintenancesDataLists[index],
      offerPricePerUnit: value,
      offerPriceTotal: (value * maintenancesData[index].offerPriceQuantity),
    };

    maintenancesDataLists.splice(index, 1, data);

    setMaintenanceData(maintenancesDataLists);
  };

  const onChangeConfirmPriceUnitMaintenance = (value: number, index: number) => {
    const maintenancesDataLists = [...maintenancesData];
    const data: MaintenancesModel = {
      ...maintenancesDataLists[index],
      confirmPriceUnit: value,
      confirmPriceTotal: (value * maintenancesData[index].offerPriceQuantity),
    };

    maintenancesDataLists.splice(index, 1, data);

    setMaintenanceData(maintenancesDataLists);
  };

  return (
    <Modal show={props.show}
      size='xl'
      className="fix-width-1800">
      {!onInit ? (
        <>
          <div className='d-flex justify-content-between align-items-center mb-2'>
            <h5 className='mb-1'>{props.type ? 'บันทึกผลพิจารณา' : 'บันทึกเสนอราคา'}</h5>
            <FaTimes onClick={onHideModal} />
          </div>
          <hr />
          <Card className='mt-2'>
            <Title className='fs-5 text-primary'
              text='ข้อมูลคู่ค้า' />
            <Row className='mx-2 mt-3'>
              <Col sm={4}>
                <p>สัญชาติของผู้ประกอบการ : {convertNationalToThWord(entrepreneurs.nationality)} </p>
              </Col>
              <Col sm={4}>
                <p>ประเภท : {getEntrepreneurType(entrepreneurs.type)} </p>
              </Col>
            </Row>
            <Row className='mx-2'>
              <Col sm={4}>
                <p>เลขประจำตัวผู้เสียภาษี : {entrepreneurs.taxPayerIdentificationNo}</p>
              </Col>
              <Col sm={4}>
                <p>ประเภทผู้ประกอบการ : {entrepreneurs.entrepreneurType} </p>
              </Col>
              <Col sm={4}>
                <p>ชื่อสถานประกอบการ : {entrepreneurs.establishmentName} </p>
              </Col>
            </Row>
            <Row className='mx-2'>
              <Col sm={4}>
                <p>หมายเลขโทรศัพท์สำหรับติดต่อ : {entrepreneurs.tel} </p>
              </Col>
              <Col sm={4}>
                <p>อีเมล : {entrepreneurs.email} </p>
              </Col>
            </Row>
          </Card>

          <Card className='mt-2'>
            <Title className='fs-5 text-primary'
              text='รายการของพัสดุ' />
            <div className='d-flex justify-content-between align-items-center'>
              <p className='mb-0'>รายการของพัสดุที่จะซื้อ</p>
              <Button
                variant='outline-primary'
                onClick={() => addParcelData()}
                disabled={props.type || disabledField}
              >
                <FaPlus className='me-1' />เพิ่มรายการ
              </Button>
            </div>
            <Table className='mt-3'
              hidePagination>
              <thead>
                <tr>
                  <th rowSpan={2} style={{ minWidth: 80 }}>ลำดับ</th>
                  <th rowSpan={2} style={{ minWidth: 400 }}>รายการ</th>
                  <th rowSpan={2} style={{ minWidth: 100 }}>จำนวน</th>
                  <th rowSpan={2} style={{ minWidth: 250 }}>หน่วย</th>
                  <th rowSpan={2} style={{ minWidth: 200 }}>ประเภท VAT</th>
                  <th colSpan={2} style={{ minWidth: 250 }}>ราคาที่เสนอ</th>
                  <th colSpan={2} style={{ minWidth: 250 }}>ราคาที่ตกลง</th>
                </tr>
                <tr>
                  <th style={{ minWidth: 250 }}>ต่อหน่วย</th>
                  <th style={{ minWidth: 250 }}>รวม</th>
                  <th style={{ minWidth: 250 }}>ต่อหน่วย</th>
                  <th style={{ minWidth: 250 }}>รวม</th>
                  <th />
                </tr>

              </thead>
              <tbody>
                {parcelData.sort((a, b) => a.sequence - b.sequence).map((data, index) => (
                  <tr
                    key={index}
                    className='text-center'>
                    <td>{index + 1}</td>
                    <td>
                      <InputTextArea
                        value={data.offerParcel}
                        disabled={props.type || disabledField}
                        onChange={(Value) => onChangeOfferParcel(Value, index)} />
                    </td>
                    <td>
                      <InputNumber
                        value={data.offerPriceQuantity}
                        disabled={props.type || disabledField}
                        onChange={(value) => onChangeofferPriceQuantity(Number(value ?? '0'), index)} />
                    </td>
                    <td>
                      <Selector
                        className='primary'
                        items={UnitType}
                        value={data.offerPriceUnitId}
                        onChange={(val) => onChangeOfferPriceUnit(val, index)}
                        rule={{ required: false }}
                        disabled={props.type || disabledField} />
                    </td>
                    <td>
                      <div>
                        <Selector
                          rule={{ required: false }}
                          items={vatType}
                          disabled={props.type || disabledField}
                          value={data.vatTypeId}
                          onChange={(value) => onChangeVatType(value, index)} />
                      </div>
                    </td>
                    <td>
                      <Currency
                        rule={{ required: false }}
                        value={data.offerPricePerUnit}
                        disabled={props.type || disabledField}
                        onChange={(value) => onChangeOfferPricePerUnit(value ?? 0, index)} />
                    </td>
                    <td>
                      <Currency
                        value={data.offerPriceTotal}
                        disabled />
                    </td>
                    <td>
                      <Currency
                        rule={{ required: false }}
                        value={data.confirmPriceUnit}
                        disabled={props.type || disabledField}
                        onChange={(value) => onChangeConfirmPriceUnit(value ?? 0, index)} />
                    </td>
                    <td>
                      <Currency
                        value={data.confirmPriceTotal}
                        disabled />
                    </td>
                    <td>
                      <div className='d-flex justify-content-end'>
                        <Button
                          variant='danger'
                          className='d-flex align-items-center gap-2'
                          onClick={() => handlerRemove(index)}
                          disabled={props.type || disabledField}
                        >
                          <FaTrashAlt />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {
              props.isTorMA && (
                <>
                  <div className='d-flex justify-content-between align-items-center mt-5'>
                    <p className='mb-0'>รายการของพัสดุที่จะซื้อ (งานจ้างบริการบำรุงรักษา)</p>
                    <Button
                      variant='outline-primary'
                      onClick={() => addMaintenancesData()}
                      disabled={props.type || disabledField}
                    >
                      <FaPlus className='me-1' />เพิ่มรายการ
                    </Button>
                  </div>
                  <Table className='mt-3'
                    hidePagination>
                    <thead>
                      <tr>
                        <th rowSpan={2} style={{ minWidth: 80 }}>ลำดับ</th>
                        <th rowSpan={2} style={{ minWidth: 400 }}>รายการ</th>
                        <th rowSpan={2} style={{ minWidth: 100 }}>จำนวน</th>
                        <th rowSpan={2} style={{ minWidth: 250 }}>ระยะเวลา</th>
                        <th rowSpan={2} style={{ minWidth: 200 }}>ประเภท VAT</th>
                        <th colSpan={2} style={{ minWidth: 250 }}>ราคาที่เสนอ</th>
                        <th colSpan={2} style={{ minWidth: 250 }}>ราคาที่ตกลง</th>
                      </tr>
                      <tr>
                        <th style={{ minWidth: 250 }}>ต่อหน่วย</th>
                        <th style={{ minWidth: 250 }}>รวม</th>
                        <th style={{ minWidth: 250 }}>ต่อหน่วย</th>
                        <th style={{ minWidth: 250 }}>รวม</th>
                        <th />
                      </tr>

                    </thead>
                    <tbody>
                      {maintenancesData.sort((a, b) => a.sequence - b.sequence).map((data, index) => (
                        <tr
                          key={index}
                          className='text-center'>
                          <td>{index + 1}</td>
                          <td>
                            <InputTextArea
                              value={data.offerParcel}
                              disabled={props.type || disabledField}
                              onChange={(Value) => onChangeMaintenanceOfferParcel(Value, index)} />
                          </td>
                          <td>
                            <InputNumber
                              value={data.offerPriceQuantity}
                              onChange={(value) => onChangeMaintenanceOfferPriceQuantity(Number(value ?? '0'), index)}
                              disabled={props.type || disabledField} />
                          </td>
                          <td>
                            <Selector
                              className='primary'
                              items={periodUnitType}
                              value={data.offerPeriodType}
                              onChange={(val) => onChangeMaintenanceOfferPeriodType(val, index)}
                              rule={{ required: false }}
                              disabled={props.type || disabledField} />
                          </td>
                          <td>
                            <div>
                              <Selector
                                rule={{ required: false }}
                                items={vatType}
                                disabled={props.type || disabledField}
                                value={data.vatTypeId}
                                onChange={(value) => onChangeVatTypeMaintenance(value, index)} />
                            </div>
                          </td>
                          <td>
                            <Currency
                              rule={{ required: false }}
                              value={data.offerPricePerUnit}
                              disabled={props.type || disabledField}
                              onChange={(value) => onChangeOfferPricePerUnitMaintenances(value ?? 0, index)} />
                          </td>
                          <td>
                            <Currency
                              value={data.offerPriceTotal}
                              disabled />
                          </td>
                          <td>
                            <Currency
                              rule={{ required: false }}
                              value={data.confirmPriceUnit}
                              disabled={props.type || disabledField}
                              onChange={(value) => onChangeConfirmPriceUnitMaintenance(Number(value ?? 0), index)} />
                          </td>
                          <td>
                            <Currency
                              value={data.confirmPriceTotal}
                              disabled />
                          </td>
                          <td>
                            <div className='d-flex justify-content-end'>
                              <Button
                                variant='danger'
                                className='d-flex align-items-center gap-2'
                                onClick={() => handlerMaintenanceRemove(index)}
                                disabled={props.type || disabledField}
                              >
                                <FaTrashAlt />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )
            }

            <Row className='mx-2 mt-5'>
              <Col sm={4}
                lg={8}>
                <h5 className='text-end mt-2'>งบประมาณ {formatter.format(props.financialAmount)}</h5>
              </Col>
              <Col sm={4}>
                <h5 className='text-end mt-2'>ราคารวมที่เสนอ {formatter.format(totalOfferPrice)}</h5>
              </Col>
            </Row>

            <Row className='mx-2'>
              <Col sm={4} lg={8}>
                <h5 className='text-end mt-2'>ต่ำกว่างบประมาณ {formatter.format(budgetAmountLower)} คิดเป็นร้อยละ {formatter.format(budgetAmountLowerPercent)} % </h5>
              </Col>
            </Row>

            <Row className='mx-2'>
              <Col sm={4} lg={8}>
                <h5 className='text-end mt-2'>ราคากลาง {formatter.format(props.medianPriceSourcesAmount)}</h5>
              </Col>
              <Col sm={4}>
                <h4 className='text-end mt-2 text-primary'>ราคารวมที่ตกลง {formatter.format(totalConfirmPrice)}</h4>
              </Col>
            </Row>

            <Row className='mx-2'>
              <Col sm={4} lg={8}>
                <h5 className='text-end mt-2'>ต่ำกว่าราคากลาง {formatter.format(medianPriceSourcesAmountLower)} คิดเป็นร้อยละ {formatter.format(medianPriceSourcesAmountLowerPercent)} %</h5>
              </Col>
              <Col sm={4}>
                <h5 className='text-end mt-2'>ต่ำกว่าราคาที่เสนอ {formatter.format(totalConfirmPriceLower)} คิดเป็นร้อยละ {formatter.format(totalConfirmPriceLowerPercent)} %</h5>
              </Col>
            </Row>

            {/* <Row className='mt-5'>
              <Col lg={3}>
                <h5 className='text-end mt-2'>ราคารวมที่เสนอ</h5>
              </Col>
              <Col lg={3}>
                <Currency
                  value={totalOfferPrice}
                  disabled
                  sizeText='lg' />
              </Col>
              <Col lg={3}>
                <h5 className='text-end mt-2'>ราคารวมที่ตกลง</h5>
              </Col>
              <Col lg={3}>
                <Currency
                  value={totalConfirmPrice}
                  disabled
                  sizeText='lg' />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col lg={3}>
                <h5 className='text-end mt-2'>วงเงินงบประมาณ</h5>
              </Col>
              <Col lg={3}>
                <Currency
                  value={props.financialAmount}
                  disabled
                  sizeText='lg' />
              </Col>
              <Col lg={3}>
                <h5 className='text-end mt-2'>ต่ำกว่าราคาที่เสนอ</h5>
              </Col>
              <Col lg={3}>
                <Currency
                  value={totalConfirmPriceLower}
                  disabled
                  sizeText='lg' />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col lg={3}>
                <h5 className='text-end mt-2'>ราคากลาง</h5>
              </Col>
              <Col lg={3}>
                <Currency
                  value={props.medianPriceSourcesAmount}
                  disabled
                  sizeText='lg' />
              </Col>
              <Col lg={3}>
                <h5 className='text-end mt-2'>ต่ำกว่าราคาที่เสนอ (เปอร์เซ็น)</h5>
              </Col>
              <Col lg={3}>
                <Currency
                  value={totalConfirmPriceLowerPercent}
                  disabled
                  sizeText='lg' />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col lg={9}>
                <h5 className='text-end mt-2'>ต่ำกว่างบประมาณ</h5>
              </Col>
              <Col lg={3}>
                <Currency
                  value={budgetAmountLower}
                  disabled
                  sizeText='lg' />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col lg={9}>
                <h5 className='text-end mt-2'>ต่ำกว่างบประมาณ (เปอร์เซ็น)</h5>
              </Col>
              <Col lg={3}>
                <Currency
                  value={budgetAmountLowerPercent}
                  disabled
                  sizeText='lg' />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col lg={9}>
                <h5 className='text-end mt-2'>ต่ำกว่าราคากลาง</h5>
              </Col>
              <Col lg={3}>
                <Currency
                  value={medianPriceSourcesAmountLower}
                  disabled
                  sizeText='lg' />
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col lg={9}>
                <h5 className='text-end mt-2'>ต่ำกว่าราคากลาง (เปอร์เซ็น)</h5>
              </Col>
              <Col lg={3}>
                <Currency
                  value={medianPriceSourcesAmountLowerPercent}
                  disabled
                  sizeText='lg' />
              </Col>
            </Row> */}
          </Card>
          {(props.type)
            ? (
              <>
                <Card className='mt-2'>
                  <Row>
                    <Col sm={12}>
                      <Title className='fs-5 text-primary'
                        text='ผลการพิจารณา' />
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col sm={4}>
                      <div className='d-flex justify-content-start'>
                        <Check
                          disabled={disabledField}
                          value={considerationsData.isWinner}
                          onChange={(value) => setConsiderationsData({
                            ...considerationsData,
                            isWinner: value,
                          })} /> ผู้ชนะ
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col sm={4}>
                      <Selector
                        disabled={disabledField}
                        items={winnerReasonItems}
                        rule={{ required: true }}
                        label='เหตุผลที่เลือก'
                        value={considerationsData.winnerReason}
                        onChange={(value) => setConsiderationsData({
                          ...considerationsData,
                          winnerReason: value,
                        })} />
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col sm={12}>
                      <InputTextArea
                        disabled={disabledField}
                        label='เหตุผล'
                        value={considerationsData.remark}
                        onChange={(value) => setConsiderationsData({
                          ...considerationsData,
                          remark: value,
                        })} />
                    </Col>
                  </Row>
                </Card>
              </>
            )
            : <></>}
          {props.type ? !disabledField && (
            <Row className='mt-3'>
              <Col className='d-flex justify-content-end'>
                <Button
                  variant='outline-primary'
                  onClick={onHideModal}
                  className='me-2 px-3'>
                  ยกเลิก
                </Button>
                <Button
                  variant='success'
                  onClick={onUpdate}
                  className='me-2 px-3'>
                  <FaSave className='me-1 pb-1 fs-5' />
                  บันทึก
                </Button>
              </Col>
            </Row>
          ) : (!disabledField) && (
            <Row className='mt-3'>
              <Col className='d-flex justify-content-end'>
                <Button
                  variant='outline-primary'
                  onClick={onHideModal}
                  className='me-2 px-3'>
                  ยกเลิก
                </Button>
                <Button
                  variant='success'
                  onClick={onUpdate}
                  className='me-2 px-3'>
                  <FaSave className='me-1 pb-1 fs-5' />
                  บันทึก
                </Button>
              </Col>
            </Row>
          )}
        </>
      ) : <></>}
    </Modal>
  );
}

export {
  COIAndWatchListModal,
  TraderListModal,
  BidPrice,
};