import {
  Input,
  InputTextArea,
  Modal,
  Table,
} from 'components';
import {
  ContractAgreementContractNumberResponseModel,
  ContractHistoryListResponseModel,
  UpdateContractAgreementContractNumberModel,
} from 'models/CM/CM01Models';
import {
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import {
  MdSave,
} from 'react-icons/md';
import { useNavigate } from 'react-router';
import CM01Service from 'services/CM/CM01Service';
import {
  GetFormatDateBC,
  GetFormatTimeBC,
  HttpStatusCode,
} from 'utils';
import toast from 'utils/toast';

interface Props {
  show: boolean;
  onHide: () => void;
  id?: string;
  oldNumber?: string;
  onChange?: (newNumber: string) => void;
}

export function ModalEditContractNumber(props: Props) {
  const [showComment, setShowComment] = useState(false);
  const [data, setData] = useState<ContractAgreementContractNumberResponseModel>({} as ContractAgreementContractNumberResponseModel);
  const [history, setHistory] = useState<ContractHistoryListResponseModel>({} as ContractHistoryListResponseModel);
  const navigate = useNavigate();
  const [showRemark, setShowRemark] = useState<boolean[]>([]);

  useEffect(() => {

    if (props.id) {
      getHistoryDetailAsync(props.id);
    }

    if (props.show) {
      setData({} as ContractAgreementContractNumberResponseModel)
    }

  }, [props.id]);

  const getHistoryDetailAsync = async (id: string) => {
    const response = await CM01Service.getContractHistoryByIdAsync(id);
    if (response.status === HttpStatusCode.OK) {
      setHistory(response);
    }
  };

  const onSubmitUpdateAsync = async () => {

    if ((!data.newContractNumber || !data.remark)) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');
      return;
    }

    if (data.newContractNumber === props.oldNumber) {
      toast.warn('กรุณากรอกเลขที่สัญญา จพ.(สบส.) ใหม่ที่ต่างจากเลขที่เดิม');
      return;
    }

    if (!data || !props.id) {
      console.log(props.id)
      toast.error('ไม่พบข้อมูลเลขที่สัญญา จพ.(สบส.)');
      return;
    }

    const updateDataModel: UpdateContractAgreementContractNumberModel = {
      contractNumber: data.newContractNumber,
      remark: data.remark,
    };

    const res = await CM01Service.updateContractNumberAsync(props.id, updateDataModel);

    if (res.status === HttpStatusCode.OK) {

      toast.success('บันทึกข้อมูลสำเร็จ');
      props.onChange!(data.newContractNumber);
      props.onHide();
      getHistoryDetailAsync(props.id);

    } else {
      toast.error(res.statusText);
    }
  };

  const onOpen = (index: number) => {
    setShowComment(true);
    setShowRemark((show) => {
      const newModal = [...show];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHide = (index: number) => {
    setShowComment(false);
    setShowRemark((show) => {
      const newModal = [...show];
      newModal[index] = false;
      return newModal;
    });
  };

  return (
    <Modal
      show={props.show}
      size='xl'
      onHide={() => props.onHide()}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>เลขที่สัญญา จพ.(สบส.)</ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body>
            <form>
              <Row>
                <Col sm={12}
                  md={6}>
                  <Input label='เลขที่สัญญา จพ.(สบส.) ปัจจุบัน'
                    placeholder='เลขที่สัญญา จพ.(สบส.) ปัจจุบัน'
                    disabled
                    value={props.oldNumber} />
                </Col>
                <Col sm={12}
                  md={6}>
                  <Input label='เลขที่สัญญา จพ.(สบส.) ใหม่'
                    placeholder='เลขที่สัญญา จพ.(สบส.) ใหม่'
                    rule={{ required: true }}
                    value={data.newContractNumber}
                    onChange={val => setData({
                      ...data,
                      newContractNumber: val,
                    })} />
                </Col>
                <Col sm={12}
                  md={12}>
                  <InputTextArea label='หมายเหตุ'
                    placeholder='หมายเหตุ'
                    value={data.remark}
                    rule={{ required: true }}
                    onChange={val => setData({
                      ...data,
                      remark: val,
                    })} />
                </Col>
                <Col sm={12}
                  className='d-flex justify-content-center gap-3'>
                  <Button
                    variant='success'
                    className='me-2 px-3'
                    onClick={onSubmitUpdateAsync}
                  >
                    <MdSave className='mx-2 pb-1 fs-4' />
                    บันทึก
                  </Button>
                </Col>
                <Col sm={12}
                  className='mt-4'>
                  <p className='fs-5 text-primary border-bottom border-primary'>ประวัติการแก้ไข </p>
                  <Table hidePagination
                    notResponse>
                    <thead>
                      <tr>
                        <th className='bg-white text-muted fw-normal text-start'
                          style={{ minWidth: '10%' }}>วันที่ เวลา
                        </th>
                        <th className='bg-white text-muted fw-normal'
                          style={{ minWidth: '25%' }}>เลขที่สัญญา จพ.(สบส.) เดิม
                        </th>
                        <th className='bg-white text-muted fw-normal'
                          style={{ minWidth: '25%' }}>เลขที่สัญญา จพ.(สบส.) ใหม่
                        </th>
                        <th className='bg-white text-muted fw-normal'
                          style={{ minWidth: '25%' }}>ผู้บันทึก/แก้ไข
                        </th>
                        <th className='bg-white text-muted fw-normal' />
                      </tr>
                    </thead>
                    <tbody>
                      {history.data?.map((data, index) => (
                        <>
                          <tr>
                            <td className='text-start'>
                              <p className='text-primary mb-0'>{GetFormatDateBC(data.updateDate)} </p>
                              <p className='text-muted mb-0'>{GetFormatTimeBC(data.updateDate)}</p>
                            </td>
                            <td className='text-start'>{data.oldContractNumber}</td>
                            <td className='text-start'>{data.newContractNumber}</td>
                            <td className='text-start'>{data.updateByName}</td>
                            <td>
                              <Button
                                variant='primary'
                                className='d-flex align-items-center gap-2'
                                onClick={() => (showRemark[index] ? onHide(index) : onOpen(index))}
                              >
                                {(showRemark[index] ? <FaRegEye /> : <FaRegEyeSlash />)} หมายเหตุ
                              </Button>
                            </td>
                          </tr>
                          {
                            showRemark[index] &&
                            <tr className='bg-opacity-10 bg-light'>
                              <td colSpan={5}>
                                <p>{data.remark}</p>
                              </td>
                            </tr>
                          }
                        </>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </form>
          </ModalBT.Body>
        </>
      )}
    />
  );
}
