import {
  Card,
  Check,
  Input,
  Table,
  TorStatus,
  UploadFile,
  ButtonCustom,
  ArrowCollapse,
  ApproverStatus,
  InputTextArea,
  Selector,
  Modal,
  FileValue,
} from 'components';
import Title from 'components/Controls/Title';
import { HistoryModal, SearchBuNameModal } from 'components/Modal';
import { sectionApproverModel } from 'models/delegateModel';
import { CommitteeApproversModel, JorPor06Detail, Jorpor06User } from 'models/PR/JorPor06Models';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import {
  FaCheckCircle,
  FaCircle,
  FaHistory,
  FaPlus,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import { account, jorPor06, shareValue } from 'services';
import {
  HttpStatusCode,
  PositionOnBoardIdEnum,
  ProcurementSection,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import { AcceptorStatus, AnnouncementStatus, ProgramType } from 'utils/constants/PlanEnum';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { Context } from '.';
import { ItemModel, JorporDirectorBeInformed, sharedValueResponse, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import { JorPorCode } from 'constant/basicInformation';
import { Acceptors } from 'components/Table/Acceptors';
import { useLoaderData, useNavigate, useParams } from 'react-router';
import { TorBudgetConstant, TorEditCancelStatusConstant, TorInRefCodeConstant } from 'constant/PreProcumentTorStatus';
import { BasicInformationAction } from 'models/PP';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import { isNull } from 'utils/helper';
import { BsCheckCircle } from 'react-icons/bs';
import { onCheckQueueSectionApprover } from 'utils/checkQueueSectionApprover';
import { textLastSectionApprover } from 'utils/textLastSectionApprover';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

enum ModalType {
  APPROVE = 'Approve',
  REJECT = 'Reject',
  WaitingForDirectorJorPorAssign = 'WaitingForDirectorJorPorAssign',
}

type loader = {
  positionOnBoardProcurementDDL: ItemModel[],
  budgetConstantJorPro06: sharedValueResponse,
};

export default function Step2({ onClickNext, onClickBack }: Props) {
  const {
    jorpor06Detail,
    getJorPor06DetailAsync,
    getNewJorPor06DetailAsync,
    setJorpor06Detail,
    updateJorPor06Async,
    disabledField,
    uploadFile,
    downloadFileAsync,
    removeFile,
    activityData,
    recallAsync,
    recallShow,
    isCreate,
    acceptorId,
  } = useContext(Context);

  const [showHistory, setShowHistory] = useState(false);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [showModals, setShowModals] = useState(false);
  const [showResponsibleModals, setShowResponsibleModals] = useState(false);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const { departmentCode, userId } = useAppContext();
  const [sectionType, setSectionType] = useState<string>();
  const [sectionSequence, setSectionSequence] = useState<number>();
  const [openCollapse, setOpenCollapse] = useState(true);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [openCollapse3, setOpenCollapse3] = useState(true);
  const [openCollapse4, setOpenCollapse4] = useState(true);
  const [openCollapse5, setOpenCollapse5] = useState(true);
  const [committeeApprovers, setCommitteeApprovers] = useState<CommitteeApproversModel[]>([]);
  const [isAvailableAll, setIsAvailableAll] = useState(jorpor06Detail.committees?.filter((a) => a.isDisabled === false).length > 0);
  const [showEdit, setShowEdit] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.APPROVE);
  const [remark, setRemark] = useState('');
  const [showPending, setShowPending] = useState(false);
  const [docJorPor006Id, setDocJorPor006Id] = useState('');
  const [docWinnerId, setDocWinnerId] = useState('');
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const { positionOnBoardProcurementDDL, budgetConstantJorPro06 } = useLoaderData() as loader;
  const [isJorPorDirector, setIsJorPorDirector] = useState(false);
  const [isResponsible, setIsResponsible] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [jorPorComment, setJorPorComment] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);
  const collaboraWinnerRef = useRef<CollaboraRef>(null);
  const [showWaitingForApprove, setShowWaitingForApprove] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [showApproveAndReject, setShowApproveAndReject] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [docCancelJorPor006Id, setDocCancelJorPor006Id] = useState('');
  const collaboraCancelRef = useRef<CollaboraRef>(null);
  const [files, setFiles] = useState<FileValue[]>([]);
  const navigate = useNavigate();
  const [responsibilities, setResponsibilities] = useState<Jorpor06User[]>([]);
  const [acceptors, setAcceptors] = useState<Jorpor06User[]>([]);
  const [jorPorDirectors, setJorPorDirectors] = useState<Jorpor06User[]>([]);

  const getDefaultUserAsync = async () => {
    const [
      jorporDirectorBeInformedDefault,
    ] = await Promise.all([
      account.getDefaultDepartmentDirectorAgree(ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED),
    ]);

    const jorporDirectorBeInformed = jorporDirectorBeInformedDefault.data as JorporDirectorBeInformed[];

    const newJorPorDirectors: Jorpor06User[] = [];

    jorporDirectorBeInformed.forEach((item, index) => {
      newJorPorDirectors.push({
        sequence: item.sequence,
        sectionCode: TorInRefCodeConstant.JorPorDirector,
        fullName: item.fullName,
        userId: item.userId,
        departmentName: item.departmentName,
        positionName: item.positionName,
        sectionSequence: 0,
        status: AcceptorStatus.PENDING,
        inRefCode: TorInRefCodeConstant.JorPorDirector,
        remark: '',
        isAvailable: false,
        jorPor06Id: jorpor06Detail.id,
      } as Jorpor06User);
    });

    setJorPorDirectors(newJorPorDirectors);

    return;
  };

  useEffect(() => {
    if (jorpor06Detail) {
      getJorPorDocument(jorpor06Detail.id);
      getWinnerDocument(jorpor06Detail.id);
      getCancelJorPorDocument(jorpor06Detail.id);

      if (jorpor06Detail.jorPorDirectors.length === 0 && jorpor06Detail.confirmPriceSum > Number(budgetConstantJorPro06.sharedValue.value)) {
        getDefaultUserAsync();
      }

      if (jorpor06Detail.jorPorDirectors) {
        setJorPorDirectors(jorpor06Detail.jorPorDirectors);
      }

      if (jorpor06Detail.committees) {
        setCommitteeApprovers(jorpor06Detail.committees);
        const queueApprover = onCheckQueueApprover(jorpor06Detail.committees);
        setIsQueueApprover(queueApprover);
      }

      if (jorpor06Detail.acceptors) {
        setIsApprover(onCheckQueueSectionApprover(jorpor06Detail.acceptors, userId));
        setAcceptors(jorpor06Detail.acceptors);
      }

      if (jorpor06Detail.jorPorDirectors && jorpor06Detail.jorPorDirectors.length > 0) {
        setIsJorPorDirector(jorpor06Detail.jorPorDirectors.some(a => isNull(a.delegateUserId, a.userId) === userId));
      }

      if (jorpor06Detail.responsibilities && jorpor06Detail.responsibilities.length > 0) {
        setIsResponsible(jorpor06Detail.responsibilities.some(a => isNull(a.delegateUserId, a.userId) === userId));

        setResponsibilities(jorpor06Detail.responsibilities);
      }

      if (jorpor06Detail.jorPorComment) {
        setJorPorComment(jorpor06Detail.jorPorComment)
      }

      if (jorpor06Detail.documentList
        && files.length === 0) {
        handleSetFile(jorpor06Detail.documentList);
      }
    }
  }, [jorpor06Detail]);

  const onCheckQueueAcceptorsApprover = (data: JorPor06Detail) => {

    const maxValueSectionSequence = Math.max.apply(null,
      data.acceptors?.map((x) => {
        return x.sectionSequence;
      }));

    const maxValueSequence = Math.max.apply(null,
      data.acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence)?.map((x) => {
        return x.sequence;
      }));

    const dataMaxValueSectionSequence = data.acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence && a.sequence === maxValueSequence);

    const approverIndex = dataMaxValueSectionSequence?.findIndex(x => isNull(x.delegateUserId, x.userId) === userId) + 1;

    if (approverIndex) {
      return true;
    }

    return false;
  };

  const onCheckQueueApprover = (datas: CommitteeApproversModel[]) => {
    if (datas && jorpor06Detail.status === AnnouncementStatus.WaitingComitteeApprove) {
      const pendings = datas?.filter((a) => a.isDisabled === false && a.approverStatus === AcceptorStatus.PENDING);
      const president = pendings.filter((a) => a.approverPositionOnBoardId !== PositionOnBoardIdEnum.Chairman);

      if (president.length > 0) {
        return president.filter((a) => a.approverUserId === userId).length > 0;
      }

      return (pendings.filter((a) => a.approverPositionOnBoardId === PositionOnBoardIdEnum.Chairman && a.approverUserId === userId).length > 0);
    }
    return false;
  };

  const getJorPorDocument = useCallback(
    async (id: string) => {

      const { data: docId, status } = await jorPor06.getJorPor006DocumentAsync(id, false);

      if (status === HttpStatusCode.OK) {
        setDocJorPor006Id(docId);
      }
    }, []);

  const getWinnerDocument = useCallback(
    async (id: string) => {

      const { data: docId, status } = await jorPor06.getWinnerDocumentAsync(id, false);

      if (status === HttpStatusCode.OK) {
        setDocWinnerId(docId);
      }
    }, []);

  const getCancelJorPorDocument = useCallback(
    async (id: string) => {
      const { data: docId, status } = await jorPor06.getCancelJorPor006DocumentAsync(id);

      if (status === HttpStatusCode.OK) {
        setDocCancelJorPor006Id(docId);
      }
    }, []);

  const onShowModal = (modalType: ModalType) => {
    setModalType(modalType);
    setShowEdit(true);
  };

  const onShowApproveAndReject = (modalType: ModalType) => {
    setModalType(modalType);
    setShowApproveAndReject(true);
  };

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      jorpor06Detail.acceptors?.map((x) => x.sectionSequence),
    );

    const valueSequence = jorpor06Detail.acceptors?.filter((a) => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  // const onOpenModal = (index: number) => {
  //   setShowModals((showModals) => {
  //     const newModal = [...showModals];
  //     newModal[index] = true;
  //     return newModal;
  //   });
  // };

  // const onHideModal = (index: number) => {
  //   setShowModals((showModals) => {
  //     const newModal = [...showModals];
  //     newModal[index] = false;
  //     return newModal;
  //   });
  // };

  const onSelectEmpApprover = (
    empId: string,
    name: string,
    position: string,
    department: string,
    index: number,
    sectionSequence?: number,
    inRefCode?: string,
    delegateUserId?: string,
    delegateFullName?: string,
    delegateDepartmentName?: string,
    delegatePositionName?: string) => {
    if (acceptors && acceptors.filter((x) => x.inRefCode === inRefCode && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const newData: Jorpor06User[] = acceptors ? [...acceptors] : [];

      const acceptorBySection = acceptors.filter((x) => x.sectionSequence === sectionSequence);

      const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
        acceptorBySection.map((x) => {
          return x.sequence;
        })) : 0;

      newData.push({
        sectionCode: ProcurementSection.APPROVER,
        status: AcceptorStatus.DRAFT,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        sequence: maxValueSequence + 1,
        inRefCode: inRefCode!,
        processType: '',
        sectionLevel: undefined,
        sectionName: '',
        sectionSequence: sectionSequence!,
        sectionId: '',
        sectionType: inRefCode,
        delegateDepartmentName: delegateDepartmentName,
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegatePositionName: delegatePositionName,
      } as Jorpor06User);

      setAcceptors(newData);
    }
  };

  const onSelectResponsibilities = (
    empId: string,
    name: string,
    position: string,
    department: string,
    index: number,
    sectionSequence?: number,
    inRefCode?: string,
    delegateUserId?: string,
    delegateFullName?: string,
    delegateDepartmentName?: string,
    delegatePositionName?: string) => {
    if (responsibilities && responsibilities.filter((x) => x.sectionSequence === sectionSequence && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับผิดชอบซ้ำได้');
    } else {
      const newData: Jorpor06User[] = responsibilities ? [...responsibilities] : [];

      newData.push({
        sectionCode: TorInRefCodeConstant.Responsible,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        sequence: responsibilities?.length + 1,
        inRefCode: TorInRefCodeConstant.Responsible,
        processType: '',
        sectionLevel: undefined,
        sectionName: '',
        sectionSequence: sectionSequence!,
        sectionId: '',
        sectionType: TorInRefCodeConstant.Responsible,
        delegateDepartmentName: undefined,
        delegateUserId: undefined,
        delegateFullName: undefined,
        delegatePositionName: undefined,
      } as Jorpor06User);

      setResponsibilities(newData);
    }
  };

  const handlerRemoveResponsible = (index: number) => {
    const temp: Jorpor06User[] = [...responsibilities];
    temp.splice(index, 1);
    setResponsibilities(temp);
  };

  const handlerOnChangeCheckbox = (value: boolean, seqKey: string) => {
    const data = [...jorpor06Detail.acceptors];
    data.filter((x) => `${x.sectionSequence}${x.sequence}` === seqKey)[0].isAvailable = value;
    setJorpor06Detail({
      ...jorpor06Detail,
      acceptors: data,
    });
  };

  const handlerOnChangeRemark = (value: string, seqKey: string) => {
    const data = [...jorpor06Detail.acceptors];
    data.filter((x) => `${x.sectionSequence}${x.sequence}` === seqKey)[0].remark = value;
    setJorpor06Detail({
      ...jorpor06Detail,
      acceptors: data,
    });
  };

  const removeApprover = (seqKey: string) => {
    const temp: Jorpor06User[] = [...jorpor06Detail.acceptors];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setJorpor06Detail({
      ...jorpor06Detail,
      acceptors: temp,
    });
  };

  useEffect(() => {
    // if (!jorpor06Detail.acceptors || jorpor06Detail.acceptors.length === 0) {
    //   onGetDefaultApprover();
    // } else {
    //   const sectionDataGroup: sectionGroup[] = [];
    //   jorpor06Detail.acceptors
    //     .sort((a, b) => a.sectionSequence - b.sectionSequence)
    //     .forEach((item) => {
    //       sectionDataGroup.push({
    //         sectionName: item.sectionName,
    //         level: item.sectionLevel,
    //         sectionSequence: item.sectionSequence,
    //         inRefCode: item.inRefCode,
    //         sectionNameTh: item.sectionName,
    //         sectionId: item.sectionId,
    //       } as sectionGroup);
    //     });

    //   const uniqueNames = sectionDataGroup
    //     .filter((data, index) => sectionDataGroup
    //       .findIndex((d) => d.sectionSequence === data.sectionSequence) === index);

    //   setSections(uniqueNames);
    // }

    onCheckApproverStatus();
  }, []);

  const onSaveStep2 = (isPending: boolean) => {
    collaboraRef.current?.clickSave();
    collaboraWinnerRef.current?.clickSave();
    collaboraCancelRef.current?.clickSave();
    setTimeout(async () => {

      if (jorpor06Detail.committees && jorpor06Detail.committees.filter((x) => x.isDisabled === true && (x.remark === undefined || x.remark === null || x.remark === '')).length > 0) {
        toast.warn('กรุณาระบุหมายเหตุ');

        return;
      }

      if (jorPorDirectors) {
        jorpor06Detail.jorPorDirectors = jorPorDirectors;
      }

      if (committeeApprovers) {
        jorpor06Detail.committees = committeeApprovers;

        setShowPending(false);
        if (isPending) {
          updateJorPor06Async(AnnouncementStatus.WaitingComitteeApprove, false, false, docJorPor006Id, docWinnerId, docCancelJorPor006Id, isPending);

        } else {
          updateJorPor06Async(jorpor06Detail.status, false, false, docJorPor006Id, docWinnerId, docCancelJorPor006Id);

        }

        getJorPorDocument(jorpor06Detail.id);
        getWinnerDocument(jorpor06Detail.id);
        getCancelJorPorDocument(jorpor06Detail.id);

      }

      return;
    }, 500);
  };

  const sendApprove = async () => {

    if (!acceptors || acceptors.length === 0) {
      toast.warn('กรุณาเลือกผู้มีอำนาจเห็นชอบ');

      return;
    }

    if (jorpor06Detail.confirmPriceSum > Number(budgetConstantJorPro06.sharedValue.value) &&
      (jorPorComment === undefined || jorPorComment === null)) {
      toast.warn('กรุณาระบุความเห็น จพ.');

      return;
    }

    collaboraRef.current?.clickSave();
    collaboraWinnerRef.current?.clickSave();
    collaboraCancelRef.current?.clickSave();

    setTimeout(async () => {
      if (acceptors) {
        jorpor06Detail.acceptors = acceptors
        updateJorPor06Async(AnnouncementStatus.WaitingAccept, false, false, docJorPor006Id, docWinnerId, docCancelJorPor006Id, true, jorPorComment);
        setShowWaitingForApprove(false);
        return;
      }

      getJorPorDocument(jorpor06Detail.id);
      getWinnerDocument(jorpor06Detail.id);
      getCancelJorPorDocument(jorpor06Detail.id);
    }, 500);
  };

  const isReadonlyDoc = useMemo(() => {
    return !(jorpor06Detail.status !== AnnouncementStatus.WaitingAccept && jorpor06Detail.status !== AnnouncementStatus.Accepted);
  }, [jorpor06Detail]);

  const onSaveResponsibilities = (isAssignee: boolean) => {
    collaboraRef.current?.clickSave();
    collaboraWinnerRef.current?.clickSave();
    collaboraCancelRef.current?.clickSave();
    setShowAssignModal(false);
    setShowPending(false);

    setTimeout(async () => {

      if (!responsibilities || responsibilities.length === 0) {
        toast.warn('กรุณาเลือกผู้รับผิดชอบ');

        return;
      }

      if (responsibilities) {
        jorpor06Detail.responsibilities = responsibilities;

        setSaveApprover(acceptors);

        if (isAssignee) {
          updateJorPor06Async(AnnouncementStatus.DraftAcceptor, false, true, docJorPor006Id, docWinnerId, docCancelJorPor006Id, undefined, undefined, true);
        } else {
          updateJorPor06Async(AnnouncementStatus.WaitingForDirectorJorPorAssign, false, true, docJorPor006Id, docWinnerId, docCancelJorPor006Id);
        }
      }
      getJorPorDocument(jorpor06Detail.id);
      getWinnerDocument(jorpor06Detail.id);
      getCancelJorPorDocument(jorpor06Detail.id);

      return;
    }, 500);
  };

  const onSaveAcceptors = () => {
    collaboraRef.current?.clickSave();
    collaboraWinnerRef.current?.clickSave();
    collaboraCancelRef.current?.clickSave();
    if (!acceptors || acceptors.length === 0) {
      toast.warn('กรุณาเลือกผู้มีอำนาจเห็นชอบ');

      return;
    }

    if (jorpor06Detail.confirmPriceSum > Number(budgetConstantJorPro06.sharedValue.value) &&
      (jorPorComment === undefined || jorPorComment === null || jorPorComment === '')) {
      toast.warn('กรุณาระบุความเห็น จพ.');

      return;
    }

    setTimeout(async () => {
      if (acceptors) {
        jorpor06Detail.acceptors = acceptors;

        if (jorpor06Detail.status === AnnouncementStatus.WaitingAccept) {
          updateJorPor06Async(AnnouncementStatus.WaitingAccept, false, true, docJorPor006Id, docWinnerId, docCancelJorPor006Id, undefined, jorPorComment);
        } else {
          updateJorPor06Async(jorpor06Detail.status, false, true, docJorPor006Id, docWinnerId, docCancelJorPor006Id, undefined, jorPorComment);
        }
      }

      getJorPorDocument(jorpor06Detail.id);
      getWinnerDocument(jorpor06Detail.id);
      getCancelJorPorDocument(jorpor06Detail.id);

      return;
    }, 500);
  };

  const setSaveApprover = (data: Jorpor06User[]) => {
    if (data) {
      const saveAcceptor: Jorpor06User[] = [];

      data?.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          const newData: Jorpor06User = {
            id: item.id,
            jorPor06Id: item.jorPor06Id,
            sectionCode: item.sectionCode,
            userId: item.userId,
            fullName: item.fullName,
            departmentName: item.departmentName,
            positionName: item.positionName,
            isAvailable: item.isAvailable,
            availableRemark: item.availableRemark,
            remark: item.remark,
            sequence: index + 1,
            status: item.status,
            inRefCode: item.inRefCode,
            processType: item.processType,
            sectionLevel: item.sectionLevel,
            sectionName: item.sectionName,
            sectionSequence: item.sectionSequence,
            sectionId: item.sectionId,
            sectionType: item.sectionType,
            delegateDepartmentName: item.delegateDepartmentName,
            delegateEmployeeCode: item.delegateEmployeeCode,
            delegateFullName: item.delegateFullName,
            delegatePositionName: item.delegatePositionName,
            delegateUserId: item.delegateUserId,
          };

          saveAcceptor.push(newData);
        });

      jorpor06Detail.acceptors = saveAcceptor;

    }
  };

  const handlerOnChangeCommitteeCheckbox = (value: boolean, index: number) => {
    const data = [...committeeApprovers];
    data[index].isDisabled = value;
    setCommitteeApprovers(data);
  };

  const handlerOnChangeCommitteeRemark = (value: string, index: number) => {
    const data = [...committeeApprovers];
    data[index].remark = value;
    setCommitteeApprovers(data);
  };

  // const handlerOnChangeCommitteeCheckbox = (value: boolean, index: number) => {
  //   const data = [...jorpor06Detail.committees];
  //   data[index].isDisabled = value;
  //   setJorpor06Detail({
  //     ...jorpor06Detail,
  //     committees: data,
  //   });

  // };

  // const handlerOnChangeCommitteeRemark = (value: string, index: number) => {
  //   const data = [...jorpor06Detail.committees];
  //   data[index].remark = value;
  //   setJorpor06Detail({
  //     ...jorpor06Detail,
  //     committees: data,
  //   });
  // };

  const onApproveAndReject = async () => {
    submitForm();
    if (modalType === ModalType.REJECT && (!remark || remark.length === 0)) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    if (jorpor06Detail.id) {
      if (modalType === ModalType.REJECT) {

        if (jorpor06Detail.status === AnnouncementStatus.WaitingForDirectorJorPorAssign) {

          const res = await jorPor06.jorporDirectorRejectAsync(jorpor06Detail.id, remark);

          if (res.status !== HttpStatusCode.OK) {
            toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
            return;
          }

        } else {
          const res = await jorPor06.sendRejectCommitteeAsync(jorpor06Detail.id, remark);
          if (res.status !== HttpStatusCode.OK) {
            toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
            return;
          }
        }

        setRemark('');
        getJorPor06DetailAsync();
        toast.success('ส่งกลับแก้ไขสำเร็จ');

      } else {
        const res = await jorPor06.sendApproveCommitteeAsync(jorpor06Detail.id, remark);
        if (res.status === HttpStatusCode.OK) {
          setRemark('');
          getJorPor06DetailAsync();
          toast.success('เห็นชอบสำเร็จ');
        } else {
          toast.error('เห็นชอบไม่สำเร็จ');
          return;
        }
      }
    }

    setShowEdit(false);
  };

  const onCancelingOrEditing = async () => {
    submitForm();

    if (!remark || remark.length === 0) {
      return;
    }

    const res = await jorPor06.cancelJorPor06Async(jorpor06Detail.id, remark);

    if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
      const newId: string = res.data;
      getNewJorPor06DetailAsync(newId);
      navigate(`/procurement-create-006/detail/${jorpor06Detail.inviteId}/${newId}`);
      toast.success('บันทึกขอยกเลิกสำเร็จ');
    } else {
      toast.warn('บันทึกขอยกเลิกไม่สำเร็จ');
    }

    setShowCancelModal(false);
  };

  const handleSetFile = (documents: FileValue[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];

      const newFile: FileValue = {
        id: document.id,
        name: document.fileName ? document.fileName : document.name,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const uploadFileOnChangeAsync = async (file: FileValue) => {
    if (jorpor06Detail.id) {
      const res = await jorPor06.uploadJorPor06DocumentAsync(jorpor06Detail.id, file);

      if (res.status === HttpStatusCode.ACCEPTED) {
        handleSetFile(res.data);
        toast.success('อัปโหลดไฟล์สำเร็จ');
      }
    }
  };

  const removeFileAsync = async (index: number, id: string | undefined) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.id === id);
    newArray.splice(i, 1);
    setFiles(newArray);

    if (jorpor06Detail.id
      && id
    ) {
      const res = await jorPor06.removeJorPor06FileAsync(jorpor06Detail.id, id ?? '');

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }
  };

  return (
    <div className='document'>

      <Card className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <Title
              text='ผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้าง'
              className='fs-5 text-primary'
            />
            {openCollapse ? (
              <ArrowCollapse onClick={() => setOpenCollapse(!openCollapse)} openCollapse={openCollapse} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse(true)} openCollapse={openCollapse} />)}
          </div>
          <div className='d-flex gap-2'>
            {
              jorpor06Detail.isChanging && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-warning' /></div>
                    {TorEditCancelStatusConstant.Editing}</span>
                </div>
              )
            }
            {
              jorpor06Detail.isCanceling && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-danger' /></div>
                    {TorEditCancelStatusConstant.Canceling}</span>
                </div>
              )
            }
            <TorStatus value={jorpor06Detail.status} />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>

        <Collapse in={openCollapse}>
          <div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>ลำดับ</th>
                      <th style={{ width: 200 }}>ชื่อ-นามสกุล</th>
                      <th style={{ width: 200 }}>ฝ่าย/สำนัก</th>
                      <th style={{ width: 200 }}>ตำแหน่ง</th>
                      <th style={{ width: 200 }}>ตำแหน่งในคณะกรรมการ</th>
                      <th style={{ width: 200 }}>สถานะ</th>
                      <th style={{ width: 200 }}>วันที่เห็นชอบ</th>
                      <th style={{ width: 200 }}>ไม่สามารถปฏิบัติงานได้</th>
                      <th style={{ width: 200 }}>หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {committeeApprovers.map((dataCommittee, index) => (
                      <tr className='text-center'
                        key={index}>
                        <td>{index + 1}</td>
                        <td className='text-start'>{dataCommittee.approverFullName}</td>
                        <td className='text-start'>{dataCommittee.departmentName}</td>
                        <td className='text-start'>{dataCommittee.position}</td>
                        <td className='text-center'>
                          <Selector
                            items={positionOnBoardProcurementDDL}
                            value={dataCommittee.approverPositionOnBoardId}
                            disabled
                          />
                        </td>
                        <td><ApproverStatus value={dataCommittee.approverStatus} outline /></td>
                        <td>{dataCommittee.approvedDateAt ? thaiFormatDateWithSlash(dataCommittee.approvedDateAt) : ''}</td>
                        <td>{
                          (dataCommittee.approverPositionOnBoardId !== PositionOnBoardIdEnum.Chairman) && (
                            <Check
                              className='d-inline-block'
                              value={dataCommittee.isDisabled}
                              onChange={(val) => handlerOnChangeCommitteeCheckbox(val, index)}
                              disabled={dataCommittee.approverStatus === AcceptorStatus.APPROVED
                                || (jorpor06Detail.status !== AnnouncementStatus.Draft
                                  && jorpor06Detail.status !== AnnouncementStatus.Reject
                                  && jorpor06Detail.status !== AnnouncementStatus.WaitingComitteeApprove
                                )
                                || dataCommittee.approverUserId === userId
                              }
                            />
                          )
                        }
                        </td>
                        <td>{
                          (dataCommittee.approverPositionOnBoardId !== PositionOnBoardIdEnum.Chairman) && (
                            <Input
                              value={dataCommittee.remark}
                              onChange={(val) => handlerOnChangeCommitteeRemark(val, index)}
                              disabled={!dataCommittee.isDisabled || dataCommittee.approverStatus === AcceptorStatus.APPROVED}
                            />
                          )
                        }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>

      {
        ((jorpor06Detail.confirmPriceSum > Number(budgetConstantJorPro06.sharedValue.value)) &&
          (jorpor06Detail.status === AnnouncementStatus.WaitingForDirectorJorPorAssign ||
            jorpor06Detail.status === AnnouncementStatus.DraftAcceptor ||
            jorpor06Detail.status === AnnouncementStatus.Approve ||
            jorpor06Detail.status === AnnouncementStatus.Accepted ||
            jorpor06Detail.status === AnnouncementStatus.WaitingAcceptor ||
            jorpor06Detail.status === AnnouncementStatus.WaitingAccept ||
            jorpor06Detail.status === AnnouncementStatus.Cancelled)
        ) ? (
          <Card className='mt-3'>
            <>
              <div className='d-flex mb-4 align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Title text='ผอ.จพ.มอบหมาย'
                    className='fs-5 text-primary' />
                  {openCollapse2 ? (
                    <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
                  ) : (
                    <ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
                </div>
              </div>
              <Collapse in={openCollapse2}
                className='mt-3 px-2'>
                <div>
                  <Table
                    hidePagination
                    className='mt-3 step-bar'
                    notResponse
                  >
                    <thead>
                      <tr>
                        <th style={{ width: '5%' }}>ลำดับ</th>
                        <th className='text-start'
                          style={{ width: '30%' }}>ชื่อ-นามสกุล
                        </th>
                        <th className='text-start'
                          style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {jorpor06Detail.jorPorDirectors?.sort((a, b) => a.sequence - b.sequence)
                        .map((data, i) => (
                          <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                            key={data.id}>
                            <td className='text-center'>{i + 1}</td>
                            <td>
                              {data.fullName}
                              <p className='m-0 department'>
                                {data.positionName}
                              </p>
                            </td>
                            <td className='text-left'>
                              {data.delegateFullName &&
                                <>
                                  {data.delegateFullName}
                                  <p className='m-0 department'>
                                    {data.delegatePositionName}
                                  </p>
                                </>
                              }
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </>
          </Card>
        ) : (<></>)
      }
      {
        ((jorpor06Detail.confirmPriceSum > Number(budgetConstantJorPro06.sharedValue.value)) &&
          (jorpor06Detail.status === AnnouncementStatus.WaitingForDirectorJorPorAssign ||
            jorpor06Detail.status === AnnouncementStatus.DraftAcceptor ||
            jorpor06Detail.status === AnnouncementStatus.Approve ||
            jorpor06Detail.status === AnnouncementStatus.Accepted ||
            jorpor06Detail.status === AnnouncementStatus.WaitingAcceptor ||
            jorpor06Detail.status === AnnouncementStatus.WaitingAccept ||
            jorpor06Detail.status === AnnouncementStatus.Cancelled)) ? (
          <>
            <Card className='mt-3'>
              <>
                <div className='d-flex mb-4 align-items-center justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <Title text='เจ้าหน้าที่พัสดุให้ความเห็น'
                      className='fs-5 text-primary' />
                    {openCollapse3 ? (
                      <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
                    ) : (
                      <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />)}
                  </div>
                </div>
                <Collapse in={openCollapse3}
                  className='mt-3 px-2'>
                  <div>
                    <div className='d-flex justify-content-end'>
                      {
                        (isJorPorDirector && jorpor06Detail.status === AnnouncementStatus.WaitingForDirectorJorPorAssign) && (
                          <Button
                            className='d-flex align-items-center gap-2'
                            variant='outline-primary'
                            onClick={() => setShowResponsibleModals(true)}
                          >
                            <FaPlus />เพิ่มรายชื่อ
                          </Button>
                        )
                      }
                    </div>
                    <Table
                      hidePagination
                      className='mt-3 step-bar'
                      notResponse
                    >
                      <thead>
                        <tr>
                          <th style={{ width: '5%' }}>ลำดับ</th>
                          <th className='text-start'
                            style={{ width: '30%' }}>ชื่อ-นามสกุล
                          </th>
                          <th className='text-start'
                            style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                          </th>
                          <th style={{ minHeight: 100, width: '25%' }} />
                        </tr>
                      </thead>
                      <tbody>
                        {responsibilities?.sort((a, b) => a.sequence - b.sequence)
                          .map((data, i) => (
                            <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                              key={data.id}>
                              <td className='text-center'>{i + 1}</td>
                              <td>
                                {data.fullName}
                                <p className='m-0 department'>
                                  {data.positionName}
                                </p>
                              </td>
                              <td className='text-left'>
                                {data.delegateFullName &&
                                  <>
                                    {data.delegateFullName}
                                    <p className='m-0 department'>
                                      {data.delegatePositionName}
                                    </p>
                                  </>
                                }
                              </td>
                              <td className='text-end'>
                                {((jorpor06Detail.status === AnnouncementStatus.WaitingForDirectorJorPorAssign) && isJorPorDirector) &&
                                  <Button
                                    variant='danger'
                                    onClick={() => handlerRemoveResponsible(i)}
                                  >
                                    <FaTrashAlt />
                                  </Button>}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Collapse>
              </>
            </Card>
            <Card className='mt-3'>
              <>
                <div className='d-flex mb-4 align-items-center justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <Title text='ความเห็น จพ.'
                      className='fs-5 text-primary' />
                    {openCollapse4 ? (
                      <ArrowCollapse onClick={() => setOpenCollapse4(!openCollapse4)} openCollapse={openCollapse4} />
                    ) : (
                      <ArrowCollapse onClick={() => setOpenCollapse4(true)} openCollapse={openCollapse4} />)}
                  </div>
                </div>
                <Collapse in={openCollapse4}
                  className='mt-3 px-2'>
                  <>
                    <div>
                      <InputTextArea
                        label='ความเห็นเพิ่มเติม'
                        value={jorPorComment}
                        onChange={(val) => setJorPorComment(val)}
                        rule={{ required: true }}
                        disabled={jorpor06Detail.status !== AnnouncementStatus.DraftAcceptor || !isResponsible} />
                    </div>
                    {((jorpor06Detail.isCanceling
                      && jorpor06Detail.confirmPriceSum > Number(budgetConstantJorPro06.sharedValue.value)
                      && (jorpor06Detail.status === AnnouncementStatus.Accepted
                        || jorpor06Detail.status === AnnouncementStatus.DraftAcceptor
                        || jorpor06Detail.status === AnnouncementStatus.Approve
                        || jorpor06Detail.status === AnnouncementStatus.WaitingAcceptor
                        || jorpor06Detail.status === AnnouncementStatus.WaitingAccept
                        || jorpor06Detail.status === AnnouncementStatus.WaitingForDirectorJorPorAssign))
                      || (jorpor06Detail.status === AnnouncementStatus.Cancelled)) && (
                        <div className='document'>
                          <div className='d-flex justify-content-between align-items-center gap-3 mt-3'>
                            <div className='d-flex justify-content-between align-items-center gap-3'>
                              <Title text='ตัวอย่างเอกสารขออนุมัติยกเลิกสั่งซื้อ/สั่งจ้าง (จพ.006)'
                                className='fs-5 text-primary' />
                            </div>
                          </div>
                          <div className='mt-3'>
                            <Collabora
                              docId={docCancelJorPor006Id}
                              docName='doc-cancel-jorpor006'
                              ref={collaboraCancelRef}
                              readonly={false} />
                          </div>
                        </div>
                      )}
                  </>
                </Collapse>
              </>
            </Card>
          </>
        ) : (<></>)
      }

      {(jorpor06Detail.status === AnnouncementStatus.DraftAcceptor || jorpor06Detail.status === AnnouncementStatus.Approve || jorpor06Detail.status === AnnouncementStatus.Accepted || jorpor06Detail.status === AnnouncementStatus.WaitingAcceptor || jorpor06Detail.status === AnnouncementStatus.WaitingAccept) && (
        <Acceptors
          titleHistory='ประวัติการใช้งานจัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้า (จพ.006)'
          data={acceptors.length > 0 ? acceptors : jorpor06Detail.acceptors}
          visible={jorpor06Detail.status === AnnouncementStatus.DraftAcceptor}
          isDepartmentDirector={false}
          processType={PROCESSTYPE.ApproveWinnerAnnouncementProcess}
          supplyMethodId={jorpor06Detail.supplyMethod}
          program={ProgramType.JorPor06}
          budgetAmount={jorpor06Detail.confirmPriceSum}
          onSetSectionType={(type) => setSectionType(type)}
          onAcceptorModalShow={() => setShowModals(!showModals)}
          onSetSectionSequence={(section) => setSectionSequence(section)}
          onAcceptorDataChange={(data) => setAcceptors(data)}
        />
      )}

      <div className='document'>
        <Card className='mt-3'>
          <div className='d-flex justify-content-between align-items-center gap-3'>
            <div className='d-flex justify-content-between align-items-center gap-3'>
              <Title text='ตัวอย่างเอกสารจัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้าง (จพ.006)'
                className='fs-5 text-primary' />
            </div>
          </div>
          <div className='mt-3'>
            <Collabora
              docId={docJorPor006Id}
              docName='doc-jorpor006'
              ref={collaboraRef}
              readonly={isReadonlyDoc}
              showRemark={true} />
          </div>
        </Card>
      </div>
      <div className='document'>
        <Card className='mt-3'>
          <div className='d-flex justify-content-between align-items-center gap-3'>
            <div className='d-flex justify-content-between align-items-center gap-3'>
              <Title text='ตัวอย่างเอกสารประกาศผู้ชนะ'
                className='fs-5 text-primary' />
            </div>
          </div>
          <div className='mt-3'>
            <Collabora
              docId={docWinnerId}
              docName='doc-winner'
              ref={collaboraWinnerRef}
              readonly={isReadonlyDoc} />
          </div>
        </Card>
      </div>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12} lg={6}>
            <UploadFile disabled={false}
              value={files}
              onChange={(value) => uploadFileOnChangeAsync(value)}
              onRemove={(index, id) => removeFileAsync(index, id)}
              onDownload={(index, id) => downloadFileAsync(index, id)}
              userId={userId} />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <div>
          {recallShow && (
            <ButtonCustom
              icon='undo'
              variant='danger'
              onClick={() => recallAsync()}
              text="เรียกคืนแก้ไข"
            />
          )}
          {/* save committee */}
          {(isCreate
            && (jorpor06Detail.status === AnnouncementStatus.Draft
              || jorpor06Detail.status === AnnouncementStatus.Reject
              || jorpor06Detail.status === AnnouncementStatus.WaitingComitteeApprove
            ))
            && (
              <ButtonCustom
                onClick={() => onSaveStep2(false)}
                text="บันทึก"
              />
            )}
          {(isJorPorDirector
            && (jorpor06Detail.status === AnnouncementStatus.WaitingForDirectorJorPorAssign
            ))
            && (
              <>
                <ButtonCustom
                  onClick={() => onSaveResponsibilities(false)}
                  text="บันทึก"
                />
                <ButtonCustom
                  icon="undo"
                  variant='danger'
                  onClick={() => onShowModal(ModalType.REJECT)}
                  text="ส่งกลับแก้ไข"
                />
                <ButtonCustom
                  onClick={() => setShowAssignModal(true)}
                  text="มอบหมาย"
                />
              </>
            )}
          {/* save acceptor */}
          {(isCreate
            && (jorpor06Detail.status === AnnouncementStatus.DraftAcceptor))
            && (
              <>
                <ButtonCustom
                  onClick={onSaveAcceptors}
                  text="บันทึก"
                />
                <ButtonCustom
                  icon="checked"
                  onClick={() => setShowWaitingForApprove(true)}
                  text="ส่งอนุมัติ/เห็นชอบ"
                />
              </>
            )}
          {(isCreate
            && (jorpor06Detail.status === AnnouncementStatus.Draft
              || jorpor06Detail.status === AnnouncementStatus.Reject
            ))
            && (
              <ButtonCustom
                onClick={() => setShowPending(true)}
                text="ส่งเห็นชอบ"
              />
            )}
          {(isQueueApprover && jorpor06Detail.status === AnnouncementStatus.WaitingComitteeApprove) && (
            <>
              <ButtonCustom
                icon="undo"
                variant='danger'
                onClick={() => onShowModal(ModalType.REJECT)}
                text="ส่งกลับแก้ไข"
              />
              <ButtonCustom
                variant='success'
                onClick={() => onShowModal(ModalType.APPROVE)}
                icon="checked"
                text='เห็นชอบ' />
            </>
          )}
          {(jorpor06Detail.status === AnnouncementStatus.WaitingAccept && isApprover) && (
            <>
              <ButtonCustom
                icon="undo"
                variant='danger'
                onClick={() => onShowApproveAndReject(jorpor06Detail.confirmPriceSum > Number(budgetConstantJorPro06.sharedValue.value) ? ModalType.WaitingForDirectorJorPorAssign : ModalType.REJECT)}
                text="ส่งกลับแก้ไข"
              />
              <ButtonCustom
                variant='success'
                onClick={() => onShowApproveAndReject(ModalType.APPROVE)}
                icon="checked"
                text={textLastSectionApprover(jorpor06Detail.acceptors)} />
            </>
          )}
          {
            (isCreate && jorpor06Detail.status === AnnouncementStatus.Accepted) && (
              <>
                <ButtonCustom
                  onClick={() => setShowCancelModal(true)}
                  variant='danger'
                  icon='cancel'
                  disabled={false}
                  text="บันทึกขออนุมัติยกเลิกการสั่งซื้อ/สั่งจ้าง"
                />
              </>
            )
          }
        </div>
        {/* {jorpor06Detail.entrepreneurs.some((i) => i.consideration.isWinner)
          ? (
            <ButtonCustom
              onClick={onClickNext}
              text="ถัดไป"
            />
          )
          : <div />} */}
        <div style={{ width: 100 }} />
      </div>
      <HistoryModal
        title='ประวัติการใช้งานจัดทำรายงานผลการพิจารณาและขออนุมัติสั่งซื้อ/สั่งจ้า (จพ.006)'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        data={activityData} />

      <SearchBuNameModal
        total={userTotal}
        show={showModals}
        onHide={() => setShowModals(!showModals)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName) => onSelectEmpApprover(id, name, position!, department!, jorpor06Detail.acceptors.length + 1, sectionSequence, sectionType, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showResponsibleModals}
        onHide={() => setShowResponsibleModals(false)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName) => onSelectResponsibilities(id, name, position!, department!, jorpor06Detail.acceptors.length + 1, sectionSequence, sectionType, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <Modal
        show={showEdit}
        size='lg'
        onHide={() => setShowEdit(!showEdit)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>{modalType === ModalType.APPROVE ? 'เห็นชอบ' : 'ส่งกลับแก้ไข'} </ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: modalType === ModalType.REJECT }}
                onChange={(val) => setRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowEdit(!showEdit)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                variant={modalType === ModalType.REJECT ? 'danger' : 'success'}
                onClick={() => onApproveAndReject()}
              >
                {modalType === ModalType.REJECT ? <FaUndo className='mx-2 pb-1 fs-4' /> : <FaCheckCircle className='mx-2 pb-1 fs-4' />}

                {modalType === ModalType.APPROVE ? 'เห็นชอบ' : 'ส่งกลับแก้ไข'}
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showPending}
        size='lg'
        onHide={() => setShowPending(!showPending)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งเห็นชอบผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อจัดจ้าง หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPending(!showPending)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSaveStep2(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showAssignModal}
        size='lg'
        onHide={() => setShowAssignModal(false)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการมอบหมายผู้รับผิดชอบ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowAssignModal(false)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSaveResponsibilities(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showWaitingForApprove}
        size='lg'
        onHide={() => setShowWaitingForApprove(!showWaitingForApprove)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งอนุมัติ/เห็นชอบ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitingForApprove(!showWaitingForApprove)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => sendApprove()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showCancelModal}
        size='lg'
        onHide={() => setShowCancelModal(!showCancelModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>บันทึกขออนุมัติยกเลิกการสั่งซื้อ/สั่งจ้าง</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowCancelModal(!showCancelModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                onClick={() => onCancelingOrEditing()}
              >
                <BsCheckCircle className='mx-2 pb-1 fs-4' />
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <ApproveAndRejectModal modalType={modalType}
        show={showApproveAndReject}
        onHide={() => setShowApproveAndReject(false)}
        acceptorId={acceptorId}
        isApprover={isApprover} />
    </div>
  );
}


function ApproveAndRejectModal(props: {
  modalType: ModalType,
  show: boolean,
  onHide: () => void,
  acceptorId?: string
  isApprover: boolean,
}) {
  const { jorpor06Detail, getJorPor06DetailAsync } = useContext(Context);
  const { userId } = useAppContext();
  const { id } = useParams();
  const [remark, setRemark] = useState<string>();

  const onSubmit = () => {
    submitForm();

    if ((props.modalType === ModalType.REJECT
      || props.modalType === ModalType.WaitingForDirectorJorPorAssign)
      && !remark) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    onSubmitApproveOrRejectAsync();
  };

  const onSubmitApproveOrRejectAsync = async () => {
    if (jorpor06Detail.acceptors && jorpor06Detail.acceptors.length > 0 && id) {

      const acceptor = jorpor06Detail.acceptors.find((b) => isNull(b.delegateUserId, b.userId) === userId);

      if (acceptor) {
        const body: BasicInformationAction = {
          sectionCode: acceptor.sectionCode,
          act: props.modalType,
          remark: remark!,
          acceptorId: acceptor.id ? acceptor.id : '',
        };

        const response = await jorPor06.sendApproveAndRejectAsync(id, body);

        if (props.modalType === ModalType.APPROVE) {
          if (response.status === HttpStatusCode.ACCEPTED) {
            toast.success(`${props.isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
            onHideModal();
            getJorPor06DetailAsync();
          }

          return;
        }

        if (response.status === HttpStatusCode.ACCEPTED) {
          toast.success('ส่งกลับแก้ไขสำเร็จ');

          onHideModal();
          getJorPor06DetailAsync();

          return;
        }
      }

    }
  };

  const onHideModal = () => {
    setRemark('');
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      size='lg'
      onHide={onHideModal}
      children={(
        <>
          <ModalBT.Header closeButton>
            <ModalBT.Title>
              {
                (props.modalType === ModalType.APPROVE) && (
                  textLastSectionApprover(jorpor06Detail.acceptors)
                )
              }
              {
                (props.modalType === ModalType.REJECT || props.modalType === ModalType.WaitingForDirectorJorPorAssign) && (
                  'ส่งกลับแก้ไข'
                )
              }
            </ModalBT.Title>
          </ModalBT.Header>
          <ModalBT.Body className='p-0 ps-4'>
            {
              (props.modalType === ModalType.APPROVE) && (
                <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                  value={remark}
                  onChange={(value) => setRemark(value)} />
              )
            }
            {
              (props.modalType === ModalType.REJECT || props.modalType === ModalType.WaitingForDirectorJorPorAssign) && (
                <InputTextArea label='หมายเหตุ'
                  rule={{ required: true }}
                  value={remark}
                  onChange={(value) => setRemark(value)} />
              )
            }
          </ModalBT.Body>
          <ModalBT.Footer className='justify-content-end'>
            <Button
              variant='light'
              onClick={onHideModal}
              className='me-2 px-3'>
              ยกเลิก
            </Button>
            {props.modalType === ModalType.APPROVE ? (
              <Button
                onClick={onSubmit}
                variant='success'
                className='me-2 px-3'>
                <FaCheckCircle className='mx-2 pb-1 fs-4' />
                {textLastSectionApprover(jorpor06Detail.acceptors)}
              </Button>
            ) : (
              <Button
                onClick={onSubmit}
                variant='danger'
                className='me-2 px-3'>
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            )}
          </ModalBT.Footer>
        </>
      )}
    />
  );
}
