import { Card, Currency, DatePicker, Input, Selector } from 'components';
import { InputNumber } from 'components/Controls/InputNumber';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import { TorCorrectiveMaintenanceModel } from 'models/PP/TorModels';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import { ValidatePattern } from 'utils';
import { Context } from '../../step';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';

interface ICorrectiveMaintenanceProps {
  correctiveMaintenance: TorCorrectiveMaintenanceModel;
  onCorrectiveMaintenanceChange: (value: TorCorrectiveMaintenanceModel) => void;
  periodUnitDDL: ItemModel[];
  dayOfWeekDDL: ItemModel[];
  cmFineTypeDDL: ItemModel[];
  isDisabled?: boolean;
}

export function CorrectiveMaintenance(props: ICorrectiveMaintenanceProps) {
  const { readonly } = useContext(Context);
  const [correctiveMaintenance, setCorrectiveMaintenance] = useState<TorCorrectiveMaintenanceModel>(props.correctiveMaintenance);
  const [firstLoad, setFirstLoad] = useState(true);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    props.onCorrectiveMaintenanceChange(correctiveMaintenance);
  }, [correctiveMaintenance]);

  useEffect(() => {
    if (props.correctiveMaintenance && firstLoad) {
      setCorrectiveMaintenance(props.correctiveMaintenance);
      setFirstLoad(false);
    }
  }, [props.correctiveMaintenance, firstLoad]);

  const onTextChange = useCallback((key: string, value: string) => {
    setCorrectiveMaintenance(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setCorrectiveMaintenance(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onDateChange = useCallback((key: string, value: Date | undefined) => {
    setCorrectiveMaintenance(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <div className='d-flex align-items-center'>
        <Title
          text='การซ่อมแซมแก้ไข (Corrective Maintenance)'
          className='fs-5 text-primary'
        />
        {
          collapsed
            ? (
              <FaChevronCircleDown
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(false)}
              />
            ) : (
              <FaChevronCircleUp
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(true)}
              />
            )
        }
      </div>
      <Collapse in={!collapsed} className='ps-40'>
        <div>
          <Row className='mt-2'>
            <Col sm={12} md={2}>
              <Selector
                label='ต้องให้บริการซ่อมแซม ในวัน'
                items={props.dayOfWeekDDL}
                value={correctiveMaintenance?.dayStart}
                onChange={(value) => onTextChange('dayStart', value)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly} />
            </Col>
            <Col sm={12} md={2}>
              <Selector
                label='ถึงวัน'
                items={props.dayOfWeekDDL}
                value={correctiveMaintenance?.dayEnd}
                onChange={(value) => onTextChange('dayEnd', value)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly} />
            </Col>
            <Col sm={12} md={1}>
              <Input
                label='เวลา'
                type='time'
                value={correctiveMaintenance?.startTime}
                onChange={(value) => onTextChange('startTime', value)}
                disabled={props.isDisabled || readonly}
              />
            </Col>
            <Col sm={12} md={1}>
              <Input
                label='ถึงเวลา'
                type='time'
                value={correctiveMaintenance?.endTime}
                onChange={(value) => onTextChange('endTime', value)}
                disabled={props.isDisabled || readonly}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3}>
              <InputNumber
                label='เริ่มจัดการซ่อมแซมภายใน'
                value={correctiveMaintenance?.cmCount}
                onChange={(value) => onNumberChange('cmCount', Number(value))}
                disabled={props.isDisabled || readonly}
              />
            </Col>
            <Col sm={12} md={2}>
              <Selector
                className='primary no-label'
                items={props.periodUnitDDL}
                value={correctiveMaintenance?.cmUnit}
                onChange={(value) => onTextChange('cmUnit', value)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly} />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={3}>
              <InputNumber
                label='ให้แล้วเสร็จภายใน'
                value={correctiveMaintenance?.cmCompleteCount}
                onChange={(value) => onNumberChange('cmCompleteCount', Number(value))}
                disabled={props.isDisabled || readonly}
              />
            </Col>
            <Col sm={12} md={2}>
              <Selector
                className='primary no-label'
                items={props.periodUnitDDL}
                value={correctiveMaintenance?.cmCompleteUnit}
                onChange={(value) => onTextChange('cmCompleteUnit', value)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly} />
            </Col>
          </Row>
          <label>หากไม่เข้ามาซ่อมแซมภายในเวลาที่กำหนด</label>
          <Row className='mt-2'>
            <Col sm={12} md={3}>
              <Currency
                label='ยินยอมให้คิดค่าปรับ (%)'
                value={correctiveMaintenance?.cmFinePercent}
                onChange={(value) => onNumberChange('cmFinePercent', Number(value))}
                maxValue={100}
                disabled={props.isDisabled || readonly}
              />
            </Col>
            <Col sm={12} md={3}>
              <Currency
                label='ค่าปรับกรณีเกิดความเสียหาย (%)'
                value={correctiveMaintenance?.cmDisruptedFinePercent}
                onChange={(value) => onNumberChange('cmDisruptedFinePercent', Number(value))}
                maxValue={100}
                disabled={props.isDisabled || readonly}
              />
            </Col>
            <Col sm={12} md={2}>
              <Selector
                className='primary no-label'
                items={props.cmFineTypeDDL}
                value={correctiveMaintenance?.cmFinePercentUnit}
                onChange={(value) => onTextChange('cmFinePercentUnit', value)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly} />
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card>
  );
}
