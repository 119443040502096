import {
  Card, Check, ContractCompleteReportStatus, DatePicker, Input, Readonly, Selector, Table, TorStatus, ButtonCustom,
} from 'components';
import Title from 'components/Controls/Title';
import {
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { useLoaderData, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { Context } from '.';
import { ContractCompleteReportItemModel, ContractCompleteReportModel, SearchCriteriaReportListModel } from 'models/CM/ContractCompleteReportModel';
import { Button, Col, Row } from 'react-bootstrap';
import { ItemModel } from 'models';
import { FaEraser, FaFileExcel, FaHistory, FaRedo, FaSearch, FaTrash } from 'react-icons/fa';
import ContractCompleteReportService from 'services/CM/ContractCompleteReportService';
import { HttpStatusCode, THCurrency, fullDate, submitForm } from 'utils';
import { MdArrowBack, MdArrowForward, MdCircle, MdSave } from 'react-icons/md';
import toast from 'utils/toast';
import { shareValue } from 'services';
import { ContractTypeEnum, QuarterCodeEnum, SharedvalueGroup, SubGroup } from 'utils/constants/ContractEnum';
import { HistoryModal } from '../Modal/HistoryModal';
import { SummaryModal } from '../Modal/SummaryModal';
import { Quarter } from 'constant/ContractStatus';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type Loader = {
  quarterDDL: ItemModel[],
  budgetYearDDL: ItemModel[],
  supplyMethodDDL: ItemModel[],
  supplyMethodTypeDDL: ItemModel[],
  contractManagementTypeDDL: ItemModel[],
};

enum GroupReportEnum {
  AllBudgetYear = 'AllBudgetYear',
  AllQuarter = 'AllQuarter',
  AllCurrent = 'AllCurrent',
}

export default function Step1({ onClickNext, onClickBack }: Props) {
  const {
    quarterDDL,
    budgetYearDDL,
    contractManagementTypeDDL,
  } = useLoaderData() as Loader;
  const { id } = useParams();
  const navigate = useNavigate();
  const { dataContext, setDataContext } = useContext(Context);
  const [contractData, setContractData] = useState<ContractCompleteReportModel>({} as ContractCompleteReportModel);
  const [contractDataItems, setContractDataItems] = useState<ContractCompleteReportItemModel[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState(false);
  const [quarter, setQuarter] = useState(0);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const { supplyMethodDDL, supplyMethodTypeDDL } = useLoaderData() as Loader;
  const [supplyMethodSpecialDDL, setSupplyMethodSpecialDDL] = useState<ItemModel[]>([]);
  // const [contractManagementTypeDDL, setContractManagementTypeDDLL] = useState<ItemModel[]>([]);
  const [searchData, setSearchData] = useState<SearchCriteriaReportListModel>({} as SearchCriteriaReportListModel);

  const onChangeSupplyMethodType = (value: string) => {
    // getSupplyMethodSpecialType(value);
    // getContractManagementType(value);
    setSearchData({ ...searchData, supplyMethodTypeId: value })
  };

  const getSupplyMethodSpecialType = useCallback(async (supplyMethodId: string) => {
    const { data, status } = await shareValue.getSupplyMethodListAsync(supplyMethodId);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setSupplyMethodSpecialDDL(data);
  }, [searchData.supplyMethodId]);

  // const getContractManagementType = useCallback(async (supplyMethodTypeId?: string) => {
  //   const { data, status } = await shareValue.getListSharedValueAsync(SharedvalueGroup.ContractManagementType, '', SubGroup.SubContractManagementType);
  //   if (status !== HttpStatusCode.OK) {
  //     return;
  //   }
  //   setContractManagementTypeDDLL(data);
  // }, [searchData.supplyMethodTypeId]);

  useEffect(() => {
    if (dataContext) {
      setContractData(dataContext);
      if (dataContext.contractCompleteReportItems) {
        setContractDataItems(dataContext.contractCompleteReportItems);
      }

      setIsDisabled(dataContext.id !== undefined && dataContext.status !== ContractCompleteReportStatus.Draft && dataContext.status !== ContractCompleteReportStatus.Rejected);
      if (dataContext.quarterId === Quarter.Q1) {
        setQuarter(1);
      }
      if (dataContext.quarterId === Quarter.Q2) {
        setQuarter(2);
      }
      if (dataContext.quarterId === Quarter.Q3) {
        setQuarter(3);
      }
      if (dataContext.quarterId === Quarter.Q4) {
        setQuarter(4);
      }

      if (!contractData.budgetYear) {
        const date = new Date();
        const budgetYear = date.getUTCFullYear() + 543;
        setContractData({ ...contractData, budgetYear: budgetYear })
      }

    }
  }, [dataContext]);

  const onGetContractCompleteReportByIdAsync = async (id: string) => {
    const { data, status } = await ContractCompleteReportService.getContractCompleteReportByIdAsync(id);
    if (status === HttpStatusCode.OK) {
      setDataContext(data);
      setContractData(data);
    }
  }

  const onGetContractCompleteReportItemsAsync = async () => {
    const { data, status } = await ContractCompleteReportService.getContractCompleteReportItemByCriteriaAsync(contractData);
    if (status === HttpStatusCode.OK) {
      setContractDataItems(data);
      toast.success('ดึงข้อมูลสัญญาสำเร็จ');
      return;
    }

    toast.warn('ดึงข้อมูลสัญญาไม่สำเร็จ');
  }

  const onChangeQuarter = async (quarterId: string) => {
    const { data, status } = await shareValue.getSingleSharedValueAsync(quarterId);
    if (status !== HttpStatusCode.OK) {
      return;
    }
    if (contractData.budgetYear > 0) {
      onSetDateQuarter(quarterId, data.sharedValue.code);
    } else {
      setContractData({ ...contractData, quarterId: quarterId });
    }
  }

  const onSetDateQuarter = (quarterId: string, quarterCode: string) => {
    const year = contractData.budgetYear - 543;
    let startDate = new Date();
    let endDate = new Date();
    switch (quarterCode) {
      case QuarterCodeEnum.Q1:
        startDate = new Date(year, 1 * 3 - 3, 1);
        endDate = new Date(year, startDate.getMonth() + 3, 0);
        break;
      case QuarterCodeEnum.Q2:
        startDate = new Date(year, 2 * 3 - 3, 1);
        endDate = new Date(year, startDate.getMonth() + 3, 0);
        break;
      case QuarterCodeEnum.Q3:
        startDate = new Date(year, 3 * 3 - 3, 1);
        endDate = new Date(year, startDate.getMonth() + 3, 0);
        break;
      case QuarterCodeEnum.Q4:
        startDate = new Date(year, 4 * 3 - 3, 1);
        endDate = new Date(year, startDate.getMonth() + 3, 0);
        break;
    }

    setContractData({ ...contractData, quarterId: quarterId, startSignDate: startDate, endSignDate: endDate });
  }

  const removeItem = (contractNumber: string) => {
    const newData = [...contractDataItems];
    const index = newData.findIndex(i => i.contractNumber === contractNumber);
    newData.splice(index, 1);
    setContractDataItems(newData);
  };

  const onSave = async () => {
    if (contractData.budgetYear === undefined || contractData.budgetYear === null) {
      toast.warn('กรุณาระบุปี');
      return;
    }

    if (contractData.contractCompleteReportDate === undefined || contractData.contractCompleteReportDate === null) {
      toast.warn('กรุณาระบุวันที่ทำเอกสาร');
      return;
    }

    if (contractData.startSignDate === undefined || contractData.startSignDate === null) {
      toast.warn('กรุณาระบุข้อมูลวันที่เริ่มต้น');
      return;
    }

    if (contractData.endSignDate === undefined || contractData.endSignDate === null) {
      toast.warn('กรุณาระบุข้อมูลวันที่สิ้นสุด');
      return;
    }

    if (contractData.startSignDate > contractData.endSignDate) {
      toast.warn('กรุณาระบุข้อมูลวันที่ให้ถูกต้อง');
      return;
    }

    if (contractDataItems === undefined || contractDataItems.length === 0) {
      toast.warn('กรุณาดึงข้อมูลสัญญาแล้วเสร็จ');
      return;
    }

    if (contractData.id) {
      const { data, status } = await ContractCompleteReportService.UpdateContractCompleteReport(contractData, contractDataItems);
      if (status === HttpStatusCode.OK) {
        onGetContractCompleteReportByIdAsync(contractData.id);
        toast.success('บันทึกข้อมูลสำเร็จ');
      }
    } else {
      const { data, status } = await ContractCompleteReportService.CreateContractCompleteReport(contractData, contractDataItems);
      if (status === HttpStatusCode.OK) {
        toast.success('บันทึกข้อมูลสำเร็จ');
        navigate(`/cm/cm05/detail/${data}`);
      }
    }
  }

  const GroupReports = [
    { label: `รายงานสัญญาแล้ว เสร็จไตรมาสที่ ${quarter}/${dataContext.budgetYear} ทั้งหมด`, value: 'AllQuarter' },
    { label: `รายงานสัญญาแล้วเสร็จปี ${dataContext.budgetYear} ทั้งหมด`, value: 'AllBudgetYear' },
    { label: 'รายงานสัญญาแล้วเสร็จตามรายการปัจจุบัน', value: 'AllCurrent' },
  ];

  const onExportReportAsync = async () => {

    const { data } = await ContractCompleteReportService.getExcelReportAsync(contractData.id!,
      searchData.reportGroup === GroupReportEnum.AllQuarter ? dataContext.quarterId : undefined,
      searchData.reportGroup === GroupReportEnum.AllBudgetYear ? dataContext.budgetYear : undefined,
      searchData.contractManagementType);

    const todayText = new Date().toISOString()
      .substring(0, 10)
      .replaceAll('-', '');

    let quarterName = '';
    if (quarterDDL.filter(q => q.value === contractData.quarterId).length > 0) {
      quarterName = quarterDDL.filter(q => q.value === contractData.quarterId)[0].label;
    }


    downloadReport(data, `รายงานสัญญาแล้วเสร็จ${quarterName}_${todayText}.xlsx`);
  };

  const downloadReport = (fileData: Blob, fileName: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(fileData);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const onSearch = async (searchFrom: SearchCriteriaReportListModel) => {
    submitForm();

    // if (!searchFrom.contractManagementType) {
    //   toast.warn('กรุณาเลือกประเภทสัญญา');
    //   return;
    // }

    getContractCompleteReportByIdAsync(contractData.id!, searchFrom.contractManagementType);
  }

  const onClear = async () => {
    setSearchData({ ...searchData, contractManagementType: '' });
    getContractCompleteReportByIdAsync(contractData.id!);
  }

  const getContractCompleteReportByIdAsync = async (id: string, contractManagementType?: string) => {
    const {
      data,
      status,
    } = await ContractCompleteReportService.getContractCompleteReportByIdAsync(id, contractManagementType);
    if (status === HttpStatusCode.OK) {
      setDataContext(data);
    }
  };

  return (
    <>
      <div className='document'>
        <Card className='mt-3'>
          <Title text='ข้อมูลรายงานสัญญาแล้วเสร็จตามไตรมาส'
            className='fs-5 text-primary'
            extraElement={
              <>
                <TorStatus
                  value={contractData.status}
                />
                <Button
                  onClick={() => setShowHistory(!showHistory)}
                  className='ms-2'
                  variant='outline-primary'
                >
                  <FaHistory className='me-1' />
                  ประวัติการใช้งาน
                </Button>
              </>
            } />
          <Row>
            <Col sm={12}
              md={4}
              lg={4}
              xl={3}>
              <Input
                label='เลขที่เอกสาร'
                value={contractData.contractCompleteReportNumber}
                disabled
              />
            </Col>
            <Col sm={12}
              md={4}
              lg={4}
              xl={3}>
              <DatePicker label='วันที่ทำเอกสาร'
                value={contractData.contractCompleteReportDate}
                onChangeHasNullable={(val) => setContractData({ ...contractData, contractCompleteReportDate: val })}
                rule={{ required: true }}
                disabled={isDisabled} />
            </Col>
          </Row>
          <Row>
            <Col sm={12}
              md={4}
              lg={4}
              xl={3}>
              <Selector label='ปี'
                value={contractData.budgetYear?.toString()}
                items={budgetYearDDL}
                rule={{ required: true }}
                onChange={(val) => setContractData({ ...contractData, budgetYear: Number(val) })}
                disabled={isDisabled} />
            </Col>
            <Col sm={12}
              md={4}
              lg={4}
              xl={3}>
              <Selector label='ไตรมาส'
                value={contractData.quarterId}
                items={quarterDDL}
                rule={{ required: true }}
                onChange={(val) => onChangeQuarter(val)}
                disabled={isDisabled} />
            </Col>
            <Col sm={12}
              md={4}
              lg={4}
              xl={3}>
              <DatePicker label='วันที่ลงนามในสัญญาตั้งแต่'
                value={contractData.startSignDate}
                onChangeHasNullable={(val) => setContractData({ ...contractData, startSignDate: val })}
                rule={{ required: true }}
                disabled={isDisabled} />
            </Col>
            <Col sm={12}
              md={4}
              lg={4}
              xl={3}>
              <DatePicker label='ถึงวันที่'
                value={contractData.endSignDate}
                onChangeHasNullable={(val) => setContractData({ ...contractData, endSignDate: val })}
                rule={{ required: true }}
                disabled={isDisabled} />
            </Col>
          </Row>
          <Row className='mt-4 mb-4'>
            <Col>
              <Button
                className='d-flex align-items-center gap-2'
                variant='outline-primary'
                onClick={onGetContractCompleteReportItemsAsync}
                disabled={isDisabled}
              >
                <FaRedo />ดึงข้อมูลสัญญา
              </Button>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Title text='ค้นหาข้อมูลสำหรับพิมพ์รายงาน'
              className='fs-5 text-primary' />
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}
              lg={8}>
              <Row>
                {/* <Col sm={12}
                  lg={4}>
                  <Selector
                    label='วิธี'
                    placeholder='กรุณาเลือก'
                    items={supplyMethodDDL}
                    value={searchData.supplyMethodId}
                    onChange={(val) => onChangeSupplyMethod(val)}
                  />
                </Col> */}
                {/* <Col sm={12}
                  lg={4}>
                  <Selector
                    label='วิธี'
                    placeholder='กรุณาเลือก'
                    items={supplyMethodTypeDDL}
                    value={searchData.supplyMethodTypeId}
                    onChange={(val) => onChangeSupplyMethodType(val)}
                  />
                </Col> */}

                <Col sm={12}
                  lg={6}>
                  <Selector
                    label='ประเภทสัญญา'
                    placeholder='กรุณาเลือก'
                    items={contractManagementTypeDDL}
                    value={searchData.contractManagementType}
                    onChange={(val) => setSearchData({ ...searchData, contractManagementType: val })}
                  />
                </Col>
              </Row>
            </Col>
            <div className='d-flex gap-4 justify-content-between'>
              <div>
                <Button
                  variant="primary"
                  className="px-3 me-2"
                  onClick={() => onSearch(searchData)}>
                  <FaSearch className="me-2" />ค้นหา
                </Button>
                <Button
                  className="px-3"
                  variant="outline-primary"
                  onClick={onClear}>
                  <FaEraser className="me-2" />ล้าง
                </Button>
              </div>
              {
                contractData.id && (
                  <Col sm={6}
                    lg={6}
                    className='d-flex'>
                    <Selector
                      className="px-3"
                      placeholder='กรุณาเลือก'
                      value={searchData.reportGroup}
                      onChange={(val) => setSearchData({ ...searchData, reportGroup: val })}
                      items={GroupReports} />
                    <Button
                      style={{ minWidth: '25%' }}
                      variant='outline-primary'
                      onClick={() => onExportReportAsync()}
                    >
                      <FaFileExcel />
                      พิมพ์รายงาน
                    </Button>
                  </Col>

                )
              }
            </div>
          </Row>
          {/* <Row className='mt-4 mb-4'>
            <Col className='d-flex justify-content-end align-items-end'>
              {
                contractData.id && (
                  <Button
                    variant='outline-primary'
                    className='d-flex align-items-center gap-2'
                    onClick={onExportReportAsync}
                  >
                    <FaFileExcel />
                    พิมพ์รายงานสัญญาแล้วเสร็จทั้งหมด
                  </Button>
                )
              }
            </Col>
          </Row> */}
          <Row className='mb-2'>
            <Col>
              <div className='bg-light rounded  bg-opacity-25  tabs-filter'>
                <p className='ms-2 mb-0'> จำนวนสัญญา(ฉบับ):</p>
                <Check className='mb-0 check-item'
                  labelNotbtn={<div className='align-items-center d-flex'>ทั้งหมด <span className='text-primary mx-2'>{contractDataItems?.length}</span>
                  </div>}
                />
                <Check className='mb-0 check-item'
                  labelNotbtn={<div className='align-items-center d-flex'>ซื้อขาย <span className='text-primary mx-2'>{contractDataItems?.filter(c => c.contractType === ContractTypeEnum.PurchaseId).length}</span>
                  </div>}
                />
                <Check className='mb-0 check-item'
                  labelNotbtn={<div className='align-items-center d-flex'>จ้าง<span className='text-primary mx-2'>{contractDataItems?.filter(c => c.contractType === ContractTypeEnum.HireId).length}</span></div>}
                />
                <Check className='mb-0 check-item'
                  labelNotbtn={<div className='align-items-center d-flex'>เช่า<span className='text-primary mx-2'>{contractDataItems?.filter(c => c.contractType === ContractTypeEnum.LeaseId).length}</span></div>}
                />
                <p className='mb-0'>หมายเหตุ : <MdCircle className='mx-2 text-success' />สัญญาภายในไตรมาส
                  <MdCircle className='mx-2 text-danger' />สัญญานอกไตรมาส</p>
                <Button
                  onClick={() => setShowSummaryModal(true)}
                  disabled={contractData.status === undefined}>ตารางสรุปการทำสัญญา</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table hidePagination>
                <thead>
                  <tr className='text-center'>
                    <th style={{ width: '2%' }}></th>
                    <th style={{ width: '10%' }}>เลขที่สัญญา</th>
                    <th style={{ width: '10%' }}>วันที่ทำสัญญา</th>
                    <th style={{ width: '10%' }}>วันที่ลงนามสัญญา</th>
                    <th style={{ width: '10%' }}>ประเภทสัญญา</th>
                    <th style={{ width: '20%' }}>ชื่อสัญญา</th>
                    <th style={{ width: '10%' }}>วงเงินตามสัญญา</th>
                    <th style={{ width: '10%' }}>ระยะเวลา (ปี)</th>
                    <th style={{ width: '10%' }}>คู่ค้า</th>
                    <th style={{ width: '10%' }}>วันที่เริ่มสัญญา<br />/เริ่มรับประกันฯ</th>
                    <th style={{ width: '10%' }}>วันที่ครบกำหนดสัญญา<br />/ครบกำหนดประกันฯ</th>
                    <th style={{ width: '10%' }}>วิธีการ</th>
                    <th style={{ width: '10%' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    contractDataItems?.map((data, index) => (
                      <tr key={index}>
                        <td>
                          {
                            (data.contractCreateDate < contractData.startSignDate!) ?
                              (<MdCircle className='mx-2 text-danger' />)
                              : (<MdCircle className='mx-2 text-success' />)
                          }
                        </td>
                        <td className='text-center'>{data.contractNumber}</td>
                        <td className='text-center'>{fullDate(data.contractCreateDate)}</td>
                        <td className='text-center'>{fullDate(data.contractDate)}</td>
                        <td className='text-center'>{data.contractTypeName}</td>
                        <td>{data.planName}</td>
                        <td style={{ textAlign: 'right' }}>{THCurrency(data.offerTotalAmountVat)}</td>
                        <td className='text-center'>
                          {
                            data.yearCount > 0 ?
                              <>{data.yearCount} ปี </> : <></>
                          }
                          {
                            data.monthCount > 0 ?
                              <>{data.monthCount} เดือน </> : <></>
                          }
                          {
                            data.dayCount > 0 ?
                              <>{data.dayCount} วัน</> : <></>
                          }
                        </td>
                        <td>{data.entrepreneurName}</td>
                        <td className='text-center'>{fullDate(data.contractStartDate)}</td>
                        <td className='text-center'>{fullDate(data.contractEndDate)}</td>
                        <td className='text-center'>{data.supplyMethodSpecialTypeName}</td>
                        <td>
                          {
                            (contractData.status === ContractCompleteReportStatus.Draft
                              || contractData.status === ContractCompleteReportStatus.Rejected) ? (
                              <Button
                                variant='danger'
                                onClick={() => removeItem(data.contractNumber)}
                              >
                                <FaTrash />
                              </Button>
                            ) : (<></>)
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      </div >
      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          text="กลับหน้าแรก"
          onClick={onClickBack}
        />
        {
          contractData.status === ContractCompleteReportStatus.Draft
            || contractData.status === ContractCompleteReportStatus.Rejected
            || contractData.status === undefined
            ? (
              <ButtonCustom
                text="บันทึก"
                onClick={onSave}
              />
            )
            : <></>
        }
        {(contractData.status !== undefined)
          ? (<ButtonCustom
            text="ถัดไป"
            onClick={onClickNext}
          />)
          : <div style={{ width: '190px' }} />
        }
      </div>
      <HistoryModal
        title='ประวัติการใช้งานรายงานสัญญาแล้วเสร็จ'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        id={contractData.id}
      />
      <SummaryModal
        show={showSummaryModal}
        onHide={() => setShowSummaryModal(!showSummaryModal)}
        year={contractData.budgetYear}
        quarter={quarter}
        contractCompleteReportId={contractData.id!}
      />
    </>
  );
}