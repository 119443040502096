import {
  Approver,
  ApproverStatus,
  ButtonCustom,
  CMDetailStatus,
  Card,
  InvitedContractStatus,
  Modal,
  SearchBuNameModal,
  Status,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
  AcceptorsRequestModel,
  AttachmentsModel,
  ContractAgreementInvitationAcceptorListModel,
  ContractAgreementInvitationAcceptorModel,
  ContractAgreementInvitationAcceptorSignedListModel,
  contractAgreementModel,
} from 'models/CM/CM01Models';
import { sectionApproverModel } from 'models/delegateModel';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  FaPlus,
  FaRedo,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { account, shareValue } from 'services';
import CM01Service from 'services/CM/CM01Service';
import {
  fullDatetime,
  fullDatetimeTH,
  HttpStatusCode,
  showConfirmModalAsync,
  submitForm,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { Context } from '.';
import Collabora, { CollaboraRef } from '../../../../components/Document/Collabora';
import { ApproveModal } from '../Modal/ApproveModal';
import { HistoryModal } from '../Modal/HistoryModal';
import { RejectModal } from '../Modal/RejectModal';
import UploadFile from '../UploadFile';
import { JorPorCode } from 'constant/basicInformation';
import { onCheckQueueSectionApprover } from 'utils/checkQueueSectionApprover';
import { AcceptorStatus } from 'utils/constants/PlanEnum';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function Step2({ onClickNext, onClickBack }: Props) {
  const [docId, setDocId] = useState('');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showWaitingForApprovedModal, setShowWaitingForApprovedModal] = useState(false);
  const [showApproveEmailModal, setShowApproveEmailModal] = useState(false);
  const { data, setData } = useContext(Context);
  const [acceptorSections, setAcceptorSections] = useState<ContractAgreementInvitationAcceptorListModel[]>([]);
  const [acceptorSigned, setAcceptorSigned] = useState<ContractAgreementInvitationAcceptorSignedListModel[]>([]);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showUserModals, setShowUserModals] = useState<boolean[]>([]);
  const [showUserSignedModals, setShowUserSignedModals] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const { userId, departmentCode } = useAppContext();
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [files, setFiles] = useState<IFile[]>([]);
  const navigate = useNavigate();
  const [acceptorData, setAcceptorData] = useState<ContractAgreementInvitationAcceptorModel>({} as ContractAgreementInvitationAcceptorModel);
  const collaboraRef = useRef<CollaboraRef>(null);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [contractAgreementData, setContractAgreementData] = useState<contractAgreementModel[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isSendApprover, setIsSendApprover] = useState(false);
  const [showDefaultApproverModal, setShowDefaultApproverModal] = useState<boolean>(false);

  const handlerSetDefaultApproverAsync = async () => {
    onGetDefaultApprover(true);
    setShowDefaultApproverModal(false);
  }

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const getDocument = useCallback(async (contractAgreement: string, index: number) => {
    const isDisabledDoc = data.status !== InvitedContractStatus.DRAFT && data.status !== InvitedContractStatus.REJECTED;
    const res = await CM01Service.getDocumentByIdAsync(contractAgreement, isDisabledDoc);
    if (res.status === HttpStatusCode.OK) {
      setDocId(res.data);

      handlerOnChangeDocId(res.data, index);
    }
  }, [setDocId]);

  const handlerOnChangeDocId = (docId: string, index: number) => {
    const temp = data.contractAgreements;
    temp[index].docId = docId;
    setContractAgreementData(temp);
  };

  const handlerOnChangeDocumentId = (documentId: string, index: number) => {
    const temp = [...contractAgreementData];
    temp[index].documentId = documentId;
    setContractAgreementData(temp);
  };

  useEffect(() => {
    if (data.id) {
      getAcceptorsByIdAsync(data.id);
    }
  }, [data.id]);

  const isRecall = useMemo(() => {
    return acceptorData?.acceptor?.filter(a => a.status === AcceptorStatus.APPROVE).length === 0;
  }, [acceptorData, userId])

  useEffect(() => {
    if (acceptorData?.acceptor?.length === 0) {
      onGetDefaultApprover(false);
    } else {
      setAcceptorSigned(acceptorData?.acceptorSigned);
      setAcceptorSections(acceptorData?.acceptor);

      setIsQueueApprover(onCheckQueueSectionApprover(acceptorData?.acceptor, userId))

      const isApprover = onCheckApprover(acceptorData?.acceptor);
      setIsApprover(isApprover);
      onCheckApproverStatus(acceptorData?.acceptor);

      const sectionDataGroup: sectionGroup[] = [];
      acceptorData?.acceptor?.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
        sectionDataGroup.push({
          sectionName: item.sectionName,
          level: item.sectionLevel,
          sectionSequence: item.sectionSequence,
          inRefCode: item.sectionInRefCode,
          sectionNameTh: item.sectionName,
        } as sectionGroup);
      });

      const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex((d) => d.inRefCode === data.inRefCode) === index);

      setSections(uniqueNames);
    }

  }, [acceptorData, userId]);

  useEffect(() => {
    if (data) {
      if (data.status === InvitedContractStatus.DRAFT || data.status === InvitedContractStatus.REJECTED) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }

      if (data.attachments) {
        handleSetFile(data.attachments);
      }

      if (data.contractAgreements) {
        data.contractAgreements.forEach((item, i) => {
          if (firstLoad) {
            getDocument(item.id, i);
          } else {
            handlerOnChangeDocumentId(item.documentId, i);
          }
        });

        setFirstLoad(false);

        if (data.contractAgreements.filter(x => x.documentId === null || x.documentId === undefined).length > 0) {
          setIsSendApprover(false);
        } else {
          setIsSendApprover(true);
        }
      }

    }
  }, [data]);

  useEffect(() => {
    if (acceptorSections) {
      const maxSection = Math.max(...acceptorSections?.map((o) => o.sectionSequence));

      const maxSequence = Math.max(...acceptorSections?.filter((d) => d.sectionSequence === maxSection).map((o) => o.sequence));

      setAcceptorSigned(acceptorSections.filter(x => x.sectionSequence === maxSection && x.sequence === maxSequence));
    }
  }, [acceptorSections]);

  const handleSetFile = (documents: AttachmentsModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };

      setFiles((pre) => [...pre, newFile]);
    }

    if (data && data.id) {
      const res = await CM01Service.uploadAttachmentAsync(
        data.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');
        handleSetFile(res.data);
      }
    }
  };

  const removeItemAsync = useCallback(async (index: number, docId: string) => {
    if (data && data.id) {
      const res = await CM01Service.removeFileAsync(
        data.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
        await getDocumentContractByIdAsync(data.id, data.taxId);
      }
    }
  }, [files, data.id]);

  const getDocumentContractByIdAsync = async (id: string, taxId: string) => {
    const { data, status } = await CM01Service.getInvitedContractByIdAsync(id, taxId);

    if (status === HttpStatusCode.OK) {
      const attachments = data.attachments as AttachmentsModel[];

      if (attachments && attachments.length > 0) {
        const newFiles: IFile[] = attachments?.map((data, index) => ({
          id: data.id!,
          name: data.fileName,
          file: null,
        } as IFile));

        setFiles(newFiles);
      } else {
        setFiles([]);
      }
    }
  };

  const getInvitedContractAsync = async (id: string, taxId: string) => {
    const response = await CM01Service.getInvitedContractByIdAsync(id, taxId);
    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
      navigate(`/contract-invitation-sign/detail/${id}/${taxId}`);
    }
  };

  const onCheckApprover = (datas: ContractAgreementInvitationAcceptorListModel[]) => {
    const unApproved = datas?.filter((d) => d.status === 'Pending');

    if (unApproved?.length === 1) {
      return true;
    }

    return false;
  };

  const getAcceptorsByIdAsync = async (id: string) => {
    const response = await CM01Service.getAcceptorsByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setAcceptorData(response.data);
    }
  };

  const onCheckApproverStatus = (datas: ContractAgreementInvitationAcceptorListModel[]) => {
    const maxValueSectionSequence = datas ? Math.max.apply(null,
      datas.map((x) => {
        return x.sectionSequence!;
      })) : 0;

    const valueSequence = datas?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence!);
    setIsSectionSequence(maxValueSectionSequence);
  };

  const recallAsync = async () => {
    const confirmRes = await showConfirmModalAsync('คุณต้องการเรียกคืนแก้ไขหรือไม่ ?');

    if (confirmRes) {
      const res = await CM01Service.reCallAsync(data.id);

      if (res.status === HttpStatusCode.OK) {
        toast.success('เรียกคืนแก้ไขสำเร็จสำเร็จ');

        getInvitedContractAsync(data.id, data.taxId);
      }
    }
  };

  const onSubmitAsync = async (isWaitingForApproved: boolean, tabIndex: number) => {
    submitForm();
    collaboraRef.current?.clickSave();
    setTimeout(async () => {

      if (acceptorSections.length === 0) {
        toast.warn('กรุณาระบุผู้มีอำนาจเห็นชอบ/อนุมัติ');
        return;
      }

      acceptorSections.sort((a, b) => a.sequence - b.sequence)
        .forEach((data, index) => {
          data.sequence = index + 1;
        });

      if (acceptorSections && acceptorSections.length > 0) {
        const updateDataModel: AcceptorsRequestModel = {
          acceptors: acceptorSections,
          invitationAcceptorSigned: acceptorSigned,
        };

        const res = await CM01Service.acceptorRequestAsync(data.id, updateDataModel);

        if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.CREATED) {
          if (isWaitingForApproved) {
            const response = await CM01Service.waitingForApprovedAsync(data.id);
            toast.success('ส่งอนุมัติสำเร็จ');
            setShowWaitingForApprovedModal(false);
          } else {
            toast.success('บันทึกข้อมูลสำเร็จ');
          }

          await CM01Service.updateDocumentAsync(contractAgreementData[tabIndex].id, contractAgreementData[tabIndex].docId);

          getAcceptorsByIdAsync(data.id);
          await getInvitedContractAsync(data.id, data.taxId);

        } else {
          toast.error(res.statusText);
        }
      }
    }, 500);
  };

  const onGetDefaultApprover = async (isSetNew: boolean) => {

    const response = await account
      .getDefaultApproverByTopLeve(PROCESSTYPE.ApproveInvitationSignContractProcess, data.totalConfirmPrice, undefined, data.ignorePurchaseOnTable159, data.departmentId);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: ContractAgreementInvitationAcceptorListModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
        sectionDataGroup.push({
          sectionName: item.sectionName,
          level: item.sectionLevel,
          sectionSequence: item.sectionSequence,
          inRefCode: item.inRefCode,
          sectionNameTh: item.sectionName,
          sectionId: item.sectionId,
        } as sectionGroup);

        approver.push({
          sectionSequence: item.sectionSequence,
          sectionName: item.sectionName,
          sequence: item.sequence,
          userId: item.userId,
          fullName: item.fullName,
          positionName: item.positionName,
          departmentName: item.departmentName,
          sectionInRefCode: item.inRefCode,
          isDisabled: false,
          delegateUserId: item.delegateUserId,
          delegateEmployeeCode: item.delegateEmployeeCode,
          delegateFullName: item.delegateFullName,
          delegateDepartmentName: item.delegateDepartmentName,
          delegatePositionName: item.delegatePositionName,
          sectionLevel: item.sectionLevel,
          status: AcceptorStatus.DRAFT,
        } as ContractAgreementInvitationAcceptorListModel);
      });

      const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex((d) => d.inRefCode === data.inRefCode) === index);

      if (isSetNew || (acceptorSections === undefined || acceptorSections === null || acceptorSections.length === 0)) {
        let inx = 0;
        let sectionSeq = 0;

        approver?.sort((a, b) => a.sectionSequence - b.sectionSequence).forEach((item, index) => {
          if (sectionSeq !== item.sectionSequence) {
            inx = 1;
            sectionSeq = item.sectionSequence;
          }

          approver[index].sequence = inx;

          inx++;
        });

        setAcceptorSections(approver);
        setSections(uniqueNames);
        handleSetAcceptorSigned(approver);
      }
    }
  };

  const handlerOnChangeCheckbox = (value: boolean, seqKey: string) => {
    const data = [...acceptorSections];
    data.filter((x) => `${x.sectionSequence}${x.sequence}` === seqKey)[0].isDisabled = value;
    setAcceptorSections(data);
  };

  const handlerOnChangeRemark = (value: string, seqKey: string) => {
    const data = [...acceptorSections];
    data.filter((x) => `${x.sectionSequence}${x.sequence}` === seqKey)[0].acceptRemark = value;
    setAcceptorSections(data);
  };

  const removeApprover = (seqKey: string) => {
    const temp: ContractAgreementInvitationAcceptorListModel[] = [...acceptorSections];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setAcceptorSections(temp);
  };

  const handlerRemoveAcceptorSigned = (i: number) => {
    const temp: ContractAgreementInvitationAcceptorSignedListModel[] = [...acceptorSigned];
    temp.splice(i, 1);
    setAcceptorSigned(temp);
  };

  const onOpenModal = (index: number) => {
    setShowUserModals((showUserModals) => {
      const newModal = [...showUserModals];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideModal = (index: number) => {
    setShowUserModals((showUserModals) => {
      const newModal = [...showUserModals];
      newModal[index] = false;
      return newModal;
    });
  };

  const onSelectEmpApprover = (empId: string, name: string, position: string, department: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
    delegatePositionName?: string) => {
    if (acceptorSections && acceptorSections.filter((x) => x.sectionSequence === sectionSequence
      && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
      return;
    }

    const acceptorBySection = acceptorSections.filter((x) => x.sectionSequence === sectionSequence);

    const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
      acceptorBySection.map((x) => {
        return x.sequence;
      })) : 0;

    const newData: ContractAgreementInvitationAcceptorListModel[] = acceptorSections || [];
    newData.push({
      sectionSequence: sectionSequence!,
      sectionName: sectionName!,
      sequence: maxValueSequence + 1,
      userId: empId,
      fullName: name,
      positionName: position,
      departmentName: department,
      sectionInRefCode: inRefCode!,
      isDisabled: false,
      disabledRemark: '',
      status: AcceptorStatus.DRAFT,
      // status: InvitedContractStatus.DRAFT,
      seqKey: `${sectionSequence?.toString()}${(acceptorSections.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
      delegateFullName: delegateFullName,
      delegateDepartmentName: delegateDepartmentName,
      delegateEmployeeCode: delegateEmployeeCode,
      delegatePositionName: delegatePositionName,
      delegateUserId: delegateUserId,
    } as ContractAgreementInvitationAcceptorListModel);

    setAcceptorSections(newData);
    handleSetAcceptorSigned(acceptorSections);
  };

  const onSelectEmpAcceptorSigned = (empId: string, name: string, position: string, department: string, index: number, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
    delegatePositionName?: string) => {

    const newData: ContractAgreementInvitationAcceptorSignedListModel[] = [];
    newData.push({
      sequence: 1,
      userId: empId,
      fullName: name,
      positionName: position,
      departmentName: department,
      isDisabled: false,
      disabledRemark: '',
      delegateFullName: delegateFullName,
      delegateDepartmentName: delegateDepartmentName,
      delegateEmployeeCode: delegateEmployeeCode,
      delegatePositionName: delegatePositionName,
      delegateUserId: delegateUserId,
    } as ContractAgreementInvitationAcceptorListModel);

    setAcceptorSigned(newData);
  };

  const handleSetAcceptorSigned = (acceptor: ContractAgreementInvitationAcceptorListModel[]) => {
    if (acceptor) {
      const maxSection = Math.max(...acceptor?.map((o) => o.sectionSequence));
      const maxSequence = Math.max(...acceptor?.filter((d) => d.sectionSequence === maxSection).map((o) => o.sequence));

      setAcceptorSigned(acceptor.filter(x => x.sectionSequence === maxSection && x.sequence === maxSequence));
    }
  };

  const onApproved = async (remark: string) => {
    const response = await CM01Service.acceptorApprovedAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK) {
      toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);

      if (isApprover) {
        data.contractAgreements.forEach((item, index) => {
          CM01Service.uploadDocumentByDocumentIdAsync(item.id, item.documentId);
        });
      }

      getAcceptorsByIdAsync(data.id);
      getInvitedContractAsync(data.id, data.taxId);
    }

    window.location.reload();
    setShowApproveModal(false);
  };

  const onApprovedEmail = async (remark: string) => {
    const response = await CM01Service.acceptorApprovedAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK) {
      toast.success(`${isApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);

      // navigate(`${location.pathname}`);
    }

    setShowApproveEmailModal(false);
  };

  const onReject = async (remark: string) => {
    submitForm();

    if (!remark) {
      toast.warn('กรุณากรอกหมายเหตุ');
      return;
    }

    const response = await CM01Service.acceptorRejectAsync(data.id, remark);

    if (response.status === HttpStatusCode.OK) {
      toast.success('ส่งกลับแก้ไขสำเร็จ');
      getAcceptorsByIdAsync(data.id);
      getInvitedContractAsync(data.id, data.taxId);
    }

    setShowRejectModal(false);
  };

  return (
    <div className='document'>

      <Title text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
        extraElement={(
          <>
            <CMDetailStatus systemStatue={data.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        )} />
      <Col sm={12}
        className='mt-3'>
        <Row className='mt-4 align-items-center gap-5'>
          <div>
            {(data.status === InvitedContractStatus.DRAFT || data.status === InvitedContractStatus.REJECTED)
              ? (
                <Button
                  className='d-flex align-items-center gap-2'
                  variant='outline-primary'
                  onClick={() => {
                    setShowDefaultApproverModal(true);
                  }}
                >
                  <FaRedo />กำหนดผู้มีอำนาจเห็นชอบเริ่มต้น
                </Button>
              ) : null}
          </div>
          {
            sections.length > 0 ? (
              sections
                .sort((a, b) => a.sectionSequence - b.sectionSequence)
                .map((section, i) => (
                  <Col sm={12} key={i}>
                    {!isDisabled && (
                      <div className='d-flex justify-content-between'>
                        <p className='fs-5 text-primary'>
                          {/* {section.sectionName} */}
                        </p>
                        <Button
                          variant='outline-primary'
                          disabled={isDisabled}
                          onClick={() => onOpenModal(i)}
                        >
                          <FaPlus className='me-1' /> เพิ่มรายชื่อ
                        </Button>
                        <SearchBuNameModal
                          total={userTotal}
                          show={showUserModals[i]}
                          onHide={() => onHideModal(i)}
                          onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode,
                            delegateDepartmentName, delegatePositionName) => onSelectEmpApprover(id, name, position!, department!, acceptorSections.length + 1, section.sectionSequence, section.sectionName, section.inRefCode, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName)}
                          data={users}
                          onSearch={onSearchUserAsync}
                          onPageChange={onPageChangeAsync}
                          departmentCode={departmentCode}
                          departmentDisabled={departmentCode !== JorPorCode.CODE}
                        />
                        {/* <UserApproveModal
                      show={showUserModals[i]}
                      onHide={() => onHideModal(i)}
                      onSelectItem={onSelectEmpApprover}
                      sectionSequence={section.sectionSequence!}
                      sectionName={section.sectionName!}
                      inRefCode={section.inRefCode!}
                    /> */}
                      </div>
                    )}
                    <Table notResponse hidePagination className='mt-3 step-bar'>
                      <thead>
                        <tr>
                          <th style={{ width: 20 }}>ลำดับ</th>
                          <th className='text-start' style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                          <th className='text-start' style={{ width: '30%' }}>ผู้ปฏิบัติงานแทน</th>
                          <th style={{ width: 100 }}>สถานะ</th>
                          <th style={{ width: 100 }}>วันที่เห็นชอบ</th>
                          <th style={{ width: 100 }} />
                        </tr>
                      </thead>
                      <tbody>
                        {acceptorSections?.filter((x) => x.sectionInRefCode === section.inRefCode)
                          .sort((a, b) => a.sequence - b.sequence)
                          .map((data, index) => (
                            <>
                              <tr>
                                <td className='text-center'>{index + 1}</td>
                                <td className='text-left'>
                                  {data.fullName}
                                  <p className='m-0 department'>
                                    {data.positionName}
                                  </p>
                                </td>
                                <td className='text-left'>
                                  {data.delegateFullName
                                    && (
                                      <>
                                        {data.delegateFullName}
                                        <p className='m-0 department'>
                                          {data.delegatePositionName}
                                        </p>
                                      </>
                                    )}
                                </td>
                                <td className='text-center'>
                                  {isSectionSequence === data.sectionSequence && isApproverStatus === index + 1 ? <Approver value={data.status} outline /> : <ApproverStatus value={data.status} outline />}
                                </td>
                                <td className='text-center'>
                                  {fullDatetimeTH(data.acceptDate)}
                                </td>
                                <td className='text-end'>
                                  <Button
                                    onClick={() => removeApprover(`${data.sectionSequence}${data.sequence}`)}
                                    variant='danger'
                                    disabled={isDisabled}
                                  >
                                    <FaTrashAlt />
                                  </Button>
                                </td>
                              </tr>

                              <tr>
                                <td />
                                <td colSpan={6} className='p-0'>
                                  <ul className='step-bar-list'>
                                    {data.isDisabled
                                      && (
                                        <li>
                                          <div className='description'>
                                            <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                            <p className='mb-0' style={{ color: 'gray' }}>
                                              {data.disabledRemark}
                                            </p>
                                          </div>
                                        </li>
                                      )}
                                    {data.acceptRemark
                                      && (
                                        <li>
                                          <div className='description'>
                                            <p className='mb-0 text-primary'>หมายเหตุ{isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ? 'อนุมัติ' : 'เห็นชอบ'}
                                            </p>
                                            <p className='mb-0 text-wrap' style={{ color: 'gray' }}>
                                              <span className='text-muted me-2'>[{data.acceptDate ? fullDatetime(data.acceptDate) : ''}]</span>
                                              {data.acceptRemark}
                                            </p>
                                          </div>
                                        </li>
                                      )}
                                    {data.rejectRemark
                                      && (
                                        <li>
                                          <div className='description'>
                                            <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                            <p className='mb-0 text-wrap' style={{ color: 'gray' }}>
                                              <span className='text-muted me-2'>[{data.rejectDate ? fullDatetime(data.rejectDate) : ''}]</span>
                                              {data.rejectRemark}
                                            </p>
                                          </div>
                                        </li>
                                      )}
                                  </ul>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                ))
            ) : (
              <Col>
                <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
              </Col>
            )
          }
        </Row>
      </Col>
      {
        (false) &&
        (
          <>
            <Title text='ลงนามโดย' />
            <Row>
              <Col sm={12}>
                <Table notResponse hidePagination className='mt-3 step-bar'>
                  <thead>
                    <tr>
                      <th style={{ width: 20 }}>ลำดับ</th>
                      <th className='text-start' style={{ width: '30%' }}>ชื่อ-นามสกุล</th>
                      <th className='text-start' style={{ width: '45%' }}>ผู้ปฏิบัติงานแทน</th>
                      <th style={{ width: 100 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {acceptorSigned?.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <>
                          <tr>
                            <td className='text-center'>{index + 1}</td>
                            <td className='text-left'>
                              {data.fullName}
                              <p className='m-0 department'>
                                {data.positionName}
                              </p>
                            </td>
                            <td className='text-left'>
                              {data.delegateFullName
                                && (
                                  <>
                                    {data.delegateFullName}
                                    <p className='m-0 department'>
                                      {data.delegatePositionName}
                                    </p>
                                  </>
                                )}
                            </td>
                            <td className='text-end'>
                              {/* <Button
                          onClick={() => handlerRemoveAcceptorSigned(index)}
                          disabled={isDisabled}
                        >
                          <FaUndo className='me-1' /> เปลี่ยนผู้ลงนาม
                        </Button> */}
                              <Button
                                variant='primary'
                                disabled={isDisabled}
                                onClick={() => setShowUserSignedModals(true)}
                              >
                                <FaUndo className='me-1' /> เปลี่ยนผู้ลงนาม
                              </Button>
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )
      }

      <Card className='mt-3'>
        <Title
          text='เอกสารเชิญชวนทำสัญญา'
          className='fs-5'
        // lineBorder
        />
        <Row className='justify-content-center mt-3'>
          <div className='mt-3'>
            <Col className='tab-border-active'>
              <Tabs
                defaultActiveKey='1'
                id='justify-tab-example'
                className='mb-2'
                justify
              >
                {
                  contractAgreementData?.map((item, index) => (
                    <Tab
                      eventKey={index + 1}
                      title={<p><Status value={item.documentId ? 'Invited' : 'None'}
                        showValue={item.contractNumber}
                        type={StatusType.INVITEDCONTRACT} /> </p>}
                    >
                      <Collabora
                        docId={item.docId}
                        docName={index.toString()}
                        ref={collaboraRef}
                        readonly={isDisabled} />
                      <div className='d-flex justify-content-center pt-3'>
                        {
                          (item.isResponsible && data.status === InvitedContractStatus.WAITINGFORAPPROVE && isRecall) && (
                            <ButtonCustom
                              text="เรียกคืนแก้ไข"
                              icon='undo'
                              variant='danger'
                              onClick={recallAsync}
                            />
                          )
                        }
                        {
                          (item.isResponsible && (data.status === InvitedContractStatus.DRAFT || data.status === InvitedContractStatus.REJECTED) && item.id) && (
                            <ButtonCustom
                              onClick={() => onSubmitAsync(false, index)}
                              text="บันทึก"
                            />
                          )
                        }
                        {
                          ((data.status === InvitedContractStatus.DRAFT || data.status === InvitedContractStatus.REJECTED) && isSendApprover && item.isResponsible) && (
                            <ButtonCustom
                              onClick={() => setShowWaitingForApprovedModal(true)}
                              icon="checked"
                              text=" ส่งเห็นชอบ/อนุมัติ"
                            />
                          )
                        }
                      </div>
                    </Tab>
                  ))}
              </Tabs>
            </Col>
          </div>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ' className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12} md={6}>
            <UploadFile
              disabled={isDisabled}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractRecordId={data.id!}
            />
          </Col>
        </Row>
      </Card>
      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <div>
          {
            (isQueueApprover && data.status === InvitedContractStatus.WAITINGFORAPPROVE) && (
              <>
                <ButtonCustom
                  onClick={() => setShowRejectModal(true)}
                  icon="undo"
                  text="ส่งกลับแก้ไข"
                  variant='danger'
                />
                <ButtonCustom
                  onClick={() => setShowApproveModal(true)}
                  className='me-2 ms-2'
                  variant='success'
                  text={isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
                  icon="checked"
                />
              </>
            )
          }
        </div>
        <div style={{ width: 145 }} />
      </div>
      <SearchBuNameModal
        total={userTotal}
        show={showUserSignedModals}
        onHide={() => setShowUserSignedModals(!showUserSignedModals)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode,
          delegateDepartmentName, delegatePositionName) => onSelectEmpAcceptorSigned(id, name, position!, department!, acceptorSections.length + 1, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={data.id}
      />
      <RejectModal
        show={showRejectModal}
        onHide={() => setShowRejectModal(!showRejectModal)}
        onRemark={onReject}
      />
      <ApproveModal
        show={showApproveEmailModal}
        onRemark={onApprovedEmail}
        onHide={() => setShowApproveEmailModal(!showApproveEmailModal)}
        isApprover={isApprover}
      />
      <ApproveModal
        show={showApproveModal}
        onRemark={onApproved}
        onHide={() => setShowApproveModal(!showApproveModal)}
        isApprover={isApprover}
      />
      <Modal
        show={showWaitingForApprovedModal}
        size='lg'
        onHide={() => setShowWaitingForApprovedModal(!showWaitingForApprovedModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งเห็นชอบ/อนุมัติ หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button
                onClick={() => setShowWaitingForApprovedModal(!showWaitingForApprovedModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(true, 0)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showDefaultApproverModal}
        size='lg'
        onHide={() => setShowDefaultApproverModal(!showDefaultApproverModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการกำหนดผู้มีอำนาจเห็นชอบเริ่มต้นใหม่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultApproverModal(!showDefaultApproverModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerSetDefaultApproverAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div >
  );
}

export default Step2;
