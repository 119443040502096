import {
  ButtonCustom,
  Check,
  ContractAuditStatus,
  DatePicker,
  Input,
  Status,
  StatusCM,
  StatusType,
  Table,
} from 'components';
import Title from 'components/Controls/Title';
import { ContractAuditListModel, ContractAuditRequestListModel, CountStatusModel } from 'models/CM/CM04Models';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { FaEraser, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import CM04Service from 'services/CM/CM04Service';
import {
  HttpStatusCode,
  THCurrency,
  thaiFormatDateWithSlash,
} from 'utils';

interface IExampleData {
  no: string;
  year: string;
  round: string;
  roundDate: string;
  status: string;
  contract: string;
  value: string;
}

const exampleData: IExampleData[] = [
  {
    no: '1455485258',
    year: '2564',
    round: '11 สิงหาคม 2566',
    roundDate: '1 มกราคม  2565 - 31 มีนาคม 2565',
    status: 'Pending',
    contract: '126',
    value: '35,555,255',
  },
  {
    no: '1455485258',
    year: '2565',
    round: '11 สิงหาคม 2566',
    roundDate: '1 มกราคม  2565 - 31 มีนาคม 2565',
    status: 'Pending',
    contract: '126',
    value: '35,555,255',
  },
];

interface IFromSearch {
  project: string;
  subject: string;
  method: string;
  budget: string;
  contractor: string;
  creator: string;
  status: string;
}

interface StatusItemModel {
  label: string;
  value: boolean;
}

const defaultStatus = [ContractAuditStatus.Draft, ContractAuditStatus.Pending, ContractAuditStatus.Approved, ContractAuditStatus.Rejected];

export default function CM04() {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [criteria, setCriteria] = useState<ContractAuditRequestListModel>({} as ContractAuditRequestListModel);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [statusAll, setStatusAll] = useState<boolean>(false);
  const [statusDraft, setStatusDraft] = useState<boolean>(false);
  const [statusRejected, setStatusRejected] = useState<boolean>(false);
  const [statusApproved, setStatusApproved] = useState<boolean>(false);
  const [statusPending, setStatusPending] = useState<boolean>(false);
  const [dataList, setDataList] = useState<ContractAuditListModel[]>([]);
  const [countStatus, setCountStatus] = useState<CountStatusModel[]>([]);
  const [statusLists, setStatusLists] = useState<StatusItemModel[]>([
    {
      value: false,
      label: ContractAuditStatus.Draft,
    },
    {
      value: false,
      label: ContractAuditStatus.Approved,
    },
    {
      value: false,
      label: ContractAuditStatus.Rejected,
    },
    {
      value: false,
      label: ContractAuditStatus.Pending,
    },
  ]);

  const goToEditPage = (id?: string) => {
    if (id) {
      navigate(`/cm/cm04/detail/${id}`);
      return;
    }
    navigate('/cm/cm04/detail');
  };

  useEffect(() => {
    setStatusAll(true);
    setStatusApproved(true);
    setStatusRejected(true);
    setStatusPending(true);
    setStatusDraft(true);
    criteria.isAssignToMeOnly = true;
    getListDataAsync(criteria)
  }, []);

  const getListDataAsync = async (searchModel: ContractAuditRequestListModel) => {
    if (!searchModel.page) {
      searchModel.page = page;
    }

    if (!searchModel.pageSize) {
      searchModel.pageSize = 10;
    }

    if (searchModel.status === undefined || searchModel.status.length === 0) {
      searchModel.status = defaultStatus;
    }

    const res = await CM04Service.getListContractAuditAsync(
      searchModel,
    );

    if (res.status === HttpStatusCode.OK) {
      setDataList(res.data.data);
      setTotalRecords(res.data.totalRecords);
    }

    await getCountStatus(searchModel);
  };

  const getCountStatus = useCallback(async (searchModel: ContractAuditRequestListModel) => {
    const countStatusRes = await CM04Service.getCountStatusAsync(
      searchModel,
    );

    if (countStatusRes.status === HttpStatusCode.OK) {
      setCountStatus(countStatusRes.data);
    }
  }, []);

  const onChangeStatusAll = (result: boolean) => {
    if (result) {
      setStatusAll(true);
      setStatusApproved(true);
      setStatusRejected(true);
      setStatusPending(true);
      setStatusDraft(true);
    } else {
      setStatusAll(false);
      setStatusApproved(false);
      setStatusDraft(false);
      setStatusRejected(false);
      setStatusPending(false);
    }
  };

  const onChangeCheckStatus = (status: string, value: boolean) => {
    const statusList = [...statusLists];
    const index = statusLists.findIndex(s => s.label === status);
    statusList[index].value = value;
    setStatusLists(statusList);

    if (!value) {
      setStatusAll(false);
    }

    if (statusList.filter(s => s.value === false).length === 0) {
      setStatusAll(true);
    }

    switch (status) {
      case ContractAuditStatus.Draft:
        return setStatusDraft(value);
      case ContractAuditStatus.Pending:
        return setStatusPending(value);
      case ContractAuditStatus.Approved:
        return setStatusApproved(value);
      case ContractAuditStatus.Rejected:
        return setStatusRejected(value);
    }
  };

  const onPaginationChange = (size: number, page: number) => {
    setPageSize(size);
    setPage(page);
    criteria.page = page;
    criteria.pageSize = size;
    onSearch();
  };

  const onSearch = () => {
    const status: string[] = [];

    if (statusDraft) {
      status.push(ContractAuditStatus.Draft);
    }

    if (statusRejected) {
      status.push(ContractAuditStatus.Rejected);
    }

    if (statusApproved) {
      status.push(ContractAuditStatus.Approved);
    }

    if (statusPending) {
      status.push(ContractAuditStatus.Pending);
    }

    const searchCriteria = {
      contractAuditNumber: criteria.contractAuditNumber,
      contractEndDate: criteria.contractEndDate,
      contractStartDate: criteria.contractStartDate,
      documentDate: criteria.documentDate,
      isAssignToMeOnly: criteria.isAssignToMeOnly,
      status,
      page,
      pageSize,
    } as ContractAuditRequestListModel;
    getListDataAsync(searchCriteria);
  };

  const onClearSearch = () => {
    const status: string[] = [];
    const isSize = 10;
    const isPage = 1;
    setPage(isPage);
    setPageSize(isSize);
    setStatusAll(true);
    setStatusApproved(true);
    setStatusRejected(true);
    setStatusPending(true);
    setStatusDraft(true);

    onPaginationChange(isSize, isPage);
    setCriteria({
      isAssignToMeOnly: true,
      page: isPage,
      pageSize: isSize
    } as ContractAuditRequestListModel);

    const searchCriteria = {
      page: isPage,
      pageSize: isSize,
      isAssignToMeOnly: true,
      status,
    } as ContractAuditRequestListModel;
    getListDataAsync(searchCriteria);
  };

  return (
    <div className='m01'>
      <Title text='รายงานสำนักงานตรวจเงินแผ่นดินและหนังสือถึงสรรพากร' />

      <Row className='criteria my-4'>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <Input label='เลขที่เอกสาร'
            onChange={contractAuditNumber => setCriteria({ ...criteria, contractAuditNumber })}
            value={criteria.contractAuditNumber} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker label='วันที่ทำรายงาน'
            onChangeHasNullable={documentDate => setCriteria({ ...criteria, documentDate })}
            value={criteria.documentDate} />
        </Col>
        <Col sm={12} xl={6}></Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker label='ข้อมูลตั้งแต่วันที่'
            onChangeHasNullable={contractStartDate => setCriteria({ ...criteria, contractStartDate })}
            value={criteria.contractStartDate} />
        </Col>
        <Col sm={12}
          md={4}
          lg={4}
          xl={3}>
          <DatePicker label='ถึงข้อมูลวันที่'
            onChangeHasNullable={contractEndDate => setCriteria({ ...criteria, contractEndDate })}
            value={criteria.contractEndDate} />
        </Col>
        <Col sm={12}>
          <Form.Group className='mb-3'>
            <Form.Label>สถานะ</Form.Label>
            <InputGroup className='align-items-center gap-3'>
              <Check
                label={<div className='align-items-center d-flex'>ทั้งหมด <Badge className='h-50 mx-2'
                  bg='light'>{countStatus.reduce((a, v) => a += v.count, 0)}</Badge>
                </div>}
                value={statusAll}
                onChange={(val) => onChangeStatusAll(val)}
              />
              <Check
                label={(
                  <div className='align-items-center d-flex'>แบบร่าง <Badge className='h-50 mx-2'
                    bg='secondary'>
                    {countStatus.filter((x) => x.status === ContractAuditStatus.Draft).length > 0 ? countStatus.filter((x) => x.status === ContractAuditStatus.Draft)[0].count : 0}
                  </Badge>
                  </div>
                )}
                value={statusDraft}
                onChange={(val) => onChangeCheckStatus(ContractAuditStatus.Draft, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>รอเห็นชอบ/อนุมัติ <Badge className='h-50 mx-2'
                  bg='warning'>{countStatus.filter((x) => x.status === ContractAuditStatus.Pending).length > 0 ? countStatus.filter((x) => x.status === ContractAuditStatus.Pending)[0].count : 0}</Badge>
                </div>}
                value={statusPending}
                onChange={(val) => onChangeCheckStatus(ContractAuditStatus.Pending, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>เห็นชอบ/อนุมัติ<Badge className='h-50 mx-2'
                  bg='success'>{countStatus.filter((x) => x.status === ContractAuditStatus.Approved).length > 0 ? countStatus.filter((x) => x.status === ContractAuditStatus.Approved)[0].count : 0}</Badge>
                </div>}
                value={statusApproved}
                onChange={(val) => onChangeCheckStatus(ContractAuditStatus.Approved, val)}
              />
              <Check
                label={<div className='align-items-center d-flex'>ส่งกลับแก้ไข<Badge className='h-50 mx-2'
                  bg='danger'>{countStatus.filter((x) => x.status === ContractAuditStatus.Rejected).length > 0 ? countStatus.filter((x) => x.status === ContractAuditStatus.Rejected)[0].count : 0}</Badge>
                </div>}
                value={statusRejected}
                onChange={(val) => onChangeCheckStatus(ContractAuditStatus.Rejected, val)}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Row>
          <Col sm={12}
            md={4}
            lg={4}
            xl={3}>
            <Check
              label={<div className='align-items-center d-flex'>แสดงเฉพาะรายการที่ได้รับมอบหมาย</div>}
              onChange={isAssignToMeOnly => setCriteria({ ...criteria, isAssignToMeOnly })}
              value={criteria.isAssignToMeOnly}
            />
          </Col>
        </Row>
        <div className='d-flex gap-2 justify-content-between'>
          <div className='d-flex'>
            <Button
              type='submit'
              variant='primary'
              className='me-2'
              onClick={onSearch}>
              <FaSearch className='me-2' />
              ค้นหา
            </Button>
            <Button
              onClick={onClearSearch}
              variant='outline-primary'
              type='submit'
              form='formCM02'
              className='d-flex align-items-center gap-2 ms-2'
            >
              <FaEraser />
              ล้าง
            </Button>
          </div>
          <ButtonCustom
            text='เพิ่มรายการ'
            onClick={() => goToEditPage()}
          />
        </div>
      </Row>

      <Row>
        <Col sm={12}>
          <Table total={totalRecords}
            onChange={(size, page) => {
              onPaginationChange(size, page);
            }}>
            <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>เลขที่เอกสาร</th>
                <th style={{ width: 100 }}>สถานะ</th>
                <th style={{ width: '10%' }}>วันที่ทำรายงาน</th>
                <th style={{ width: '15%' }}>ข้อมูลตั้งแต่วันที่</th>
                <th style={{ width: 100 }}>จำนวนสัญญา (ฉบับ)</th>
                <th className='text-end'
                  style={{ width: '20%' }}>มูลค่ารวม (บาท)
                </th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((data, index) => (
                <tr className='text-center'>
                  <td className='text-center'>
                    <Button variant='link'
                      onClick={() => goToEditPage(data.id ? data.id : '')}>{data.contractAuditNumber}
                    </Button>
                  </td>
                  <td>
                    {/* <StatusCM systemStatue={data.status}
                      systemName='cm-04' /> */}
                    <Status value={data.status}
                      type={StatusType.ContractStatus} />
                  </td>
                  <td className='text-start'>{thaiFormatDateWithSlash(data.documentDate)}</td>
                  <td>{thaiFormatDateWithSlash(data.contractStartDate)}{"-"}{thaiFormatDateWithSlash(data.contractEndDate)}</td>
                  <td>{data.contractCount}</td>
                  <td className='text-end'>{THCurrency(data.totalAmount)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {/* <ModalReply
        show={show}
        onHide={() => setShow(!show)}
        title='หมายเหตุ'
        status='general'
        textInBtn='บันทึก'
      /> */}
    </div>
  );
}
