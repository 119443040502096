import { Card, Currency, DatePicker, Input, Radio, Selector, TorStatus } from 'components';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import { PaymentModel } from 'models/CM/CM02Models';
import { TorCollateralModel, TorDefectWarrantyModel } from 'models/PP/TorModels';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import { BsArrowDownCircle } from 'react-icons/bs';
import { FaHistory, FaChevronCircleDown } from 'react-icons/fa';

const HasWarranty = [
  {
    label: 'มีการรับประกัน',
    value: 'true',
  },
  {
    label: 'ไม่มีการรับประกัน',
    value: 'false',
  },
];

interface ICollateralProps {
  collateral: TorCollateralModel;
  onCollateralChange: (value: TorCollateralModel) => void;
  isDisabled?: boolean;
}

export function Collateral(props: ICollateralProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [collateral, setCollateral] = useState<TorCollateralModel>(props.collateral);

  useEffect(() => {
    props.onCollateralChange(collateral);
  }, [collateral]);

  useEffect(() => {
    if (props.collateral && firstLoad) {
      setCollateral(props.collateral);
      setFirstLoad(false);
    }
  }, [props.collateral, firstLoad]);

  const onTextChange = useCallback((key: string, value: string) => {
    setCollateral(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onDateChange = useCallback((key: string, value: Date | undefined) => {
    setCollateral(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setCollateral(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return (
    <Card className='mt-3'>
      <div className='d-flex align-items-center'>
        <Title
          text='หลักประกันการเสนอราคา'
          className='fs-5 text-primary'
        />
      </div>
      <div className='ps-40'>
        <Row>
          <Col sm={12} md={5}>
            <Currency
              label='จำนวนเงินหลักประกัน'
              value={collateral?.amount}
              onChange={(value) => onNumberChange('amount', Number(value))}
              rule={{ required: true }}
              disabled={props.isDisabled}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={5}>
            <label>ธนาคารตรวจสอบความถูกต้อง</label>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col sm={12} md={3}>
            <DatePicker
              label='ตั้งแต่วันที่'
              value={collateral?.startDueDate}
              onChange={(value) => onDateChange('startDueDate', value)}
              disabled={props.isDisabled}
            />
          </Col>
          <Col sm={12} md={3}>
            <DatePicker
              label='ถึงวันที่'
              value={collateral?.endDueDate}
              onChange={(value) => onDateChange('endDueDate', value)}
              disabled={props.isDisabled}
            />
          </Col>
        </Row>
      </div>
    </Card>
  );
}
