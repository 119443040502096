import {
  Card,
  ContractAgreementStatus,
  Modal,
  TorStatus,
  ButtonCustom,
} from 'components';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  MdArrowBack,
  MdArrowForward,
  MdSave,
} from 'react-icons/md';
import { FineRate, TorDetailModel, TorDocumentModel } from 'models/PP/TorModels';
import { IFile, ItemModel } from 'models';
import { TorEditCancelStatusConstant, TorStatusConstant } from 'constant/PreProcumentTorStatus';
import { HttpStatusCode, clearForm, submitForm, useAppContext } from 'utils';
import toast from 'utils/toast';
import { DefectsWarranty } from './Components/DefectsWarranty';
import { Evidence } from './Components/Evidence';
import { DocumentDescription } from './Components/DocumentDescription';
import { Collateral } from './Components/Collateral';
import { SupplyMethodIdConstrants } from 'utils/constants/torTemplateCodeEnum';
import { CriteriaConsiderDescription } from './Components/CriteriaConsiderDescription';
import { PreventiveMaintenance } from './Components/PreventiveMaintenance';
import { CorrectiveMaintenance } from './Components/CorrectiveMaintenance';
import { Training } from './Components/Training';
import { ManuelDescription } from './Components/ManuelDescription';
import { Impediment } from './Components/Impediment';
import { FaCircle, FaHistory } from 'react-icons/fa';
import Title from 'components/Controls/Title';
import { HistoryModal } from '../Modal/HistoryModal';
import PreProcurementTorServices from 'services/PP/PreProcurementTorServices';
import UploadFile from '../UploadFile';
import { defaultFineRateValues } from '../data';
import { FineRateComponent } from './Components/FineRateComponent';
import { Context } from '../step';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  contextTorData: TorDetailModel;
  onSubmit: (updateData: TorDetailModel) => void;
  periodUnitDDL: ItemModel[];
  periodTimeUnitDDL: ItemModel[];
  defectsWarrantyCountUnitDDL: ItemModel[];
  pmFineTypeDDL: ItemModel[],
  cmFineTypeDDL: ItemModel[],
  trainingUnitTypeDDL: ItemModel[],
  dayOfWeekDDL: ItemModel[],
}

export function StepTemplateComputer(props: Props) {
  const { readonly } = useContext(Context);
  const { userId } = useAppContext();
  const [torData, setTorData] = useState<TorDetailModel>({} as TorDetailModel);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [isDraftTorPerson, setIsDraftTorPerson] = useState(false);

  useEffect(() => {
    if (props.contextTorData) {
      if (!props.contextTorData.fineRates || props.contextTorData.fineRates.length === 0) {
        setTorData(pre => ({ ...props.contextTorData, fineRates: getDefaultFineRates(props.contextTorData.supplyMethodType) }))
      } else {
        setTorData(props.contextTorData);
      }
      setIsDisabled(props.contextTorData.isCancel ||
        !(
          props.contextTorData.status === TorStatusConstant.Draft ||
          props.contextTorData.status === TorStatusConstant.DraftTor ||
          props.contextTorData.status === TorStatusConstant.Rejected
        ));

      if (props.contextTorData.documents) {
        handleSetFile(props.contextTorData.documents);
      }
    }

    const isDraftTorPersonValue =
      props.contextTorData.appointPreProcurement &&
      props.contextTorData.appointPreProcurement.committeeTors &&
      props.contextTorData.appointPreProcurement.committeeTors
        .filter((c) => c.committeeUserId === userId)
        .length > 0;

    setIsDraftTorPerson(isDraftTorPersonValue);
  }, [props.contextTorData]);

  const getDefaultFineRates = (supplyMethodTypeId: string) => {
    const newFineRates: FineRate[] = [];
    defaultFineRateValues.filter(d => d.supplyMethodType === supplyMethodTypeId).forEach((data, i) => {
      const newData: FineRate = {
        id: undefined,
        torId: torData.id,
        description: data.description,
        fineRatePeriodType: props.periodUnitDDL[0].value,
        fineRateType: 'percent',
        fine: data.fine,
        sequence: i + 1,
      };
      newFineRates.push(newData);
    });
    return newFineRates;
  };

  const handleSetFile = (documents: TorDocumentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    const newUpdFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFiles((pre) => [...pre, newFile]);
      newUpdFiles.push(file);
    }

    if (newUpdFiles && newUpdFiles.length > 0) {
      const res = await PreProcurementTorServices.onAttachmentTorDocumentByIdAsync(props.contextTorData.id, newUpdFiles);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('อัปโหลดเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('อัปโหลดเอกสารแนบสำเร็จ');
    }

    await getTorDocumentsByIdAsync(props.contextTorData.id);
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    const delId = newArray.filter((i) => i.name === docName)[0].id;
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName && delId) {
      const res = await PreProcurementTorServices.deleteTorDocumentByIdAsync(props.contextTorData.id, delId);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('ลบเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('ลบเอกสารแนบสำเร็จ');
    }
  }, [files, props.contextTorData.id]);

  const getTorDocumentsByIdAsync = async (id: string) => {
    const response = await PreProcurementTorServices.getTorByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      if (response.data.documents) {
        setTorData({ ...torData, documents: response.data.documents });
      }
    }
  };

  const onSave = async () => {
    submitForm();

    if (torData.defectsWarranty === undefined || torData.defectsWarranty === null) {
      setShowSaveConfirmModal(false);
      toast.warn('กรุณากรอกข้อมูลการชำระเงิน');

      return;
    }

    if (torData.defectsWarranty.hasWarranty === undefined || torData.defectsWarranty.hasWarranty === null) {
      setShowSaveConfirmModal(false);
      toast.warn('กรุณาระบุข้อมูลการจ่ายเงินล่วงหน้า');

      return;
    }

    if (torData.defectsWarranty.hasWarranty) {
      if (!torData.defectsWarranty.count) {
        setShowSaveConfirmModal(false);
        toast.warn('กรุณากรอกข้อมูลจำนวนเงินจ่ายล่วงหน้า');

        return;
      }

      if (!torData.defectsWarranty.unit) {
        setShowSaveConfirmModal(false);
        toast.warn('กรุณากรอกข้อมูลจำนวนเงินจ่ายล่วงหน้าภายใน');

        return;
      }
    }

    if (!torData.fineRates || torData.fineRates.length === 0) {
      toast.warn('กรุณาระบุอัตราค่าปรับ');
      return;
    }

    if (torData.fineRates.filter((x) => x.description === '' || x.fine <= 0 || x.fineRatePeriodType === '').length > 0) {
      toast.warn('กรุณาระบุรายละเอียด อัตราร้อยละ หรือประเภท อัตราค่าปรับ');
      return;
    }

    if (torData.fineRates.reduce((a, v) => a += v.fine, 0) > 100) {
      toast.warn('ไม่สามารถระบุอัตราค่าปรับเกิน 100% ได้');
      return;
    }

    clearForm();

    // TODO: Change this if api is change type

    const newCorrectiveMaintenance = torData.correctiveMaintenance;

    if (torData.preventiveMaintenance) {
      newCorrectiveMaintenance.cmProductName = torData.preventiveMaintenance.pmProductName;
    }

    const updateData: TorDetailModel = {
      id: torData.id,
      torNumber: torData.torNumber,
      torTemplateCode: torData.torTemplateCode,
      appointNumber: torData.appointNumber,
      planName: torData.planName,
      sourceAndReasons: torData.sourceAndReasons,
      departmentId: torData.departmentId,
      budgetYear: torData.budgetYear,
      memorandumNo: torData.memorandumNo,
      memorandumDate: torData.memorandumDate,
      expectingProcurementAt: torData.expectingProcurementAt,
      planBudget: torData.planBudget,
      supplyMethod: torData.supplyMethod,
      supplyMethodType: torData.supplyMethodType,
      supplyMethodSpecialType: torData.supplyMethodSpecialType,
      objectives: torData.objectives,
      parcels: torData.parcels,
      qualifications: torData.qualifications,
      financialAmount: torData.financialAmount,
      financials: torData.financials,
      deliveryPeriodSum: torData.deliveryPeriodSum,
      deliveryUnit: torData.deliveryUnit,
      deliveryCountUnit: torData.deliveryCountUnit,
      workPhases: torData.workPhases,
      defectsWarranty: torData.defectsWarranty,
      fineRates: torData.fineRates,
      appointPreProcurement: torData.appointPreProcurement,
      documents: torData.documents,
      committeeTorApprovals: torData.committeeTorApprovals,
      committeeMiddlePriceApprovals: torData.committeeMiddlePriceApprovals,
      jorPorDirectors: torData.jorPorDirectors,
      assignees: torData.assignees,
      responsibles: torData.responsibles,
      committeeApproverTorSections: torData.committeeApproverTorSections,
      middlePrice: torData.middlePrice,
      middlePriceSetDate: torData.middlePriceSetDate,
      middlePriceSources: torData.middlePriceSources,
      isConsumableMaterials: torData.isConsumableMaterials,
      evidenceCompareItems: torData.evidenceCompareItems,
      trainingItems: torData.trainingItems,
      impediments: torData.impediments,
      evidence: torData.evidence,
      evidenceDescription: torData.evidenceDescription,
      evidenceNumber: torData.evidenceNumber,
      documentDescription: torData.documentDescription,
      collateral: torData.collateral,
      criteriaConsiderDescription: torData.criteriaConsiderDescription,
      manuelDescription: torData.manuelDescription,
      commentDescription: torData.commentDescription,
      preventiveMaintenance: torData.preventiveMaintenance,
      correctiveMaintenance: newCorrectiveMaintenance,
      training: torData.training,
      isInCanceling: torData.isInCanceling,
      isInEditing: torData.isInEditing,
      status: torData.status,
      remark: torData.remark,
      isChange: torData.isChange,
      isCancel: torData.isCancel,
      isActive: torData.isActive,
      hasPerformanceBond: torData.hasPerformanceBond,
      hasBidBond: torData.hasBidBond,
      refId: torData.refId,
      docId: torData.docId,
      documentTemplateCode: torData.documentTemplateCode,
      step: (torData.step != null && torData.step > 1) ? torData.step : 2
    };

    setShowSaveConfirmModal(false);

    props.onSubmit(updateData);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <Title
              text='ข้อมูลขอบเขตของงาน (เพิ่มเติม)'
              className='fs-5 text-primary'
            />
          </div>
          <div className='d-flex gap-2'>
            {
              torData.isInEditing && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-warning' /></div>
                    {TorEditCancelStatusConstant.Editing}</span>
                </div>
              )
            }
            {
              torData.isInCanceling && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-danger' /></div>
                    {TorEditCancelStatusConstant.Canceling}</span>
                </div>
              )
            }
            <TorStatus
              value={torData.status}
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <div></div>
      </Card>

      <DefectsWarranty
        torDefectWarranty={torData.defectsWarranty}
        onTorDefectWarrantyChange={(obj) => setTorData(pre => ({ ...pre, defectsWarranty: obj }))}
        periodUnitDDL={props.periodUnitDDL}
        defectsWarrantyCountUnitDDL={props.defectsWarrantyCountUnitDDL}
        isDisabled={isDisabled}
      />

      <FineRateComponent
        torData={torData}
        onFineRatesChange={(obj) => setTorData(pre => ({ ...pre, fineRates: obj }))}
        periodUnitDDL={props.periodUnitDDL}
        isDisabled={isDisabled}
      />

      {/* <Evidence
        torId={torData.id}
        evidence={torData.evidence}
        onEvidenceChange={(obj) => setTorData(pre => ({ ...pre, evidence: obj }))}
        periodUnitDDL={props.periodUnitDDL}
        isDisabled={isDisabled}
      /> */}

      <DocumentDescription
        documentDescription={torData.documentDescription}
        onDocumentDescriptionChange={(obj) => setTorData(pre => ({ ...pre, documentDescription: obj }))}
        isDisabled={isDisabled}
      />

      {
        torData.supplyMethodSpecialType === SupplyMethodIdConstrants.EBIDDING && (
          <Collateral
            collateral={torData.collateral}
            onCollateralChange={(obj) => setTorData(pre => ({ ...pre, collateral: obj }))}
            isDisabled={isDisabled}
          />
        )
      }

      <PreventiveMaintenance
        preventiveMaintenance={torData.preventiveMaintenance}
        onPreventiveMaintenanceChange={(obj) => setTorData(pre => ({ ...pre, preventiveMaintenance: obj }))}
        periodUnitDDL={props.periodUnitDDL}
        periodTimeUnitDDL={props.periodTimeUnitDDL}
        pmFineTypeDDL={props.pmFineTypeDDL}
        isDisabled={isDisabled}
      />

      <CorrectiveMaintenance
        correctiveMaintenance={torData.correctiveMaintenance}
        onCorrectiveMaintenanceChange={(obj) => setTorData(pre => ({ ...pre, correctiveMaintenance: obj }))}
        periodUnitDDL={props.periodUnitDDL}
        dayOfWeekDDL={props.dayOfWeekDDL}
        cmFineTypeDDL={props.cmFineTypeDDL}
        isDisabled={isDisabled}
      />

      <Training
        torId={torData.id}
        training={torData.training}
        onTrainingChange={(obj) => setTorData(pre => ({ ...pre, training: obj }))}
        trainingItems={torData.trainingItems}
        onTrainingItemsChange={(obj) => setTorData(pre => ({ ...pre, trainingItems: obj }))}
        periodUnitDDL={props.periodUnitDDL}
        trainingUnitTypeDDL={props.trainingUnitTypeDDL}
        isDisabled={isDisabled}
      />

      <ManuelDescription
        manuelDescription={torData.manuelDescription}
        onManuelDescriptionChange={(obj) => setTorData(pre => ({ ...pre, manuelDescription: obj }))}
        isDisabled={isDisabled}
      />

      <Impediment
        torId={torData.id}
        impediments={torData.impediments}
        onImpedimentsChange={(obj) => setTorData(pre => ({ ...pre, impediments: obj }))}
        isDisabled={isDisabled}
      />

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              torId={props.contextTorData.id}
              disabled={readonly}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={props.onClickBack}
          text="ย้อนกลับ"
        />
        {
          isDraftTorPerson &&
          (
            torData.status === TorStatusConstant.Draft ||
            torData.status === TorStatusConstant.DraftTor ||
            torData.status === TorStatusConstant.Rejected ||
            torData.status === TorStatusConstant.WaitingForCommitteeApprove
          ) &&
          (
            <ButtonCustom
              onClick={() => setShowSaveConfirmModal(true)}
              text="บันทึก"
            />
          )
        }
        {torData.step > 1
          ? <ButtonCustom
            onClick={props.onClickNext}
            text="ถัดไป"
          />
          : <div style={{ width: '190px' }} />}
      </div>

      <Modal
        show={showSaveConfirmModal}
        size='lg'
        onHide={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกข้อมูลหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSaveConfirmModal(!showSaveConfirmModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onSave}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <HistoryModal
        title='ประวัติการใช้งานแบบร่างขอบเขตของงาน (TOR)'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        torNumber={torData.torNumber}
      />
    </div>
  );
}
