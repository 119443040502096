import {
  ApproverStatus,
  ButtonCustom,
  Card,
  ContractReceiveCollateral,
  ContractReturnRequestStatus,
  InputTextArea,
  StatusCM,
  Table,
  Modal,
  SearchBuNameModal,
} from 'components';
import Title from 'components/Controls/Title';
import { IFile, UserListSelectionByCodeParams, UserListSelectionResponse } from 'models';
import {
  AcceptorModel,
  AttachmentFileModel,
  ContractReturnCollateralRequestModel,
} from 'models/CM/CM06Model';
import { sectionApproverModel } from 'models/delegateModel';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import {
  FaCheckCircle,
  FaPlus,
  FaRedo,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  MdArrowBack,
} from 'react-icons/md';
import { useNavigate } from 'react-router';
import { account, shareValue } from 'services';
import CM06Service from 'services/CM/CM06Service';
import {
  fullDatetimeTH,
  HttpStatusCode,
  submitForm,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import { Context } from '.';
import Collabora, { CollaboraRef } from '../../../../../components/Document/Collabora';
import { HistoryModal } from '../../Modal/HistoryModal';
import { UserSectionApproveModal } from './Modal/UserSectionApproveModal';
import UploadFile from './UploadFile';
import { AcceptorStatus } from 'utils/constants/PlanEnum';
import { isNull } from 'utils/helper';
import { JorPorCode } from 'constant/basicInformation';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
  collateralId: string;
}

const INFO = [
  { label: 'มีหลักประกัน', value: '1' },
  { label: 'ไม่มีหลักประกัน', value: '2' },
];

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function Step2({ onClickNext, onClickBack, collateralId }: Props) {
  const { dataContext, setDataContext } = useContext(Context);
  const [contractReturnRequestData, setContractReturnRequestData] = useState<ContractReturnCollateralRequestModel>({} as ContractReturnCollateralRequestModel);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showUserModals, setShowUserModals] = useState<boolean[]>([]);
  const [acceptors, setAcceptors] = useState<AcceptorModel[]>([]);
  const { userId, departmentCode } = useAppContext();
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [approveReason, setApproveReason] = useState<string>('');
  const [rejectReason, setRejectReason] = useState<string>('');
  const [isCanApprove, setIsCanApprove] = useState(false);
  const [docId, setDocId] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [personalfiles, setPersonalFiles] = useState<IFile[]>([]);
  const [isCanPending, setIsCanPending] = useState(false);
  const [showDefaultApproverModal, setShowDefaultApproverModal] = useState<boolean>(false);
  const [showReCall, setShowReCall] = useState(false);
  const [isAssignee, setIsAssignee] = useState(false);
  const [isReCall, setIsReCall] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const navigate = useNavigate();

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const handlerSetDefaultApproverAsync = async () => {
    onGetDefaultApprover(true);
    setShowDefaultApproverModal(false);
  }

  const getDocument = useCallback(async (contractReturnCollateralId: string) => {
    const res = await CM06Service.getDocumentApprovalAsync(contractReturnCollateralId);

    if (res.status === HttpStatusCode.OK) {
      setDocId(res.data);
    }
  }, [setDocId]);

  useEffect(() => {
    if (dataContext.contractReturnCollateralConsiderId) {
      getDocument(dataContext.contractReturnCollateralConsiderId);
    }
  }, [dataContext.contractReturnCollateralConsiderId, getDocument]);

  useEffect(() => {
    if (dataContext) {
      onGetDefaultApprover(false);
      if (dataContext.acceptors && dataContext.acceptors.length > 0) {
        setIsCanPending(true);
        setAcceptors(dataContext.acceptors);

        const isCanApprove = onCheckCanApprove(dataContext.acceptors);
        setIsCanApprove(isCanApprove);
      } else {
        setIsCanPending(false);
      }

      if (dataContext.status === ContractReturnRequestStatus.WaitForApprove
        || dataContext.status === ContractReturnRequestStatus.Approved) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }

      if (dataContext.id !== null && dataContext.id !== undefined) {
        getContractReturnCollateralRequestAttachmentByIdAsync(dataContext.id!);
        getContractReturnCollateralRequestAttachmentPersonalByIdAsync(dataContext.id!);
      }

      setContractReturnRequestData(dataContext);

      if (dataContext.contractReturnCollateralConsiderAssignee) {
        if (dataContext.contractReturnCollateralConsiderAssignee.filter(x => isNull(x.delegateUserId, x.userId) === userId && x.level === 1).length > 0) {
          setIsAssignee(true);
        }
      }

      if (dataContext.acceptors) {
        const queueReCall = dataContext.acceptors?.filter(a => a.status === AcceptorStatus.APPROVE).length === 0;

        if (queueReCall) {
          setIsReCall(true);
        } else {
          setIsReCall(false);
        }
      }
    }
  }, [dataContext, userId]);

  const onCheckCanApprove = (datas: AcceptorModel[]) => {
    const minSection = Math.min(...datas
      .filter(d => d.status === 'Pending'
        && !d.isAvailable)
      .map(o => o.sectionSequence!));

    const sectionData = datas
      .filter(d => d.sectionSequence === minSection
        && d.status === 'Pending'
        && !d.isAvailable)
      .sort((a, b) => a.sequence - b.sequence)[0];

    if (sectionData) {
      return isNull(sectionData.delegateUserId, sectionData.userId) === userId;
    }

    return false;
  };

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      acceptors?.map((x) => x.sectionSequence!));

    const valueSequence = acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  const onGetDefaultApprover = async (isSetNew: boolean) => {

    const collateralAmount = (dataContext.collateralChannes?.reduce((a, v) => a += v.amount ?? 0, 0) - dataContext.fineAmount);

    const response = await account
      .getDefaultApproverByTopLevel285(PROCESSTYPE.ApproveContractReturnCollateral, collateralAmount);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: AcceptorModel[] = [];
      if (isSetNew || (dataContext.acceptors === undefined
        || dataContext.acceptors === null
        || dataContext.acceptors.length === 0)) {

        newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {

            sectionDataGroup.push({
              sectionName: item.sectionName,
              level: item.sectionLevel,
              sectionSequence: item.sectionSequence,
              inRefCode: item.inRefCode,
              sectionNameTh: item.sectionName,
              sectionId: item.sectionId,
            } as sectionGroup);

            approver.push({
              id: undefined,
              sectionInRefCode: item.inRefCode!,
              sectionSequence: item.sectionSequence!,
              sectionName: item.sectionName!,
              userId: item.userId,
              fullName: item.fullName,
              positionName: item.positionName,
              departmentName: item.departmentName,
              isAvailable: false,
              availableRemark: undefined,
              acceptDate: undefined,
              acceptRemark: undefined,
              rejectDate: undefined,
              rejectRemark: undefined,
              delegateUserId: item.delegateUserId,
              delegateEmployeeCode: item.delegateEmployeeCode,
              delegateFullName: item.delegateFullName,
              delegateDepartmentName: item.delegateDepartmentName,
              delegatePositionName: item.delegatePositionName,
              sequence: acceptors.filter((x) => x.sectionSequence === item.sectionSequence).length + 1,
              status: AcceptorStatus.DRAFT,
              seqKey: `${item.sectionSequence?.toString()}${(acceptors.filter((x) => x.sectionSequence === item.sectionSequence).length + 1).toString()}`,
              level: item.sectionLevel,
            } as AcceptorModel);
          });

        const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);

        if (isSetNew || (acceptors === undefined || acceptors === null || acceptors.length === 0)) {
          let inx = 0;
          let sectionSeq = 0;
          approver?.sort((a, b) => a.sectionSequence! - b.sectionSequence!)
            .forEach((item, index) => {

              if (sectionSeq !== item.sectionSequence) {
                inx = 1;
                sectionSeq = item.sectionSequence!;
              }

              approver[index].sequence = inx;

              inx++;
            });

          setAcceptors(approver);
          setSections(uniqueNames);
        }
      } else {
        dataContext.acceptors.forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.level,
            sectionSequence: item.sectionSequence,
            inRefCode: item.sectionInRefCode,
            sectionNameTh: item.sectionName,
            sectionId: '',
          } as sectionGroup);
        });

        const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index);
        setSections(uniqueNames);
      }
    }
  };

  const goToBack = () => {
    navigate('/cm/cm03');
  };

  const onOpenModal = (index: number) => {
    setShowUserModals((showUserModals) => {
      const newModal = [...showUserModals];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideModal = (index: number) => {
    setShowUserModals((showUserModals) => {
      const newModal = [...showUserModals];
      newModal[index] = false;
      return newModal;
    });
  };

  const onSelectEmpApprover = (empId: string, name: string, department: string, position: string, index: number, sectionSequence?: number, sectionName?: string, inRefCode?: string, level?: number) => {
    if (acceptors && acceptors.filter((x) => x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {

      const acceptorBySection = acceptors.filter((x) => x.sectionSequence === sectionSequence);

      const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
        acceptorBySection.map((x) => {
          return x.sequence;
        })) : 0;

      const newData: AcceptorModel = {
        id: undefined,
        ContractReturnCollateralConsiderId: contractReturnRequestData.id,
        sectionInRefCode: inRefCode!,
        sectionSequence: sectionSequence!,
        sectionName: sectionName!,
        userId: empId,
        employeeCode: empId,
        fullName: name,
        positionName: position,
        departmentName: department,
        isAvailable: false,
        availableRemark: undefined,
        acceptDate: undefined,
        acceptRemark: undefined,
        rejectDate: undefined,
        rejectRemark: undefined,
        delegateUserId: undefined,
        delegateEmployeeCode: undefined,
        delegateFullName: undefined,
        delegateDepartmentName: undefined,
        delegatePositionName: undefined,
        status: AcceptorStatus.DRAFT,
        sequence: maxValueSequence,
        level: level!,
        seqKey: `${sectionSequence?.toString()}${(acceptors.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
      };
      setAcceptors((acceptors) => [...acceptors, newData]);
    }
  };

  const removeAccepter = (seqKey: string) => {
    const temp: AcceptorModel[] = [...acceptors];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setAcceptors(temp);
  };

  const onSave = async () => {
    submitForm();

    collaboraRef.current?.clickSave()

    setTimeout(async () => {
      setShowSaveConfirmModal(false);
      if (acceptors.length === 0) {
        toast.warn('กรุณาเลือกผู้มีอำนาจเห็นชอบอย่างน้อย 1 คน');
        return;
      }

      const { data, status } = await CM06Service.updateContractReturnCollateralRequestAcceptorAsync(contractReturnRequestData.id, acceptors, docId);

      if (status !== HttpStatusCode.OK && status !== HttpStatusCode.CREATED) {
        toast.error('บันทึกข้อมูลขออนุมัติคืนหลักประกันสัญญาไม่สำเร็จ');
        return;
      }

      // collaboraRef.current?.clickSave();
      // await CM06Service.updateDocumentApprovalAsync(
      //   contractReturnRequestData.contractReturnCollateralConsiderId,
      //   docId);

      toast.success('บันทึกข้อมูลขออนุมัติคืนหลักประกันสัญญาสำเร็จ');

      getContractReturnCollateralById(contractReturnRequestData.contractReturnCollateralConsiderId);
      getDocument(dataContext.contractReturnCollateralConsiderId);
    }, 500);
  };

  const getContractReturnCollateralById = async (id: string) => {
    const { data, status } = await CM06Service.getContractReturnCollateralRequestApprovalByIdAsync(id);
    if (status !== HttpStatusCode.OK) {
      return;
    }

    setDataContext(data);
    const isCanApprove = onCheckCanApprove(data.acceptors);
    setIsCanApprove(isCanApprove);
    navigate(`/cm/cm06/detail/requests/${id}`);
  };

  const onPending = async () => {
    setShowPendingModal(false);
    if (acceptors.length === 0) {
      toast.warn('กรุณาบันทึกผู้มีอำนาจ');
      return;
    }

    const { data, status } = await CM06Service.updateContractReturnCollateralRequestAcceptorAsync(contractReturnRequestData.id, acceptors, undefined);

    if (status === HttpStatusCode.OK || status === HttpStatusCode.CREATED) {
      const { data, status } = await CM06Service.pendingContractReturnCollateralRequestAcceptorAsync(contractReturnRequestData.id, docId);

      if (status !== HttpStatusCode.OK && status !== HttpStatusCode.CREATED) {
        toast.error('บันทึกแจ้งรับทราบไม่สำเร็จ');
        return;
      }

      toast.success('บันทึกแจ้งรับทราบสำเร็จ');
      getContractReturnCollateralById(contractReturnRequestData.contractReturnCollateralConsiderId);
      getDocument(dataContext.contractReturnCollateralConsiderId);
      return;
    }

    toast.error('บันทึกแจ้งรับทราบไม่สำเร็จ');
  };

  const onApprove = async () => {
    setShowApproveModal(false);
    const { data, status } = await CM06Service.approveContractReturnCollateralRequestAcceptorAsync(contractReturnRequestData.id, approveReason);
    if (status !== HttpStatusCode.OK && status !== HttpStatusCode.CREATED) {
      toast.error('บันทึกเห็นชอบ/อนุมัติไม่สำเร็จ');
      return;
    }
    toast.success('บันทึกเห็นชอบ/อนุมัติสำเร็จ');
    getContractReturnCollateralById(contractReturnRequestData.contractReturnCollateralConsiderId);
    getDocument(dataContext.contractReturnCollateralConsiderId);
  };

  const onReject = async () => {
    if (rejectReason.length === 0) {
      toast.warn('กรุณาระบุเหตุผล');
      return;
    }

    setShowRejectModal(false);
    const { data, status } = await CM06Service.rejectContractReturnCollateralRequestAcceptorAsync(contractReturnRequestData.id, rejectReason);
    if (status !== HttpStatusCode.OK && status !== HttpStatusCode.CREATED) {
      toast.error('บันทึกส่งกลับแก้ไขไม่สำเร็จ');
      return;
    }
    toast.success('บันทึกส่งกลับแก้ไขสำเร็จ');
    getContractReturnCollateralById(contractReturnRequestData.contractReturnCollateralConsiderId);
    getDocument(dataContext.contractReturnCollateralConsiderId);
  };

  const handleFileChange = async (files: File[]) => {
    if (contractReturnRequestData && contractReturnRequestData.id) {
      const res = await CM06Service.uploadContractReturnCollateralRequestAttachmentAsync(
        contractReturnRequestData.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');

        const dataFile: AttachmentFileModel[] = res.data as AttachmentFileModel[];
        if (dataFile && dataFile.length > 0) {
          const newFiles: IFile[] = dataFile.map((data, index) => ({
            id: data.id!,
            name: data.fileName,
            file: null,
          } as IFile));

          setFiles(newFiles);
        }
      }
    }
  };

  const removeItemAsync = async (index: number, docId: string) => {

    if (contractReturnRequestData && contractReturnRequestData.id) {
      const res = await CM06Service.removeFileContractReturnCollateralRequestAttachmentAsync(
        contractReturnRequestData.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');

        getContractReturnCollateralRequestAttachmentByIdAsync(contractReturnRequestData.id);
      }
    }

  };

  const handlePersonalFileChange = async (files: File[]) => {
    if (contractReturnRequestData && contractReturnRequestData.id) {
      const res = await CM06Service.uploadContractReturnCollateralRequestAttachmentPersonalAsync(
        contractReturnRequestData.id,
        files,
      );

      if (res.status === HttpStatusCode.OK) {
        toast.success('อัปโหลดไฟล์สำเร็จ');

        const dataFile: AttachmentFileModel[] = res.data as AttachmentFileModel[];
        if (dataFile && dataFile.length > 0) {
          const newFiles: IFile[] = dataFile.map((data, index) => ({
            id: data.id!,
            name: data.fileName,
            file: null,
          } as IFile));

          setPersonalFiles(newFiles);
        }
      }
    }
  };

  const removeItemPersonalAsync = async (index: number, docId: string) => {

    if (contractReturnRequestData && contractReturnRequestData.id) {
      const res = await CM06Service.removeFileContractReturnCollateralRequestAttachmentAsync(
        contractReturnRequestData.id,
        docId,
      );

      if (res.status === HttpStatusCode.OK || res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');

        getContractReturnCollateralRequestAttachmentPersonalByIdAsync(contractReturnRequestData.id);
      }
    }

  };

  const getContractReturnCollateralRequestAttachmentByIdAsync = async (id: string) => {
    const { data, status } = await CM06Service.getContractReturnCollateralRequestAttachmentAsync(id);
    if (status === HttpStatusCode.OK) {
      const attachments = data.attachments as AttachmentFileModel[];
      if (attachments && attachments.length > 0) {
        const newFiles: IFile[] = attachments.map((data, index) => ({
          id: data.id!,
          name: data.fileName,
          file: null,
        } as IFile));

        setFiles(newFiles);
      } else {
        setFiles([]);
      }
    }
  };

  const getContractReturnCollateralRequestAttachmentPersonalByIdAsync = async (id: string) => {
    const { data, status } = await CM06Service.getContractReturnCollateralRequestAttachmentPersonalAsync(id);
    if (status === HttpStatusCode.OK) {
      const attachments = data.attachments as AttachmentFileModel[];
      if (attachments && attachments.length > 0) {
        const newFiles: IFile[] = attachments.map((data, index) => ({
          id: data.id!,
          name: data.fileName,
          file: null,
        } as IFile));

        setPersonalFiles(newFiles);
      } else {
        setPersonalFiles([]);
      }
    }
  };

  const reCallAsync = async () => {

    if (contractReturnRequestData.id && contractReturnRequestData.id) {
      const response = await CM06Service.onReCallRequestApprovalAsync(contractReturnRequestData.id);

      if (response.status === HttpStatusCode.OK) {
        toast.success('เรียกคืนแก้ไขสำเร็จ');
        setIsReCall(false);
        getContractReturnCollateralById(contractReturnRequestData.contractReturnCollateralConsiderId);
        getDocument(dataContext.contractReturnCollateralConsiderId);
      }
    }

    setShowReCall(false);
  };

  return (
    <div className='document'>
      <Title text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
        extraElement={(
          <>
            <StatusCM systemStatue={contractReturnRequestData.status}
              systemName='cm-03' />
            <ButtonCustom variant='outline-primary'
              onClick={() => {
                setShowHistoryModal(true);
              }}
              text='ประวัติการใช้งาน'
              icon='history' />
          </>
        )} />

      <Row className='mt-4 align-items-center'>
        <Col sm={12}>
          <Row className='mt-4 align-items-center gap-5'>
            <div>
              {(dataContext.status === ContractReturnRequestStatus.Draft || dataContext.status === ContractReturnRequestStatus.Rejected)
                ? (
                  <Button
                    className='d-flex align-items-center gap-2'
                    variant='outline-primary'
                    onClick={() => {
                      setShowDefaultApproverModal(true);
                    }}
                  >
                    <FaRedo />กำหนดผู้มีอำนาจเห็นชอบเริ่มต้น
                  </Button>
                ) : null}
            </div>
            {
              sections.length > 0 ? (
                sections
                  .sort((a, b) => a.sectionSequence - b.sectionSequence)
                  .map((section, i) => (
                    <Col sm={12}
                      key={i}>
                      {!isDisabled && (
                        <div className='d-flex justify-content-between'>
                          <h5 className='fw-bold'>
                            {/* {section.sectionName} */}
                          </h5>
                          <Button
                            variant='outline-primary'
                            disabled={isDisabled}
                            onClick={() => onOpenModal(i)}
                          >
                            <FaPlus className='me-1' /> เพิ่มรายชื่อ
                          </Button>
                          <SearchBuNameModal
                            total={userTotal}
                            show={showUserModals[i]}
                            onHide={() => onHideModal(i)}
                            onSelect={(id, name, department, position) => onSelectEmpApprover(id, name, department!, position!, acceptors.length + 1, section.sectionSequence, section.sectionName, section.inRefCode, section.level!)}
                            data={users}
                            onSearch={onSearchUserAsync}
                            onPageChange={onPageChangeAsync}
                            departmentCode={departmentCode}
                            departmentDisabled={departmentCode !== JorPorCode.CODE}
                          />
                        </div>
                      )}
                      <Table notResponse
                        hidePagination
                        className='mt-3 step-bar'>
                        <thead>
                          <tr>
                            <th style={{ width: 20 }}>ลำดับ</th>
                            <th className='text-start'
                              style={{ width: '50%' }}>ชื่อ-นามสกุล
                            </th>
                            <th className='text-start'
                              style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                            </th>
                            <th style={{ width: 100 }}>สถานะ</th>
                            <th style={{ width: 100 }}>วันที่เห็นชอบ</th>
                            <th style={{ width: 100 }} />
                          </tr>
                        </thead>
                        <tbody>
                          {acceptors?.filter((x) => x.sectionInRefCode === section.inRefCode)
                            .sort((a, b) => a.sequence - b.sequence)
                            .map((data, index) => (
                              <>
                                <tr>
                                  <td className='text-center'>{index + 1}</td>
                                  <td className='text-left'>
                                    {data.fullName}
                                    <p className='m-0 department'>
                                      {data.positionName}
                                    </p>
                                  </td>
                                  <td className='text-left'>
                                    {data.delegateFullName &&
                                      <>
                                        {data.delegateFullName}
                                        <p className='m-0 department'>
                                          {data.delegatePositionName}
                                        </p>
                                      </>
                                    }
                                  </td>
                                  <td className='text-center'>
                                    <ApproverStatus value={data.status} outline />
                                  </td>
                                  <td className='text-center'>
                                    {fullDatetimeTH(data.acceptDate!)}
                                  </td>
                                  <td className='text-end'>
                                    <Button
                                      onClick={() => removeAccepter(`${data.sectionSequence}${data.sequence}`)}
                                      variant='danger'
                                      disabled={isDisabled}
                                    >
                                      <FaTrashAlt />
                                    </Button>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </Table>
                    </Col>
                  ))
              ) : (
                <Col>
                  <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>

      <Card className='mt-3'>
        <Title
          text='เอกสารผลการขออนุมัติคืนหลักประกันสัญญา'
          className='fs-5'
          lineBorder
        />
        <div className='mt-3'>
          <Collabora
            docId={docId}
            docName='doc-01'
            ref={collaboraRef}
            readonly={contractReturnRequestData.status === ContractReturnRequestStatus.WaitForApprove
              || contractReturnRequestData.status === ContractReturnRequestStatus.Approved} />
        </div>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5'
          confidentiality />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={contractReturnRequestData.id === null || contractReturnRequestData.id === undefined}
              files={personalfiles}
              handleFileChange={handlePersonalFileChange}
              removeItem={removeItemPersonalAsync}
              contractApprovalId={contractReturnRequestData.id!}
            />
          </Col>
        </Row>
      </Card>
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5' />
        <Row className='justify-content-center'>
          <Col sm={12}
            md={6}>
            <UploadFile
              disabled={contractReturnRequestData.id === null || contractReturnRequestData.id === undefined}
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              contractApprovalId={contractReturnRequestData.id!}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between align-items-center mt-3 pb-3'>
        <div>
          <Button
            onClick={onClickBack}
            className='me-2 px-3'
            variant='outline-primary'
          >
            <MdArrowBack className='me-2 pb-1 fs-5' />
            ย้อนกลับ
          </Button>
        </div>
        <div className='d-flex gap-3 align-items-center'>
          {
            isAssignee &&
              (contractReturnRequestData.status !== ContractReturnRequestStatus.WaitForApprove
                && contractReturnRequestData.status !== ContractReturnRequestStatus.Approved)
              ? (
                <>
                  <ButtonCustom text='บันทึก'
                    variant='outline-primary'
                    onClick={() => setShowSaveConfirmModal(true)} />
                </>
              ) : <></>
          }
          {
            isAssignee &&
              (contractReturnRequestData.status !== ContractReturnRequestStatus.WaitForApprove
                && contractReturnRequestData.status !== ContractReturnRequestStatus.Approved)
              ? (
                <>
                  <ButtonCustom text='ส่งเห็นชอบ/อนุมัติ'
                    onClick={() => setShowPendingModal(true)} />
                </>
              ) : <></>
          }
          {(isAssignee &&
            isReCall &&
            contractReturnRequestData.status === ContractReturnRequestStatus.WaitForApprove) && (
              <Button
                className='me-2 px-3'
                onClick={() => setShowReCall(true)}
                variant='danger'
              >
                <FaUndo className='mx-2 pb-1 fs-5' />
                เรียกคืนแก้ไข
              </Button>
            )}
          {
            contractReturnRequestData.status === ContractReturnRequestStatus.WaitForApprove
              && isCanApprove
              ? (
                <>
                  <Button
                    onClick={() => setShowRejectModal(true)}
                    variant='danger'
                  >
                    <FaUndo className='me-2 pb-1 fs-5' />ส่งกลับแก้ไข
                  </Button>
                  <Button
                    variant='success'
                    onClick={() => setShowApproveModal(true)}
                    className='me-2 px-3 ms-2'
                  >
                    <FaCheckCircle className='me-2 pb-1 fs-5' />
                    เห็นชอบ
                  </Button>
                </>
              ) : <></>
          }
        </div>
        <div style={{ width: 140 }} />
      </div>

      <HistoryModal
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        id={contractReturnRequestData.id}
        title='ขออนุมัติคืนหลักประกันสัญญา'
        status={ContractReceiveCollateral.Request}
      />

      <Modal
        show={showSaveConfirmModal}
        size='lg'
        onHide={() => setShowSaveConfirmModal(!showSaveConfirmModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการบันทึกข้อมูลหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowSaveConfirmModal(!showSaveConfirmModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onSave}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showDefaultApproverModal}
        size='lg'
        onHide={() => setShowDefaultApproverModal(!showDefaultApproverModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการกำหนดผู้มีอำนาจเห็นชอบเริ่มต้นใหม่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultApproverModal(!showDefaultApproverModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerSetDefaultApproverAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showPendingModal}
        size='lg'
        onHide={() => setShowPendingModal(!showPendingModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งตรวจสอบ</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการส่งตรวจสอบหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowPendingModal(!showPendingModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onPending()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApproveModal}
        size='lg'
        onHide={() => setShowApproveModal(!showApproveModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ความเห็นชอบพิจารณา</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body>
              <InputTextArea label='ความเห็นเพิ่มเติม (ถ้ามี)'
                onChange={(val) => setApproveReason(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApproveModal(!showApproveModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='success'
                onClick={onApprove}
              >
                <FaCheckCircle className='me-2 pb-1 fs-5' />
                เห็นชอบ
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showRejectModal}
        size='lg'
        onHide={() => setShowRejectModal(!showRejectModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body>
              <InputTextArea label='หมายเหตุ'
                onChange={(val) => setRejectReason(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRejectModal(!showRejectModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='danger'
                onClick={onReject}
              >
                <FaUndo className='me-2 pb-1 fs-5' />ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />

      <Modal
        show={showReCall}
        size='lg'
        onHide={() => setShowReCall(!showReCall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowReCall(!showReCall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={reCallAsync}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </div>
  );
}

export default Step2;
