import { Rule } from 'models';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Form,
  InputGroup,
} from 'react-bootstrap';
import { MaxNumber, ValidatePattern } from 'utils';
import regex from 'utils/regex';

interface Props {
  label?: string;
  placeholder?: string;
  rule?: Rule;
  value?: number;
  name?: string;
  onChange?: (value: number | undefined) => void;
  className?: string;
  disabled?: boolean;
  maxValue?: number;
  sizeText?: 'sm' | 'lg';
}

export function Currency(props: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [valueShow, setValueShow] = useState<string>('');
  const [value, setValue] = useState<number | undefined>(props.value);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const formatter = new Intl.NumberFormat('th-TH', {
    currency: 'THB',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const rounded = (number: number | undefined) => {
    if (!number) {
      return 0;
    }

    const decimalPart: string | undefined = number.toString().split(".")[1];

    if (decimalPart && decimalPart.length >= 3) {
      const thirdDecimal = Number(decimalPart[2]);

      return thirdDecimal >= 5 ? Math.ceil(number * 100) / 100 : Math.floor(number * 100) / 100;
    }

    return number;
  };

  document.addEventListener('onFormSubmit', (data) => {
    const event = data as CustomEvent;

    if (event.detail.hasNameOnly) {
      if (props.name) {
        validate(value);
      }
    } else {
      validate(value);
    }
  });

  document.addEventListener('onClearError', () => {
    setErrorMessage('');
  });

  useEffect(() => {
    if (isFocus) {
      setValue(props.value);
    } else if (props.value || props.value === 0) {
      setValue(props.value);
      setValueShow(formatter.format(+props.value));
    } else {
      setValue(undefined);
      setValueShow('');
    }
  }, [props.value, isFocus, formatter]);

  const getRequired = () => {
    if (props.rule?.required) {
      return <span className='text-danger'>*</span>;
    }

    return null;
  };

  const validate = (validateValue: number | undefined) => {
    if (!checkRequired(validateValue)) {
      return;
    }

    setErrorMessage('');
  };

  const checkRequired = (checkRequiredValue: number | undefined) => {
    if (props.rule?.required && checkRequiredValue === undefined) {
      setErrorMessage('กรุณากรอกข้อมูล');

      return false;
    }

    return true;
  };

  const getErrorMessage = useMemo(() => {
    if (errorMessage) {
      return (
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      );
    }

    return null;
  }, [errorMessage]);

  const handlerOnChange = (event: InputEvent) => {
    const target = event.target as HTMLInputElement;
    const checkedCurrencyValue = !event.data || event.data === '.' || regex.validateCurrency(event.data);

    if (!checkedCurrencyValue) {
      return;
    }

    if (target.value.replace(/[^.]/g, '').length > 1) {
      return;
    }

    const inputValue = target.value.trim();
    const numericValue = inputValue === '0' || inputValue ? +inputValue : undefined;
    const stringValue = inputValue || inputValue === '0' ? inputValue.toString() : '';

    if (props.maxValue && numericValue! > props.maxValue) {
      return;
    }

    if (numericValue! > MaxNumber.INTEGER) {
      return;
    }


    if (props.rule?.pattern && props.rule?.pattern === ValidatePattern.PERCENTAGE && numericValue! > MaxNumber.PERCENT) {
      setErrorMessage('ระบุได้ไม่เกิน 100 เปอร์เซ็น');
      return;
    }

    setValue(numericValue);
    setValueShow(stringValue);

    if (props.onChange) {
      props.onChange(numericValue);
    }

    validate(numericValue);

    setErrorMessage('');
  };

  const handlerOnFocus = () => {
    setIsFocus(true);

    if (value) {
      setValueShow(value.toString());
    }
  };

  const handlerOnBlur = () => {
    setIsFocus(false);

    if (value) {
      const finalValue = rounded(value);

      setValue(finalValue);
      setValueShow(formatter.format(finalValue));

      if (props.onChange) {
        props.onChange(finalValue);
      }
    }
  };

  useEffect(() => {
    const invalid: NodeListOf<HTMLElement> = document.querySelectorAll('input.is-invalid');

    if (invalid.length > 0) {
      invalid[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [getErrorMessage]);

  return (
    <Form.Group className={`w-100 ${props.className ?? ''} ${props.label ? 'mb-3' : ''}`}>
      {props.label ? <Form.Label>{props.label} {getRequired()}</Form.Label> : null}
      <InputGroup style={{ zIndex: '0' }}
        hasValidation>
        <Form.Control
          size={props.sizeText}
          className={`text-end ${getErrorMessage ? 'is-invalid' : ''}`}
          value={valueShow ?? ''}
          type='text'
          placeholder={props.placeholder}
          onChange={(event) => handlerOnChange(event.nativeEvent as InputEvent)}
          disabled={props.disabled}
          onFocus={handlerOnFocus}
          onBlur={handlerOnBlur}
        />
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}
