import { Card, Input, Radio, Selector, Table, TorStatus } from 'components';
import { InputNumber } from 'components/Controls/InputNumber';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import { PaymentModel } from 'models/CM/CM02Models';
import { FineRate, TorDefectWarrantyModel, TorDetailModel, TorEvidenceCompareItemModel, TorEvidenceModel, TorTrainingItemModel, TorTrainingModel } from 'models/PP/TorModels';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  Row,
} from 'react-bootstrap';
import { BsArrowDownCircle } from 'react-icons/bs';
import { FaChevronCircleDown, FaChevronCircleUp, FaHistory, FaPlus, FaTrashAlt } from 'react-icons/fa';
import { ValidatePattern } from 'utils';
import { Context } from '../../step';

interface ITrainingProps {
  torId: string;
  training: TorTrainingModel;
  onTrainingChange: (value: TorTrainingModel) => void;
  trainingItems: TorTrainingItemModel[];
  onTrainingItemsChange: (value: TorTrainingItemModel[]) => void;
  periodUnitDDL: ItemModel[];
  trainingUnitTypeDDL: ItemModel[];
  isDisabled?: boolean;
}

export function Training(props: ITrainingProps) {
  const { readonly } = useContext(Context);
  const [trainingItems, setTrainingItems] = useState<TorTrainingItemModel[]>(props.trainingItems);
  const [training, setTraining] = useState<TorTrainingModel>(props.training);
  const [firstLoadTrainingItems, setFirstLoadTrainingItems] = useState(true);
  const [firstLoadTraining, setFirstLoadTraining] = useState(true);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    props.onTrainingChange(training);
  }, [training]);

  useEffect(() => {
    props.onTrainingItemsChange(trainingItems);
  }, [trainingItems]);

  useEffect(() => {
    if (props.training && firstLoadTraining) {
      setTraining(props.training);
      setFirstLoadTraining(false);
    }

    if (props.trainingItems && firstLoadTrainingItems) {
      setTrainingItems(props.trainingItems);
      setFirstLoadTrainingItems(false);
    }
  }, [props.training, props.trainingItems, firstLoadTrainingItems, firstLoadTraining]);

  const onTextChange = useCallback((key: string, value: string) => {
    setTraining(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setTraining(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onDateChange = useCallback((key: string, value: Date | undefined) => {
    setTraining(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);


  const addTrainingItem = useCallback(async () => {
    const newData: TorTrainingItemModel = {
      id: undefined,
      termOfRefId: props.torId,
      courseName: '',
      periodDay: 0,
      place: '',
      trainingCount: 0,
      totalPersonPerTime: 0,
      sequence: trainingItems.length + 1,
    };

    setTrainingItems((trainingItems) => [...trainingItems, newData]);
  }, [trainingItems]);

  const handlerOnChangeTrainingCourseName = (value: string, index: number) => {
    const data = [...trainingItems];
    data[index].courseName = value;
    setTrainingItems(data);
  };

  const handlerOnChangeTrainingPeriodDay = (value: number, index: number) => {
    const data = [...trainingItems];
    data[index].periodDay = value;
    setTrainingItems(data);
  };

  const handlerOnChangeTrainingPlace = (value: string, index: number) => {
    const data = [...trainingItems];
    data[index].place = value;
    setTrainingItems(data);
  };

  const handlerOnChangeTrainingCount = (value: number, index: number) => {
    const data = [...trainingItems];
    data[index].trainingCount = value;
    setTrainingItems(data);
  };

  const handlerOnChangeTotalPersonPerTime = (value: number, index: number) => {
    const data = [...trainingItems];
    data[index].totalPersonPerTime = value;
    setTrainingItems(data);
  };

  const removeTrainingItem = (i: number) => {
    const temp: TorTrainingItemModel[] = [...trainingItems];
    temp.splice(i, 1);
    setTrainingItems(temp);
  };

  return (
    <Card className='mt-3'>
      <div className='d-flex align-items-center'>
        <Title
          text='การฝึกอบรม (IT)'
          className='fs-5 text-primary'
        />
        {
          collapsed
            ? (
              <FaChevronCircleDown
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(false)}
              />
            ) : (
              <FaChevronCircleUp
                className='text-primary fs-5 cursor-pointer'
                onClick={() => setCollapsed(true)}
              />
            )
        }
      </div>
      <Collapse in={!collapsed} className='ps-40'>
        <div>
          <Row>
            <Col sm={12} md={3}>
              <InputNumber
                label='ต้องจัดการฝึกอบรมภายในเวลา'
                value={training?.trainingCount}
                onChange={(value) => onNumberChange('trainingCount', Number(value))}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly}
              />
            </Col>
            <Col sm={12} md={2}>
              <Selector
                className='primary no-label'
                items={props.periodUnitDDL}
                value={training?.trainingCountUnit}
                onChange={(value) => onTextChange('trainingCountUnit', value)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly} />
            </Col>
            <Col sm={12} md={2}>
              <Selector
                className='primary no-label'
                items={props.trainingUnitTypeDDL}
                value={training?.trainingUnitId}
                onChange={(value) => onTextChange('trainingUnitId', value)}
                rule={{ required: false }}
                disabled={props.isDisabled || readonly} />
            </Col>
          </Row>
          <div>
            <div className='d-flex align-items-center justify-content-between'>
              <p className='mb-0'>รายการหลักสูตร</p>
              <Button
                variant='outline-primary'
                onClick={addTrainingItem}
                disabled={props.isDisabled || readonly}
              >
                <FaPlus className='me-1' />เพิ่มรายการ
              </Button>
            </div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 20 }}>ลำดับ</th>
                      <th style={{ width: 200 }}>ชื่อหลักสูตร</th>
                      <th style={{ width: 100 }}>จำนวนวัน</th>
                      <th style={{ width: 200 }}>สถานที่อบรม</th>
                      <th style={{ width: 100 }}>จำนวนครั้ง</th>
                      <th style={{ width: 100 }}>จำนวนคนต่อครั้ง</th>
                      <th style={{ width: 20 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {trainingItems?.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr key={index}>
                          <td className='text-center'>{index + 1}</td>
                          <td>
                            <Input
                              value={data.courseName}
                              onChange={(val) => handlerOnChangeTrainingCourseName(val, index)}
                              rule={{ required: true }}
                              disabled={props.isDisabled || readonly}
                            />
                          </td>
                          <td>
                            <InputNumber
                              value={data.periodDay}
                              onChange={(val) => handlerOnChangeTrainingPeriodDay(Number(val), index)}
                              rule={{ required: true }}
                              disabled={props.isDisabled || readonly}
                            />
                          </td>
                          <td>
                            <Input
                              value={data.place}
                              onChange={(val) => handlerOnChangeTrainingPlace(val, index)}
                              rule={{ required: true }}
                              disabled={props.isDisabled || readonly}
                            />
                          </td>
                          <td>
                            <InputNumber
                              value={data.trainingCount}
                              onChange={(val) => handlerOnChangeTrainingCount(Number(val), index)}
                              rule={{ required: true }}
                              disabled={props.isDisabled || readonly}
                            />
                          </td>
                          <td>
                            <InputNumber
                              value={data.totalPersonPerTime}
                              onChange={(val) => handlerOnChangeTotalPersonPerTime(Number(val), index)}
                              rule={{ required: true }}
                              disabled={props.isDisabled || readonly}
                            />
                          </td>
                          <td className='text-end'>
                            <Button
                              onClick={() => removeTrainingItem(index)}
                              variant='danger'
                              disabled={props.isDisabled || readonly}
                            >
                              <FaTrashAlt />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </div>
      </Collapse>
    </Card>
  );
}
