import {
  Card,
  Check,
  Input,
  InputTextArea,
  Modal,
  Selector,
  Table,
  TorStatus,
  TorStatusCommitteeApprovers,
  ArrowCollapse,
  ButtonCustom,
  SearchBuNameModal,
  Approver,
  ApproverStatus,
} from 'components';
import Title from 'components/Controls/Title';
import {
  TorEditCancelStatusConstant,
  TorInRefCodeConstant,
  TorStatusConstant,
} from 'constant/PreProcumentTorStatus';
import {
  IFile,
  ItemModel,
  JorporDirectorBeInformed,
  UserListSelectionByCodeParams,
  UserListSelectionResponse,
} from 'models';
import {
  CommitteeApproverTorModel,
  SequenceApproverTorModel,
  TorDetailModel,
  TorDocumentModel,
} from 'models/PP/TorModels';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import {
  Button,
  Col,
  Modal as ModalBT,
  Row,
} from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { BsCheckCircle } from 'react-icons/bs';
import {
  FaCircle,
  FaHistory,
  FaPlus,
  FaRedo,
  FaTrashAlt,
  FaUndo,
} from 'react-icons/fa';
import {
  useLocation,
  useNavigate,
} from 'react-router';
import { account, shareValue } from 'services';
import PreProcurementTorServices from 'services/PP/PreProcurementTorServices';
import {
  HttpStatusCode,
  PositionOnBoardIdEnum,
  ProcurementSection,
  fullDatetime,
  submitForm,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import toast from 'utils/toast';
import { nullObject } from '../../../utils/helper';
import { UserApproveModal } from '../UserApproveModal';
import { HistoryModal } from './Modal/HistoryModal';
import { Context } from './step';
import UploadFile from './UploadFile';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import { SubGroup } from 'utils/constants/ContractEnum';
import { PROCESSTYPE, SectionType } from 'utils/constants/ProcessTypeEnum';
import { sectionApproverModel } from 'models/delegateModel';
import { JorPorCode } from 'constant/basicInformation';
import { PreProcumentFlag, PreProcumentStatus } from 'constant/PreProcumentFlag';
import { onCheckQueueSectionApprover } from 'utils/checkQueueSectionApprover';
import { AcceptorStatus } from 'utils/constants/PlanEnum';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
}

function Step3({ onClickNext, onClickBack }: Props) {
  const { data, setData, readonly, setReadonly } = useContext(Context);
  const { userId, departmentCode } = useAppContext();
  const [torData, setTorData] = useState<TorDetailModel>({} as TorDetailModel);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [openCollapse2, setOpenCollapse2] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showRecall, setShowRecall] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [currentPath, setCurrentPath] = useState('preprocurement-tor/detail');
  const [committeeApprovers, setCommitteeApprovers] = useState<CommitteeApproverTorModel[]>([]);
  const [committeeMiddlePriceApprovers, setCommitteeMiddlePriceApprovers] = useState<CommitteeApproverTorModel[]>([]);
  const [showCommitteesApprovers, setShowCommitteesApprovers] = useState(false);
  const [showCommitteesMiddlePriceApprovers, setShowCommitteesMiddlePriceApprovers] = useState(false);
  const [positionOnBoardItems, setPositionOnBoardItems] = useState<ItemModel[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [rejectRemark, setRejectRemark] = useState('');
  const [isHasMiddlePrice, setIsHasMiddlePrice] = useState(false);
  const [isCanApproveTor, setIsCanApproveTor] = useState(false);
  const [isCanApproveMiddle, setIsCanApproveMiddle] = useState(false);
  const [showWaitingForApprove, setShowWaitingForApprove] = useState(false);
  const [, setShowApproveMiddle] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [isDraftTorPerson, setIsDraftTorPerson] = useState(false);
  const [isCanRecall, setIsCanRecall] = useState(true);
  const [approveRemark, setApproveRemark] = useState('');
  const [torDocId, setTorDocId] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);
  const [isCheckBook, setIsCheckBook] = useState<boolean>(false);
  const [openCollapse1, setOpenCollapse1] = useState(true);
  const [openCollapse3, setOpenCollapse3] = useState(true);
  const [openCollapse4, setOpenCollapse4] = useState(true);
  const [openCollapse5, setOpenCollapse5] = useState(true);
  const [openCollapse6, setOpenCollapse6] = useState(true);
  const [approverUsers, setApproverUsers] = useState<SequenceApproverTorModel[]>([]);
  const [responsibles, setResponsibles] = useState<SequenceApproverTorModel[]>([]);
  const [assignees, setAssignees] = useState<SequenceApproverTorModel[]>([]);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [showModals, setShowModals] = useState<boolean[]>([]);
  const [isSectionsApprover, setIsSectionsApprover] = useState(false);
  const [isCanSendApprove, setIsCanSendApprove] = useState(false);
  const [showConfirmAssign, setShowConfirmAssign] = useState(false);
  const [isJorPorComment, setIsJorPorComment] = useState(false);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [showDefaultApproverModal, setShowDefaultApproverModal] = useState<boolean>(false);
  const [checkApprover, setCheckApprover] = useState(false);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [showAssigneeModal, setShowAssigneeModal] = useState<boolean>(false);
  const [showResponsibleModal, setShowResponsibleModal] = useState<boolean>(false);
  const [isJorPorDirector, setIsJorPorDirector] = useState<boolean>(false);
  const [isAssignee, setIsAssignee] = useState<boolean>(false);
  const [isResponsible, setIsResponsible] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [remark, setRemark] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [jorPorDirectors, setJorPorDirectors] = useState<SequenceApproverTorModel[]>([]);
  const [isApproverCanRecall, setIsApproverCanRecall] = useState(true);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      data.committeeApproverTorSections?.map((x) => {
        return x.sectionSequence;
      }));

    const valueSequence = data.committeeApproverTorSections?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  useEffect(() => {
    const paramId = location.pathname.slice(location.pathname.lastIndexOf('/'), location.pathname.length);

    setCurrentPath(location.pathname.replace(paramId, ''));

    getPositionOnBoardAsync();
  }, []);

  const getDefaultUserAsync = async () => {
    const [
      jorporDirectorBeInformedDefault,
    ] = await Promise.all([
      account.getDefaultDepartmentDirectorAgree(ProcurementSection.JORPOR_DIRECTOR_BE_INFORMED),
    ]);

    const jorporDirectorBeInformed = jorporDirectorBeInformedDefault.data as JorporDirectorBeInformed[];

    const newJorPorDirectors: SequenceApproverTorModel[] = [];

    jorporDirectorBeInformed.forEach((item, index) => {
      newJorPorDirectors.push({
        id: item.id,
        approverFullName: item.fullName,
        approverUserId: item.userId,
        departmentName: item.departmentName,
        position: item.positionName,
        isDisabled: item.isAvailable,
        sequence: item.sequence,
        inRefCode: TorInRefCodeConstant.JorPorDirector,
        rejectedRemark: item.rejectRemark,
        rejectedDateAt: item.rejectDate,
        delegateFullName: item.delegateFullName,
        delegateAnnotation: item.delegateAnnotation,
        delegateDepartmentName: item.delegateDepartmentName,
        delegateEmployeeCode: item.delegateEmployeeCode,
        delegatePositionName: item.delegatePositionName,
        delegateUserId: item.delegateUserId,
        sectionSequence: item.sequence,
        status: TorStatusConstant.Draft,
        remark: '',
      } as SequenceApproverTorModel);
    });

    setJorPorDirectors(newJorPorDirectors);

    return;
  };

  useEffect(() => {
    if (!nullObject(data)) {
      setReadonly(!(
        data.status === TorStatusConstant.Draft ||
        data.status === TorStatusConstant.DraftTor ||
        data.status === TorStatusConstant.Rejected
      ));

      if (data.jorPorDirectors.length === 0) {
        getDefaultUserAsync();
      }

      if (data.jorPorDirectors) {
        setJorPorDirectors(data.jorPorDirectors);
      }

      if (data.jorPorDirectors) {
        if (data.jorPorDirectors.filter(x => x.delegateUserId === userId).length > 0) {
          setIsJorPorDirector(true);
        } else {
          setIsJorPorDirector(data.jorPorDirectors.filter(x => x.approverUserId === userId).length > 0);
        }
      }

      if (data.assignees) {
        setAssignees(data.assignees);
        if (data.assignees.filter(x => x.delegateUserId === userId).length > 0) {
          setIsAssignee(true);
        } else {
          setIsAssignee(data.assignees.filter(x => x.approverUserId === userId).length > 0);
        }
      }

      setTorData(data);

      if (data.documents
        && files.length === 0) {
        handleSetFile(data.documents);
      }

      if (data.id) {
        getDocumentTor(data.id);
      }

      let isDraftTor = false;

      if (data.appointPreProcurement) {
        setIsHasMiddlePrice(data.appointPreProcurement.planBudget > 100000);
        if (data.appointPreProcurement.committeeTors) {
          isDraftTor = data.appointPreProcurement.committeeTors.filter((x) => x.committeeUserId === userId).length > 0;
        }
      }

      if (data.isJorPorComment) {
        setIsJorPorComment(data.isJorPorComment);
      }

      setIsDraftTorPerson(isDraftTor);

      if (data.isJorPorComment) {
        if (data.responsibles) {
          setResponsibles(data.responsibles);
          if (data.responsibles.filter(x => x.delegateUserId === userId).length > 0) {
            setIsResponsible(true);
          } else {
            const isResp = data.responsibles.filter(x => x.approverUserId === userId).length > 0;
            setIsResponsible(isResp);
          }
        }
      } else {
        setIsResponsible(isDraftTor);
      }

      if (data.planBudget) {
        setIsHasMiddlePrice(data.planBudget > 100000);
      }

      let isApprover = false;
      let isCanApproveTor = false;
      let isCanApproveMid = false;
      let isCanRecall = true;

      if (data.committeeTorApprovals) {
        setCommitteeApprovers(data.committeeTorApprovals);
        isApprover = onCheckCanApprove(data.committeeTorApprovals);
        isCanApproveTor = isApprover;

        isCanRecall = !(data.committeeTorApprovals.filter(c => c.approverStatus === TorStatusConstant.Approved || c.approverStatus === TorStatusConstant.Rejected).length > 0);
      }

      if (data.committeeMiddlePriceApprovals) {
        setCommitteeMiddlePriceApprovers(data.committeeMiddlePriceApprovals);
        isApprover = onCheckCanApprove(data.committeeMiddlePriceApprovals);
        isCanApproveMid = isApprover;

        if (isCanRecall) {
          isCanRecall = !(data.committeeMiddlePriceApprovals.filter(c => c.approverStatus === TorStatusConstant.Approved || c.approverStatus === TorStatusConstant.Rejected).length > 0);
        }
      }

      let isSectionsApprove = false;

      if (data.committeeApproverTorSections) {
        onCheckApproverStatus();
        setApproverUsers(data.committeeApproverTorSections);

        const queueReCall = data.committeeApproverTorSections?.filter(a => a.status === AcceptorStatus.APPROVED).length === 0;

        if (queueReCall) {
          setIsApproverCanRecall(true);
        } else {
          setIsApproverCanRecall(false);
        }

        isSectionsApprove = onCheckSectionCanApprove(data.committeeApproverTorSections);

        const sectionDataGroup: sectionGroup[] = [];
        data.committeeApproverTorSections.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {
            sectionDataGroup.push({
              sectionName: item.sectionName,
              level: item.sectionLevel,
              sectionSequence: item.sectionSequence,
              inRefCode: item.inRefCode,
              sectionNameTh: item.sectionName,
              sectionId: item.sectionId,
            } as sectionGroup);
          });

        const uniqueNames = sectionDataGroup.filter((data, index) => {
          return sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index;
        });
        setSections(uniqueNames);
      }

      setIsSectionsApprover(isSectionsApprove);
      setIsQueueApprover(onCheckQueueSectionApprover(torData.committeeApproverTorSections, userId))

      if (data.appointPreProcurement) {
        if (data.appointPreProcurement.departmentId && data.financialAmount && (!sections || sections.length === 0) && (data.status === TorStatusConstant.DraftTor || data.status === TorStatusConstant.DraftSectionApprover)) {
          onGetDefaultApprover();
        }
      }

      setIsCanApproveTor(isCanApproveTor);
      setIsCanApproveMiddle(isCanApproveMid);
      setIsCanRecall(isCanRecall);

      setIsDisabled(
        !(
          data.status === TorStatusConstant.Draft ||
          data.status === TorStatusConstant.DraftTor ||
          data.status === TorStatusConstant.Rejected ||
          data.status === TorStatusConstant.WaitingForDirectorJorPorAssign ||
          data.status === TorStatusConstant.WaitingForAssignResponsible ||
          data.status === TorStatusConstant.DraftSectionApprover
        ));
    }
  }, [data]);

  const onCheckSectionCanApprove = (datas: SequenceApproverTorModel[]) => {
    if (torData.status === TorStatusConstant.WaitingForApprove) {
      if (datas.filter(d => d.status === TorStatusConstant.Rejected).length > 0) {
        return false;
      }

      const minSection = Math.min(...datas.filter(d => d.status === PreProcumentStatus.Pending && !d.isDisabled)
        .map(o => o.sectionSequence));

      const sectionDatas = datas.filter(a => a.status === PreProcumentStatus.Pending && a.sectionSequence === minSection && !a.isDisabled)
        .sort((a, b) => a.sequence - b.sequence);

      const checkApprover = onCheckApprover(data);
      setCheckApprover(checkApprover);

      if (sectionDatas && sectionDatas.length > 0) {
        return sectionDatas[0].approverUserId === userId;
      }
      return false;
    }

    return false;
  };

  const onCheckApprover = (data: TorDetailModel) => {

    const maxValueSectionSequence = Math.max.apply(null,
      data.committeeApproverTorSections?.map((x) => {
        return x.sectionSequence;
      }));

    const dataMaxValueSectionSequence = data.committeeApproverTorSections?.filter(a => a.sectionSequence === maxValueSectionSequence);

    const approverIndex = dataMaxValueSectionSequence?.findIndex(x => x.approverUserId === userId) + 1;

    const maxValue = Math.max.apply(null,
      dataMaxValueSectionSequence?.map((x) => {
        return x.sequence;
      }));

    if (approverIndex === maxValue) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (approverUsers && sections) {
      sections.forEach((section) => {
        if (approverUsers.filter((x) => x.sectionSequence === section.sectionSequence).length === 0) {
          setIsCanSendApprove(false);
        } else {
          setIsCanSendApprove(true);
        }
      });
    }

    if (sections) {
      let isApp = false;
      if (torData.committeeApproverTorSections) {
        isApp = onCheckSectionCanApprove(torData.committeeApproverTorSections);
      }
      setIsSectionsApprover(isApp);

      setIsQueueApprover(onCheckQueueSectionApprover(torData.committeeApproverTorSections, userId))

    }
  }, [approverUsers, sections]);

  useCallback(async (
    termOfRefId: string,
  ) => {
    const { data: docId, status } = await PreProcurementTorServices.createDocumentTorAsync(termOfRefId, isReadonlyDoc);

    if (status === HttpStatusCode.OK) {
      setData({ ...data, docId: docId });
    }
  }, []);

  const getDocumentTor = useCallback(async (
    termOfRefId: string,
  ) => {
    const {
      data: docId,
      status,
    } = await PreProcurementTorServices.createDocumentTorAsync(termOfRefId, isReadonlyDoc);

    if (status === HttpStatusCode.OK) {
      setTorDocId(docId);
    }
  }, []);

  const onCheckCanApprove = (datas: CommitteeApproverTorModel[]) => {
    if (datas && data.status === TorStatusConstant.WaitingForCommitteeApprove) {
      const pendings = datas?.filter((a) => a.isDisabled === false && a.approverStatus === "Pending");
      const president = pendings.filter((a) => a.approverPositionOnBoardId !== PositionOnBoardIdEnum.Chairman);

      if (president.length > 0) {
        return president.filter((a) => a.approverUserId === userId).length > 0;
      }

      return (pendings.filter((a) => a.approverPositionOnBoardId === PositionOnBoardIdEnum.Chairman && a.approverUserId === userId).length > 0);
    }
    return false;
  };

  const getPositionOnBoardAsync = async () => {
    const { data, status } = await shareValue.getPositionOnBoardListAsync('', SubGroup.PositionOnBoardCreator);

    if (status === HttpStatusCode.OK) {
      const positionOnBoards: ItemModel[] = data;
      setPositionOnBoardItems(positionOnBoards);
    }
  };

  const onSelectEmpApprover = (empId: string, name: string, position: string, department: string) => {
    if (committeeApprovers && committeeApprovers.filter((x) => x.approverUserId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const newData: CommitteeApproverTorModel = {
        id: undefined,
        approverUserId: empId,
        sequence: committeeApprovers.length + 1,
        approverFullName: name,
        position,
        approverPositionOnBoardId: positionOnBoardItems[0].value,
        approverPositionOnBoardName: positionOnBoardItems[0].label,
        isDisabled: false,
        remark: '',
        departmentName: department,
        approverStatus: '',
        approvedDateAt: undefined,
      };
      setCommitteeApprovers((committeeApprovers) => [...committeeApprovers, newData]);
    }
  };

  const handlerOnChangeCheckbox = (value: boolean, index: number) => {
    const data = [...committeeApprovers];
    data[index].isDisabled = value;

    if (!value) {
      data[index].remark = '';
    }

    setIsCheckBook(value)
    setCommitteeApprovers(data);
  };

  const handlerOnChangeRemark = (value: string, index: number) => {
    const data = [...committeeApprovers];
    data[index].remark = value;
    setCommitteeApprovers(data);
  };

  const onSelectEmpMiddlePriceApprover = (empId: string, name: string, position: string, department: string) => {
    if (committeeMiddlePriceApprovers && committeeMiddlePriceApprovers.filter((x) => x.approverUserId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const newData: CommitteeApproverTorModel = {
        id: undefined,
        approverUserId: empId,
        sequence: committeeMiddlePriceApprovers.length + 1,
        approverFullName: name,
        position,
        approverPositionOnBoardId: positionOnBoardItems[0].value,
        approverPositionOnBoardName: positionOnBoardItems[0].label,
        isDisabled: false,
        remark: '',
        departmentName: department,
        approverStatus: '',
      };
      setCommitteeMiddlePriceApprovers((committeeMiddlePriceApprovers) => [...committeeMiddlePriceApprovers, newData]);
    }
  };

  const handlerOnChangeMiddlePriceCheckbox = (value: boolean, index: number) => {
    const data = [...committeeMiddlePriceApprovers];
    data[index].isDisabled = value;
    setCommitteeMiddlePriceApprovers(data);
  };

  const handlerOnChangeMiddlePriceRemark = (value: string, index: number) => {
    const data = [...committeeMiddlePriceApprovers];
    data[index].remark = value;
    setCommitteeMiddlePriceApprovers(data);
  };

  const handleSetFile = (documents: TorDocumentModel[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const newFile: IFile = {
        id: document.id,
        name: document.fileName,
        file: null,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const handleFileChange = async (files: File[]) => {
    const newUpdFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const newFile: IFile = {
        id: '',
        name: file.name,
        file,
      };
      setFiles((pre) => [...pre, newFile]);
      newUpdFiles.push(file);
    }

    if (newUpdFiles && newUpdFiles.length > 0) {
      const res = await PreProcurementTorServices.onAttachmentTorDocumentByIdAsync(torData.id, newUpdFiles);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('อัปโหลดเอกสารแนบไม่สำเร็จ');
        return;
      }
      handleSetFile(res.data);
      toast.success('อัปโหลดเอกสารแนบสำเร็จ');
    }
  };

  const removeItemAsync = useCallback(async (index: number, docName: string) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.name === docName);
    const delId = newArray.filter((i) => i.name === docName)[0].id;
    newArray.splice(i, 1);
    setFiles(newArray);

    if (docName && delId) {
      const res = await PreProcurementTorServices.deleteTorDocumentByIdAsync(torData.id, delId);
      if (res.status !== HttpStatusCode.OK && res.status !== HttpStatusCode.NO_CONTENT) {
        toast.error('ลบเอกสารแนบไม่สำเร็จ');
        return;
      }
      toast.success('ลบเอกสารแนบสำเร็จ');
    }
  }, [files, torData.id]);

  const onOpenModal = (index: number) => {
    setShowModals((showModals) => {
      const newModal = [...showModals];
      newModal[index] = true;
      return newModal;
    });
  };

  const onHideModal = (index: number) => {
    setShowModals((showModals) => {
      const newModal = [...showModals];
      newModal[index] = false;
      return newModal;
    });
  };

  const onSave = async (isSendApprove: boolean) => {
    submitForm();

    collaboraRef.current?.clickSave();

    setTimeout(async () => {
      const step = (torData.step != null && torData.step > 2) ? torData.step : 3

      if (data.status !== TorStatusConstant.DraftSectionApprover && data.status !== TorStatusConstant.WaitingForDirectorJorPorAssign && data.status !== TorStatusConstant.WaitingForAssignResponsible) {

        if (!committeeApprovers || committeeApprovers.length === 0) {
          toast.warn('กรุณาระบุบุคคล/คณะกรรมการเห็นชอบ');
          return;
        }

        if (!committeeApprovers || committeeApprovers.filter(x => x.isDisabled === true && (x.remark === undefined || x.remark === null || x.remark === '')).length > 0) {
          toast.warn('กรุณาระบุหมายเหตุ');
          return;
        }

        // if (isHasMiddlePrice && (!committeeMiddlePriceApprovers || committeeMiddlePriceApprovers.length === 0)) {
        //   toast.warn('กรุณาระบุบุคคล/คณะกรรมการกำหนดราคากลาง');
        //   return;
        // }

        const saveApprovers: CommitteeApproverTorModel[] = [];
        const saveSectionApprovers: SequenceApproverTorModel[] = [];
        const saveMiddlePriceApprovers: CommitteeApproverTorModel[] = [];

        committeeApprovers.sort((a, b) => a.sequence - b.sequence)
          .forEach((data, index) => {
            const newData: CommitteeApproverTorModel = {
              id: data.id,
              approverUserId: data.approverUserId,
              sequence: index + 1,
              approverFullName: data.approverFullName,
              position: data.position,
              approverPositionOnBoardId: data.approverPositionOnBoardId,
              approverPositionOnBoardName: data.approverPositionOnBoardName,
              isDisabled: data.isDisabled,
              remark: data.remark,
              departmentName: data.departmentName,
              approverStatus: data.approverStatus,
              approvedDateAt: data.approvedDateAt,
            };

            saveApprovers.push(newData);
          });

        sections.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((section) => {
            approverUsers.filter((x) => x.inRefCode === section.inRefCode)
              .forEach((data, index) => {
                const newData: SequenceApproverTorModel = {
                  id: data.id,
                  sectionSequence: section.sectionSequence,
                  approverUserId: data.approverUserId,
                  sequence: index + 1,
                  approverFullName: data.approverFullName,
                  position: data.position,
                  approvedDateAt: data.approvedDateAt,
                  isDisabled: data.isDisabled,
                  remark: data.remark,
                  departmentName: data.departmentName,
                  status: data.status,
                  seqKey: data.seqKey,
                  processType: data.processType,
                  inRefCode: section.inRefCode,
                  sectionLevel: data.sectionLevel,
                  sectionId: data.sectionId,
                  sectionName: data.sectionName,
                  rejectedDateAt: data.rejectedDateAt,
                  approvedRemark: data.approvedRemark,
                  approvedUserBy: data.approvedUserBy,
                  delegateAnnotation: data.delegateAnnotation,
                  delegateDateRange: data.delegateDateRange,
                  delegateDepartmentName: data.delegateDepartmentName,
                  delegateEmployeeCode: data.delegateEmployeeCode,
                  delegateFullName: data.delegateFullName,
                  delegatePositionName: data.delegatePositionName,
                  delegateUserId: data.delegateUserId,
                  rejectedRemark: data.rejectedRemark,
                };

                saveSectionApprovers.push(newData);
              });
          });

        if (data.status === TorStatusConstant.DraftSectionApprover && (!saveSectionApprovers || saveSectionApprovers.length === 0)) {
          toast.warn('กรุณาเลือกผู้เห็นชอบ/อนุมัติ');
          return;
        }

        if (isHasMiddlePrice) {
          committeeMiddlePriceApprovers.sort((a, b) => a.sequence - b.sequence)
            .forEach((data, index) => {
              const newData: CommitteeApproverTorModel = {
                id: data.id,
                approverUserId: data.approverUserId,
                sequence: index + 1,
                approverFullName: data.approverFullName,
                position: data.position,
                approverPositionOnBoardId: data.approverPositionOnBoardId,
                approverPositionOnBoardName: data.approverPositionOnBoardName,
                isDisabled: data.isDisabled,
                remark: data.remark,
                departmentName: data.departmentName,
                approverStatus: data.approverStatus,
                approvedDateAt: data.approvedDateAt,
              };

              saveMiddlePriceApprovers.push(newData);
            });
        }

        const saveSectionApproverData = [...jorPorDirectors, ...saveSectionApprovers];
        const res = await PreProcurementTorServices.updateTorStep4Async(
          torData.id,
          torDocId,
          saveApprovers,
          saveMiddlePriceApprovers,
          saveSectionApproverData,
          isSendApprove,
          step,
          isJorPorComment);

        if (res.status !== HttpStatusCode.OK) {
          toast.error('บันทึกร่างขอบเขตของงาน (TOR) ไม่สำเร็จ');
          return;
        }

      } else {

        const saveApprovers: SequenceApproverTorModel[] = [];
        const saveAssignees: SequenceApproverTorModel[] = [];
        const saveResponsibles: SequenceApproverTorModel[] = [];

        assignees.sort((a, b) => a.sequence - b.sequence)
          .forEach((data, index) => {
            const newData: SequenceApproverTorModel = {
              id: data.id,
              sectionSequence: index + 1,
              approverUserId: data.approverUserId,
              sequence: index + 1,
              approverFullName: data.approverFullName,
              position: data.position,
              approvedDateAt: data.approvedDateAt,
              isDisabled: data.isDisabled,
              remark: data.remark,
              departmentName: data.departmentName,
              status: data.status,
              seqKey: data.seqKey,
              processType: data.processType,
              inRefCode: TorInRefCodeConstant.Assignee,
              sectionLevel: data.sectionLevel,
              sectionId: data.sectionId,
              sectionName: data.sectionName,
              rejectedDateAt: data.rejectedDateAt,
              approvedRemark: data.approvedRemark,
              approvedUserBy: data.approvedUserBy,
              delegateAnnotation: data.delegateAnnotation,
              delegateDateRange: data.delegateDateRange,
              delegateDepartmentName: data.delegateDepartmentName,
              delegateEmployeeCode: data.delegateEmployeeCode,
              delegateFullName: data.delegateFullName,
              delegatePositionName: data.delegatePositionName,
              delegateUserId: data.delegateUserId,
              rejectedRemark: data.rejectedRemark,
            };

            saveAssignees.push(newData);
          });

        responsibles.sort((a, b) => a.sequence - b.sequence)
          .forEach((data, index) => {
            const newData: SequenceApproverTorModel = {
              id: data.id,
              sectionSequence: index + 1,
              approverUserId: data.approverUserId,
              sequence: index + 1,
              approverFullName: data.approverFullName,
              position: data.position,
              approvedDateAt: data.approvedDateAt,
              isDisabled: data.isDisabled,
              remark: data.remark,
              departmentName: data.departmentName,
              status: data.status,
              seqKey: data.seqKey,
              processType: data.processType,
              inRefCode: TorInRefCodeConstant.Responsible,
              sectionLevel: data.sectionLevel,
              sectionId: data.sectionId,
              sectionName: data.sectionName,
              rejectedDateAt: data.rejectedDateAt,
              approvedRemark: data.approvedRemark,
              approvedUserBy: data.approvedUserBy,
              delegateAnnotation: data.delegateAnnotation,
              delegateDateRange: data.delegateDateRange,
              delegateDepartmentName: data.delegateDepartmentName,
              delegateEmployeeCode: data.delegateEmployeeCode,
              delegateFullName: data.delegateFullName,
              delegatePositionName: data.delegatePositionName,
              delegateUserId: data.delegateUserId,
              rejectedRemark: data.rejectedRemark,
            };

            saveResponsibles.push(newData);
          });

        sections.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((section) => {
            approverUsers.filter((x) => x.inRefCode === section.inRefCode)
              .forEach((data, index) => {
                const newData: SequenceApproverTorModel = {
                  id: data.id,
                  sectionSequence: section.sectionSequence,
                  approverUserId: data.approverUserId,
                  sequence: index + 1,
                  approverFullName: data.approverFullName,
                  position: data.position,
                  approvedDateAt: data.approvedDateAt,
                  isDisabled: data.isDisabled,
                  remark: data.remark,
                  departmentName: data.departmentName,
                  status: data.status,
                  seqKey: data.seqKey,
                  processType: data.processType,
                  inRefCode: section.inRefCode,
                  sectionLevel: data.sectionLevel,
                  sectionId: data.sectionId,
                  sectionName: data.sectionName,
                  rejectedDateAt: data.rejectedDateAt,
                  approvedRemark: data.approvedRemark,
                  approvedUserBy: data.approvedUserBy,
                  delegateAnnotation: data.delegateAnnotation,
                  delegateDateRange: data.delegateDateRange,
                  delegateDepartmentName: data.delegateDepartmentName,
                  delegateEmployeeCode: data.delegateEmployeeCode,
                  delegateFullName: data.delegateFullName,
                  delegatePositionName: data.delegatePositionName,
                  delegateUserId: data.delegateUserId,
                  rejectedRemark: data.rejectedRemark,
                };

                saveApprovers.push(newData);
              });
          });

        if (torData.status === TorStatusConstant.WaitingForDirectorJorPorAssign) {
          if (!saveAssignees || saveAssignees.length === 0) {
            toast.warn('กรุณาเลือกผู้ได้รับมอบหมาย');
            return;
          }
        } else if (torData.status === TorStatusConstant.WaitingForAssignResponsible) {
          if (!saveResponsibles || saveResponsibles.length === 0) {
            toast.warn('กรุณาเลือกผู้รับผิดชอบ');
            return;
          }
        } else if (torData.status === TorStatusConstant.DraftSectionApprover) {
          if (!torData.commentDescription && data.isJorPorComment) {
            toast.warn('กรุณาระบุความเห็น จพ.');
            return;
          }

          if (!saveApprovers || saveApprovers.length === 0) {
            toast.warn('กรุณาเลือกผู้เห็นชอบ/อนุมัติ');
            return;
          }
        }

        const saveDatas = [...torData.jorPorDirectors, ...saveAssignees, ...saveResponsibles, ...saveApprovers];
        const res = await PreProcurementTorServices.updateTorStep5Async(torData.id, torDocId, saveDatas, isSendApprove, torData.commentDescription, step);

        if (res.status !== HttpStatusCode.CREATED) {
          toast.error('บันทึกร่างขอบเขตของงาน (TOR) ไม่สำเร็จ');

          return;
        }
      }

      await getTorByIdAsync(torData.id);
      if (isSendApprove) {
        toast.success('ส่งเห็นชอบสำเร็จ');
      } else {
        toast.success('บันทึกร่างขอบเขตของงาน (TOR) สำเร็จ');
      }
      navigate(`${currentPath}/${torData.id}`);
      setShowWaitingForApprove(false);
      setShowConfirmAssign(false);
    }, 500);
  };

  const getTorByIdAsync = async (id: string) => {
    const response = await PreProcurementTorServices.getTorByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
    }
  };

  const getReturnTorByIdAsync = async (id: string) => {
    const response = await PreProcurementTorServices.getTorByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      setData(response.data);
      return response.data as TorDetailModel;
    }

    return {} as TorDetailModel;
  };

  const getTorDocumentsByIdAsync = async (id: string) => {
    const response = await PreProcurementTorServices.getTorByIdAsync(id);

    if (response.status === HttpStatusCode.OK) {
      if (response.data.documents) {
        setData({ ...data, documents: response.data.documents });
      }
    }
  };

  const onApprove = async () => {
    collaboraRef.current?.clickSave();
    setTimeout(async () => {

      if (data.status === TorStatusConstant.WaitingForCommitteeApprove) {
        if (isCanApproveTor) {
          const res = await PreProcurementTorServices.onCommiteeApproveAsync(torData.id, false, approveRemark);
          if (res.status !== HttpStatusCode.OK) {
            toast.error('เห็นชอบไม่สำเร็จ');
            return;
          }
        }

        if (isCanApproveMiddle) {
          const res = await PreProcurementTorServices.onCommiteeApproveAsync(torData.id, true, approveRemark);
          if (res.status !== HttpStatusCode.OK) {
            toast.error('เห็นชอบไม่สำเร็จ');
            return;
          }
        }
        setApproveRemark('');
        const resData = await getReturnTorByIdAsync(torData.id);
        toast.success('เห็นชอบสำเร็จ');
      } else if (data.status === TorStatusConstant.WaitingForApprove) {
        if (torData.isCancel && torData.isActive) {
          const res = await PreProcurementTorServices.onSectionApproveCancelingAsync(torData.id, approveRemark);
          if (res.status !== HttpStatusCode.OK) {
            toast.error(`${checkApprover ? 'อนุมัติไม่สำเร็จ' : 'เห็นชอบไม่สำเร็จ'}`);
            setShowApprove(false);
            return;
          }
          getTorByIdAsync(torData.id);
          toast.success(`${checkApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
          setShowApprove(false);
        } else if (torData.isChange && torData.isActive) {
          const res = await PreProcurementTorServices.onSectionApproveEditingAsync(torData.id, approveRemark);
          if (res.status !== HttpStatusCode.OK) {
            toast.error(`${checkApprover ? 'อนุมัติไม่สำเร็จ' : 'เห็นชอบไม่สำเร็จ'}`);
            setShowApprove(false);
            return;
          }

          getTorByIdAsync(torData.id);
          toast.success(`${checkApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
          setShowApprove(false);
        } else {
          const res = await PreProcurementTorServices.onSectionApproveAsync(torData.id, approveRemark);
          if (res.status !== HttpStatusCode.OK) {
            toast.error(`${checkApprover ? 'อนุมัติไม่สำเร็จ' : 'เห็นชอบไม่สำเร็จ'}`);
            setShowApprove(false);
            return;
          }

          setApproveRemark('');
          getTorByIdAsync(torData.id);
          toast.success(`${checkApprover ? 'อนุมัติสำเร็จ' : 'เห็นชอบสำเร็จ'}`);
          setShowApprove(false);
          navigate(`${currentPath}/${torData.id}`);
        }
      }
      navigate(`${currentPath}/${torData.id}`);
      setShowApprove(false);
      setShowApproveMiddle(false);
    }, 500);
  };

  const onReject = async () => {
    submitForm();
    if (!rejectRemark || rejectRemark.length === 0) {
      toast.warn('กรุณาระบุหมายเหตุ');
      return;
    }

    if (data.status === TorStatusConstant.WaitingForCommitteeApprove) {
      const res = await PreProcurementTorServices.onCommiteeRejectAsync(torData.id, rejectRemark, isCanApproveTor ? false : isCanApproveMiddle);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
        return;
      }
    } else if (data.status === TorStatusConstant.WaitingForDirectorJorPorAssign) {
      const res = await PreProcurementTorServices.onJorPorDirectorRejectedAsync(torData.id, rejectRemark);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
        return;
      }
    } else {
      const res = await PreProcurementTorServices.onSectionRejectAsync(torData.id, rejectRemark);

      if (res.status !== HttpStatusCode.OK) {
        toast.error('ส่งกลับแก้ไขไม่สำเร็จ');
        return;
      }
    }
    setRejectRemark('');
    setShowReject(false);
    await getTorByIdAsync(torData.id);
    toast.success('ส่งกลับแก้ไขสำเร็จ');
    navigate(`${currentPath}/${torData.id}`);
    window.location.reload();
  };

  const onRecall = async () => {
    const res = await PreProcurementTorServices.onRecallAsync(torData.id);

    if (res.status !== HttpStatusCode.OK) {
      toast.error('เรียกคืนแก้ไขไม่สำเร็จ');
      return;
    }

    await getTorByIdAsync(torData.id);
    toast.success('เรียกคืนแก้ไขสำเร็จ');
    navigate(`${currentPath}/${torData.id}`);
    setShowRecall(false);
  };

  const onGetDefaultApprover = async () => {

    const processType = data.isConsumableMaterials ? PROCESSTYPE.ApproveTorProcessStock : PROCESSTYPE.ApproveTorProcessNonStock;

    const response = await account
      .getDefaultApproverByTopLeve(processType, data.planBudget, data.supplyMethod, data.ignorePurchaseOnTable159, departmentCode);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: SequenceApproverTorModel[] = [];
      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            sectionNameTh: item.sectionName,
            sectionId: item.sectionId,
          } as sectionGroup);

          approver.push({
            approverUserId: item.userId, approverFullName: item.fullName, departmentName: item.departmentName, position: item.positionName, sequence: item.sequence, sectionSequence: item.sectionSequence, inRefCode: item.inRefCode, processType: item.processType, seqKey: '', remark: '', isDisabled: false, approvedDateAt: undefined, status: AcceptorStatus.DRAFT, sectionLevel: item.sectionLevel, delegateFullName: item.delegateFullName, delegatePositionName: item.delegatePositionName, delegateUserId: item.delegateUserId, delegateDepartmentName: item.delegateDepartmentName, delegateEmployeeCode: item.delegateEmployeeCode,
          } as SequenceApproverTorModel);
        });

      const uniqueNames = sectionDataGroup.filter((data, index) => {
        return sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index;
      });

      if (data.committeeApproverTorSections === undefined || data.committeeApproverTorSections === null || data.committeeApproverTorSections.length === 0) {

        let inx = 0;
        let sectionSeq = 0;

        approver?.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {

            if (sectionSeq !== item.sectionSequence) {
              inx = 1;
              sectionSeq = item.sectionSequence;
            }

            approver[index].sequence = inx;

            inx++;
          });

        setApproverUsers(approver);
        setSections(uniqueNames);

        if (approver.filter(x => x.inRefCode === SectionType.BP001 || x.inRefCode === SectionType.BP002).length > 0) {
          setIsJorPorComment(true);
        } else {
          setIsJorPorComment(false);
        }
      }
    }
  };

  const handlerSetDefaultApproverAsync = async () => {
    const approver: SequenceApproverTorModel[] = [];
    const sectionDataGroup: sectionGroup[] = [];

    setApproverUsers(approver);
    setSections(sectionDataGroup);
    setDefaultApproverAsync();
  }

  const setDefaultApproverAsync = async () => {

    const processType = data.isConsumableMaterials ? PROCESSTYPE.ApproveTorProcessStock : PROCESSTYPE.ApproveTorProcessNonStock;

    const response = await account
      .getDefaultApproverByTopLeve(processType, data.planBudget, data.supplyMethod, data.ignorePurchaseOnTable159, departmentCode);

    if (response.status === HttpStatusCode.OK || response.status === HttpStatusCode.ACCEPTED) {
      const newDatas = response.data as sectionApproverModel[];
      const sectionDataGroup: sectionGroup[] = [];
      const approver: SequenceApproverTorModel[] = [];

      newDatas.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {
          sectionDataGroup.push({
            sectionName: item.sectionName,
            level: item.sectionLevel,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            sectionNameTh: item.sectionName,
            sectionId: item.sectionId,
          } as sectionGroup);

          approver.push({
            approverUserId: item.userId,
            approverFullName: item.fullName,
            departmentName: item.departmentName,
            position: item.positionName,
            sequence: item.sequence,
            sectionSequence: item.sectionSequence,
            inRefCode: item.inRefCode,
            processType: item.processType,
            seqKey: '',
            remark: '',
            isDisabled: false,
            approvedDateAt: undefined,
            status: AcceptorStatus.DRAFT,
            sectionLevel: item.sectionLevel,
            delegateFullName: item.delegateFullName,
            delegatePositionName: item.delegatePositionName,
            delegateUserId: item.delegateUserId,
            delegateDepartmentName: item.delegateDepartmentName,
            delegateEmployeeCode: item.delegateEmployeeCode,
          } as SequenceApproverTorModel);
        });

      const uniqueNames = sectionDataGroup.filter((data, index) => {
        return sectionDataGroup.findIndex(d => d.inRefCode === data.inRefCode) === index;
      });

      let inx = 0;
      let sectionSeq = 0;

      approver?.sort((a, b) => a.sectionSequence - b.sectionSequence)
        .forEach((item, index) => {

          if (sectionSeq !== item.sectionSequence) {
            inx = 1;
            sectionSeq = item.sectionSequence;
          }

          approver[index].sequence = inx;

          inx++;
        });

      setApproverUsers(approver);
      setSections(uniqueNames);
      setShowDefaultApproverModal(false);
      toast.success('กำหนดผู้มีอำนาจเห็นชอบตั้งต้น สำเร็จ');
    } else {
      toast.warn('กำหนดผู้มีอำนาจเห็นชอบตั้งต้น ไม่สำเร็จ');
    }
  };

  const onSelectSectionEmpApprover = (empId: string, name: string, position: string, department: string, index: number, sectionSequence?: number, inRefCode?: string) => {
    if (approverUsers && approverUsers.filter((x) => x.inRefCode === inRefCode && x.approverUserId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {
      const newData: SequenceApproverTorModel = {
        id: undefined,
        sectionSequence: sectionSequence!,
        approverUserId: empId,
        sequence: approverUsers.filter((x) => x.inRefCode === inRefCode).length + 1,
        approverFullName: name,
        position,
        approvedDateAt: undefined,
        isDisabled: false,
        remark: '',
        departmentName: department,
        status: AcceptorStatus.DRAFT,
        inRefCode: inRefCode!,
        processType: '',
        sectionLevel: undefined,
        sectionId: '',
        sectionName: '',
        seqKey: `${sectionSequence?.toString()}${(approverUsers.filter((x) => x.inRefCode === inRefCode).length + 1).toString()}`,
        approvedUserBy: undefined,
        approvedRemark: undefined,
        rejectedUserBy: undefined,
        rejectedDateAt: undefined,
        rejectedRemark: undefined,
      };
      setApproverUsers((approverUsers) => [...approverUsers, newData]);
    }
  };

  const handlerRemoveApproverUser = (seqKey: string) => {
    const temp: SequenceApproverTorModel[] = [...approverUsers];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    setApproverUsers(temp);
  };

  const handlerRemoveAssignee = (index: number) => {
    const temp: SequenceApproverTorModel[] = [...assignees];
    temp.splice(index, 1);
    setAssignees(temp);
  };

  const handlerRemoveResponsible = (index: number) => {
    const temp: SequenceApproverTorModel[] = [...responsibles];
    temp.splice(index, 1);
    setResponsibles(temp);
  };

  const onSelectAssignee = (empId: string, name: string, position: string, department: string, sectionSequence: number, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
    delegatePositionName?: string, delegateAnnotation?: string, delegateDateRange?: string) => {
    if (assignees && assignees.filter(x => x.approverUserId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผอ.จพ. มอบหมายซ้ำ');
    } else {

      const newData: SequenceApproverTorModel = {
        id: undefined,
        approverFullName: name,
        approverUserId: empId,
        departmentName: department,
        position: position,
        sectionSequence: sectionSequence,
        sequence: assignees.filter((x) => x.sectionSequence === sectionSequence).length + 1,
        approvedDateAt: undefined,
        isDisabled: false,
        remark: '',
        status: '',
        inRefCode: undefined!,
        processType: '',
        sectionLevel: undefined,
        sectionId: '',
        sectionName: '',
        seqKey: `${sectionSequence?.toString()}${(approverUsers.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        approvedUserBy: undefined,
        approvedRemark: undefined,
        rejectedUserBy: undefined,
        rejectedDateAt: undefined,
        rejectedRemark: undefined,
        delegateFullName: delegateFullName,
        delegateUserId: delegateUserId,
        delegatePositionName: delegatePositionName,
        delegateAnnotation: delegateAnnotation,
        delegateDateRange: delegateDateRange,
        delegateDepartmentName: delegateDepartmentName,
        delegateEmployeeCode: delegateEmployeeCode,
      };

      setAssignees((assignees) => [...assignees, newData]);
    }
  };

  const onSelectResponsible = (empId: string, name: string, position: string, department: string, sectionSequence: number, delegateUserId?: string, delegateFullName?: string, delegateEmployeeCode?: string, delegateDepartmentName?: string,
    delegatePositionName?: string, delegateAnnotation?: string, delegateDateRange?: string) => {

    if (responsibles && responsibles.filter(x => x.approverUserId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้รับผิดชอบซ้ำ');
    } else {

      const newData: SequenceApproverTorModel = {
        id: undefined,
        approverFullName: name,
        approverUserId: empId,
        departmentName: department,
        position: position,
        sectionSequence: sectionSequence,
        sequence: responsibles.filter((x) => x.sectionSequence === sectionSequence).length + 1,
        approvedDateAt: undefined,
        isDisabled: false,
        remark: '',
        status: '',
        inRefCode: undefined!,
        processType: '',
        sectionLevel: undefined,
        sectionId: '',
        sectionName: '',
        seqKey: `${sectionSequence?.toString()}${(approverUsers.filter((x) => x.sectionSequence === sectionSequence).length + 1).toString()}`,
        approvedUserBy: undefined,
        approvedRemark: undefined,
        rejectedUserBy: undefined,
        rejectedDateAt: undefined,
        rejectedRemark: undefined,
        delegateFullName: delegateFullName,
        delegateUserId: delegateUserId,
        delegatePositionName: delegatePositionName,
        delegateAnnotation: delegateAnnotation,
        delegateDateRange: delegateDateRange,
        delegateDepartmentName: delegateDepartmentName,
        delegateEmployeeCode: delegateEmployeeCode,
      };

      setResponsibles((responsibles) => [...responsibles, newData]);
    }
  };

  const isReadonlyDoc = useMemo(() => {
    return readonly || data.isCancel ||
      !(
        data.status === TorStatusConstant.Draft ||
        data.status === TorStatusConstant.DraftTor ||
        data.status === TorStatusConstant.Rejected ||
        data.status === TorStatusConstant.WaitingForDirectorJorPorAssign ||
        data.status === TorStatusConstant.WaitingForAssignResponsible ||
        data.status === TorStatusConstant.DraftSectionApprover
      );
  }, [data.status]);

  const onCancelingOrEditing = async (type: PreProcumentFlag) => {
    submitForm();
    if (!remark || remark.length === 0) {
      return;
    }

    if (type === PreProcumentFlag.Cancel) {
      const res = await PreProcurementTorServices.onSectiTermofrefCancelingAsync(torData.id, remark);

      if (res.status === HttpStatusCode.CREATED) {
        const newId: string = res.data.id;
        await getTorByIdAsync(newId);
        toast.success('บันทึกขอยกเลิกสำเร็จ');
        navigate(`/cancel-preprocurement-tor/detail/${newId}`);
      } else {
        toast.warn('บันทึกขอยกเลิกไม่สำเร็จ');
      }
    } else if (type === PreProcumentFlag.Editing) {
      const res = await PreProcurementTorServices.onSectiTermofrefEditingAsync(torData.id, remark);

      if (res.status === HttpStatusCode.CREATED) {
        const newId: string = res.data.id;
        await getTorByIdAsync(newId);
        toast.success('บันทึกขอแก้ไขสำเร็จ');
        navigate(`/edit-preprocurement-tor/detail/${newId}`);
      } else {
        toast.warn('บันทึกขอยกเลิกไม่สำเร็จ');
      }
    }

    setShowEditModal(false);
    setShowCancelModal(false);
  };

  return (
    <div className='document'>
      <Card className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <Title
              text='บุคคล/คณะกรรมการเห็นชอบ'
              className='fs-5 text-primary'
            />
            {openCollapse2 ? (
              <ArrowCollapse onClick={() => setOpenCollapse2(!openCollapse2)} openCollapse={openCollapse2} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse2(true)} openCollapse={openCollapse2} />)}
          </div>
          <div className='d-flex gap-2'>
            {
              torData.isInEditing && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-warning' /></div>
                    {TorEditCancelStatusConstant.Editing}</span>
                </div>
              )
            }
            {
              torData.isInCanceling && (
                <div className='status'
                  style={{ marginRight: 10 }}>
                  <span><div><FaCircle style={{ fontSize: 10 }}
                    className='text-danger' /></div>
                    {TorEditCancelStatusConstant.Canceling}</span>
                </div>
              )
            }
            <TorStatus
              value={torData.status}
            />
            <Button
              onClick={() => setShowHistory(!showHistory)}
              variant='outline-primary'
            >
              <FaHistory className='me-1' />
              ประวัติการใช้งาน
            </Button>
          </div>
        </div>
        <Collapse in={openCollapse2}>
          <div>
            <Row className='mt-3 align-items-center'>
              <Col sm={12}>
                {/* <div className="d-flex justify-content-between my-3">
                  <p>บุคคล/คณะกรรมการเห็นชอบ</p>
                  <Button
                    variant="outline-primary"
                    onClick={() => setShowCommitteesApprovers(true)}
                    disabled={isDisabled}
                  >
                    <FaPlus />เพิ่มรายชื่อ
                  </Button>
                </div> */}
                <Table total={1}
                  hidePagination>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>ลำดับ</th>
                      <th style={{ width: 200 }}>ชื่อ-นามสกุล</th>
                      <th style={{ width: 200 }}>ฝ่าย/สำนัก</th>
                      <th style={{ width: 200 }}>ตำแหน่ง</th>
                      <th style={{ width: 200 }}>ตำแหน่งในคณะกรรมการ</th>
                      <th style={{ width: 200 }}>สถานะ</th>
                      <th style={{ width: 200 }}>วันที่เห็นชอบ</th>
                      <th style={{ width: 200 }}>ไม่สามารถปฏิบัติงานได้</th>
                      <th style={{ width: 200 }}>หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {committeeApprovers.sort((a, b) => a.sequence - b.sequence)
                      .map((data, index) => (
                        <tr className='text-center'
                          key={index}>
                          <td>{index + 1}</td>
                          <td className='text-start'>{data.approverFullName}</td>
                          <td className='text-start'>{data.departmentName}</td>
                          <td className='text-start'>{data.position}</td>
                          <td className='text-center'>
                            <Selector
                              className='primary'
                              items={positionOnBoardItems}
                              value={data.approverPositionOnBoardId}
                              disabled
                            />
                          </td>
                          <td>
                            {
                              !(data.isDisabled) &&
                              <TorStatusCommitteeApprovers value={data.approverStatus} />
                            }
                          </td>
                          <td>{data.approvedDateAt ? thaiFormatDateWithSlash(data.approvedDateAt) : ''}</td>
                          <td>
                            {(data.approverPositionOnBoardId !== PositionOnBoardIdEnum.Chairman) && (
                              <Check
                                className='d-inline-block'
                                value={data.isDisabled}
                                onChange={(val) => handlerOnChangeCheckbox(val, index)}
                                disabled={data.approverUserId === userId
                                  || data.approverStatus === TorStatusConstant.Approved
                                  || (readonly && torData.status !== TorStatusConstant.WaitingForCommitteeApprove)}
                              />
                            )}
                          </td>
                          <td>
                            {(data.approverPositionOnBoardId !== PositionOnBoardIdEnum.Chairman) && (
                              <Input
                                value={data.remark}
                                onChange={(val) => handlerOnChangeRemark(val, index)}
                                rule={{ required: data.isDisabled }}
                                disabled={!data.isDisabled
                                  || (readonly && torData.status !== TorStatusConstant.WaitingForCommitteeApprove)}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Card>
      {
        (((torData.status === TorStatusConstant.WaitingForDirectorJorPorAssign)
          || (torData.status === TorStatusConstant.WaitingForAssignResponsible)
          || (torData.status === TorStatusConstant.DraftSectionApprover)
          || (torData.status === TorStatusConstant.WaitingForApprove)
          || (torData.status === TorStatusConstant.Approved)
          || (torData.status === TorStatusConstant.Cancelled)) && data.isJorPorComment) && (
          <>
            <Card className='mt-3'>
              <div className='d-flex mb-4 align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Title text='ผอ.จพ.'
                    className='fs-5 text-primary' />
                  {openCollapse1 ? (
                    <ArrowCollapse onClick={() => setOpenCollapse1(!openCollapse1)} openCollapse={openCollapse1} />
                  ) : (
                    <ArrowCollapse onClick={() => setOpenCollapse1(true)} openCollapse={openCollapse1} />)}
                </div>
              </div>
              <Collapse in={openCollapse1}
                className='mt-3 px-2'>
                <div>
                  <Table
                    hidePagination
                    className='mt-3 step-bar'
                    notResponse
                  >
                    <thead>
                      <tr>
                        <th style={{ width: '5%' }}>ลำดับ</th>
                        <th className='text-start'
                          style={{ width: '30%' }}>ชื่อ-นามสกุล
                        </th>
                        <th className='text-start'
                          style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                        </th>
                        <th style={{ minHeight: 100, width: '25%' }} />
                      </tr>
                    </thead>
                    <tbody>
                      {torData.jorPorDirectors?.sort((a, b) => a.sequence - b.sequence)
                        .map((data, i) => (
                          <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                            key={data.id}>
                            <td className='text-center'>{i + 1}</td>
                            <td>
                              {data.approverFullName}
                              <p className='m-0 department text-wrap'>
                                {data.position}
                              </p>
                            </td>
                            <td className='text-left'>
                              {data.delegateFullName &&
                                <>
                                  {data.delegateFullName}
                                  <p className='m-0 department text-wrap'>
                                    {data.delegatePositionName}
                                  </p>
                                </>
                              }
                            </td>
                            <td />
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </Card>
            <Card className='mt-3'>
              <div className='d-flex mb-4 align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <Title text='ผอ.จพ. มอบหมาย'
                    className='fs-5 text-primary' />
                  {openCollapse6 ? (
                    <ArrowCollapse onClick={() => setOpenCollapse6(!openCollapse6)} openCollapse={openCollapse6} />
                  ) : (
                    <ArrowCollapse onClick={() => setOpenCollapse6(true)} openCollapse={openCollapse6} />)}
                </div>
              </div>
              <Collapse in={openCollapse6}
                className='mt-3 px-2'>
                <div>
                  {
                    (isJorPorDirector && torData.status === TorStatusConstant.WaitingForDirectorJorPorAssign) && (
                      <div className='d-flex justify-content-end'>
                        <Button
                          className='d-flex align-items-center gap-2'
                          variant='outline-primary'
                          onClick={() => setShowAssigneeModal(true)}
                          disabled={isDisabled}>
                          <FaPlus />เพิ่มรายชื่อ
                        </Button>
                      </div>
                    )
                  }
                  <Table
                    hidePagination
                    className='mt-3 step-bar'
                    notResponse
                  >
                    <thead>
                      <tr>
                        <th style={{ width: '5%' }}>ลำดับ</th>
                        <th className='text-start'
                          style={{ width: '30%' }}>ชื่อ-นามสกุล
                        </th>
                        <th className='text-start'
                          style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                        </th>
                        <th style={{ minHeight: 100, width: '25%' }} />
                      </tr>
                    </thead>
                    <tbody>
                      {assignees?.sort((a, b) => a.sequence - b.sequence)
                        .map((data, i) => (
                          <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                            key={data.id}>
                            <td className='text-center'>{i + 1}</td>
                            <td>
                              {data.approverFullName}
                              <p className='m-0 department'>
                                {data.position}
                              </p>
                            </td>
                            <td className='text-left'>
                              {data.delegateFullName &&
                                <>
                                  {data.delegateFullName}
                                  <p className='m-0 department'>
                                    {data.delegatePositionName}
                                  </p>
                                </>
                              }
                            </td>
                            <td className='text-end'>
                              {((torData.status === TorStatusConstant.WaitingForDirectorJorPorAssign) && isJorPorDirector) &&
                                <Button
                                  variant='danger'
                                  onClick={() => handlerRemoveAssignee(i)}
                                >
                                  <FaTrashAlt />
                                </Button>}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </Card>
            {
              (torData.status === TorStatusConstant.WaitingForAssignResponsible
                || torData.status === TorStatusConstant.DraftSectionApprover
                || torData.status === TorStatusConstant.WaitingForApprove
                || torData.status === TorStatusConstant.Approved
                || torData.status === TorStatusConstant.Cancelled) && (
                <>
                  <Card className='mt-3'>
                    <>
                      <div className='d-flex mb-4 align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                          <Title text='ผู้รับผิดชอบ'
                            className='fs-5 text-primary' />
                          {openCollapse3 ? (
                            <ArrowCollapse onClick={() => setOpenCollapse3(!openCollapse3)} openCollapse={openCollapse3} />
                          ) : (
                            <ArrowCollapse onClick={() => setOpenCollapse3(true)} openCollapse={openCollapse3} />)}
                        </div>
                      </div>
                      <Collapse in={openCollapse3}
                        className='mt-3 px-2'>
                        <div>
                          <div className='d-flex justify-content-end'>
                            {
                              (isAssignee && torData.status === TorStatusConstant.WaitingForAssignResponsible) && (
                                <Button
                                  className='d-flex align-items-center gap-2'
                                  variant='outline-primary'
                                  onClick={() => setShowResponsibleModal(true)}
                                >
                                  <FaPlus />เพิ่มรายชื่อ
                                </Button>
                              )
                            }
                          </div>
                          <Table
                            hidePagination
                            className='mt-3 step-bar'
                            notResponse
                          >
                            <thead>
                              <tr>
                                <th style={{ width: '5%' }}>ลำดับ</th>
                                <th className='text-start'
                                  style={{ width: '30%' }}>ชื่อ-นามสกุล
                                </th>
                                <th className='text-start'
                                  style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                                </th>
                                <th style={{ minHeight: 100, width: '25%' }} />
                              </tr>
                            </thead>
                            <tbody>
                              {responsibles?.sort((a, b) => a.sequence - b.sequence)
                                .map((data, i) => (
                                  <tr style={{ borderBottom: '1pt solid #E4E4E4' }}
                                    key={data.id}>
                                    <td className='text-center'>{i + 1}</td>
                                    <td>
                                      {data.approverFullName}
                                      <p className='m-0 department'>
                                        {data.position}
                                      </p>
                                    </td>
                                    <td className='text-left'>
                                      {data.delegateFullName &&
                                        <>
                                          {data.delegateFullName}
                                          <p className='m-0 department'>
                                            {data.delegatePositionName}
                                          </p>
                                        </>
                                      }
                                    </td>
                                    <td className='text-end'>
                                      {((torData.status === TorStatusConstant.WaitingForAssignResponsible) && isAssignee) &&
                                        <Button
                                          variant='danger'
                                          onClick={() => handlerRemoveResponsible(i)}
                                        >
                                          <FaTrashAlt />
                                        </Button>}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </Collapse>
                    </>
                  </Card>
                  <Card className='mt-3'>
                    <div className='d-flex mb-4 align-items-center justify-content-between'>
                      <div className='d-flex align-items-center'>
                        <Title text='ความเห็น จพ.'
                          className='fs-5 text-primary' />
                        {openCollapse4 ? (
                          <ArrowCollapse onClick={() => setOpenCollapse4(!openCollapse4)} openCollapse={openCollapse4} />
                        ) : (
                          <ArrowCollapse onClick={() => setOpenCollapse4(true)} openCollapse={openCollapse4} />)}
                      </div>
                    </div>
                    <Collapse in={openCollapse4}
                      className='mt-3'>
                      {/* <div >
                        <Row className='justify-content-center'>
                          <Col sm={12}
                            lg={12}> */}
                      <InputTextArea
                        label='ความเห็นเพิ่มเติม'
                        value={torData.commentDescription}
                        onChange={val => setTorData({ ...torData, commentDescription: val })}
                        rule={{ required: torData.status === TorStatusConstant.DraftSectionApprover }}
                        disabled={torData.status !== TorStatusConstant.DraftSectionApprover || !isResponsible} />
                      {/* </Col>
                        </Row>
                      </div> */}
                    </Collapse>
                  </Card>
                </>
              )
            }
          </>
        )
      }
      {
        (torData.status === TorStatusConstant.DraftSectionApprover
          || torData.status === TorStatusConstant.WaitingForApprove
          || torData.status === TorStatusConstant.Approved
          || torData.status === TorStatusConstant.Cancelled) && (
          <Card className='mt-3'>
            <div className='d-flex mb-4 align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <Title text='ผู้มีอำนาจเห็นชอบ/อนุมัติ'
                  className='fs-5 text-primary' />
                {openCollapse5 ? (
                  <ArrowCollapse onClick={() => setOpenCollapse5(!openCollapse5)} openCollapse={openCollapse5} />
                ) : (
                  <ArrowCollapse onClick={() => setOpenCollapse5(true)} openCollapse={openCollapse5} />)}
              </div>
            </div>
            <Collapse in={openCollapse5}>
              <Row>
                <div>
                  {((torData.status === TorStatusConstant.DraftSectionApprover) && isResponsible)
                    ? (
                      <Button
                        className='d-flex align-items-center gap-2'
                        variant='outline-primary'
                        onClick={() => {
                          setShowDefaultApproverModal(true);
                        }}
                      >
                        <FaRedo />กำหนดผู้มีอำนาจเห็นชอบเริ่มต้น
                      </Button>
                    ) : null}
                </div>
                {
                  sections.length > 0 ? (
                    sections
                      .sort((a, b) => a.sectionSequence - b.sectionSequence)
                      .map((section, index) => (
                        <Col sm={12}
                          key={index}>
                          {
                            ((torData.status === TorStatusConstant.DraftSectionApprover) && isResponsible) && (
                              <div className='d-flex justify-content-between mb-1'>
                                <p className='fs-5 text-primary'>
                                  {/* {section.sectionName} */}
                                </p>
                                <Button
                                  variant='outline-primary'
                                  onClick={() => onOpenModal(index)}
                                >
                                  <FaPlus className='me-1' /> เพิ่มรายชื่อ
                                </Button>
                              </div>
                            )
                          }
                          <Table
                            hidePagination
                            className='mt-3 step-bar'
                            notResponse
                          >
                            <thead>
                              <tr>
                                <th style={{ width: '5%' }}>ลำดับ</th>
                                <th className='text-start'
                                  style={{ width: '30%' }}>ชื่อ-นามสกุล
                                </th>
                                <th className='text-start'
                                  style={{ width: '30%' }}>ผู้ปฏิบัติหน้าที่แทน
                                </th>
                                <th style={{ width: '10%' }}>สถานะ</th>
                                <th style={{ width: '10%' }}>วันที่เห็นชอบ</th>
                                <th style={{ minHeight: 100, width: '5%' }} />
                              </tr>
                            </thead>
                            <tbody>
                              {
                                (approverUsers && approverUsers.length > 0) && (
                                  approverUsers
                                    .filter((x) => x.inRefCode === section.inRefCode)
                                    .sort((a, b) => a.sequence - b.sequence)
                                    .map((data, i) => (
                                      <>
                                        <tr key={i}>
                                          <td className='text-center'>{i + 1}</td>
                                          <td className='text-left'>
                                            {data.approverFullName}
                                            <p className='m-0 department'>
                                              {data.position}
                                            </p>
                                          </td>
                                          <td className='text-left'>
                                            {data.delegateFullName &&
                                              <>
                                                {data.delegateFullName}
                                                <p className='m-0 department'>
                                                  {data.delegatePositionName}
                                                </p>
                                              </>
                                            }
                                          </td>
                                          <td className='text-center'>
                                            {isSectionSequence === data.sectionSequence && isApproverStatus === i + 1
                                              ? <Approver value={data.status}
                                                outline />
                                              : <ApproverStatus value={data.status}
                                                outline />}
                                          </td>
                                          <td className='text-center'>
                                            {
                                              (data.status === TorStatusConstant.Approved) && (
                                                <>
                                                  {data.approvedDateAt ? fullDatetime(data.approvedDateAt) : ''}
                                                </>
                                              )
                                            }
                                          </td>
                                          <td className='text-end'>
                                            {((torData.status === TorStatusConstant.DraftSectionApprover) && isResponsible) &&
                                              <Button
                                                variant='danger'
                                                onClick={() => handlerRemoveApproverUser(`${data.sectionSequence}${data.sequence}`)}
                                              >
                                                <FaTrashAlt />
                                              </Button>}
                                          </td>
                                        </tr>
                                        {data.delegateDateRange || data.approvedRemark || data.rejectedRemark ?
                                          <tr className='border-bottom bg-opacity-10 bg-light'>
                                            <td />
                                            <td colSpan={5}
                                              className='p-0'>
                                              <ul className='step-bar-list'>
                                                {data.delegateDateRange &&
                                                  <li>
                                                    <div className='description'>
                                                      <p className='mb-0 text-primary'>มอบหมายให้ปฏิบัติหน้าที่แทน</p>
                                                      <p className='mb-0'
                                                        style={{ color: 'gray' }}>
                                                        {data.delegateDateRange}
                                                      </p>
                                                    </div>
                                                  </li>
                                                }
                                                {data.approvedRemark &&
                                                  <li>
                                                    <div className='description'>
                                                      <p className='mb-0 text-primary'>หมายเหตุ{isSectionSequence === data.sectionSequence && isApproverStatus === i + 1 ? 'อนุมัติ' : 'เห็นชอบ'}
                                                      </p>
                                                      <p className='mb-0 text-wrap'
                                                        style={{ color: 'gray' }}>
                                                        <span className='text-muted me-2'>[{data.approvedDateAt ? fullDatetime(data.approvedDateAt) : ''}]</span>
                                                        {data.approvedUserBy} {data.approvedRemark}
                                                      </p>
                                                    </div>
                                                  </li>
                                                }
                                                {data.rejectedRemark &&
                                                  <li>
                                                    <div className='description'>
                                                      <p className='mb-0 text-primary'>หมายเหตุส่งกลับแก้ไข</p>
                                                      <p className='mb-0 text-wrap'
                                                        style={{ color: 'gray' }}>
                                                        <span className='text-muted me-2'>[{data.rejectedDateAt ? fullDatetime(data.rejectedDateAt) : ''}]</span>
                                                        {data.rejectedUserBy} {data.rejectedRemark}
                                                      </p>
                                                    </div>
                                                  </li>
                                                }
                                              </ul>
                                            </td>
                                          </tr>
                                          : <tr className='border-bottom' />}
                                      </>
                                    ))
                                )
                              }
                            </tbody>
                          </Table>
                          <SearchBuNameModal
                            total={userTotal}
                            show={showModals[index]}
                            onHide={() => onHideModal(index)}
                            onSelect={(id, name, department, position) => onSelectSectionEmpApprover(id, name, position!, department!, index, section.sectionSequence, section.inRefCode)}
                            data={users}
                            onSearch={onSearchUserAsync}
                            onPageChange={onPageChangeAsync}
                            departmentCode={departmentCode}
                            departmentDisabled={departmentCode !== JorPorCode.CODE}
                          />
                        </Col>
                      ))
                  ) : (
                    <Col>
                      <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
                    </Col>
                  )
                }
              </Row>
            </Collapse>
          </Card >
        )
      }
      <Card className='mt-3'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            <Title
              text='ตัวอย่างเอกสาร'
              className='fs-5 text-primary'
            />
            {openCollapse ? (
              <ArrowCollapse onClick={() => setOpenCollapse(!openCollapse)} openCollapse={openCollapse} />
            ) : (
              <ArrowCollapse onClick={() => setOpenCollapse(true)} openCollapse={openCollapse} />)}
          </div>
        </div>

        <Collapse in={openCollapse}>
          <div className='mt-3'>
            <Collabora
              docId={torDocId}
              docName='doc-01'
              ref={collaboraRef}
              readonly={isReadonlyDoc} />
          </div>
        </Collapse>
      </Card>

      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>
            <UploadFile
              files={files}
              handleFileChange={handleFileChange}
              removeItem={removeItemAsync}
              torId={torData.id}
              disabled={readonly || !isDraftTorPerson}
            />
          </Col>
        </Row>
      </Card>

      <div className='d-flex justify-content-between pt-3'>
        <ButtonCustom
          onClick={onClickBack}
          text="ย้อนกลับ"
        />
        <div>
          {
            (isDraftTorPerson
              && ((torData.status === TorStatusConstant.WaitingForCommitteeApprove
                && isCanRecall) || (isApproverCanRecall && torData.status === TorStatusConstant.WaitingForApprove))) && (
              <ButtonCustom
                icon='undo'
                variant='danger'
                onClick={() => setShowRecall(true)}
                text="เรียกคืนแก้ไข"
              />
            )
          }
          {
            (((isCanApproveTor || isCanApproveMiddle) && torData.status === TorStatusConstant.WaitingForCommitteeApprove)
              || (isQueueApprover && torData.status === TorStatusConstant.WaitingForApprove)
              || (isJorPorDirector && torData.status === TorStatusConstant.WaitingForDirectorJorPorAssign)) && (
              <ButtonCustom
                icon='undo'
                variant='danger'
                onClick={() => setShowReject(true)}
                text="ส่งกลับแก้ไข"
              />
            )
          }
          {
            (((torData.status === TorStatusConstant.Draft
              || torData.status === TorStatusConstant.DraftTor
              || torData.status === TorStatusConstant.Rejected
              || torData.status === TorStatusConstant.WaitingForCommitteeApprove) && isDraftTorPerson)
              || ((torData.status === TorStatusConstant.DraftSectionApprover && isResponsible)
                || (torData.status === TorStatusConstant.WaitingForDirectorJorPorAssign && isJorPorDirector)
                || (torData.status === TorStatusConstant.WaitingForAssignResponsible && isAssignee)))
            && (
              <ButtonCustom
                onClick={() => onSave(false)}
                text="บันทึก"
              />
            )
          }
          {
            ((torData.status === TorStatusConstant.WaitingForDirectorJorPorAssign && isJorPorDirector)
              || (torData.status === TorStatusConstant.WaitingForAssignResponsible && isAssignee)) && (
              <ButtonCustom
                onClick={() => setShowConfirmAssign(true)}
                text='มอบหมาย'
              />
            )
          }
          {
            (((torData.status === TorStatusConstant.Draft || torData.status === TorStatusConstant.DraftTor || torData.status === TorStatusConstant.Rejected)
              && isDraftTorPerson
              && committeeApprovers.length > 0)
              || (torData.status === TorStatusConstant.DraftSectionApprover
                && isResponsible)
            ) && (
              <ButtonCustom
                icon='checked'
                onClick={() => setShowWaitingForApprove(true)}
                text={torData.status === TorStatusConstant.DraftSectionApprover ? "ส่งอนุมัติ/เห็นชอบ" : "ส่งเห็นชอบ"}
              />
            )
          }
          {
            (((isCanApproveTor) && torData.status === TorStatusConstant.WaitingForCommitteeApprove)
              || (isQueueApprover && torData.status === TorStatusConstant.WaitingForApprove)) && (
              <ButtonCustom
                icon='checked'
                onClick={() => setShowApprove(true)}
                variant='success'
                text={checkApprover ? 'อนุมัติ' : 'เห็นชอบ'} />
            )
          }
          {
            (torData.status === TorStatusConstant.Approved && isDraftTorPerson) && (
              <>
                <ButtonCustom
                  onClick={() => setShowEditModal(true)}
                  disabled={torData.isInEditing || torData.isInCanceling}
                  variant='success'
                  text="บันทึกขอแก้ไขร่างขอบเขตของงาน (TOR)"
                  icon='save'
                />
                <ButtonCustom
                  onClick={() => setShowCancelModal(true)}
                  variant='danger'
                  icon='cancel'
                  disabled={torData.isInEditing || torData.isInCanceling}
                  text="บันทึกขอยกเลิกร่างขอบเขตของงาน (TOR)"
                />
              </>
            )
          }
        </div>
        <div style={{ width: 120 }} />
        {/* {
          (torData.status !== TorStatusConstant.Draft
            && torData.status !== TorStatusConstant.DraftTor
            && torData.status !== TorStatusConstant.Rejected
            && torData.status !== TorStatusConstant.WaitingForCommitteeApprove) ? (
            <ButtonCustom
              onClick={onClickNext}
              text="ถัดไป"
            />
          ) : (<div style={{ width: 120 }} />)
        } */}
      </div>
      <HistoryModal
        title='ประวัติการใช้งานแบบร่างขอบเขตของงาน (TOR)'
        show={showHistory}
        onHide={() => setShowHistory(!showHistory)}
        torNumber={torData.torNumber}
      />
      <Modal
        show={showReject}
        size='lg'
        onHide={() => setShowReject(!showReject)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ส่งกลับแก้ไข</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRejectRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowReject(!showReject)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                variant='danger'
                onClick={() => onReject()}
              >
                <FaUndo className='mx-2 pb-1 fs-4' />
                ส่งกลับแก้ไข
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <UserApproveModal
        show={showCommitteesApprovers}
        onHide={() => setShowCommitteesApprovers(!showCommitteesApprovers)}
        onSelectItem={onSelectEmpApprover}
      />
      <UserApproveModal
        show={showCommitteesMiddlePriceApprovers}
        onHide={() => setShowCommitteesMiddlePriceApprovers(!showCommitteesMiddlePriceApprovers)}
        onSelectItem={onSelectEmpMiddlePriceApprover}
      />
      <Modal
        show={showWaitingForApprove}
        size='lg'
        onHide={() => setShowWaitingForApprove(!showWaitingForApprove)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              {torData.status === TorStatusConstant.DraftSectionApprover ? 'คุณต้องการส่งอนุมัติ/เห็นชอบหรือไม่ ?' : 'คุณต้องการส่งเห็นชอบหรือไม่ ?'}
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowWaitingForApprove(!showWaitingForApprove)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showApprove}
        size='lg'
        onHide={() => setShowApprove(!showApprove)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>{checkApprover ? "อนุมัติ" : "เห็นชอบ"}</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: false }}
                onChange={(val) => setApproveRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowApprove(!showApprove)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onApprove()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      {/* <Modal
        show={showApproveMiddle}
        size="lg"
        onHide={() => setShowApproveMiddle(!showApproveMiddle)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className="p-0 ps-4">
              คุณต้องการอนุมัติหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant="outline-primary"
                onClick={() => setShowApproveMiddle(!showApproveMiddle)}>
                ยกเลิก
              </Button>
              <Button
                variant="primary"
                onClick={() => onApprove(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      /> */}
      <Modal
        show={showRecall}
        size='lg'
        onHide={() => setShowRecall(!showRecall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowRecall(!showRecall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={onRecall}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showConfirmAssign}
        size='lg'
        onHide={() => setShowConfirmAssign(!showConfirmAssign)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการมอบหมายงาน?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowConfirmAssign(!showConfirmAssign)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSave(true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showDefaultApproverModal}
        size='lg'
        onHide={() => setShowDefaultApproverModal(!showDefaultApproverModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการกำหนดผู้มีอำนาจเห็นชอบเริ่มต้นใหม่หรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowDefaultApproverModal(!showDefaultApproverModal)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => handlerSetDefaultApproverAsync()}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showEditModal}
        size='lg'
        onHide={() => setShowEditModal(!showEditModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ขอแก้ไขร่างขอบเขตของงาน (TOR) และกำหนดราคากลาง</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowEditModal(!showEditModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                onClick={() => onCancelingOrEditing(PreProcumentFlag.Editing)}
              >
                <BsCheckCircle className='mx-2 pb-1 fs-4' />
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showCancelModal}
        size='lg'
        onHide={() => setShowCancelModal(!showCancelModal)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ขอยกเลิกร่างขอบเขตของงาน (TOR) และกำหนดราคากลาง</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              <InputTextArea label='หมายเหตุ'
                rule={{ required: true }}
                onChange={(val) => setRemark(val)} />
            </ModalBT.Body>
            <ModalBT.Footer className='justify-content-end'>
              <Button
                variant='light'
                onClick={() => setShowCancelModal(!showCancelModal)}
                className='me-2 px-3'
              >
                ยกเลิก
              </Button>
              <Button
                className='me-2 px-3'
                onClick={() => onCancelingOrEditing(PreProcumentFlag.Cancel)}
              >
                <BsCheckCircle className='mx-2 pb-1 fs-4' />
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <SearchBuNameModal
        total={userTotal}
        show={showAssigneeModal}
        onHide={() => setShowAssigneeModal(!showAssigneeModal)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange) => onSelectAssignee(id, name, position!, department!, assignees.length + 1, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />

      <SearchBuNameModal
        total={userTotal}
        show={showResponsibleModal}
        onHide={() => setShowResponsibleModal(!showResponsibleModal)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange) => onSelectResponsible(id, name, position!, department!, responsibles.length + 1, delegateUserId, delegateFullName, delegateEmployeeCode, delegateDepartmentName, delegatePositionName, delegateAnnotation, delegateDateRange)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
    </div>
  );
}

export default Step3;
