import {
  Card,
  FileValue,
  SupplyMethodEnum,
  UploadFile,
} from 'components';

import Title from 'components/Controls/Title';
import {
  HistoryModal,
  Modal,
  SearchBuNameModal,
  SearchNameModal,
} from 'components/Modal';
import {
  Acceptor,
  IFile,
  JorPor05Document,
  JorPorO5,
  UserListSelectionByCodeParams,
  UserListSelectionParams,
  UserListSelectionResponse,
} from 'models';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
  Modal as ModalBT,
} from 'react-bootstrap';
import {
  FaHistory,
  FaPlus,
  FaSave,
  FaTrashAlt,
  FaUndo,
  FaCheckCircle,
  FaRegCheckCircle,
} from 'react-icons/fa';
import {
  MdArrowBack,
  MdArrowForward,
} from 'react-icons/md';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  account,
  jorPor05,
  shareValue,
} from 'services';
import {
  downloadFile,
  HttpStatusCode,
  ProcurementSection,
  showConfirmModalAsync,
  thaiFormatDateWithSlash,
  useAppContext,
} from 'utils';
import { PROCESSTYPE } from 'utils/constants/ProcessTypeEnum';
import toast from 'utils/toast';
import {
  AcceptorStatus,
  ActionModal,
  CommitteeType,
  Context,
  CriteriaModal,
  DataModal,
  JorPor05Status,
  Pagination,
  Section,
  Status,
  UserResponse,
} from './Detail';
import { UserApproveModal } from './UserApproveModal';
import { JorPorCode } from 'constant/basicInformation';
import { Acceptors } from 'components/Table/Acceptors';
import { isNull, nullObject } from '../../../utils/helper';
import Collabora, { CollaboraRef } from 'components/Document/Collabora';
import { TypeStatus } from 'components/StatusDetail';

interface Props {
  onClickNext: () => void;
  onClickBack: () => void;
}

interface ModalData {
  show: boolean;
  section?: Section;
  status?: AcceptorStatus;
  accecptorId?: string;
}

type sectionGroup = {
  sectionId: string;
  sectionName: string;
  level: number;
  sectionSequence: number;
  inRefCode: string;
  sectionNameTh: string;
  sectionCode: string;
  sectionType: string;
}

export default function Step2({ onClickNext, onClickBack }: Props) {
  const context = useContext(Context);
  const { userId, departmentCode } = useAppContext();
  const [showSearchUserModal, setShowUserModal] = useState<{ show: boolean, section?: Section }>({ show: false });
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [criteriaModal, setCriteriaModal] = useState<CriteriaModal>({} as CriteriaModal);
  const [userDataModal, setUserDataModal] = useState<DataModal>({} as DataModal);
  const [paginationModal, setPaginationModal] = useState<Pagination>({ size: 10, page: 1 });
  const [modalData, setModalData] = useState<ModalData>({ accecptorId: context.data.acceptorId, show: false });
  const location = useLocation();
  const navigate = useNavigate();
  // const [showModals, setShowModals] = useState<boolean[]>([]);
  const [showModals, setShowModals] = useState(false);
  const [sections, setSections] = useState<sectionGroup[]>([]);
  const [isApproverStatus, setIsApproverStatus] = useState(0);
  const [isSectionSequence, setIsSectionSequence] = useState(0);
  const [userTotal, setUserTotal] = useState<number>(0);
  const [users, setUsers] = useState<UserListSelectionResponse[]>([]);
  const [sectionType, setSectionType] = useState<string>();
  const [sectionSequence, setSectionSequence] = useState<number>();
  const [isQueueApprover, setIsQueueApprover] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [showReCall, setShowReCall] = useState(false);
  const [docId, setDocId] = useState('');
  const [docOrderId, setDocOrderId] = useState('');
  const collaboraRef = useRef<CollaboraRef>(null);
  const collaboraRefOrder = useRef<CollaboraRef>(null);
  const [showModalPending, setShowModalPending] = useState(false);
  const [files, setFiles] = useState<FileValue[]>([]);
  const [acceptor, setAcceptor] = useState<Acceptor[]>(context.data.acceptors);

  const onCheckQueueApprover = (data: JorPorO5) => {

    const maxValueSectionSequence = Math.max.apply(null,
      data.acceptors?.map((x) => {
        return x.sectionSequence;
      }));

    const maxValueSequence = Math.max.apply(null,
      data.acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence)?.map((x) => {
        return x.sequence;
      }));

    const dataMaxValueSectionSequence = data.acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence && a.sequence === maxValueSequence);

    const approverIndex = dataMaxValueSectionSequence?.findIndex(x => x.userId === userId || x.delegateUserId === userId) + 1;

    if (approverIndex) {
      return true;
    }

    return false;
  };

  const getDocument = useCallback(
    async (id: string) => {
      const { data: docId, status } =
        await jorPor05.getDocumentAsync(id, isDisableForm);

      if (status === HttpStatusCode.OK) {
        setDocId(docId);
      }
    }, []);

  const getDocumentOrder = useCallback(
    async (id: string) => {
      const { data: docId, status } =
        await jorPor05.getDocumentOrderAsync(id, isDisableForm);

      if (status === HttpStatusCode.OK) {
        setDocOrderId(docId);
      }
    }, []);

  useEffect(() => {
    if (context.data.acceptors) {
      const isApprover = onCheckQueueApprover(context.data);
      setIsApprover(isApprover);
      setAcceptor(context.data.acceptors);
      const queueApprover = context.data.acceptors?.filter((a) => a.status !== AcceptorStatus.APPROVE)
        .sort((a, b) => a.sequence - b.sequence)[0];

      if (queueApprover) {
        setIsQueueApprover(isNull(queueApprover.delegateUserId, queueApprover.userId) === userId);
      }
    }

    getDocument(context.data.id);
    getDocumentOrder(context.data.id);

    if (context.data.documents
      && files.length === 0) {
      handleSetFile(context.data.documents);
    }

  }, [context.data]);

  useEffect(() => {
    if (departmentCode !== JorPorCode.CODE && departmentCode !== undefined) {
      getUserData(undefined, departmentCode, undefined);
    } else {
      getUserData();
    }
  }, [departmentCode]);

  const getUserData = async (fullName?: string, departmentCode?: string, positionId?: string, positionName?: string, page = 1, size = 10) => {
    const search: UserListSelectionByCodeParams = { fullName, departmentCode, positionId, positionName } as UserListSelectionByCodeParams;
    const { data, status } = await shareValue.getUserListSelectionForAcceptorAsync(page, size, search);

    if (status === HttpStatusCode.OK) {
      setUsers(data.data);
      setUserTotal(data.totalRecords);
    }
  };

  const onPageChangeAsync = async (size: number, page: number, params?: UserListSelectionByCodeParams) => {
    await getUserData(params?.fullName, params?.departmentCode, params?.positionId, params?.positionName, page, size);
  };

  const onSearchUserAsync = async (searchData: UserListSelectionByCodeParams) => {
    await getUserData(searchData.fullName, searchData.departmentCode, searchData.positionId, searchData.positionName);
  };

  const onCheckApproverStatus = () => {
    const maxValueSectionSequence = Math.max.apply(null,
      context.data.acceptors?.map((x) => x.sectionSequence));

    const valueSequence = context.data.acceptors?.filter(a => a.sectionSequence === maxValueSectionSequence).length;

    setIsApproverStatus(valueSequence);
    setIsSectionSequence(maxValueSectionSequence);
  };

  useEffect(() => {
    onCheckApproverStatus();

    // if (!context.data.acceptors || context.data.acceptors.length === 0) {
    //   onGetDefaultApprover();
    // } else {
    //   const sectionDataGroup: sectionGroup[] = [];
    //   context.data.acceptors.sort((a, b) => a.sectionSequence - b.sectionSequence)
    //     .forEach((item, index) => {
    //       sectionDataGroup.push({
    //         sectionName: item.sectionName,
    //         level: item.sectionLevel,
    //         sectionSequence: item.sectionSequence,
    //         inRefCode: item.inRefCode,
    //         sectionNameTh: item.sectionName,
    //         sectionId: item.sectionId,
    //       } as sectionGroup);
    //     });

    //   const uniqueNames = sectionDataGroup.filter((data, index) => sectionDataGroup.findIndex(d => d.sectionSequence === data.sectionSequence) === index);
    //   setSections(uniqueNames);
    // }

  }, []);

  const isDisabled = useMemo(() => {
    if (context.data.isAssign && (context.data.status === Status.DRAFT
      || context.data.status === Status.REJECT)) {
      return false;
    }

    return true;
  }, [context.data]);

  const removeApprover = (seqKey: string) => {
    const temp: Acceptor[] = [...context.data.acceptors];
    const index = temp.findIndex((x) => `${x.sectionSequence}${x.sequence}` === seqKey);
    temp.splice(index, 1);
    context.setData({
      ...context.data,
      acceptors: temp,
    });
  };

  const onSelectEmpApprover = (empId: string, name: string, department: string, position: string, index: number, sectionSequence?: number, inRefCode?: string, sectionCode?: string, delegateUserId?: string, delegateFullName?: string, delegateDepartmentName?: string, delegatePositionName?: string) => {
    if (acceptor && acceptor.filter((x) => x.inRefCode === inRefCode && x.userId === empId).length > 0) {
      toast.warn('ไม่สามารถเลือกผู้ลงนามซ้ำ');
    } else {

      const newData: Acceptor[] = acceptor ? [...acceptor] : [];

      const acceptorBySection = acceptor.filter((x) => x.sectionSequence === sectionSequence);

      const maxValueSequence = acceptorBySection.length > 0 ? Math.max.apply(null,
        acceptorBySection.map((x) => {
          return x.sequence;
        })) : 0;

      newData.push({
        sectionCode: ProcurementSection.APPROVER,
        userId: empId,
        fullName: name,
        departmentName: department,
        positionName: position,
        sequence: maxValueSequence + 1,
        inRefCode: inRefCode!,
        processType: '',
        status: AcceptorStatus.DRAFT,
        sectionLevel: undefined,
        sectionName: '',
        sectionSequence: sectionSequence!,
        sectionId: '',
        sectionType: inRefCode,
        delegateDepartmentName: delegateDepartmentName,
        delegateUserId: delegateUserId,
        delegateFullName: delegateFullName,
        delegatePositionName: delegatePositionName,
      } as Acceptor);

      setAcceptor(newData);
    }
  };

  const onModalHide = () => {
    setShowUserModal({ show: false });
    setCriteriaModal({} as CriteriaModal);
    setPaginationModal({ size: 10, page: 1 });
  };

  const onModalSearch = (params: UserListSelectionParams) => {
    setCriteriaModal({
      fullName: params.fullName,
      departmentId: params.departmentId,
      positionId: params.positionId,
    });

    getUserAsync(
      paginationModal.size,
      paginationModal.page,
      params.fullName,
      params.departmentId,
      params.positionId,
    );
  };

  const getUserAsync = async (
    size: number,
    page: number,
    name?: string,
    departmentId?: string,
    positionId?: string,
  ) => {
    const res = await account.getUsersOnModalAsync(
      size,
      page,
      name,
      departmentId,
      positionId,
    );

    if (res.status === HttpStatusCode.OK) {
      const data: UserListSelectionResponse[] = res.data.data.map((user: UserResponse) => ({
        id: user.id,
        fullName: user.name,
        departmentName: user.department,
        positionName: user.position,
      }));

      setUserDataModal({
        data,
        totalRecords: res.data.totalRecords,
      });
    }
  };

  const onModalPageChange = async (size: number, page: number) => {
    setPaginationModal({ size, page });

    getUserAsync(
      size,
      page,
      criteriaModal.fullName,
      criteriaModal.departmentId,
      criteriaModal.positionId,
    );
  };

  const onModalSelect = (userId: string, section?: string) => {
    if (acceptor?.some(r => r.userId === userId && r.sectionCode === section)) {
      toast.warn('รายชื่อนี้ถูกเลือกแล้ว');

      return;
    }

    const user = userDataModal.data.find(u => u.id === userId);

    if (user) {
      if (!acceptor) {
        context.data.acceptors = [];
      }

      const newData: Acceptor[] = acceptor ? [...acceptor] : [];

      newData.push({
        userId: user.id,
        fullName: user.fullName,
        positionName: user.positionName,
        status: AcceptorStatus.PENDING,
        sectionCode: showSearchUserModal.section,
      } as Acceptor);

      setAcceptor(newData)

    }
  };

  const onShowSearchUserModalAsync = async (section: Section) => {
    await getUserAsync(paginationModal.size, paginationModal.page);

    setShowUserModal({
      show: true,
      section,
    });
  };

  const removeUser = (acceptorId: string, i: number, section: Section, userId: string) => {

    const index = context.data.acceptors.findIndex(c => (c.id === acceptorId || c.userId === userId)
      && c.sectionCode === section);
    if (index >= 0) {
      context.data.acceptors.splice(index, 1);

      context.setData({
        ...context.data,
        acceptors: [
          ...context.data.acceptors,
        ],
      });
    }

  };

  const handleSetFile = (documents: JorPor05Document[]) => {
    setFiles([]);
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];

      const newFile: FileValue = {
        id: document.id,
        name: document.fileName,
      };

      setFiles((pre) => [...pre, newFile]);
    }
  };

  const uploadFileOnChangeAsync = async (file: File) => {
    if (context.data.id) {
      const res = await jorPor05.uploadFileAsync(
        context.data.id,
        file,
      );

      if (res.status === HttpStatusCode.ACCEPTED) {

        handleSetFile(res.data);
        toast.success('อัปโหลดไฟล์สำเร็จ');
      }
    } else {
      if (!context.data.documents) {
        context.data.documents = [];
      }

      handleSetFile(context.data.documents);
    }
  };

  const removeFileAsync = async (index: number, id: string | undefined) => {
    const newArray = [...files];
    const i = newArray.findIndex((i) => i.id === id);
    newArray.splice(i, 1);
    setFiles(newArray);

    if (id) {
      const res = await jorPor05.removeFileAsync(context.data.id, id);

      if (res.status === HttpStatusCode.NO_CONTENT) {
        toast.success('ลบไฟล์สำเร็จ');
      }
    }
  };

  const downloadFileAsync = async (index: number, documentId?: string) => {
    if (documentId) {
      const res = await jorPor05
        .downloadFileAsync(context.data.id, documentId);

      if (res.status === HttpStatusCode.OK) {
        const file = context.data.documents[index];

        downloadFile(res.data);
      }
    } else {
      const file = context.data.documents[index];

      if (file.file) {
        downloadFile(file.file);
      }
    }
  };

  const onSubmitAsync = async (status: Status, isRecall?: boolean) => {
    const checkRequest = context.data.supplyMethod
      && context.data.supplyMethodType
      && context.data.supplyMethodSpecialType
      && context.data.financialAmount
      && context.data.prNumber
      && ((!context.isAmountOver && context.data.considerationDescription)
        || (context.isAmountOver && context.data.considerationSelect))
      && context.data.selectionCriteria;

    const isCommitteeError = context.data.committees?.some(t => !t.positionOnBoardId);
    if (!checkRequest || isCommitteeError) {
      toast.warn('กรุณากรอกข้อมูลให้ครบถ้วน');

      return;
    }

    if (status === Status.WAITING_ACCEPT) {
      const isProcurementExist = context.data.committees?.filter(
        c => c.committeeType === CommitteeType.PROCUREMENT).length > 0;

      if (!isProcurementExist) {
        toast.warn('กรุณาเพิ่มผู้จัดซื้อจัดจ้าง/คณะกรรมการจัดซื้อขอจ้างอย่างน้อย 1 คน');

        return;
      }

      const IsInspectExist = context.data.committees?.filter(
        c => c.committeeType === CommitteeType.INSPECT).length > 0;

      if (!IsInspectExist) {
        toast.warn('กรุณาเพิ่มรายชื่อคณะกรรมการตรวจรับ/ผู้ตรวจรับพัสดุอย่างน้อย 1 คน');

        return;
      }

      if (acceptor.length === 0) {
        toast.warn('ต้องมีผู้มีอำนาจเห็นชอบ (ผอ.จพ.) ที่สามารถปฏิบัติงานอย่างน้อย 1 คน');
        return;
      }

      setShowModalPending(false);
    }

    collaboraRef.current?.clickSave();
    collaboraRefOrder.current?.clickSave();

    setTimeout(async () => {
      let data = {
        ...context.data,
        // acceptors,
        status,
      } as JorPorO5;

      if (context.isAmountOver) {
        data = { ...data, considerationDescription: '' };
      } else {
        data = { ...data, considerationSelect: '' };
      }

      if (context.data.id) {
        if (status) {
          data.status = status;
        }

        const saveAcceptor: Acceptor[] = [];

        acceptor?.sort((a, b) => a.sectionSequence - b.sectionSequence)
          .forEach((item, index) => {
            const newData: Acceptor = {
              id: item.id,
              jorPor05Id: item.jorPor05Id,
              sectionCode: item.sectionCode,
              userId: item.userId,
              fullName: item.fullName,
              departmentName: item.departmentName,
              positionName: item.positionName,
              isAvailable: item.isAvailable,
              availableRemark: item.availableRemark,
              sequence: item.sequence,
              status: item.status,
              inRefCode: item.inRefCode,
              processType: item.processType,
              sectionLevel: item.sectionLevel,
              sectionName: item.sectionName,
              sectionSequence: item.sectionSequence,
              sectionId: item.sectionId,
              sectionType: item.sectionType,
              delegateDepartmentName: item.delegateDepartmentName,
              delegateEmployeeCode: item.delegateEmployeeCode,
              delegateFullName: item.delegateFullName,
              delegatePositionName: item.delegatePositionName,
              delegateUserId: item.delegateUserId,
            };

            saveAcceptor.push(newData);
          });

        if (saveAcceptor) {
          data = { ...data, acceptors: saveAcceptor };
        }

        data.documentId = docId;
        data.documentOrderId = docOrderId;

        const res = await jorPor05.updateAsync(data);

        if (res.status === HttpStatusCode.ACCEPTED) {
          if (isRecall) {
            toast.success('เรียกคืนแก้ไขสำเร็จ');
          } else {
            toast.success('บันทึกข้อมูลสำเร็จ');
          }
          setShowReCall(false);
          context.getDetailAsync(res.data.id);

          navigate(`${location.pathname}`);
        }
      } else {
        const res = await jorPor05.saveAsync(data);

        if (res.status === HttpStatusCode.CREATED) {
          toast.success('บันทึกข้อมูลสำเร็จ');
          navigate(`${location.pathname}`);

          context.getDetailAsync(res.data.id);
        }
      }

      getDocument(context.data.id);
      getDocumentOrder(context.data.id);
    }, 500);
  };

  // const jorPorSupervisorUserList = useMemo(() => {
  //   return context.data.acceptors?.filter(c => c.sectionCode === Section.JORPOR_SUPERVISOR_USER);
  // }, [context.data.acceptors]);

  // const jorPorAssistantPrincipleUserList = useMemo(() => {
  //   return context.data.acceptors?.filter(c => c.sectionCode === Section.JORPOR_ASSISTANT_PRINCIPLE_USER);
  // }, [context.data.acceptors]);

  // const jorPorPrincipleUserList = useMemo(() => {
  //   return context.data.acceptors?.filter(c => c.sectionCode === Section.JORPOR_PRINCIPLE_USER);
  // }, [context.data.acceptors]);

  const onChange = (acceptorId: string, key: string, value: boolean | string) => {
    const index = context.data.acceptors.findIndex(a => a.id === acceptorId);
    if (index >= 0) {
      if (key === 'isAvailable') {
        context.data.acceptors[index].isAvailable = Boolean(value);
      }

      if (key === 'availableRemark') {
        context.data.acceptors[index].availableRemark = value.toString();
      }

      context.setData({
        ...context.data,
        [key]: value,
      });
    }
  };

  // const showActionModal = (status: AcceptorStatus) => {
  //   if (context.data.acceptors.some(a => a.userId === userId)) {
  //     const acceptor = context.data.acceptors.find(a => a.userId === userId);

  //     if (acceptor) {
  //       setModalData({
  //         show: true,
  //         section: acceptor.sectionCode as Section,
  //         status,
  //         accecptorId: context.data.acceptorId,
  //       });
  //     }
  //   }
  // };

  const showActionModal = (status: AcceptorStatus) => {
    collaboraRef.current?.clickSave();
    collaboraRefOrder.current?.clickSave();

    setTimeout(async () => {
      if (context.data.acceptors.some(a => a.userId === userId || a.delegateUserId === userId)) {
        const acceptor = context.data.acceptors.find(a => isNull(a.delegateUserId, a.userId) === userId);

        if (acceptor) {
          setModalData({
            show: true,
            section: acceptor.sectionCode as Section,
            status,
            accecptorId: acceptor.id,
          });
        }
      }
    }, 500);
  };

  const isDisableForm = useMemo(() => {
    if (!context.data.isAssign) {
      return true;
    }

    if (context.data.status !== Status.DRAFT
      && context.data.status !== Status.REJECT) {
      return true;
    }

    return false;
  }, [context.data]);

  const isAddUserShow = useMemo(() => {
    if (!context.data.isAssign) {
      return false;
    }

    if (context.data.status !== Status.DRAFT
      && context.data.status !== Status.REJECT) {
      return false;
    }

    // if (context.data.acceptors?.some(a => a.status !== AcceptorStatus.PENDING)) {
    //   return false;
    // }

    return true;
  }, [context.data]);

  const isRemoveShow = useMemo(() => {
    if (!context.data.isAssign) {
      return false;
    }

    if (context.data.status !== Status.DRAFT
      && context.data.status !== Status.REJECT) {
      return false;
    }

    // if (context.data.acceptors?.some(a => a.status !== AcceptorStatus.PENDING)) {
    //   return false;
    // }

    return true;
  }, [context.data]);

  const isSaveShow = useMemo(() => {
    if (!context.data.isAssign) {
      return false;
    }

    if (context.data.status !== Status.DRAFT
      && context.data.status !== Status.REJECT) {
      return false;
    }

    // if (context.data.acceptors?.some(a => a.status !== AcceptorStatus.PENDING)) {
    //   return false;
    // }

    return true;
  }, [context.data]);

  const isSendApproveShow = useMemo(() => {
    if (!context.data.isAssign) {
      return false;
    }

    if (context.data.status !== Status.DRAFT
      && context.data.status !== Status.REJECT) {
      return false;
    }

    return true;
  }, [context.data]);

  const isApproveOrRejectShow = useMemo(() => {
    if (!context.data.isQueue) {
      return false;
    }

    if (context.data.status !== Status.WAITING_ACCEPT) {
      return false;
    }

    return true;
  }, [context.data]);

  const isRecallShow = useMemo(() => {
    if (!context.data.isAssign) {
      return false;
    }

    if (context.data.status !== Status.WAITING_ACCEPT) {
      return false;
    }

    if (context.data.acceptors?.some(a => a.status !== AcceptorStatus.PENDING)) {
      return false;
    }

    return true;
  }, [context.data]);

  return (
    <>
      <Acceptors
        titleHistory='ประวัติการใช้งานจัดทำรายงานขอซื้อขอจ้าง (จพ.005)'
        data={acceptor!}
        visible={isAddUserShow}
        isDepartmentDirector={false}
        processType={PROCESSTYPE.ApprovePrerequisiteProcess}
        budgetAmount={context.data.financialAmount}
        onSetSectionType={(type) => setSectionType(type)}
        onAcceptorModalShow={() => setShowModals(!showModals)}
        onSetSectionSequence={(section) => setSectionSequence(section)}
        onAcceptorDataChange={(data) => setAcceptor(data)}
        status={context.data.status}
        typeStatus={TypeStatus.JorPor05}
        activity={context.data.activities}
      />

      {/* <Card>
        <Title
          text='ผู้มีอำนาจเห็นชอบ'
          className='fs-5 text-primary'
          extraElement={(
            <div className='d-flex gap-2'>
              <JorPor05Status value={context.data.status} />
              <Button
                onClick={() => setShowHistoryModal(true)}
                variant='outline-primary'
                className='d-flex justify-content-center align-items-center gap-2'>
                <FaHistory />ประวัติการใช้งานผู้มีอำนาจเห็นชอบ
              </Button>
            </div>
          )} />

        <div className='d-flex flex-column gap-3 mt-3'>
          <div className='d-flex flex-column gap-3'>
            {
              sections.length > 0 ? (
                sections
                  .sort((a, b) => a.sectionSequence - b.sectionSequence)
                  .map((section, index) => (
                    <Col sm={12}
                      className='ps-5'
                      key={section.sectionId}>
                      <div className='d-flex justify-content-between mb-1'>
                        <p className='fs-5 text-primary'>{section.sectionName}</p>
                        {isAddUserShow
                          ? (
                            <Button
                              variant='outline-primary'
                              onClick={() => onOpenModal(index)}
                            >
                              <FaPlus className='me-1' /> เพิ่มรายชื่อ
                            </Button>
                          ) : null}
                      </div>
                      <Table total={1}
                        hidePagination>
                        <thead>
                          <tr>
                            <th style={{ width: 100 }}>ลำดับ</th>
                            <th style={{ width: 200 }}>ชื่อ-นามสกุล</th>
                            <th style={{ width: 200 }}>ฝ่าย/สำนัก</th>
                            <th style={{ width: 200 }}>ตำแหน่ง</th>
                            <th style={{ width: 200 }}>สถานะ</th>
                            <th style={{ width: 200 }}>วันที่เห็นชอบ</th>
                            <th style={{ width: 200 }}>ไม่สามารถปฏิบัติงานได้</th>
                            <th style={{ width: 200 }}>หมายเหตุ</th>
                            <th style={{ width: 25 }} />
                          </tr>
                        </thead>
                        <tbody>
                          {context.data.acceptors
                            .filter((x) => x.sectionSequence === section.sectionSequence)
                            .sort((a, b) => a.sequence - b.sequence)
                            .map((data, index) => (
                              <tr className='text-center'
                                key={`${data.sectionSequence}${data.sequence}`}>
                                <td>{index + 1}</td>
                                <td className='text-start'>{data.fullName}</td>
                                <td className='text-start'>{data.departmentName}</td>
                                <td className='text-start'>{data.positionName}</td>
                                <td className='text-center'>
                                  {isSectionSequence === data.sectionSequence && isApproverStatus === index + 1
                                    ? <Approver value={data.status} /> : <ApproverStatus value={data.status} />}
                                </td>
                                <td>{data.status === AcceptorStatus.PENDING ? '' : thaiFormatDateWithSlash(data.updatedAt)}
                                </td>
                                {data.id
                                  ? (
                                    <td>
                                      <div className='d-flex justify-content-center'>
                                        <Check
                                          value={data.isAvailable}
                                          onChange={val => onChange(data.id, 'isAvailable', val)}
                                          disabled={isDisableForm || data.status !== AcceptorStatus.PENDING} />
                                      </div>
                                    </td>
                                  )
                                  : <td />}
                                {data.id
                                  ? (
                                    <td>
                                      <Input
                                        value={data.availableRemark}
                                        onChange={val => onChange(data.id, 'availableRemark', val)}
                                        disabled={isDisableForm || !data.isAvailable} />
                                    </td>
                                  )
                                  : <td />}
                                <td>
                                  <div className='d-flex justify-content-center align-items-center'>
                                    {isRemoveShow
                                      ? (
                                        <div className='d-flex justify-content-center align-items-center'>
                                          <Button
                                            onClick={() => removeApprover(`${data.sectionSequence}${data.sequence}`)}
                                            variant='danger'
                                          >
                                            <FaTrashAlt />
                                          </Button>
                                        </div>
                                      ) : null}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <SearchBuNameModal
                        total={userTotal}
                        show={showModals[index]}
                        onHide={() => onHideModal(index)}
                        onSelect={(id, name, position, department) => onSelectEmpApprover(id, name, position!, department!, index, section.sectionSequence, section.inRefCode, section.sectionCode)}
                        data={users}
                        onSearch={onSearchUserAsync}
                        onPageChange={onPageChangeAsync}
                        departmentCode={departmentCode}
                        departmentDisabled={departmentCode !== JorPorCode.CODE}
                      />
                    </Col>
                  ))
              ) : (
                <Col>
                  <span className='text-primary'>ไม่พบลำดับเห็นชอบ/อนุมัติ</span>
                </Col>
              )
            }
          </div>
        </div>
      </Card> */}
      <Card className='mt-3'>
        <Title
          text='หนังสือขออนุมัติ'
          className='fs-5 text-primary' />
        <div className='mt-3'>
          <Card>
            <Collabora
              docId={docId}
              docName='doc-01'
              ref={collaboraRef}
              readonly={isDisableForm} />
          </Card>
        </div>
      </Card>
      {
        ((context.data.supplyMethod === SupplyMethodEnum.MethodId80 && context.data.financialAmount > 500000) ||
          (context.data.supplyMethod === SupplyMethodEnum.MethodId60 && context.data.financialAmount > 100000)) &&
        <Card className='mt-3'>
          <Title
            text='คำสั่งแต่งตั้ง'
            className='fs-5 text-primary' />

          <div className='mt-3'>
            <Card>
              <Collabora
                docId={docOrderId}
                docName='doc-02'
                ref={collaboraRefOrder}
                readonly={isDisableForm} />
            </Card>
          </div>
        </Card>
      }
      {/* UploadFile */}
      <Card className='mt-3'>
        <Title text='เอกสารแนบ'
          className='fs-5 text-primary' />
        <Row className='justify-content-center'>
          <Col sm={12}
            lg={6}>

            <UploadFile
              disabled={isDisableForm}
              onChange={uploadFileOnChangeAsync}
              value={files}
              onRemove={removeFileAsync}
              onDownload={downloadFileAsync} />

          </Col>
        </Row>
      </Card>

      {/* Button */}
      <div className='d-flex justify-content-between mt-3'>
        <Button
          className='me-2 px-3'
          onClick={onClickBack}
          variant='outline-primary'>
          <MdArrowBack className='me-2 pb-1 fs-5' />
          ย้อนกลับ
        </Button>
        <div className='d-flex gap-2'>
          {isRecallShow
            ? (
              <Button
                onClick={() => setShowReCall(true)}
                className='d-flex justify-content-center align-items-center gap-2'
                variant='danger'>
                <FaUndo />เรียกคืนแก้ไข
              </Button>
            ) : null}
          {isSaveShow
            ? (
              <Button
                className='d-flex align-items-center gap-2'
                variant='success'
                onClick={() => onSubmitAsync(Status.DRAFT)}>
                <FaSave />
                บันทึก
              </Button>
            ) : null}
          {isSendApproveShow &&
            <Button
              className='d-flex align-items-center gap-2'
              variant='primary'
              // onClick={() => onSubmitAsync(Status.WAITING_ACCEPT)}
              onClick={() => setShowModalPending(true)}>
              <FaRegCheckCircle />
              ส่งอนุมัติเห็นชอบ
            </Button>
          }
          {context.data.status === Status.WAITING_ACCEPT && isQueueApprover
            ? (
              <>
                <Button
                  className='d-flex align-items-center gap-2'
                  variant='danger'
                  onClick={() => showActionModal(AcceptorStatus.REJECT)}>
                  <FaUndo />
                  ส่งกลับแก้ไข
                </Button>
                <Button
                  className='d-flex align-items-center gap-2'
                  variant='success'
                  onClick={() => showActionModal(AcceptorStatus.APPROVE)}>
                  <FaCheckCircle />
                  {isApprover ? 'อนุมัติ' : 'เห็นชอบ'}
                </Button>
              </>
            ) : null}
        </div>
        {/* <Button
          onClick={onClickNext}
          className='me-2 px-3'
          variant='outline-primary'>
          ถัดไป
          <MdArrowForward className='mห-2 pb-1 fs-5' />
        </Button> */}
        <div style={{ width: 100 }} />
      </div>

      {/* Modal */}
      <HistoryModal
        title='ประวัติการใช้งานจัดทำรายงานขอซื้อขอจ้าง (จพ.005)'
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(!showHistoryModal)}
        data={context.data.activities} />
      <SearchNameModal
        show={showSearchUserModal.show}
        section={showSearchUserModal.section}
        onHide={onModalHide}
        data={userDataModal.data || []}
        total={userDataModal.totalRecords || 0}
        onSearch={onModalSearch}
        onPageChange={onModalPageChange}
        onSelectItem={(id, section) => onModalSelect(id, section)} />
      <ActionModal
        data={modalData}
        onApprove={() => { }}
        onHide={() => setModalData({ accecptorId: context.data.acceptorId, show: false })} />

      <SearchBuNameModal
        total={userTotal}
        show={showModals}
        onHide={() => setShowModals(!showModals)}
        onSelect={(id, name, department, position, delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName) => onSelectEmpApprover(id, name, department!, position!, acceptor.length + 1, sectionSequence, sectionType, '', delegateUserId, delegateFullName, delegateDepartmentName, delegatePositionName)}
        data={users}
        onSearch={onSearchUserAsync}
        onPageChange={onPageChangeAsync}
        departmentCode={departmentCode}
        departmentDisabled={departmentCode !== JorPorCode.CODE}
      />
      <Modal
        show={showModalPending}
        size='lg'
        onHide={() => setShowModalPending(!showModalPending)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              ต้องการส่งเห็นชอบ/อนุมัติการจัดทำรายงานขอซื้อขอจ้าง ( จพ.005 ) หรือไม่?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowModalPending(!showModalPending)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                // onClick={() => onSendApprove()}
                onClick={() => onSubmitAsync(Status.WAITING_ACCEPT)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
      <Modal
        show={showReCall}
        size='lg'
        onHide={() => setShowReCall(!showReCall)}
        children={(
          <>
            <ModalBT.Header closeButton>
              <ModalBT.Title>ยืนยัน</ModalBT.Title>
            </ModalBT.Header>
            <ModalBT.Body className='p-0 ps-4'>
              คุณต้องการเรียกคืนแก้ไขหรือไม่ ?
            </ModalBT.Body>
            <ModalBT.Footer>
              <Button variant='outline-primary'
                onClick={() => setShowReCall(!showReCall)}>
                ยกเลิก
              </Button>
              <Button
                variant='primary'
                onClick={() => onSubmitAsync(Status.DRAFT, true)}
              >
                ยืนยัน
              </Button>
            </ModalBT.Footer>
          </>
        )}
      />
    </>
  );
}
