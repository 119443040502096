import { Card, Input, Radio, Selector, TorStatus } from 'components';
import { InputNumber } from 'components/Controls/InputNumber';
import Title from 'components/Controls/Title';
import { ItemModel } from 'models';
import { PaymentModel } from 'models/CM/CM02Models';
import { TorDefectWarrantyModel } from 'models/PP/TorModels';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Collapse,
  Row,
} from 'react-bootstrap';
import { BsArrowDownCircle } from 'react-icons/bs';
import { FaChevronCircleDown, FaChevronCircleUp, FaHistory } from 'react-icons/fa';
import { Context } from '../../step';

const HasWarranty = [
  {
    label: 'มีการรับประกัน',
    value: 'true',
  },
  {
    label: 'ไม่มีการรับประกัน',
    value: 'false',
  },
];

interface IDefectsWarrantyProps {
  torDefectWarranty: TorDefectWarrantyModel;
  onTorDefectWarrantyChange: (value: TorDefectWarrantyModel) => void;
  periodUnitDDL: ItemModel[];
  defectsWarrantyCountUnitDDL: ItemModel[];
  isDisabled?: boolean;
}

export function DefectsWarranty(props: IDefectsWarrantyProps) {
  const { readonly } = useContext(Context);
  const [firstLoad, setFirstLoad] = useState(true);
  const [torDefectWarranty, setTorDefectWarranty] = useState<TorDefectWarrantyModel>(props.torDefectWarranty);
  const [isDisabledTorDefectWarranty, setIsDisabledTorDefectWarranty] = useState(props?.torDefectWarranty?.hasWarranty ?? true);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  useEffect(() => {
    props.onTorDefectWarrantyChange(torDefectWarranty);
  }, [torDefectWarranty]);

  useEffect(() => {
    if (props.torDefectWarranty && firstLoad) {
      setTorDefectWarranty(props.torDefectWarranty);
      setIsDisabledTorDefectWarranty(!props.torDefectWarranty.hasWarranty);
      setFirstLoad(false);
    }
  }, [props.torDefectWarranty, firstLoad]);

  const onTextChange = useCallback((key: string, value: string) => {
    setTorDefectWarranty(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onNumberChange = useCallback((key: string, value: number) => {
    setTorDefectWarranty(prevState => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onChangeDefectWarrantyActive = useCallback((val: string) => {
    setTorDefectWarranty(prevState => ({
      ...prevState,
      hasWarranty: val === 'true',
    }));

    if (val === 'false') {
      setIsDisabledTorDefectWarranty(true);
      const defectsWarranty = {
        hasWarranty: false,
        count: undefined,
        unit: undefined,
      } as TorDefectWarrantyModel;

      setTorDefectWarranty(prevState => ({
        ...prevState,
        defectsWarranty,
      }));
    } else {
      setIsDisabledTorDefectWarranty(false);
    }
  }, []);

  return (
    <Card className='mt-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          <Title
            text='การรับประกันความชำรุดบกพร่อง และการบำรุงรักษา'
            className='fs-5 text-primary'
          />
          {
            collapsed
              ? (
                <FaChevronCircleDown
                  className='text-primary fs-5 cursor-pointer'
                  onClick={() => setCollapsed(false)}
                />
              ) : (
                <FaChevronCircleUp
                  className='text-primary fs-5 cursor-pointer'
                  onClick={() => setCollapsed(true)}
                />
              )
          }
        </div>
      </div>
      <Collapse in={!collapsed} className='ps-40'>
        <div>
          <div className='d-flex align-items-center justify-content-between'>
            <p className='mb-0'>ผู้ได้รับการคัดเลือก(ผู้ขาย/ผู้รับจ้าง/ผู้ให้เช่า) ต้องยอมรับประกันความชำรุดบกพร่อง หรือขัดข้องของพัสดุ/อุปกรณ์ที่(ซื้อขาย/จ้าง/เช่า) ที่ติดตั้งให้กับธนาคาร</p>
          </div>
          <Row className='mt-3 align-items-center'>
            <Col sm={12}>
              <Radio
                dflex
                items={HasWarranty}
                value={torDefectWarranty ? torDefectWarranty.hasWarranty.toString() : 'false'}
                onChange={(val) => onChangeDefectWarrantyActive(val?.toString())}
                rule={{ required: true }}
                disabled={props.isDisabled || readonly}
                name='name1'
              />
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col sm={12} lg={3}>
              <InputNumber
                label='เป็นระยะเวลา'
                value={torDefectWarranty ? torDefectWarranty.count : undefined}
                onChange={(value) => onNumberChange('count', Number(value))}
                rule={{ required: !isDisabledTorDefectWarranty }}
                disabled={props.isDisabled || isDisabledTorDefectWarranty || readonly} />
            </Col>
            <Col sm={12} lg={2} className='no-label'>
              <Selector
                items={props.periodUnitDDL}
                value={torDefectWarranty ? torDefectWarranty.unit : undefined}
                rule={{ required: !isDisabledTorDefectWarranty }}
                onChange={(value) => onTextChange('unit', value)}
                disabled={props.isDisabled || isDisabledTorDefectWarranty || readonly}
              />
            </Col>
            <Col sm={12} lg={3} className='no-label'>
              <Selector
                items={props.defectsWarrantyCountUnitDDL}
                value={torDefectWarranty ? torDefectWarranty.countUnit : undefined}
                rule={{ required: !isDisabledTorDefectWarranty }}
                onChange={(value) => onTextChange('countUnit', value)}
                disabled={props.isDisabled || isDisabledTorDefectWarranty || readonly}
              />
            </Col>
          </Row>
        </div>
      </Collapse>
    </Card>
  );
}
