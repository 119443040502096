import { isDisabled } from '@testing-library/user-event/dist/utils';
import { Card, Currency, Input, InputTextArea, Table } from 'components';
import Title from 'components/Controls/Title';
import { MedianPriceConsultantTypeQualifyModel, MedianPriceStaffModel } from 'models/PP/MedianPriceModel';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Row,
} from 'react-bootstrap';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';

interface IMedianPriceConsultantQualificationProps {
  medianPriceId: string;
  medianPriceConsultantQualifications: MedianPriceConsultantTypeQualifyModel[];
  onMedianPriceConsultantQualifications: (value: MedianPriceConsultantTypeQualifyModel[]) => void;
  isDisabled?: boolean;
}

export function MedianPriceConsultantQualification(props: IMedianPriceConsultantQualificationProps) {
  const [medianPriceConsultantQualifications, setMedianPriceConsultantQualifications] = useState(props.medianPriceConsultantQualifications);

  useEffect(() => {
    props.onMedianPriceConsultantQualifications(medianPriceConsultantQualifications);
  }, [medianPriceConsultantQualifications]);

  useEffect(() => {
    if (props.medianPriceConsultantQualifications) {
      setMedianPriceConsultantQualifications(props.medianPriceConsultantQualifications);
    }
  }, [props.medianPriceConsultantQualifications]);

  const addConsultant = useCallback(async () => {
    const newData: MedianPriceConsultantTypeQualifyModel = {
      id: undefined,
      medianPriceId: props.medianPriceId,
      description: '',
      sequence: medianPriceConsultantQualifications.length + 1,
    };

    setMedianPriceConsultantQualifications((medianPriceConsultantQualifications) => [...medianPriceConsultantQualifications, newData]);
  }, [medianPriceConsultantQualifications]);

  const handlerOnChangeDesc = (value: string, index: number) => {
    const data = [...medianPriceConsultantQualifications];
    data[index].description = value;
    setMedianPriceConsultantQualifications(data);
  };

  const removeConsultant = (i: number) => {
    const temp: MedianPriceConsultantTypeQualifyModel[] = [...medianPriceConsultantQualifications];
    temp.splice(i, 1);
    setMedianPriceConsultantQualifications(temp);
  };

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between'>
        <p className='mb-0'>รายการคุณสมบัติที่ปรึกษา</p>
        <Button
          variant='outline-primary'
          onClick={addConsultant}
          disabled={props.isDisabled}
        >
          <FaPlus className='me-1' />เพิ่มรายการ
        </Button>
      </div>
      <Row className='align-items-center'>
        <Col sm={12}>
          <Table total={1}
            hidePagination>
            <thead>
              <tr>
                <th style={{ width: 20 }}>ลำดับ</th>
                <th>รายการ</th>
                <th style={{ width: 20 }} />
              </tr>
            </thead>
            <tbody>
              {medianPriceConsultantQualifications?.sort((a, b) => a.sequence - b.sequence)
                .map((data, index) => (
                  <tr key={index}>
                    <td className='text-center'>{index + 1}</td>
                    <td>
                      <Input
                        value={data.description}
                        onChange={(val) => handlerOnChangeDesc(val, index)}
                        rule={{ required: true }}
                        disabled={props.isDisabled}
                      />
                    </td>
                    <td className='text-end'>
                      <Button
                        onClick={() => removeConsultant(index)}
                        variant='danger'
                        disabled={props.isDisabled}
                      >
                        <FaTrashAlt />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

