import { FileValue } from 'components';

const ENTREPRENEURTYPE = [
  {
    label: 'นิติบุคคล',
    value: 'JuristicPerson',
  },
  {
    label: 'บุคคลธรรมดา',
    value: 'Individual',
  },
];

export const getEntrepreneurType = (type: string) => {
  if (type) {
    const getType = ENTREPRENEURTYPE.filter((n) => n.value === type);

    return getType[0].label;
  }

  return '';
};

export interface JorPor06Criteria {
  jorPor05Number?: string;
  jorPor06Number?: string;
  torNumber?: string;
  departmentId?: string;
  planName?: string;
  planNumber?: string;
  companyName?: string;
  isPermission: boolean;
  supplyMethod: string[];
  status: string[];
}

export interface JorPor06Count {
  jorPor05Number?: string;
  jorPor06Number?: string;
  torNumber?: string;
  departmentId?: string;
  planName?: string;
  planNumber?: string;
  companyName?: string;
  isPermission: boolean;
}

export interface JorPor06ListsResponse {
  inviteId: string;
  id?: string;
  inviteNumber: string;
  jorPor05Number?: string;
  jorPor06Number?: string;
  status: string;
  departmentName: string;
  planName: string;
  planNumber: string;
  financialAmount: number;
  supplyMethodName: string;
  expectingProcurementAt: Date;
  torNumber: number;
  isChanging: boolean;
  isCanceling: boolean;
  isInChanging: boolean;
  isInCanceling: boolean;
  isCommitteeProcurement: boolean;
  isAssign: boolean;
}

export interface JorPor06Detail {
  id: string;
  inviteId: string;
  inviteNumber: string;
  jorPor06Number: string;
  jorPor05Number: string;
  termOfRefNumber: string;
  departmentId: string;
  ignorePurchaseOnTable159?: boolean;
  budgetYear: number;
  supplyMethod: string;
  supplyMethodSpecialType: string;
  supplyMethodType: string;
  financialAmount: number;
  medianPriceSourcesAmount: number;
  isTorMA: boolean;
  expectingProcurementAt: Date;
  memorandumDate: Date;
  memorandumNo: string;
  planName: string;
  planNumber: string;
  titleDocument: string;
  sourceAndReasons?: string;
  jorPorComment?: string;
  status: string;
  activities: Jorpor06Activity[];
  jorPorDirectors: Jorpor06User[];
  responsibilities: Jorpor06User[];
  acceptors: Jorpor06User[];
  documentList?: FileValue[];
  documents?: File[];
  entrepreneurs: Entrepreneurs[];
  jorPorSupervisorUser?: Jorpor06User[];
  jorPorAssistantPricipleUser?: Jorpor06User[];
  jorPorPrincipleUser?: Jorpor06User[];
  assistantMdUser?: Jorpor06User[];
  deputyMdUser?: Jorpor06User[];
  mdUser?: Jorpor06User[];
  isAcceptor: boolean;
  isQueue: boolean;
  isCreator: boolean;
  acceptorId?: string;
  committees: CommitteeApproversModel[];
  confirmPriceSum: number;
  isCanceling?: boolean;
  cancelRemark?: string;
  isChanging?: boolean;
  changeRemar?: string;
  isChanged?: boolean;
  refId?: string;
}

export interface Jorpor06User {
  id?: string;
  userId: string;
  fullName: string;
  departmentName: string;
  positionName: string;
  status?: string;
  updatedAt?: Date;
  jorPor06Id?: string;
  sectionCode?: string;
  isAvailable?: boolean;
  availableRemark?: string;
  sequence: number;
  remark?: string;
  inRefCode: string;
  processType: string;
  sectionLevel?: number;
  sectionName: string;
  sectionId: string;
  sectionSequence: number;
  sectionType?: string;
  delegateUserId?: string;
  delegateEmployeeCode?: string;
  delegateFullName?: string;
  delegateDepartmentName?: string;
  delegatePositionName?: string;
  delegateRangeDate?: string;
}

interface File {
  id: string;
  fileName: string;
}

export interface Jorpor06Activity {
  id: string;
  date: Date;
  userId: string;
  fullName: string;
  description: string;
  action: string;
  remark: string;
}

export interface Entrepreneurs {
  inviteEntrepreneursId: string;
  jorPor06Id: string;
  nationality: string;
  type: string;
  entrepreneurType: string;
  taxPayerIdentificationNo: string;
  establishmentName: string;
  tel: string;
  email: string;
  coi: ResultRemark;
  watchList: ResultRemark;
  eGp: ResultRemark;
  consideration: Consideration;
  parcels: Parcels[];
  maintenances: MaintenancesModel[];
}

export interface ResultRemark {
  result: boolean;
  remark: string;
  webLink?: string;
}

export interface Consideration {
  id: string;
  isWinner: boolean;
  winnerReason: string;
  remark: string;
}

export interface Parcels {
  id?: string;
  vatTypeId: string;
  parcelId?: string;
  torUnit?: string;
  torQuantity: number;
  offerParcel: string;
  offerPriceQuantity: number;
  offerPriceUnitId: string;
  offerPricePerUnit: number;
  offerPriceTotal: number;
  offerPriceTotalVat: number;
  confirmPriceUnit: number;
  confirmPriceTotal: number;
  confirmPriceTotalVat: number;
  description: string;
  sequence: number;
}

export interface MaintenancesModel {
  id?: string;
  vatTypeId: string;
  torPaymentTermPeriodId: string;
  torQuantity: number;
  offerParcel: string;
  offerPriceQuantity: number;
  offerPeriodType: string;
  offerPricePerUnit: number;
  offerPriceTotal: number;
  offerPriceTotalVat: number;
  confirmPriceUnit: number;
  confirmPriceTotal: number;
  confirmPriceTotalVat: number;
  description: string;
  sequence: number;
}

export interface CoiAndWatchListAndEgp {
  jorPor06Id: string;
  result?: boolean;
  remark: string;
  type: string;
}

export interface ParcelsUpdate {
  jorPor06Id: string;
  parcels: Parcels[];
  maintenances: MaintenancesModel[];
}

export interface ConsiderationUpdate {
  jorPor06id: string;
  isWinner: boolean;
  remark: string;
  winnerReason: string;
}

export interface UpdateJorPor06 {
  titleDocument: string;
  acceptors: Jorpor06User[];
  status: string;
  documentId?: string;
  documentWinnerId?: string;
  documentCancelingId?: string;
  Committees?: CommitteeApproversModel[];
  jorPorComment?: string;
  isJorPorAssig?: boolean;
  isPending?: boolean;
}

export interface EntrepreneursModel {
  entrepreneurWinners: entrepreneursWinneModel[];
}

export interface entrepreneursWinneModel {
  id: string;
  email: string;
  entrepreneurType: string;
  establishmentName: string;
  inviteEntrepreneursId: string;
  jorPor06Id: string;
  nationality: string;
  taxPayerIdentificationNo: string;
  tel: string;
  type: string;
  parcels: parcels[];
}

export interface parcels {
  id: string;
  confirmPriceTotal: number;
  confirmPriceUnit: string;
  description: string;
  offerPricePerUnit: number;
  offerPriceTotal: number;
  price: number;
  quantity: number;
  unit: string;
  vatType: string;
}

export interface CommitteeApproversModel {
  id: string;
  termOfRefId: string;
  approverUserId: string;
  sequence: number;
  approverFullName: string;
  position: string;
  approverPositionOnBoardId: string;
  approverPositionOnBoardName: string;
  approverStatus: string;
  approvedDateAt: Date;
  isDisabled: boolean;
  remark: string;
  createdInfo: string;
  updatedAt: string;
  departmentName: string;
  isChairman: boolean;
}